import { useState, useEffect, useRef } from 'react'

// Functions
import { useDispatch, useSelector } from 'react-redux'
import { get_empty_avatar } from '../../../utils/functions'

//component

//style
import './FormSelectUserAndGroup.scss'

//assets
import remove from '../../img/x-blue.svg'
import folder from '../../img/folder.svg'
import { reqAllGroupList, reqAllUserList } from '../../../reducers/UserAndGroupReducers'


function FormSelectUserAndGroup(props) {  
    const { onSelectedUsersAndGroupsChange } = props;

    const dispatch = useDispatch();

    const userList = useSelector(state => state.UserAndGroupReducers.userList)
    const groupList = useSelector(state => state.UserAndGroupReducers.groupList)

    const [tags, setTags] = useState([]);
    const [searchTerm, setSearchTerm] = useState(""); 

    const inputSearchParticipantRef = useRef();

    useEffect(() => {
        // console.log("componentDidMount")

        // req user & group list
        dispatch(reqAllUserList())
        dispatch(reqAllGroupList())

        // inputSearchParticipantRef.current.focus();

        return () => {
            // console.log("componentWillUnmount")
        }
    }, []);

    useEffect(() => {
        onSelectedUsersAndGroupsChange(tags)
    }, [tags]);
    
    const inputKeyDown = (e) => {
        const val = e.target.value;
        
        if (e.key === 'Backspace' && !val) {
            removeTag(tags.length - 1);
        }
        // console.log(tags)
    }  

    // invite guests
    const removeTag = (i) => {
        const newTags = [...tags];
        newTags.splice(i, 1);
        setTags(newTags);
    }
    
    const selectPeople = (people) => {
        setTags([...tags, {
            id: people.id,
            name: people.name,
            type: "user"
        }]);

        setSearchTerm("")

        inputSearchParticipantRef.current.focus();
    }

    const selectTeam = (team) => {
        setTags([...tags, {
            id: team.id,
            name: team.name,
            type: "group"
        }]);

        setSearchTerm("")
        
        inputSearchParticipantRef.current.focus();
    }

    const handleChange = event => {
        setSearchTerm(event.target.value);
    };

    const onCreateNewParticipantClick = () => {
        const name = searchTerm;
        selectPeople({
            id: null,
            name: name,
            type: "user"
        })
    }
     

    // data for suggestion
    const people_list_filtered = !searchTerm
    ? []
    : userList.filter(({name}) =>
        name.toLowerCase().includes(searchTerm.toLocaleLowerCase())
    );
    
    const team_list_filtered = !searchTerm
    ? []
    : groupList.filter(({name}) =>
        name.toLowerCase().includes(searchTerm.toLocaleLowerCase())
    );

    return (
        <div className="input-tag">
            <ul className="input-tag__tags">
                {tags.map((tag, i) => (
                    <li key={"tag-"+tag.id+"-"+tag.name} className="tag">
                        {tag.name}
                        <button type="button" onClick={() => { removeTag(i); }}><img src={remove} alt="remove" /></button>
                    </li>
                ))}
                <li className="input-tag__tags__input">
                    <input 
                        ref={inputSearchParticipantRef}
                        alt="input-search-participant"
                        type="text"
                        placeholder="Input Partisipan name (optional)" 
                        onKeyDown={inputKeyDown} 
                        onChange={handleChange}
                        value={searchTerm}
                    />
                    { people_list_filtered.length === 0 && team_list_filtered.length === 0 ? 
                        ( searchTerm.trim() !== "" &&
                            tags.find(({name}) => name === searchTerm) === undefined &&
                            <div data-testid="participant-not-found" className="add-input-group-wrapper">
                                <div className="account-not-found-wrapper">
                                    <div className="oval">?</div>
                                    <div className="text">Account Not Found</div>
                                    <button className="secondary" onClick={onCreateNewParticipantClick}>Create new partisipant</button>
                                </div>
                            </div>
                        ) 
                        :
                        <div data-testid="participant-suggestion" className="add-input-group-wrapper">
                            {team_list_filtered.map(item => {
                                const image = ( item.image === undefined || item.image === null || item.image.replace(/\s+/g, '') === "" ? 
                                folder : item.image )

                                return (
                                    tags.find(({id, type}) => id === item.id && type === "group") === undefined &&
                                    <div key={"people-"+item.id} 
                                        className="item-s"
                                        onClick={() => selectTeam(item)}
                                    >
                                        <img src={image} id="icon" alt="icon-suggested-group" />
                                        <h5>{item.name}
                                            <span>Group</span>
                                        </h5>                    
                                    </div>
                                )
                            })}
                            {people_list_filtered.map(item => {
                                const image = ( item.image === undefined || item.image === null || item.image.replace(/\s+/g, '') === "" ? 
                                get_empty_avatar() : item.image )

                                return (
                                    tags.find(({id, type}) => id === item.id && type === "user") === undefined &&
                                    <div key={"team-"+item.id} 
                                        className="item-s"
                                        onClick={() => selectPeople(item)}
                                    >
                                        <img src={image} id="icon" alt="icon-suggested-user" />
                                        <h5>{item.name}
                                            <span>Participant</span>
                                        </h5>                    
                                    </div>
                                )
                            })}
                        </div>
                    }
                </li>
            </ul>
        </div>
    )
}

export default FormSelectUserAndGroup;