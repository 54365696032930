import { useState, useRef, useEffect } from 'react'
import { useDispatch } from 'react-redux';

//functions
import { fetchRecordUpdate } from '../../../../reducers/FetcherReducers';
import { reqRecordDetails } from '../../../../reducers/RecordReducers';

//components
import { Form, Modal } from 'react-bootstrap'

//styles
import '../Sidebar.scss'

//assets
// import x from '../../../img/x-close.svg'
import x from '../../../img/x-cancel.svg'
import ButtonPrimaryLoading from '../../Buttons/ButtonPrimaryLoading';


function ModalEditRecordTitle(props) {
    const { show, recordData, setNotifAlertData} = props;
    const { id_record, name } = recordData;

    const dispatch = useDispatch();

    const firstInputRef = useRef();

    const [loading, setLoading] = useState(false);

    const [title, setTitle] = useState("");

    useEffect(() => {
        if ( show ) {
            setTitle(name)
            firstInputRef.current.focus();
        }
    }, [show]);

    useEffect(() => {
        setNotifAlertData(null)
    }, [title]);

    const onSaveClick = () => {
        // check name
        if ( title.replace(/\s+/g, '') === "") {
            setNotifAlertData({
                status: "error",
                text: "please fill all column",
                timeout: -1
            })
            return
        }
        
        setLoading(true)

        var data = JSON.parse(JSON.stringify(recordData));
        data.id = data.id_record;
        data.name = title;

        dispatch(fetchRecordUpdate(data))
            .then((res) => {
                setLoading(false)
                
                props.onHide()

                // notif when success edit record title
                setNotifAlertData({
                    status: "success",
                    text: "Record title changed",
                    timeout: 3000
                })

                dispatch(reqRecordDetails(id_record));
            })
            .catch((err) => {
                var error = err.error.toString();
                
                setLoading(false)

                setNotifAlertData({
                    status: "error",
                    text: "Failed edit record title. "+error,
                    timeout: 3000
                })
            })
    }

    const allInputDisabled = ( loading ? true : false );

    return (
        <Modal
            {...props}
            size="md"
            centered
            id="modal-es"
        >
            <div id="es-container">
                <div className="es-top">
                    <h5>Edit Record Name</h5>
                    <img src={x} alt="close" onClick={() => props.onHide()} />
                </div>
                <Form className="form" 
                    onSubmit={(e) => {
                        e.preventDefault(); 
                        onSaveClick();
                    }}
                >
                    <div className="es-opt">
                        <label>Record Name</label>
                        <input 
                            ref={firstInputRef}
                            placeholder="Record Name"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)} 
                            disabled={allInputDisabled}
                        />
                    </div>
                </Form>
                { loading ?
                    <ButtonPrimaryLoading />
                    :
                    <button className="primary" onClick={onSaveClick}>Simpan</button>
                }
            </div>
        </Modal>
    )
}

export default ModalEditRecordTitle;