
/** 
Declare the type of action as constant.
WHY?
    - Help reduce typos 
    - Help reduce bugs and mistake
    - If you make typo and dispatch an undefined constants,
      the app will throw error to alert the mistake. 
*/

import { toastNotification } from "../apps/components/ToastNotification/ToastNotification";
import { fetchGroupAddToRecord, fetchParticipantAddExist, fetchParticipantAddNew, fetchParticipantList, fetchSpeakerList, fetchSpakerPairParticipant, updateFetchStatusAndData } from "./FetcherReducers";
import { reqAllUtteranceList } from "./UtteranceReducers";

// Import

// Functions

// Action Types
const UPDATE_PARTICIPANT_LIST_OFFSET	= "SpeakerAndParticipantReducers/UPDATE_PARTICIPANT_LIST_OFFSET";
const UPDATE_PARTICIPANT_LIST_AND_TOTAL	= "SpeakerAndParticipantReducers/UPDATE_PARTICIPANT_LIST_AND_TOTAL";
const RESET_PARTICIPANT_LIST_DATA  		= "SpeakerAndParticipantReducers/RESET_PARTICIPANT_LIST_DATA";
const UPDATE_SPEAKER_LIST_OFFSET		= "SpeakerAndParticipantReducers/UPDATE_SPEAKER_LIST_OFFSET";
const UPDATE_SPEAKER_LIST_AND_TOTAL  	= "SpeakerAndParticipantReducers/UPDATE_SPEAKER_LIST_AND_TOTAL";
const RESET_SPEAKER_LIST_DATA  			= "SpeakerAndParticipantReducers/RESET_SPEAKER_LIST_DATA";


export const updateParticipantListOffset = offset => ({
	type: UPDATE_PARTICIPANT_LIST_OFFSET,
    payload: {
        participantListOffset: offset
    }
});

export const updateParticipantListAndTotal = data => ({
	type: UPDATE_PARTICIPANT_LIST_AND_TOTAL,
    payload: {
        participantList: data.participantList,
        participantListTotal: data.participantListTotal
    }
});

export const resetParticipantListData = () => ({
	type: RESET_PARTICIPANT_LIST_DATA
});

export const updateSpeakerListAndTotal = data => ({
	type: UPDATE_SPEAKER_LIST_AND_TOTAL,
    payload: {
        speakerList: data.speakerList,
        speakerListTotal: data.speakerListTotal
    }
});

export const updateSpeakerListOffset = offset => ({
	type: UPDATE_SPEAKER_LIST_OFFSET,
    payload: {
        speakerListOffset: offset
    }
});

export const resetSpeakerListData = () => ({
	type: RESET_SPEAKER_LIST_DATA
});

// You can put impure functions within your action creators

// Reducers

export const rcvSpeakerListen = (data) => (dispatch, getState) => {
    const id_speaker = data.id;
    
    var speakerList       = getState().SpeakerAndParticipantReducers.speakerList.slice();
    var speakerListTotal  = getState().SpeakerAndParticipantReducers.speakerListTotal;
    
    const index = speakerList.findIndex(({id}) => id === id_speaker);
    if ( index > -1 ) {
        speakerList[index] = data;

		const speakerListAndTotal = {
            speakerList: speakerList,
            speakerListTotal: speakerListTotal
        }
        dispatch(updateSpeakerListAndTotal(speakerListAndTotal))
    }
}

export const reqAllParticipantList = (id_record, offset = 0) => (dispatch, getState) => { 
	const currentOpenedIdRecord = getState().RecordReducers.currentOpenedIdRecord;
    if ( id_record !== currentOpenedIdRecord ) return;

	dispatch(updateFetchStatusAndData("reqAllParticipantList"));

	const limit = getState().SpeakerAndParticipantReducers.participantListLimit;

	const data = {
		id_record,
		offset,
		limit
	}

	dispatch(fetchParticipantList(data))
		.then((res) => {
			dispatch(rcvAllParticipantList(res))
		})
		.catch((resErr) => {
            dispatch(rcvAllParticipantList(resErr))

		})
};

export const rcvAllParticipantList = (msg) => (dispatch, getState) => {
    const offset = getState().SpeakerAndParticipantReducers.participantListOffset;
    const limit  = getState().SpeakerAndParticipantReducers.participantListLimit;
    const total  = getState().SpeakerAndParticipantReducers.participantListTotal;

	const request   	= msg.request;
	const { id_record } = request;
    const res_offset    = request.offset;
	const res_total 	= msg.total;

	// RETURN if received participant list not belong to current opened record
	const currentOpenedIdRecord = getState().RecordReducers.currentOpenedIdRecord;
	if ( id_record !== currentOpenedIdRecord ) 
		return;

    // RETURN if not the response of last requested participant list
    if ( res_offset !== offset )
        return;
	
    // clear participant list if received total is not equal to current total 	
	if ( offset === 0 && total !== res_total ) {
		dispatch(resetParticipantListData())
	}
	
    dispatch(updateFetchStatusAndData("rcvAllParticipantList", msg));

    if ( msg.status === "failed" ) {
		toastNotification("error", "Gagal memuat daftar partisipan. "+msg.error)
        return;
    }

	var participantList = getState().SpeakerAndParticipantReducers.participantList.slice();
	msg.data.forEach((participant, index) => {
		participantList[offset+index] = participant;
	})

	const participantListAndTotal = {
		participantList: participantList,
		participantListTotal: res_total
	}
	dispatch(updateParticipantListAndTotal(participantListAndTotal));

	if ( msg.data.length === limit ) {
		dispatch(reqAllParticipantList(id_record, (offset+limit)));
	}
};

export const handlerParticipantAdd = (id_record, selectedUsersAndGroups) => async (dispatch, getState) => { 
	// users not exist
	const users_not_exist = selectedUsersAndGroups.filter(({id, type}) =>
		id === null && type === "user"
	);
	if ( users_not_exist.length > 0 ) {
		// console.log(users_not_exist)
		const data = {
			id_record: id_record,
			name: users_not_exist.map(({ name }) => name)
		}
		await dispatch(reqParticipantAddNew(data))
	}

	// users exist
	const users = selectedUsersAndGroups.filter(({id, type}) =>
		id !== null && type === "user"
	);
	if ( users.length > 0 ) {
		// console.log(users)
		const data = {
			id_record: id_record,
			id_user: users.map(({ id }) => id)
		}
		await dispatch(reqParticipantAddExist(data))
	}

	// groups
	const groups = selectedUsersAndGroups.filter(({id, type}) =>
		id !== null && type === "group"
	);
	if ( groups.length > 0 ) {
		// console.log(groups)
		const data = {
			id_record: id_record,
			id_group: groups.map(({ id }) => id)
		}
		await dispatch(reqGroupAddToRecord(data))
	}
};

export const reqParticipantAddNew = (data) => (dispatch, getState) => { 
	// console.log("reqParticipantAddNew")
    return new Promise((resolve, reject) => {
		dispatch(fetchParticipantAddNew(data))
			.then((res) => {
				dispatch(rcvParticipantAddNew(res))
                resolve(res)
			})
			.catch((resErr) => {
				dispatch(rcvParticipantAddNew(resErr))		
                reject(resErr)	
			})
	});
};

export const rcvParticipantAddNew = (msg) => (dispatch, getState) => {
    const { id_record } = msg.request;

    if ( msg.status === "failed" ) {
		toastNotification("error", "Add participants to record failed. "+msg.error)
        return;
    }

	toastNotification("success", "Participants added")

	// refresh participant list    
	dispatch(reqAllParticipantList(id_record))    
};

export const reqParticipantAddExist = (data) => (dispatch, getState) => { 
	// console.log("reqParticipantAddExist")
    return new Promise((resolve, reject) => {
		dispatch(fetchParticipantAddExist(data))
			.then((res) => {
				dispatch(rcvParticipantAddExist(res))
                resolve(res)
			})
			.catch((resErr) => {
				dispatch(rcvParticipantAddExist(resErr))	
                reject(resErr)			
			})
	});
};

export const rcvParticipantAddExist = (msg) => (dispatch, getState) => {
    const { id_record } = msg.request;

    if ( msg.status === "failed" ) {
		toastNotification("error", "Add participants to record failed. "+msg.error)
        return;
    }
	
	toastNotification("success", "Participants added")
    
	// refresh participant list    
	dispatch(reqAllParticipantList(id_record))
};

export const reqGroupAddToRecord = (data) => (dispatch, getState) => { 
	// console.log("reqGroupAddToRecord")
    return new Promise((resolve, reject) => {
		dispatch(fetchGroupAddToRecord(data))
			.then((res) => {
				dispatch(rcvGroupAddToRecord(res))
                resolve(res)
			})
			.catch((resErr) => {
				dispatch(rcvGroupAddToRecord(resErr))
                reject(resErr)	
			})
	});
};

export const rcvGroupAddToRecord = (msg) => (dispatch, getState) => {
    const { id_record } = msg.request;

    if ( msg.status === "failed" ) {
		toastNotification("error", "Add participants to record failed. "+msg.error)
        return;
    }

	toastNotification("success", "Participants added")

	// refresh participant list
	dispatch(reqAllParticipantList(id_record))
};


export const reqAllSpeakerList = (id_record, offset = 0) => (dispatch, getState) => { 
	const currentOpenedIdRecord = getState().RecordReducers.currentOpenedIdRecord;
    if ( id_record !== currentOpenedIdRecord ) return;

    dispatch(updateFetchStatusAndData("reqAllSpeakerList"));

	const limit = getState().SpeakerAndParticipantReducers.speakerListLimit;

	const data = {
		id_record,
		offset,
		limit
	}

	dispatch(fetchSpeakerList(data))
		.then((res) => {
			dispatch(rcvAllSpeakerList(res))
		})
		.catch((resErr) => {
			dispatch(rcvAllSpeakerList(resErr))
		})
};

export const rcvAllSpeakerList = (msg) => (dispatch, getState) => {
    const offset = getState().SpeakerAndParticipantReducers.speakerListOffset;
    const limit  = getState().SpeakerAndParticipantReducers.speakerListLimit;
    const total  = getState().SpeakerAndParticipantReducers.speakerListTotal;

	const request   	= msg.request;
	const { id_record } = request;
    const res_offset    = request.offset;
	const res_total 	= msg.total;

	// RETURN if received speaker list not belong to current opened record
	const currentOpenedIdRecord = getState().RecordReducers.currentOpenedIdRecord;
	if ( id_record !== currentOpenedIdRecord ) 
		return;

    // RETURN if not the response of last requested speaker list
    if ( res_offset !== offset )
        return;
		
    // clear speaker list if received total is not equal to current total 
	if ( offset === 0 && total !== res_total ) {
		dispatch(resetSpeakerListData())
	}

    dispatch(updateFetchStatusAndData("rcvAllSpeakerList", msg));

    if ( msg.status === "failed" ) {
        toastNotification("error", "Gagal memuat daftar speaker. "+msg.error)
        return;
    }

	var speakerList = getState().SpeakerAndParticipantReducers.speakerList.slice();
	msg.data.forEach((speaker, index) => {
		speakerList[offset+index] = speaker;
	})

	const speakerListAndTotal = {
		speakerList: speakerList,
		speakerListTotal: res_total
	}
	dispatch(updateSpeakerListAndTotal(speakerListAndTotal));

	if ( msg.data.length === limit ) {
		dispatch(reqAllSpeakerList(id_record, (offset+limit)));
	}
};

export const reqSpeakerPairParticipant = (id_speaker, id_participant) => (dispatch, getState) => { 
	
	const data = {
		id_speaker,
		id_participant
	}

	dispatch(fetchSpakerPairParticipant(data))
		.then((res) => {
			dispatch(rcvSpeakerPairParticipant(res))
		})
		.catch((resErr) => {
			dispatch(rcvSpeakerPairParticipant(resErr))
		})
};

export const rcvSpeakerPairParticipant = (msg) => (dispatch, getState) => {

    if ( msg.status === "failed" ) {
		toastNotification("error", "Pair participant with speaker failed. "+msg.error)
        return;
    }

	const request   = msg.request;
	const { id_speaker, id_participant } = request;

	var speakerList = getState().SpeakerAndParticipantReducers.speakerList.slice();
	const speakerListTotal = getState().SpeakerAndParticipantReducers.speakerListTotal;

	const index = speakerList.findIndex(({id}) => id === id_speaker);
	if ( index > -1 ) {
		speakerList[index].id_participant = id_participant;
	}

	const speakerListAndTotal = {
		speakerList: speakerList,
		speakerListTotal: speakerListTotal
	}
	dispatch(updateSpeakerListAndTotal(speakerListAndTotal));

    const id_record = getState().RecordReducers.currentOpenedIdRecord;
	if ( id_record !== null ) {
		dispatch(reqAllSpeakerList(id_record));
		dispatch(reqAllUtteranceList(id_record));
	}
};

// Reducer's initial state
const initialState = {
	participantList         : [],
	participantListOffset	: 0,
	participantListLimit    : 1000,
	participantListTotal    : 0,
	
	speakerList         : [],
	speakerListOffset	: 0,
	speakerListLimit    : 1000,
	speakerListTotal    : 0,
};


// You must only write pure function when trying to build the reducer! 

export default function SpeakerAndParticipantReducers(state = initialState, action) {
	switch (action.type) {
		case UPDATE_PARTICIPANT_LIST_AND_TOTAL:
			return {
				...state,
				participantList: action.payload.participantList,
				participantListTotal: action.payload.participantListTotal
			};
		case RESET_PARTICIPANT_LIST_DATA:
			return {
				...state,
				participantList: [],
				participantListOffset: 0,
				participantListTotal: 0
			};
		case UPDATE_SPEAKER_LIST_AND_TOTAL:
			return {
				...state,
				speakerList: action.payload.speakerList,
				speakerListTotal: action.payload.speakerListTotal
			};
		case RESET_SPEAKER_LIST_DATA:
			return {
				...state,
				speakerList: [],
				speakerListOffset: 0,
				speakerListTotal: 0
			};
		default:
			return state;
  }
}



// Side effects, only as applicable
// e.g. thunks,epics, etc