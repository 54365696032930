import $ from 'jquery'
import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'

//functions
import { reqAllRecordList, updateIsUploading, updateUploadingError, updateListFileUpload, updateUploadingProgress } from '../../../reducers/RecordReducers'
import { reqAllModelAsrList } from '../../../reducers/ModelAsrReducers'
import { fetchConsoleGetDikteinQuota, fetchRecordAdd } from '../../../reducers/FetcherReducers'
import { toastNotification } from '../ToastNotification/ToastNotification'
import { handlerParticipantAdd } from '../../../reducers/SpeakerAndParticipantReducers'

//component
import styled from 'styled-components'
import { Form, Modal, Spinner } from 'react-bootstrap'
import NotifAlert from '../NotifAlert/NotifAlert'
import DropFile from '../DropFile/DropFile'
import FormSelectUserAndGroup from '../FormSelectUserAndGroup/FormSelectUserAndGroup'

//style
import './CreateRecord.scss'

//assets
// import x from '../../img/x-close.svg'
import x from '../../img/x-cancel.svg'
import BKSwitch from '../BKSwitch/BKSwitch'
import DropdownLanguageList from './DropdownLanguageList'


const Button = styled.button`
    min-height: 50px;
    min-width: 110px;
    border-radius: 3px;
    font-weight: 700;
    padding: 13px 24px;
    border-radius: 8px;
    margin-left: auto;
    margin-top: auto;
`;

function ModalCreateRecord(props) {
    const dispatch = useDispatch();
    
    const [notifAlertData, setNotifAlertData] = useState(null);

    const [loading, setLoading] = useState(false);

    const [recordName, setRecordName] = useState("");
    const [attachment, setAttachment] = useState([]);
    const [attachmentDuration, setAttachmentDuration] = useState([]);
    const [selectedLanguage, setSelectedLanguage] = useState("");
    const [translation, setTranslation] = useState(false);
    const [selectedUsersAndGroups, setSelectedUsersAndGroups] = useState([]);
    const [autoTranscribe, setAutoTranscribe] = useState(true);

    useEffect(() => {
        if ( !props.show ) {
            resetStates()
        } else {
            // dispatch(reqAllModelAsrList())
        }
    }, [props.show]);
    
    useEffect(() => {
        async function fetchData() {
            // You can await here

            setAttachmentDuration([]);

            if ( attachment.length === 0 ) return;
            
            let new_attachment_duration = [];

            for (const attach of attachment) {
                const file = attach.file;
                await getDuration(file)
                    .then((duration) => {
                        // console.log(file.name)
                        // console.log(duration)
                        new_attachment_duration.push(duration);
                    })
            }
            // console.log(new_attachment_duration)
            // console.log(new_attachment_duration.reduce((a, b) => a + b))
            setAttachmentDuration(new_attachment_duration)
        }
        fetchData();
    }, [attachment]);

    const resetStates = () => {
        setNotifAlertData(null)
        
        setRecordName("")
        setAttachment([])
        setTranslation(false);
        setSelectedUsersAndGroups([]); 
        setAutoTranscribe(true)
    }
    
    const getDuration = (file) => {        
        return new Promise((resolve, reject) => {
            // Create a blob that we can use as an src for our audio element
            const urlObj = URL.createObjectURL(file);

            var audio = new Audio();
            $(audio).on("loadedmetadata", function(){
                const duration = Math.round(audio.duration);

                resolve(duration)

                URL.revokeObjectURL(urlObj);
            });
            audio.src = urlObj;
        });
    }

    const handleSelectedLanguageChange = (language) => {
        setSelectedLanguage(language);
    }

    const onSelectedUsersAndGroupsChange = (listData) => {
        // console.log(listData)
        setSelectedUsersAndGroups(listData)
    }

    const onUploadClick = async () => {
        // console.log("onUploadClick")
        if ( recordName.replace(/\s+/g, '') === "" ) {
            setNotifAlertData({
                status: "error",
                text: "Please fill record name",
                timeout: 3000
            })
            return

        } else if ( recordName.replace(/\s+/g, '').length < 2 ) {
            setNotifAlertData({
                status: "error",
                text: "Record name too short",
                timeout: 3000
            })
            return

        } else if ( recordName.replace(/\s+/g, '').length > 20 ) {
            setNotifAlertData({
                status: "error",
                text: "Record name too long",
                timeout: 3000
            })
            return

        } else if ( attachment.length === 0 ) {
            setNotifAlertData({
                status: "error",
                text: "Please select at least 1 attachment",
                timeout: 3000
            })
            return

        } 
        // else if ( attachment.findIndex(({model}) => model === 0) > -1 ) {
        //     setNotifAlertData({
        //         status: "error",
        //         text: "Please select file type",
        //         timeout: 3000
        //     })
        //     return
            
        // }
        

        setLoading(true);

        // get kuota
        let availableQuota = null;
        await dispatch(fetchConsoleGetDikteinQuota())
            .then((kuota) => {
                availableQuota = kuota;
            })
            .catch((err) => {
                // console.log(err)
                toastNotification("error", "Gagal memuat data kuota. "+err.toString())
                return;
            })    

        setLoading(false);

        // console.log(availableQuota)
        const total_attachment_duration = attachmentDuration.reduce((a, b) => a + b);
        if ( total_attachment_duration > availableQuota ) {
            toastNotification("error", "File yang ingin Anda upload melebihi sisa kuota yang Anda miliki")
            return
        } else {
            // console.log("sisa kuota cukup")
        }

        const data = {
            name            : recordName,
            audio           : attachment.map(({file, model}) => ({file: file.name, model: model})),
            files           : attachment.map(({file}) => ({file: file})),
            use_translate   : translation,
            use_transcribe  : autoTranscribe,
            select_language : selectedLanguage
        }
        // console.log(data)
        
        dispatch(updateIsUploading(true))
        dispatch(updateUploadingError(null))
        dispatch(updateUploadingProgress(0))
        dispatch(updateListFileUpload(data.files))
        
        // close modal
        props.onHide()
        
        var add_record_status;
        var new_record_data;

        const progressCallback = (progress) => {
            dispatch(updateUploadingProgress(progress))
        }

        // fetch upload document
        await dispatch(fetchRecordAdd(data, progressCallback))
            .then((res) => {
                add_record_status = "success";

                // console.log(res)
                new_record_data = res.data;

                dispatch(updateIsUploading(false))

                // req record list
                dispatch(reqAllRecordList());
                
            })
            .catch((err) => {
                var error = err.error.toString();

                add_record_status = "error";
                
                dispatch(updateUploadingError(error))
                toastNotification("error", "Gagal mengunggah rekaman. "+error)
            })
            
        // console.log(new_record_data)
        
        // add users & participants to record if add record success
        if ( add_record_status === "success" ) {
            dispatch(handlerParticipantAdd(new_record_data.id, selectedUsersAndGroups));
        }

    }
    
    // console.log(selectedLanguage)

    return (
        <Modal
            {...props}
            size="md"
            centered
            className="modal-ct"
        >
            <div id="ct-container">
                { notifAlertData !== null &&
                    <NotifAlert 
                        data={notifAlertData}
                    />
                }
                <div className="ct-top">
                    <h5>Create Transkrip</h5>
                    <img src={x} alt="close" id="close" onClick={props.onHide} />
                </div>
                <>
                    <label>Record Name</label>
                    <input id="ct-input" 
                        alt="input-record-name"
                        placeholder="ex: meeting weekly"
                        value={recordName}
                        onChange={(e) => setRecordName(e.target.value)}
                    />
                </>
                <>
                    <DropFile onFileSelected={(files) => setAttachment(files)} />
                </>
                <>
                    <div className="label-wrap">
                        <label>Transcript To</label>
                    </div>
                    <DropdownLanguageList onChange={handleSelectedLanguageChange} />
                </>
                { window._translation_ &&
                    <div className="ct-option">
                        <div>
                            <label>Translation</label>
                            <p>Transcribe will be translate to english</p>
                        </div>
                        <BKSwitch 
                            data-testid="toggle-translation"
                            id="translation"
                            checked={translation}
                            onChange={(e) => setTranslation(e.target.checked)}
                        />
                    </div>
                }
                <>
                    <div className="label-wrap">
                        <label>Partisipan</label>
                    </div>
                    <FormSelectUserAndGroup 
                        onSelectedUsersAndGroupsChange={onSelectedUsersAndGroupsChange}
                    />        
                </>
                <div className="ct-check">
                    {/* <Form.Check
                        alt="checkbox-auto-transcript"
                        required
                        name="auto transkrip"
                        label="Auto transkrip when done"
                        id="autotranskrip"
                        checked={autoTranscribe}
                        onChange={(e) => setAutoTranscribe(e.target.checked)}
                    /> */}
                </div>
                { loading || attachment.length !== attachmentDuration.length ?
                    <Button className="primary">
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                    </Button>
                    :
                    <Button className="primary" onClick={onUploadClick}>
                        Upload
                    </Button>
                }
            </div>
        </Modal>
    );
}

export default ModalCreateRecord;