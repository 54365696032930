import getRandomValues from 'get-random-values';

// Get random uuid
export function get_random_uuid() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        // (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        (c ^ getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    )
}

export function get_empty_avatar() {
    return "/img/ava-empty.svg"
}

export function clearCookie() {
    document.cookie = "LOGGED_IN= ; expires=Thu, 01 Jan 1970 00:00:00 GMT"
}

export function clearLocalStorage() {
    localStorage.removeItem("token");
    localStorage.removeItem("keycloakToken")
}

export function doesFileExist(urlToFile) {
    return new Promise((resolve, reject) => {
        var xhr = new XMLHttpRequest();
        xhr.open('HEAD', urlToFile, true);
        xhr.onload = function (e) {
            if (this.readyState === this.DONE) {
                if (this.status === 200) {
                    resolve(true);
                } else {
                    reject(this.statusText);
                }
            }
        };
        xhr.onerror = function (e) {
            reject(this.statusText);
        };
        xhr.send();
        // console.log(xhr.status)
        // return xhr.status !== 404;
    });
}

export function downloadFromURL(url) {
    var hiddenIFrameID  = 'hiddenDownloader-'+get_random_uuid();
    var iframe  = document.getElementById(hiddenIFrameID);
    if (iframe === null) {
        iframe = document.createElement('iframe');
        iframe.id = hiddenIFrameID;
        iframe.style.display = 'none';
        document.body.appendChild(iframe);
    }
    iframe.src = url;
    setTimeout(() => {
        iframe.remove();
    }, 2000)
};