import { useState } from 'react'
import BKSwitch from '../../BKSwitch/BKSwitch';

import ModalKeyword from '../../ModalKeyword/ModalKeyword';


function CardKeyword(props) {
    const { setNotifAlertData, id_record, showKeyword, setShowKeyword } = props;
    
    const [showKeywordList, setShowKeywordList] = useState(false);
    
    return (
        <>
            <ModalKeyword
                id_record={id_record}
                show={showKeywordList}
                onHide={()=>setShowKeywordList(false)}              
                setNotifAlertData={setNotifAlertData}    
            />

            <div className="item-cont speaker-list">
                <div className="keyword-top">
                    Keyword 
                    {/* <img src={info} alt="info" /> */}
                </div>
                <div className="translation-set-cont">
                    <div className="translation-set-wrap expand">
                        <div className="section">
                            <div className="translation-set-item">
                                Show Keyword
                                <BKSwitch 
                                    alt="toggle-show-keyword"
                                    id="keyword"
                                    checked={showKeyword}
                                    onChange={(e) => setShowKeyword(e.target.checked)}
                                />
                            </div>
                            <div className="translation-set-item">
                                Keyword List
                                <span onClick={()=>setShowKeywordList(true)}>View</span>                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CardKeyword;