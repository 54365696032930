import { useState } from 'react'
import { useDispatch } from 'react-redux';

//functions
import { threshold } from '../../../../threshold';
import { fetchUtteranceReload } from '../../../../reducers/FetcherReducers';

//components
import styled from 'styled-components';
import { RECORD_STATUS } from '../../../../utils/helpers';
import { ProgressBar } from 'react-bootstrap';

//assets


const Label = styled.label`
    display: unset !important;
    height: unset !important;
    overflow: unset !important;
    line-height: unset !important;

    border-radius: 7px;
    padding: 5px;
    width: 86px;
    font-size: 10px;
    font-weight: 600;
    text-align: center;
    text-transform: capitalize;

    img {
        margin-right: 5px;
        margin-top: -2px;
    }
`;

function CardRecordTranscriptProgress(props) {
    const dispatch          = useDispatch();

    const { recordData } = props;
    const { 
        state="",
        total_transcribe,
        total_statement
    } = recordData;
    
    const transcribeProgress = (total_transcribe/total_statement)*100;
    const _transcribeProgress = Math.floor( isNaN(transcribeProgress) ? 0 : transcribeProgress );

    return (
        <div className="item-cont transkrip-info">
            <>
                { 
                    state === RECORD_STATUS.TRANSCRIPTING ? 
                        <Label className="progress">{state}</Label> :
                        state === RECORD_STATUS.TRANSCRIPTED ? 
                        // <Label className="primary">{status}</Label>
                        <Label className="default">Done</Label> :
                    <Label className="primary">{state}</Label>
                }
            </>
            { state === RECORD_STATUS.TRANSCRIPTING &&
                <div className="progress-wrap">
                    <ProgressBar now={_transcribeProgress} />
                    <div className="label">{_transcribeProgress}%</div>
                </div>
            }
        </div>
    )
}

export default CardRecordTranscriptProgress;