import { useState } from 'react'
import { useDispatch, } from 'react-redux'

// functions
import { reqAllUserList } from '../../../../reducers/UserAndGroupReducers'
import { fetchUserDelete } from '../../../../reducers/FetcherReducers'

//components
import { Modal } from 'react-bootstrap'

//style
import '../ModalProfile.scss'
import './UserAndGroup.scss'
import ButtonDangerLoading from '../../Buttons/ButtonDangerLoading'

//assets


function ModalDeleteUser(props) {
    const { setNotifAlertData, userOnDelete } = props;

    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);

    const onDeleteBtnClick = () => {
        setLoading(true)

        const data = {
            id: userOnDelete.id
        }

        dispatch(fetchUserDelete(data))
            .then((res) => {
                setLoading(false)

                props.onHide();
        
                // notif when success delete user
                setNotifAlertData({
                    status: "success",
                    text: "User removed",
                    timeout: 3000
                })

                dispatch(reqAllUserList());

            })
            .catch((err) => {
                var error = err.error.toString();

                setLoading(false)

                // notif when failed delete user
                setNotifAlertData({
                    status: "error",
                    text: "Failed delete user. "+error,
                    timeout:3000
                })
            })
    }

    if ( userOnDelete === null ) {
        return null
    }

    return (
        <>
            <Modal
                {...props}
                size="sm"
                centered
                backdrop="static"
                id="modal-add-user"
            >
                <div id="add">
                    <div className="top">
                        Delete User
                    </div>
                    <div className="caption">
                        Apakah anda yakin ingin menghapus user <b>{userOnDelete.name}</b>?
                    </div>
                    <div className="btn-wrap">
                        { loading ?
                            <ButtonDangerLoading />
                            :
                            <>
                                <button 
                                    className="error" 
                                    onClick={onDeleteBtnClick}
                                >
                                    Delete
                                </button>
                                <button
                                    className="primary" 
                                    onClick={() => props.onHide()}
                                >
                                    Cancel
                                </button>
                            </>
                        }
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default ModalDeleteUser