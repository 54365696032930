import { useState, useEffect } from 'react';
import { history } from "../../../store";

//functions

//components
import { Container } from 'react-bootstrap';
import DashboardNavbar from '../../components/Navbar/DashboardNavbar';
import RecordList from './RecordList';
import TranscriptList from './TranscriptList';

//style
import './Dashboard.scss';

//assets


function Dashboard() {
    const [searchMode, setSearchMode] = useState("record"); //record/transcript
        
    const [searchTranscriptGlobally, setSearchTranscriptGlobally] = useState("");
    
    const latRange = {min: -90, max: 90};
    const lngRange = {min: -180, max: 180};
    const lobRange = {min: 0, max: 360};
    const [selectedLatRange, setSelectedLatRange] = useState(latRange);
    const [selectedLngRange, setSelectedLngRange] = useState(lngRange);
    const [selectedLobRange, setSelectedLobRange] = useState(lobRange);

    const [searchUrl, setSearchUrl] = useState("");

    useEffect(() => {
        
    }, []);

    useEffect(() => {
        // update searchUrl state
        const newSearchUrl = decodeURI(history.location.search);  
        // console.log(newSearchUrl)
        if ( searchUrl !== newSearchUrl ) { 
            setSearchUrl(newSearchUrl);
            renderSearchTranscriptGlobally(newSearchUrl)
        }
    }, [history.location.search]);

    useEffect(() => {
        if ( searchTranscriptGlobally.replace(/\s+/g, '') !== "" ) {
            setSearchMode("transcript")
        } else {
            setSearchMode("record")
        }
    }, [searchTranscriptGlobally]);

    const renderSearchTranscriptGlobally = (search) => {
        // console.log("renderSearchTranscriptGlobally")

        const params = new URLSearchParams(search);
        
        // searchTranscriptGlobally
        var qg   = params.get('qg');
        qg = ( qg !== null ? qg : "" );

        if ( qg.replace(/\s+/g, '') !== "" ) {
            setSearchTranscriptGlobally(qg)
        }
    }

    const onSaveFilterTranscriptGlobally = (keyword, lat, lng, lob) => {
        setSearchTranscriptGlobally(keyword)
        setSelectedLatRange(lat);
        setSelectedLngRange(lng);
        setSelectedLobRange(lob);
    }

    return (
        <Container fluid className="dashboard-container dashboard-bg">
            <DashboardNavbar 
                searchTranscriptGlobally={searchTranscriptGlobally}
                latRange={latRange}
                lngRange={lngRange}
                lobRange={lobRange}
                selectedLatRange={selectedLatRange}
                selectedLngRange={selectedLngRange}
                selectedLobRange={selectedLobRange}
                onSaveFilterTranscriptGlobally={onSaveFilterTranscriptGlobally}
            />

            { searchMode === "record" &&
                // Show record list
                <RecordList />
            }
            { searchMode === "transcript" &&
                // Show transcript list
                <TranscriptList 
                    searchTranscriptGlobally={searchTranscriptGlobally}
                    latRange={latRange}
                    lngRange={lngRange}
                    lobRange={lobRange}
                    selectedLatRange={selectedLatRange}
                    selectedLngRange={selectedLngRange}
                    selectedLobRange={selectedLobRange}
                    onSaveFilterTranscriptGlobally={onSaveFilterTranscriptGlobally}
                />
            }    
        </Container>
    );
}

export default Dashboard;