import { useEffect, useState } from "react"
import { Dropdown } from "react-bootstrap";
import { useDispatch } from "react-redux"
import { fetchLanguageList } from "../../../reducers/FetcherReducers";
import { toastNotification } from "../ToastNotification/ToastNotification";

function DropdownLanguageList(props) {
    const { onChange=()=>{} } = props;

    const dispatch = useDispatch();

    const [searchValue, setSearchValue] = useState("");

    const [languageList, setLanguageList] = useState([]);
    const [selectedLanguage, setSelectedLanguage] = useState("id");

    useEffect(() => {
        dispatch(fetchLanguageList())
            .then((res) => {
                setLanguageList(res.data)
            })
            .catch((err) => toastNotification("error", err))
    }, [])

    useEffect(() => {
        onChange(selectedLanguage)
    }, [selectedLanguage])

    const langList = languageList.filter(({lang}) => lang.indexOf(searchValue) > -1);

    let currentlanguage = "Don't translate";
    if ( selectedLanguage !== "" ) {
        const index = languageList.findIndex(({code}) => code === selectedLanguage);
        if ( index > -1 ) {
            currentlanguage = languageList[index].lang;
        }
    }

    return (
        <Dropdown id="dropdown-language-list">
            <Dropdown.Toggle>{currentlanguage} </Dropdown.Toggle>
            <Dropdown.Menu>
                <Dropdown.Header>
                    <input type="text" placeholder="Search" onChange={(e) => setSearchValue(e.target.value)} value={searchValue} />
                </Dropdown.Header>
                {/* <Dropdown.Divider /> */}
                <div id="lang-list-container">
                    {/* <Dropdown.Item onClick={() => setSelectedLanguage("")}>Don't translate</Dropdown.Item> */}
                    { langList.map((l) => {
                        return (
                            <Dropdown.Item key={"lang-"+l.code} onClick={() => setSelectedLanguage(l.code)}>{l.lang}</Dropdown.Item>
                        )
                    }) }
                </div>
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default DropdownLanguageList;