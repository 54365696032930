import React, { Component } from "react"

import { Carousel } from "react-bootstrap"
import './SlideShow.css'

import Slide_1 from "../../img/image-slide-1.png"
import Slide_2 from "../../img/image-slide-2.png"
import Slide_3 from "../../img/image-slide-3.png"


class SlideShow extends Component {

    render() {
        return (
            <React.Fragment>
                <Carousel controls={false} fade={true} interval={7000}>
                    <Carousel.Item>
                        <div className="slide-show">
                            <img
                                className="w-100"
                                src={Slide_1}
                                alt="Keep Connected"
                            />
                            {/* <h3>Keeping Connected</h3>
                            <p>In this together. Keeping you connected wherever you are</p> */}
                        </div>
                        <Carousel.Caption>
                            <h3>Labeling Speaker</h3>
                            <p>In this together. Keeping you connected wherever you are</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className="slide-show">
                            <img
                                className="w-100"
                                src={Slide_2}
                                alt="Meeting Point"
                            />
                        </div>
                        <Carousel.Caption>
                            <h3>Transcript with translation</h3>
                            <p>In this together. Keeping you connected wherever you are</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className="slide-show">
                            <img
                                className="w-100"
                                src={Slide_3}
                                alt="Meeting File"
                            />
                        </div>
                        <Carousel.Caption>
                            <h3>Transcript your voice</h3>
                            <p>In this together. Keeping you connected wherever you are</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </React.Fragment>
        )
    }

}
export default SlideShow