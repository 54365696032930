import moment from 'moment';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { history } from "../../../store";

//functions
import { reqAllRecordList } from '../../../reducers/RecordReducers';

//components
import { Col, Container, Row } from 'react-bootstrap';
import DashboardNavbar from '../../components/Navbar/DashboardNavbar';
import RecordListCard from '../../components/Cards/RecordListCard';
import ModalCreateRecord from '../../components/CreateRecord/ModalCreateRecord';
import ModalFilterRecordList from '../../components/ModalFilterRecordList/ModalFilterRecordList';
import NcloudPagination from '../../components/NcloudPagination';
import EmptyState from '../../components/EmptyState/EmptyState';

//style

//assets
import search from '../../img/search-black.svg';
import filter from '../../img/filter.svg';
import addfile from '../../img/upload-file.png'
import NoRecord from '../../img/no-record.svg'
import { RECORD_STATUS } from '../../../utils/helpers';


function RecordList(props) {
    const dispatch          = useDispatch();

	const { fetchStatus, fetchData } = useSelector(state => state.FetcherReducers)
	const token = useSelector(state => state.AccountManagementReducers.token)
    const recordList = useSelector(state => state.RecordReducers.recordList)
    
    const [loading, setLoading] = useState(false);
    
    const [showCreate, setShowCreate] = useState(false);
    const [showFilter, setShowFilter] = useState(false);
    
    const [searchKeyword, setSearchKeyword] = useState("");
    
    // Filter
    const listStatus = Object.values(RECORD_STATUS);
    const [selectedTranscriptStatus, setSelectedTranscriptStatus] = useState(listStatus);    
    const [selectedDate, setSelectedDate] = useState({start: null, end: null});

    const limit = ( process.env.REACT_APP_TYPE === "PANORAMA" ? 60 : 59 );
    const [offset, setOffset] = useState(0);
    const [activePage, setActivePage] = useState(1);
    
    const [searchUrl, setSearchUrl] = useState("");

    var recordListRefresher = null;
    const startRecordListRefresher = () => {
        if ( recordListRefresher !== null ) return;
             
        recordListRefresher = setInterval(() => {
            // console.log("reqAllRecordList")
            dispatch(reqAllRecordList());
        }, 60000)
    }
    const stopRecordListRefresher = () => {
        clearInterval(recordListRefresher);
        recordListRefresher = null;
    }

    useEffect(() => {
        setLoading(true)
    }, []);

    useEffect(() => {
        if ( token == null ) return;
        
        // req record list
        dispatch(reqAllRecordList());
        
        // // refresh record list every 60s
        // startRecordListRefresher();

        // return () => {
        //     stopRecordListRefresher();

        //     // // clear record list data
        //     // dispatch(resetRecordListData());
        // }
    }, [token]);

    useEffect(() => {
        if ( fetchStatus === "rcvAllRecordList" ) {
            if ( fetchData.status === "success" ) {
                setTimeout(() => {
                    setLoading(false)
                }, 500)
            } else {
                setLoading("error")
            }
        } 
    }, [fetchStatus]);
    
    useEffect(() => {
        // update searchUrl state
        const newSearchUrl = decodeURI(history.location.search);  
        if ( searchUrl !== newSearchUrl ) {  
            setSearchUrl(newSearchUrl);
            renderSearchUrl(newSearchUrl)
        }
    }, [history.location.search]);

    useEffect(() => {
        initSearchUrl();
    }, [searchKeyword, selectedTranscriptStatus, selectedDate]);

    const initSearchUrl = () => {
        // console.log("initSearchUrl")

        var newSearchUrl = "?";
        var q  = "";
        var tr = "";
        var dt = "";
        var page = 1;

        if ( searchKeyword.replace(/\s+/g, '') !== "" ) {
            q = searchKeyword;
            newSearchUrl += `q=${q}&`
        }
        if ( selectedTranscriptStatus.length !== listStatus.length && selectedTranscriptStatus.length !== 0 ) {
            tr = JSON.stringify(selectedTranscriptStatus);
            newSearchUrl += `tr=${tr}&`
        }
        if ( selectedDate.start !== null || selectedDate.end !== null ) {
            dt = JSON.stringify(selectedDate);
            newSearchUrl += `dt=${dt}&`
        }
        if ( activePage !== 1 ) {
            page = activePage;
            
            // if searchWord changed, set page to 1
            const params = new URLSearchParams(searchUrl);    
            var q = params.get('q');
            q = ( q !== null ? q : "" );

            if ( q !== searchKeyword ) {
                page = 1;
            }

            if ( page > 1 ) {
                newSearchUrl += `page=${page}&`;
            }
        }

        if ( newSearchUrl.replace('?', '') !== searchUrl.replace('?', '') ) {
            history.push(history.location.pathname+newSearchUrl)
        }
    }

    const renderSearchUrl = (search) => {
        // console.log("renderSearchUrl")

        const params = new URLSearchParams(search);
        
        // searchKeyword
        var q   = params.get('q');
        q = ( q !== null ? q : "" );

        if ( q !== searchKeyword) {
            setSearchKeyword(q);
        }

        // selectedTranscriptStatus
        var tr  = params.get('tr'); 
        tr = ( tr !== null ? JSON.parse(tr) : listStatus );
        
        if ( JSON.stringify(tr) !== JSON.stringify(selectedTranscriptStatus) ) {
            setSelectedTranscriptStatus(tr);
        }

        // selectedDate
        var dt  = params.get('dt'); 
        dt = ( dt !== null ? JSON.parse(dt) : {start: null, end: null});
        dt = {
            start : dt.start !== null ? new Date(dt.start) : dt.start,
            end   : dt.end !== null ? new Date(dt.end) : dt.end
        }

        if ( JSON.stringify(dt) !== JSON.stringify(selectedDate)) {
            setSelectedDate(dt);
        }

        // activePage
        var page  = params.get('page'); 
        page = ( page !== null ? parseInt(page) : 1 );
        
        if ( page !== activePage ) {
            handlePageChange(page);
        }
    }

    const handlePageChange = (pageNumber) => {
        // console.log(pageNumber)
        setActivePage(pageNumber);
        setOffset((pageNumber - 1) * limit);
    }

    // Save filter
    const onSaveFilter = (status, date) => {
        setSelectedTranscriptStatus(status);
        setSelectedDate(date);

        setShowFilter(!showFilter);
    }

    const onReloadClick = () => {
        setLoading(true)
        
        // req record list
        dispatch(reqAllRecordList());
    }

    function FilterSearch() {
        var filter_active = false;
        if ( 
            ( selectedTranscriptStatus.length !== 0 && selectedTranscriptStatus.length !== 3 ) ||
            selectedDate.start !== null || selectedDate.end !== null
        ) {
            filter_active = true;
        }

        return (
            <div className="dashboard-tool">
                <div className={"filter-container " + (filter_active ? "active" : "")} onClick={() => setShowFilter(true)}>
                    <img src={filter} alt="icon-filter" />
                </div>
                <div id="search-container">
                    <img src={search} className="icon" alt="search" />
                    <input placeholder="Search Record" value={searchKeyword} onChange={(e) => setSearchKeyword(e.target.value)} />
                </div>
            </div>
        )
    }

    function RecordListData() {
        var recordListFiltered = recordList;

        // Apply filter by search word
        var keywords = [];
        if (searchKeyword.replace(/\s+/g, '') !== "") {
            keywords            = searchKeyword.split(" ");
            recordListFiltered  = recordListFiltered.filter(rec => keywords.some(keyword => rec.name.toLowerCase().includes(keyword.toLowerCase())));
        }

        // Apply filter from ModalFilterRecordList
        // selectedTranscriptStatus
        if ( selectedTranscriptStatus.length !== 0 && selectedTranscriptStatus.length !== listStatus.length ) {
            recordListFiltered  = recordListFiltered.filter(rec => selectedTranscriptStatus.some( status => rec.state.toLowerCase() === status.toLowerCase() ) );
        }
        // selectedDate
        const start = selectedDate.start !== null ? moment(selectedDate.start) : moment(-1);
        const end   = selectedDate.end !== null ? moment(selectedDate.end).add(1, 'days') : moment(999999999999999);
        recordListFiltered  = recordListFiltered.filter( ({starting_date, end_date}) => moment(starting_date) > start && moment(end_date) < end );

        // Sort by date desc
        recordListFiltered.sort((a,b) => (a.starting_date < b.starting_date) ? 1 : ((b.starting_date < a.starting_date) ? -1 : 0));

        const resultTotal   = recordListFiltered.length;
        recordListFiltered  = recordListFiltered.slice(offset, offset + limit);
    
        return (
            <div className="record-list-container">
                { process.env.REACT_APP_TYPE !== "PANORAMA" &&
                    <div className="add-record-btn" onClick={() => setShowCreate(true)}>
                        <img src={addfile} alt="add-file" />
                        <p>Create New Transkrip</p>
                    </div>
                }
                <>
                    { recordListFiltered.length === 0 ?
                        <EmptyState 
                            state={loading === "error" ? "error" : ( loading ? "loading" : "loaded")}
                            onReloadClick={onReloadClick}
                            noDataImage={process.env.REACT_APP_TYPE === "PANORAMA" ? NoRecord : null}
                            noDataText={"Belum ada rekaman"}
                            searchKeyword={searchKeyword}
                        />
                        :
                        ( recordListFiltered.map((item) => (
                            <RecordListCard
                                key={"record-"+item.id_record}
                                id={item.id_record}
                                status={item.state}
                                title={item.name}
                                date={item.starting_date}
                                onCardClick={
                                    item.state === RECORD_STATUS.TRANSCRIPTED || item.state === RECORD_STATUS.TRANSCRIPTING ? 
                                        () => history.push("/record/"+item.id_record) : 
                                        null
                                }
                                warning={item.warning}
                                quota={item.quota}
                                transcribeProgress={(item.total_transcribe/item.total_statement)*100}
                            />
                        )) )
                    }
                </>
                <div className="pagination-cont">
                    <NcloudPagination
                        activePage={activePage}
                        itemPerPage={limit}
                        totalItems={resultTotal}
                        onChange={handlePageChange}
                    />
                </div>
            </div>
        );
    }
    
    return(
        <>
            <ModalFilterRecordList
                show={showFilter}
                onHide={() => setShowFilter(false)}
                onSave={(transcriptStatus, date) => onSaveFilter(transcriptStatus, date)}
                listStatus={listStatus}
                selectedTranscriptStatus={selectedTranscriptStatus}
                selectedDate={selectedDate}
            />
            <ModalCreateRecord
                show={showCreate}
                onHide={() => setShowCreate(false)}
            />

            <Row className="dashboard-body">
                <Col className="col-max-1564">
                    {FilterSearch()}
                    {RecordListData()}
                </Col>
            </Row>
        </>
    )
}

export default RecordList;