import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

// functions
import { reqAllGroupList } from '../../../../reducers/UserAndGroupReducers'
import { fetchGroupAdd } from '../../../../reducers/FetcherReducers'

//components
import { Form, Modal } from 'react-bootstrap'

//style
import '../ModalProfile.scss'
import './UserAndGroup.scss'

//assets
import xc from '../../../img/x-cancel.svg'
import ButtonPrimaryLoading from '../../Buttons/ButtonPrimaryLoading'


function ModalAddGroup(props) {
    const { setNotifAlertData } = props;

    const dispatch = useDispatch();

    const firstInputRef = useRef();

    const [loading, setLoading] = useState(false);

    const [name, setName] = useState("");

    useEffect(() => {
        if ( props.show ) {
            firstInputRef.current.focus();
        } else {
        }
    }, [props.show]);
    
    const onNameChange = ({ target }) => {
        setNotifAlertData(null)

        setName(target.value);
    };

    const onCreateClick = () => {
        // check name
        if ( name.replace(/\s+/g, '') === "") {
            setNotifAlertData({
                status: "error",
                text: "please fill all column",
                timeout: -1
            })
            return
        }

        setLoading(true)

        var data = {
            name: name
        }

        dispatch(fetchGroupAdd(data))
            .then((res) => {
                setLoading(false)
                setName("")

                props.onHide();

                // notif when success delete user
                setNotifAlertData({
                    status: "success",
                    text: "Group created",
                    timeout: 3000
                })

                dispatch(reqAllGroupList());
            })
            .catch((err) => {
                var error = err.error.toString();
                
                setLoading(false)

                // notif when failed delete user
                setNotifAlertData({
                    status: "error",
                    text: "Failed create group. "+error,
                    timeout:3000
                })
            })
    }

    return (
        <>
            <Modal
                {...props}
                size="sm"
                centered
                id="modal-add-user"
            >
                <div id="add">
                    <div className="top">
                        Create Group
                        <img className="close-btn" src={xc} alt="close" onClick={() => props.onHide()} />
                    </div>
                    <Form className="form" 
                        onSubmit={(e) => {
                            e.preventDefault(); 
                            onCreateClick();
                        }}
                    >
                        <input
                            ref={firstInputRef}
                            placeholder="Group Name"
                            type="text"
                            value={name}
                            onChange={onNameChange}
                        />
                    </Form>
                    { loading ?
                        <ButtonPrimaryLoading />
                        :
                        <>
                            <button
                                className="primary" 
                                onClick={onCreateClick}
                            >
                                Create
                            </button>
                        </>
                    }
                </div>
            </Modal>
        </>
    )
}

export default ModalAddGroup;