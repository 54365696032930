import { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Redirect, Route, NavLink } from 'react-router-dom';

// functions
import { reqRecordDetails, updateCurrentOpenedIdRecord, updateRecordDetails } from '../../../reducers/RecordReducers';
import { fetchRecordUpdate } from '../../../reducers/FetcherReducers';
import { reqAllParticipantList, reqAllSpeakerList, resetParticipantListData, resetSpeakerListData } from '../../../reducers/SpeakerAndParticipantReducers';
import { resetUtteranceListData, resetUtteranceListParagraphData } from '../../../reducers/UtteranceReducers';

//components
import { Row, Col, Container, Overlay } from 'react-bootstrap'
import BKSwitch from '../../components/BKSwitch/BKSwitch';
import NotifAlert from '../../components/NotifAlert/NotifAlert';
import EmptyState from '../../components/EmptyState/EmptyState';
import DetailRecordNavbar from '../../components/Navbar/DetailRecordNavbar'
import Sidebar from '../../components/Sidebar/Sidebar'
import DownloadTranskrip from '../../components/ModalDownloadTranskrip/DownloadTranskrip'
import AddNewFileToRecord from '../../components/CreateRecord/ModalAddNewFileToRecord';
import ModalAudioFile from '../../components/ModalFileAudio/ModalAudioFile';
import Kronologi from './Kronologi/Kronologi'
import Paragraf from './Paragraf/Paragraf';

//style
import './DetailRecord.scss'

//assets
import flag from '../../img/flag-uk.svg'
import more from '../../img/expand-more.svg'
import less from '../../img/expand-less.svg'
// import ShortcutsForWordScoring from './ShortcutsForWordScoring';


function DetailRecord(props) {
    const id_record = parseInt(props.match.params.id_record);

    const dispatch = useDispatch();
    
    const recordDetails = useSelector(state => state.RecordReducers.recordDetails)
    
    const [notifAlertData, setNotifAlertData] = useState(null);

    const [showDownload, setShowDownload] = useState(false);
    const [showAddew, setAddNew] = useState(false);
    const [showAllTranslate, setShowAllTranslate] = useState(false);
    const [showTranslate, setShowTranslate] = useState(false);
    const [showAudioFiles, setShowAudioFiles] = useState(false);
    const target = useRef(null);
    const [show, setShow] = useState(false);
    const [showKeyword, setShowKeyword] = useState(false);
    const [searchWord, setSearchWord] = useState("");

    const kronologiLimit = 10

    var refreshDetailRecordPageDataInterval = null;

    useEffect(() => {
        /** COMPONENT DID MOUNT **/

        return () => {
            /** COMPONENT WILL UNMOUNT **/
            
        };
    }, []);

    useEffect(() => {
        /** ON ID_RECORD CHANGED **/
        // console.log("/** ON ID_RECORD CHANGED **/")
        // console.log(id_record)

        // update current opened record id
        dispatch(updateCurrentOpenedIdRecord(id_record));
        
        // req record details, participant & speaker list
        reqDetailRecordPageData(id_record);

        // refresh record details, participant & speaker list every 60s
        if ( refreshDetailRecordPageDataInterval === null ) {
            // set refresh data interval
            refreshDetailRecordPageDataInterval = setInterval(() => {
                // console.log("INTERVAL RECORD "+id_record+" MASIH JALAN")
                reqDetailRecordPageData(id_record);
            }, 60000)
        }

        return () => {
            // reset detail record page data
            resetDetailRecordPageData();
        };
    }, [id_record]);

    const reqDetailRecordPageData = () => {
        // req record details, participant & speaker list
        dispatch(reqRecordDetails(id_record));
        dispatch(reqAllParticipantList(id_record));
        dispatch(reqAllSpeakerList(id_record));
    }

    const resetDetailRecordPageData = () => {
        // console.log("resetDetailRecordPageData")
        
        // clear notif alert data
        setNotifAlertData(null);

        // clear refresh data interval
        clearInterval(refreshDetailRecordPageDataInterval);
        refreshDetailRecordPageDataInterval = null;

        // clear record details, participant & speaker list data
        dispatch(updateRecordDetails(null));
        dispatch(resetParticipantListData());
        dispatch(resetSpeakerListData());
        // clear utterance list & utterance list paragraph data
        dispatch(resetUtteranceListData())
        dispatch(resetUtteranceListParagraphData())

    }

    const onShowTranslate = (value) => {
        // console.log("onShowTranslate")
        setShowTranslate(value)
        
        // if toggle 
        if ( value && !recordDetails.use_translate ) {
            var data = recordDetails;
            data.id = data.id_record;
            data.use_translate = true;

            dispatch(fetchRecordUpdate(data))
                .then((res) => {
                    if ( data.id_record !== id_record ) return;
                     
                    dispatch(reqRecordDetails(data.id_record));
                })
                .catch((err) => {
                    var error = err.error.toString();
                    
                    setNotifAlertData({
                        status: "error",
                        text: "Request translate failed. "+error,
                        timeout: 3000
                    })
                })
        }
    }

    const onReloadClick = () => {
        dispatch(updateRecordDetails(null));

        // req record list
        dispatch(reqRecordDetails(id_record));
    }
    
    if ( recordDetails === null ) {
        return (
            <EmptyState 
                state="loading"
                loadingText="Loading page"
                bgColor="#ffffff"
            />
        )
    }
    
    if ( recordDetails.status === "failed" ) {
        return (
            <EmptyState 
                state={"error"}
                onReloadClick={onReloadClick}
            />
        )
    }

    const url = props.match.url;

    return (
        <Container fluid className="detail-container">
            { notifAlertData !== null &&
                <NotifAlert 
                    data={notifAlertData}
                />
            }

            {/* <ShortcutsForWordScoring 
                kronologiLimit={kronologiLimit}    
            /> */}

            <DetailRecordNavbar
                recordName={recordDetails.name}
                onDownload={() => setShowDownload(true)}
                onAddNew={() => setAddNew(true)}
                searchWord={searchWord}
                onInputSearchWord={(value) => setSearchWord(value)}
            />
            <DownloadTranskrip
                show={showDownload}
                onHide={() => setShowDownload(false)}
                id_record={id_record}
                record_details={recordDetails}
            />
            <AddNewFileToRecord
                show={showAddew}
                onHide={() => setAddNew(false)}
                setNotifAlertData={setNotifAlertData}
                id_record={id_record}
            />
            <ModalAudioFile
                show={showAudioFiles}
                onHide={() => setShowAudioFiles(false)}
                id_record={id_record}
            />
            <Row className="detail-body">
                <Col className="side-bar" md={3} sm={5} xs={12}>
                    <Sidebar
                        setNotifAlertData={setNotifAlertData}
                        id_record={id_record}
                        recordData={recordDetails}
                        showAudio={() => setShowAudioFiles(true)}
                        showKeyword={showKeyword}
                        setShowKeyword={setShowKeyword}
                    />
                </Col>
                <Col className="main-cont cont" md={9} sm={7} xs={12}>
                    <div className="main-wrapper">
                        { ( process.env.REACT_APP_TYPE !== "PANORAMA" || window._translation_ ) &&
                            <div className={"head-nav "+ ( !window._translation_ ? "translation-off" : "")}>
                                { process.env.REACT_APP_TYPE !== "PANORAMA" && 
                                    <div className="top-wrap">
                                        <NavLink className="btn-nav" to={`${url}/kronologi`}>Kronologi</NavLink>
                                        {/* <NavLink className="btn-nav" to={`${url}/paragraf`}>Paragraf</NavLink> */}
                                    </div>
                                }
                                { window._translation_ &&
                                    <div className="top-right">
                                        <button className="btn-nav-r" ref={target} onClick={() => setShow(!show)}>
                                            <img id="flag" src={flag} alt="translate" />
                                            <p>Translation</p>
                                            <img id="toggle-t" src={show ? less : more} alt="expand"/>
                                        </button>
                                        <Overlay 
                                            target={target.current} 
                                            placement="bottom" 
                                            show={show} 
                                            onHide={() => setShow(false)}
                                            rootClose={true}
                                        >
                                            {(props) => (
                                                <div data-testid="overlay-translation" className="translate-top-wrap" {...props}>
                                                    <div id="translation-set-item">
                                                        <p>Translate to English</p>
                                                        <BKSwitch 
                                                            alt="showTranslate"
                                                            id="translate" 
                                                            checked={showTranslate} 
                                                            onChange={(e) => onShowTranslate(e.target.checked)}
                                                        />
                                                    </div>
                                                    <div id="translation-set-item">
                                                        <p>Show All Translation</p>
                                                        <BKSwitch 
                                                            alt="showAllTranslate"
                                                            id="alltranslate" 
                                                            checked={showAllTranslate} 
                                                            onChange={(e) => setShowAllTranslate(e.target.checked)} 
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                        </Overlay>
                                    </div>
                                }
                            </div>
                        }
                        <Switch>
                            <Redirect exact from={url} to={`${url}/kronologi`} />
                            <Route path={`${url}/kronologi`} render={(props) => 
                                <Kronologi
                                    {...props}
                                    id_record={id_record}
                                    showAllTranslate={showAllTranslate}
                                    translateBtn={showTranslate}
                                    showKeyword={showKeyword}
                                    searchWord={searchWord}
                                    setSearchWord={setSearchWord}
                                />
                            } />
                            
                            {/* { process.env.REACT_APP_TYPE !== "PANORAMA" && 
                                <Route path={`${url}/paragraf`} render={(props) => 
                                    <Paragraf
                                        {...props}
                                        id_record={id_record}
                                        showAllTranslate={showAllTranslate}
                                        translateBtn={showTranslate}
                                        showKeyword={showKeyword}
                                        searchWord={searchWord}
                                    />
                                } />
                            } */}
                            
                            <Redirect strict from="/:someroute" to={url} />
                        </Switch>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default DetailRecord;