import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'

import FetcherReducers from './FetcherReducers';
import NATSDefaultReducers from './NATSDefaultReducers';
import AccountManagementReducers from './AccountManagementReducers';
import UserAndGroupReducers from './UserAndGroupReducers';
import RecordReducers from './RecordReducers';
import SpeakerAndParticipantReducers from './SpeakerAndParticipantReducers';
import UtteranceReducers from './UtteranceReducers';
import ModelAsrReducers from './ModelAsrReducers';
import KeywordReducers from './KeywordReducers';
import QuotaReducers from './QuotaReducers';

const createRootReducer = (history) => combineReducers({
	router: connectRouter(history),
	FetcherReducers,
	NATSDefaultReducers,
	AccountManagementReducers,
	UserAndGroupReducers,
	RecordReducers,
	SpeakerAndParticipantReducers,
	UtteranceReducers,
	ModelAsrReducers,
	KeywordReducers,
	QuotaReducers
});

export default createRootReducer