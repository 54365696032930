import { useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// functions
import { get_empty_avatar } from '../../../../utils/functions'
import { reqAllGroupMemberList, reqAllUserList, resetUserListData } from '../../../../reducers/UserAndGroupReducers'
import { fetchGroupAddMember } from '../../../../reducers/FetcherReducers'

//components
import { Form, Popover, InputGroup, Image, Spinner } from 'react-bootstrap'

//style
import '../ModalProfile.scss'
import './UserAndGroup.scss'

//assets
import search from '../../../img/search.svg'
import plus from '../../../img/plus.svg'
import EmptyState from '../../EmptyState/EmptyState'


function PopoverAddMemberGroup(props) {
    const { setNotifAlertData, idGroup } = props;
    
    const id_group = idGroup;

    const { fetchStatus, fetchData } = useSelector(state => state.FetcherReducers)
    const { 
        userList, 
        groupMemberList 
    } = useSelector(state => state.UserAndGroupReducers)

    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);

    const [searchName, setSearchName] = useState("");

    const wrapperRef = useRef(null);
    
    useEffect(() => {
        if ( props.show ) {
            setLoading(true)
            dispatch(reqAllUserList());
        } else {
            dispatch(resetUserListData());
        }
    }, [props.show]);

    useEffect(() => {
        if ( fetchStatus === "rcvAllUserList" ) {
            if ( fetchData.status === "success" ) {
                setLoading(false)
            } else {
                setLoading("error")
            }
        }
    }, [fetchStatus]);

    function HideOnClickOutsidePopover(ref) {
        useEffect(() => {
          /**
           * Hide popover if clicked on outside
           */
          function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
            //   alert("You clicked outside of me!");
              props.onHide()
            }
          }
          // Bind the event listener
          document.addEventListener("mousedown", handleClickOutside);
          return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
          };
        }, [ref]);
    }

    HideOnClickOutsidePopover(wrapperRef);

    const onReloadClick = () => {
        setLoading(true)
        
        // req user list
        dispatch(reqAllUserList());
    }

    function ItemUser(props) {
        const { user } = props;

        const [loading, setLoading] = useState(false);

        const onAddMemberClick = (user) => {
            setLoading(true)

            const data = {
                id_group: id_group,
                id_user: user.id
            }

            dispatch(fetchGroupAddMember(data))
                .then((res) => {
                    // notif when success delete user
                    setNotifAlertData({
                        status: "success",
                        text: "User added as group member",
                        timeout: 3000
                    })

                    dispatch(reqAllGroupMemberList(id_group))
                })
                .catch((err) => {
                    var error = err.error.toString();
                    
                    // notif when failed delete user
                    setNotifAlertData({
                        status: "error",
                        text: "Failed to add user as group member. "+error,
                        timeout:3000
                    })
                })
        }

        
        const image = ( user.image === undefined || user.image === null || user.image.replace(/\s+/g, '') === "" ? 
            get_empty_avatar() : user.image )

        return (
            <div className="member">
                <Image src={image} id="ava" />
                {/* <div className="add"> */}
                    <p className="p-title">{user.name}</p>
                {/* </div> */}
                <div className="add-btn">
                    { loading ?
                        <Spinner
                            id="plus"
                            as="span"
                            animation="border"
                            size="xs"
                            role="status"
                            aria-hidden="true"
                        />
                        :
                        <img alt="add-user-to-group" src={plus} id="plus" onClick={() => onAddMemberClick(user)} />
                    }
                </div>
            </div>
        )
    }

    //get search result
    var userListFiltered = userList;
    userListFiltered = userListFiltered.filter((el) => el.name.toLowerCase().includes(searchName));
    
    return (
        props.show &&
            <Popover id="popover-add-member" ref={wrapperRef}>
                <Popover.Content>
                    {/* <img className="close-icon" src={xc} onClick={() => props.onHide()} /> */}
                    <p id="title">Add Member</p>
                    <InputGroup className="search-member">
                        <img src={search} className="search-icon" />
                        <Form.Control
                            className="input-search"
                            placeholder="Search name"
                            value={searchName}
                            onChange={(e) => setSearchName(e.target.value)}
                        />
                    </InputGroup>
                    <div className="member-list-add">
                        { userListFiltered.length === 0 ?
                            <EmptyState 
                                state={loading === "error" ? "error" : ( loading ? "loading" : "loaded")}
                                onReloadClick={onReloadClick}
                                noDataText={"Belum ada user"}
                                spinnerSize="sm"
                            />
                            :
                            ( userListFiltered.map((user, i) => (
                                groupMemberList.find(({id_user}) => id_user === user.id) === undefined &&
                                <ItemUser key={"user-"+user.id} user={user} />  
                            )) )
                        }
                    </div>
                </Popover.Content>
            </Popover>
    )
}

export default PopoverAddMemberGroup; 