import { useState } from 'react'
import { useDispatch } from 'react-redux'

// functions
import { reqAllGroupMemberList } from '../../../../reducers/UserAndGroupReducers'
import { fetchGroupDeleteMember } from '../../../../reducers/FetcherReducers'

//components
import { Modal } from 'react-bootstrap'

//style
import '../ModalProfile.scss'
import './UserAndGroup.scss'
import ButtonDangerLoading from '../../Buttons/ButtonDangerLoading'

//assets


function ModalDeleteGroupMember(props) {
    const { setNotifAlertData, groupMemberOnDelete } = props;
    const id_group = props.idGroup;
    const group_name = props.groupName;
    
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    
    const onDeleteBtnClick = () => {
        setLoading(true)

        const data = {
            id_member_group: groupMemberOnDelete.id,
        }

        dispatch(fetchGroupDeleteMember(data))
            .then((res) => {
                setLoading(false)

                props.onHide();
                
                // notif when success delete user
                setNotifAlertData({
                    status: "success",
                    text: "User removed from group",
                    timeout: 3000
                })

                dispatch(reqAllGroupMemberList(id_group))
            })
            .catch((err) => {
                var error = err.error.toString();
                
                setLoading(false)

                // notif when failed delete user
                setNotifAlertData({
                    status: "error",
                    text: "Failed to remove user from group. "+error,
                    timeout:3000
                })
            })
    }

    if ( groupMemberOnDelete === null ) {
        return null
    }

    return (
        <>
            <Modal
                {...props}
                size="sm"
                centered
                backdrop="static"
                id="modal-add-user"
            >
                <div id="add">
                    <div className="top">
                        Delete Member
                    </div>
                    <div className="caption">
                        Apakah anda yakin ingin menghapus user <b>{groupMemberOnDelete.name}</b> dari group <b>{group_name}</b>?
                    </div>
                    <div className="btn-wrap">
                        { loading ?
                            <ButtonDangerLoading />
                            :
                            <>
                                <button 
                                    className="error" 
                                    onClick={onDeleteBtnClick}
                                >
                                    Delete
                                </button>
                                <button
                                    className="primary" 
                                    onClick={() => props.onHide()}
                                >
                                    Cancel
                                </button>
                            </>
                        }
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default ModalDeleteGroupMember;