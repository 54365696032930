import { history } from "../../../store";

import { Row, Col } from 'react-bootstrap'

import './NavStyle.scss'

import arrow_left from '../../img/arrow-back.svg'
import search from '../../img/search-black.svg';
import add from '../../img/plus-1.svg'
import download from '../../img/download-1.svg'

function DetailRecordNavbar(props) {
    const { recordName } = props;

    return (
        <Row className="nav-container">
            <Col>
                <div className="nav-cont detail-cont">
                    <div className="detail-title">
                        <img src={arrow_left} alt="back" onClick={() => history.push("/")} />
                        {recordName}
                    </div>
                    <div className="detail-nav-tool">
                        <div id="search-cont">
                            <img src={search} className="icon" alt="search" />
                            <input placeholder="Search Word" value={props.searchWord} onChange={(e) => props.onInputSearchWord(e.target.value)} />
                        </div>
                        { process.env.REACT_APP_TYPE !== "PANORAMA" && 
                            <div className="btn-wrapper">
                                <button onClick={props.onAddNew} className="secondary"><img src={add} id="ic"/>Add New File</button>
                                <button onClick={props.onDownload} className="primary"><img src={download} id="ic"/>Download</button>
                            </div>
                        }
                    </div>
                </div>
            </Col>      
        </Row> 
    )

}
export default DetailRecordNavbar;