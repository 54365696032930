import React, { Component } from "react"

import store from "../../../store";

// components
import { Container, Row, Col, Spinner } from "react-bootstrap";
import SlideShow from "../../components/Slideshow/SlideShow"
import NotifAlert from "../../components/NotifAlert/NotifAlert"
import { get_random_uuid } from "../../../utils/functions"
import { fetchAuthReset } from "../../../reducers/FetcherReducers";

//assets
import back from "../../img/back.svg"
import close from "../../img/close.svg"
import sent from "../../img/email-sent.svg"


class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showResetPassSuccess: false,
            
            isLoading: false,

            email: "",
             
            notifAlertData: null
        };

        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onBlurEmail = this.onBlurEmail.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.textInput = React.createRef();
        this.focus = this.focus.bind(this);
    }
    
    componentWillUnmount() {
        this.setNotifAlertData(null)
    }

    focus() {
        // Explicitly focus the text input using the raw DOM API
        // Note: we're accessing "current" to get the DOM node
        this.textInput.current.focus();
    }

    setNotifAlertData = (data) => {
        this.setState({
            notifAlertData: data
        })        
    }

    handleEmailChange = ({ target }) => {
        this.setState({
            isLoading: false,
        });
        this.setNotifAlertData(null)

        this.setState({ email: target.value }); 
    };

    onBlurEmail = ({ target }) => {
        var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([a-zA-Z0-9-\\.]+)$/;
        if (reg.test(target.value) === false) {   
            this.setNotifAlertData({
                status: "error",
                text: "invalid email address",
                timeout: -1
            })
        }
    }

    handleKeyDown = (e) => {
        //block if input is space
        if (e.key === " ") {
          e.preventDefault();
        }
    };

    handleSubmit = (e) => {
        e.preventDefault();
        
        // check email
        var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([a-zA-Z0-9-\\.]+)$/;
        if (reg.test(this.state.email) === false) {   // alert('Invalid Email Address');
            this.setState({
                isLoading: false,
            });
            
            this.setNotifAlertData({
                status: "error",
                text: "alamat email tidak sesuai",
                timeout: -1
            })

            return
        }

        let auth_name = this.state.email;
        let method = 1;
        let req_id = get_random_uuid();

        this.setState({
            isLoading: true,
        });

        this.setNotifAlertData(null)

        const data = {
            method,
            auth_name,
            req_id
        }

        store.dispatch(fetchAuthReset(data))
            .then((res) => {
                this.setState({
                    isLoading: false,
                    showResetPassSuccess: true
                })
                
            }).catch((err) => {
                var error = err.error.toString();
                                
                this.setState({
                    isLoading: false,
                });
                
                this.setNotifAlertData({
                    status: "error",
                    text: "Reset password gagal. "+error,
                    timeout: -1
                })

            });       

    }

    ResetPassForm() {
        return (
            <React.Fragment>
                <img className="button-back" src={back} alt="icon-back" onClick={() => this.props.history.go(-1)} />

                <div className="form-wrapper">
                    
                    <NotifAlert 
                        data={this.state.notifAlertData}
                    />
                    
                    <p className="onboard-header">Forgot Password?</p>
                    <p className="onboard-header-span">Enter your registration email to receive reset password instruction.</p>
                    <form onSubmit={this.handleSubmit}>
                        <div className="form-input-onboard">
                            <input
                                alt="input-email"
                                ref={this.textInput}
                                type="email"
                                id="email"
                                placeholder=" "
                                onChange={this.handleEmailChange}
                                onBlur={this.onBlurEmail}
                                onKeyDown={this.handleKeyDown}
                            />
                            <label onClick={this.focus}>Email</label>
                        </div>

                        <button type="submit" 
                            className="submit-button-onboard" 
                            id="submit" 
                            disabled={ this.state.email.length !== 0 ? false : true }
                        >
                            { this.state.isLoading ?
                                <Spinner
                                    as="span"
                                    animation="border"
                                    variant="light"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                /> :
                                "Reset"
                            }
                        </button>
                    </form>
                </div>
            </React.Fragment>
        )
    }

    ResetPassSuccess() {
        return (
            <React.Fragment>
                <img className="button-back" src={close} alt="icon-back" onClick={() => this.props.history.push("/")} />
                <div className="form-wrapper">
                    <div className="onboard-img-sent"><img src={sent} alt="sent-email-image" /></div>
                    <p className="onboard-header">Email has been sent!</p>
                    <p className="onboard-header-span">
                        Let’s check your inbox and click in the received link to reset password
                    </p>
                    {/* <form onSubmit={this.handleSubmit}>
                        <Link to="/">
                            <button type="submit" className="submit-button-onboard" id="submit">
                                Check email
                            </button>
                        </Link>
                    </form> */}
                </div>
            </React.Fragment>
        )
    }

    render() {
        return (
            <React.Fragment>
                <Container fluid className="no-padding">
                    <Row className="onboard-container">
                        <Col sm={5} className="onboard-form">
                            { this.state.showResetPassSuccess ? 
                                this.ResetPassSuccess() 
                                : 
                                this.ResetPassForm()
                            }
                        </Col>
                        <Col sm={7} className="onboard-slider">
                            <div className="slider-wrapper">
                                <SlideShow/>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </React.Fragment>
        )
    }
}
export default ResetPassword