
import { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import { reqAllSpeakerList } from '../../../../reducers/SpeakerAndParticipantReducers';

import OverlayEditSpeaker from '../../ModalSpeakerAndParticipant/OverlayEditSpeaker';
import EmptyState from '../../EmptyState/EmptyState';

//assets
import edit from '../../../img/edit-blue.png'
import play from '../../../img/play-btn.svg'
import pause from '../../../img/pause-audio.png'


const speakerAudio = new Audio();

function SpeakerList(props) {
    const { setNotifAlertData, id_record } = props;
    
    const dispatch = useDispatch();

    const { fetchStatus, fetchData } = useSelector(state => state.FetcherReducers)
    const { speakerList, participantList } = useSelector(state => state.SpeakerAndParticipantReducers)
    
    const [loadingSpeakerList, setLoadingSpeakerList] = useState(false);

    const [play, setPlay] = useState(false);
    const [src, setSrc] = useState("");

    if ( speakerAudio.onended === null ) {
        speakerAudio.onended = () => {
            setPlay(false)
        }
    }

    useEffect(() => {
        setLoadingSpeakerList(true);
        dispatch(reqAllSpeakerList(id_record));

        return () => {
            setLoadingSpeakerList(false);
        }
    }, []);
    
    useEffect(() => {
        if ( fetchStatus === "rcvAllSpeakerList" ) {
            if ( fetchData.status === "success" ) {
                setTimeout(() => {
                    setLoadingSpeakerList(false)
                }, 1000)
            } else {
                setLoadingSpeakerList("error")
            }
        }        
    }, [fetchStatus]);

    const onPlayAudio = (audio) => {
        setSrc(audio)

        if ( speakerAudio.src.indexOf(audio) === -1 ) {
            speakerAudio.pause();
            
            speakerAudio.src = audio;
            speakerAudio.load();

            speakerAudio.play();
            setPlay(true)

        } else {
            // replay
            speakerAudio.play()
            setPlay(true)
        }
    }

    const onPauseAudio = () => {
        // pause
        speakerAudio.pause();
        setPlay(false)
    }

    const onReloadSpeakerListClick = () => {
        setLoadingSpeakerList(true)
        
        // req speaker list
        dispatch(reqAllSpeakerList(id_record));
    }

    return (
        <>
            { speakerList.length === 0 ?
                <EmptyState 
                    state={loadingSpeakerList === "error" ? "error" : ( loadingSpeakerList ? "loading" : "loaded")}
                    onReloadClick={onReloadSpeakerListClick}
                    noDataText={"Belum ada speaker"}
                    spinnerSize="sm"
                />
                :
                ( speakerList.map((speaker) =>
                    <SpeakerItem 
                        key={"speaker-"+speaker.id} 
                        setNotifAlertData={setNotifAlertData}
                        id_record={id_record}
                        speaker={speaker}  
                        participantList={participantList}
                        isAudioPlaying={src.indexOf(speaker.audio) > -1 && play ? true : false}
                        onPlayAudio={() => onPlayAudio(speaker.audio)}
                        onPauseAudio={onPauseAudio}
                    />
                ) )
            }
        </>    
    )
}

function SpeakerItem(props) {
    const { id_record, setNotifAlertData, speaker, participantList, isAudioPlaying, onPlayAudio, onPauseAudio } = props;
    
    const target = useRef(null);

    const [show, setShow] = useState(false);
    
    return (
        <>
            <OverlayEditSpeaker 
                target={target}
                show={show}
                onHide={() => setShow(false)}
                setNotifAlertData={setNotifAlertData}
                id_record={id_record}
                speaker={speaker}               
                participantList={participantList}
            />
            <div className="speaker-list-item">
                <img id="play" src={!isAudioPlaying ? play : pause} alt={!isAudioPlaying ? "icon-play-speaker" : "icon-pause-speaker"} onClick={!isAudioPlaying ? onPlayAudio : onPauseAudio} />
                <span>{speaker.name}</span>
                <div className="tools-container">
                    <img id="edit" className={show?"show":""} src={edit} alt="icon-edit-speaker"
                        ref={target} 
                        onClick={() => setShow(true)}
                    />
                    {/* <img id="delete" src={deletebin} alt="delete" /> */}
                </div>
            </div>
        </>
    )
}

export default SpeakerList;