import $ from "jquery"
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { history } from '../../../../store'

//functions
import { NATSRecordSubscribers, NATSRecordUnsubscribers } from '../../../../middleware/NATSMethods'
import { reqAllUtteranceList } from '../../../../reducers/UtteranceReducers'

//components
import NcloudPagination from '../../../components/NcloudPagination'
import Utterance from '../../../components/Utterance/Utterance'
import EmptyState from '../../../components/EmptyState/EmptyState'
import NoTranscript from '../../../img/no-transcript.svg'
import ShortcutsForWordScoring from '../ShortcutsForWordScoring'
import { threshold } from '../../../../threshold'


function Kronologi(props) {
    const { id_record, searchWord, setSearchWord } = props;

    const dispatch = useDispatch();
    
    const { fetchStatus, fetchData } = useSelector(state => state.FetcherReducers)
    const utteranceList = useSelector(state => state.UtteranceReducers.utteranceList)
    
    const [loading, setLoading] = useState(false);
    const [utteranceOnPlayAudio, setUtteranceOnPlayAudio] = useState(null);

    const limit = 10
    const [offset, setOffset] = useState(0);
    const [activePage, setActivePage] = useState(1);

    const [searchUrl, setSearchUrl] = useState("");

    const [enableWordScoringShortcuts, setEnableWordScoringShortcuts] = useState(true);
    // For focus to 'highlight-transcribe-words
    const [currentTabIndex, setCurrentTabIndex] = useState(null);
    
    var refreshKronologiDataInterval = null;

    const scrollRef = useRef();
    
    useEffect(() => {
        setLoading(true)

        // req utterance list
        dispatch(reqAllUtteranceList(id_record));
        
        // refresh utterance list every 60s
        if ( refreshKronologiDataInterval === null ) {
            // set refresh data interval
            refreshKronologiDataInterval = setInterval(() => {
                // console.log("INTERVAL KRONOLOGI "+id_record+" MASIH JALAN")
                dispatch(reqAllUtteranceList(id_record));
            }, 60000)
        }

        return () => {
            // clear refresh data interval
            clearInterval(refreshKronologiDataInterval);
            refreshKronologiDataInterval = null;
        }
    }, []);
    
    useEffect(() => {
        if ( fetchStatus === "rcvAllUtteranceList" ) {
            if ( fetchData.status === "success" ) {
                setTimeout(() => {
                    setLoading(false)
                }, 500)
            } else {
                setLoading("error")
            }
        }
    }, [fetchStatus]);

    useEffect(() => {
        // update searchUrl state
        const newSearchUrl = decodeURI(history.location.search);  
        if ( searchUrl !== newSearchUrl ) {  
            setSearchUrl(newSearchUrl);
            renderSearchUrl(newSearchUrl)
        }
    }, [history.location.search]);

    useEffect(() => {
        initSearchUrl();
    }, [searchWord, activePage]);
    
    useEffect(() => {
        $(scrollRef.current).animate({
            scrollTop: 0
        }, 0);
    }, [activePage]);

    const initSearchUrl = () => {
        // console.log("initSearchUrl")

        var newSearchUrl = "?";
        var q  = "";
        var page = 1;

        if ( searchWord.replace(/\s+/g, '') !== "" ) {
            q = searchWord;
            newSearchUrl += `q=${q}&`;
        }
        if ( activePage !== 1 ) {
            page = activePage;
            
            // if searchWord changed, set page to 1
            const params = new URLSearchParams(searchUrl);    
            var q = params.get('q');
            q = ( q !== null ? q : "" );

            if ( q !== searchWord ) {
                page = 1;
            }

            if ( page > 1 ) {
                newSearchUrl += `page=${page}&`;
            }
        }

        if ( newSearchUrl.replace('?', '') !== searchUrl.replace('?', '') ) {
            history.push(history.location.pathname+newSearchUrl)
        }
    }

    const renderSearchUrl = (search) => {
        // console.log("renderSearchUrl")

        const params = new URLSearchParams(search);
        
        // searchWord
        var q   = params.get('q');
        q = ( q !== null ? q : "" );

        if ( q !== searchWord) {
            setSearchWord(q);
        }

        // activePage
        var page  = params.get('page'); 
        page = ( page !== null ? parseInt(page) : 1 );
        
        if ( page !== activePage ) {
            handlePageChange(page);
        }
    }

    const handlePageChange = (pageNumber) => {
        // console.log(pageNumber)
        setActivePage(pageNumber);
        setOffset((pageNumber - 1) * limit);
    }

    const onReloadClick = () => {
        setLoading(true)
        
        // req utterance list
        dispatch(reqAllUtteranceList());
    }

    const filterUtterance = (utteranceList) => {
        let utteranceListFiltered = utteranceList;

        // console.log(utteranceListFiltered);
        
        // Apply filter by search word
        var keywords    = [];
        if ( searchWord.replace(/\s+/g, '') !== "" ) {
            keywords                = searchWord.split(" ");
            utteranceListFiltered   = utteranceListFiltered.filter(utt => keywords.some(keyword => utt.transcribe.text.toLowerCase().includes(keyword.toLowerCase())));
        }
          
        // console.log(utteranceListFiltered);
          
        // Add start, end, quality, tabIndex, page in transcribe.words
        utteranceListFiltered = utteranceListFiltered.map((props, index) => {
            const {
                id, 
                transcribe
            } = props;
            
            let transcribe_words = [];

    
            if ( transcribe.words !== null && transcribe.words !== undefined ) {
                // Add start & end index in transcribe.words
                transcribe.words.forEach((w, indexW) => {
                    let start, end;

                    if ( indexW === 0 ) {
                        start = 0;
                    } else {
                        start = transcribe_words[(transcribe_words.length-1)].end+1;
                    }
        
                    end   = start + w.word.length;
                    
                    const quality   = threshold.word_quality.filter((q) => w.score >= q.minValue)[0].label;
                    const tabIndex  = id+""+indexW;
                    const page      = Math.floor(index/limit)+1;
                    
                    transcribe_words.push({
                        // ...w,
                        word: w.word,
                        score: w.score,
                        start: start, 
                        end: end,
                        quality,
                        tabIndex: ( quality === "bad" || quality === "standard" ? tabIndex : undefined ),
                        page
                    });
                })
            }

            // console.log(transcribe_words)

            return {
                ...props,
                transcribe: {
                    ...transcribe,
                    words: transcribe_words,
                }
            }
        })

        // console.log(utteranceListFiltered);

        return utteranceListFiltered;
    }
    
    let utteranceListFiltered = filterUtterance(utteranceList);
    
    // console.log(utteranceList)
    // console.log(utteranceListFiltered);

    // Total utterance after filtered
    const resultTotal       = utteranceListFiltered.length;
    // Get utterance list for a page based on offset limit
    const utteranceListOfPage   = utteranceListFiltered.slice(offset, offset + limit);

    // console.log(utteranceListOfPage)

    return (
        <>
            { enableWordScoringShortcuts &&
                <ShortcutsForWordScoring 
                    activePage={activePage}
                    totalPage={Math.ceil(resultTotal/limit)}
                    utteranceList={utteranceListFiltered}
                    triggerChangePage={handlePageChange}
                    onCurrentTabIndexChanged={(val) => setCurrentTabIndex(val)}
                />
            }

            <NATSRecordSubscribersContainer id_record={id_record} />
        
            <div ref={scrollRef} className="overflow-scroll-gradient">
                <div className="utterance-cont">
                    { utteranceListOfPage.length === 0 ?
                        <EmptyState 
                            state={loading === "error" ? "error" : ( loading ? "loading" : "loaded")}
                            onReloadClick={onReloadClick}
                            noDataImage={NoTranscript}
                            noDataText={"Belum ada transkrip"}
                            searchKeyword={searchWord}
                        />
                        :
                        ( utteranceListOfPage.map((utt) => {
                            return (
                                <Utterance
                                    key={"utterance-"+utt.id}
                                    id_record={id_record}
                                    utteranceData={utt}
                                    showKeyword={props.showKeyword}
                                    showToggleTranslationBtn={props.translateBtn}
                                    showAllTranslation={props.showAllTranslate}
                                    enableAudio={utteranceOnPlayAudio === utt.id ? true : false}
                                    setUtteranceOnPlayAudio={setUtteranceOnPlayAudio}
                                    currentTabIndex={currentTabIndex}
                                    setEnableWordScoringShortcuts={setEnableWordScoringShortcuts}
                                />
                            )
                        }) )
                    }
                </div>
            </div>
            <div className="pagination-cont">
                <NcloudPagination
                    activePage={activePage}
                    itemPerPage={limit}
                    totalItems={resultTotal}
                    onChange={handlePageChange}
                />
            </div>
        </>
    )
}

function NATSRecordSubscribersContainer(props) {
    const { id_record } = props;

    const nats_status = useSelector(state => state.NATSDefaultReducers.nats_status)
    
    const [currentNatsStatus, setCurrentNatsStatus] = useState("");

    useEffect(() => {
        return () => {
            // UNMOUNT
            // unsubscribe
            NATSRecordUnsubscribers(id_record);
        };
    }, []);

    useEffect(() => {
        if ( nats_status !== currentNatsStatus ) {
            setCurrentNatsStatus(nats_status);
            
            if ( nats_status !== "connected" ) {
                // unsubscribe
                NATSRecordUnsubscribers(id_record);
            } else {
                // subscribe
                NATSRecordSubscribers(id_record);
            }
        }
    }, [nats_status]);

    return null;
}

export default Kronologi;