import { useEffect, useState } from 'react'
import styled from 'styled-components'
//component
import { Form, Modal } from 'react-bootstrap'
import DownloadPreview from './DownloadPreview'
//style
import './DownloadTranskrip.scss'
//assets
// import x from '../../img/x-close.svg'
import x from '../../img/x-cancel.svg'
import i from '../../img/info-circle.svg'
import krono from '../../img/kronologi.png'
import parag from '../../img/paragraf.png'
import pdf from '../../img/pdf.png'
import word from '../../img/word.png'
import text from '../../img/text.png'
import pdfact from '../../img/pdf-active.png'
import wordact from '../../img/word-active.png'
import textact from '../../img/text-active.png'
import { useDispatch } from 'react-redux'
import { fetchUtteranceList, fetchUtteranceListParagraph } from '../../../reducers/FetcherReducers'
import { download_meeting_handler } from '../../../utils/DownloadMeetingAsFileFunction'
import ModalLoader from '../ModalLoader/ModalLoader'
import BKSwitch from '../BKSwitch/BKSwitch'


const Button = styled.button`
    border-radius: 3px;
    font-weight: 700;
    padding: 13px 30px;
    border-radius: 8px;
    margin-left: 15px;
    // margin-top: auto;
    font-size: 18px;
    width: 145px 
`;

function DownloadTranskrip(props) {
    const { id_record, record_details } = props;

    const dispatch = useDispatch();

    const [loadingChronology, setLoadingChronology] = useState(false);
    const [loadingChronologyProgress, setLoadingChronologyProgress] = useState(100);
    const [loadingParagraph, setLoadingParagraph] = useState(false);
    const [loadingParagraphProgress, setLoadingParagraphProgress] = useState(100);

    const [selectedType, setSelectedType] = useState([]);
    const [selectedFormat, setSelectedFormat] = useState(null);
    const [translation, setTranslation] = useState(false);
    const [usePassword, setUsePassword] = useState(false);
    const [password, setPass] = useState("");
    const [show_npass, setShowNPass] = useState(false);

    const [showPreview, setShowPreview] = useState(false);

    useEffect(() => {
        setSelectedType([])
        setSelectedFormat(null)
    }, [props.show]);

    // function handlePassChange(e) {
    //     setPass(e.target.value);
    // }
    // function showHideNew(e) {
    //     e.preventDefault();
    //     e.stopPropagation();
    //     setShowNPass(!show_npass);
    // }

    function downloadChronology(format) {
        setLoadingChronologyProgress(0)
        setLoadingChronology(true);

        var retryGetChronology = 0;

        var chronologyList = [];
        const limit = 1000;
        var total = 0;

        const getChronologyList = (offset) => {
            const data = {
                id_record,
                offset,
                limit
            }
            dispatch(fetchUtteranceList(data))
                .then((res) => {
                    // console.log(res.data.length +"|"+ limit)
                    // console.log(res)

                    total = res.total;

                    res.data.forEach((chronology, index) => {
                        chronologyList[offset+index] = chronology;
                    })
                    
                    setLoadingChronologyProgress(Math.round(chronologyList.length/total*100))

                    if ( res.data.length === limit ) {
                        const new_offset = offset+limit;
                        getChronologyList(new_offset);
                    } else {
                        download_meeting_handler("chronology", format, record_details, chronologyList)
                        
                        setTimeout(() => {
                            setLoadingChronology(false)
                            props.onHide();
                        }, 1000)
                    }

                })
                .catch((err) => {
                    // console.log("ERROR")
                    // console.log(err)
                    
                    if ( retryGetChronology === 3 ) {
                        // show error
                        return
                    }

                    retryGetChronology++;
                    getChronologyList(offset);
                })
        }
        
        getChronologyList(0);
    }

    function downloadParagraph(format) {
        setLoadingParagraphProgress(0)
        setLoadingParagraph(true);
        
        var retryGetParagraph = 0;

        var paragraphList = [];
        const limit = 1000;
        var total = 0;

        const getParagraphList = (offset) => {
            const data = {
                id_record,
                offset,
                limit
            }
            dispatch(fetchUtteranceListParagraph(data))
                .then((res) => {
                    // console.log(res.data.length +"|"+ limit)
                    // console.log(res)

                    total = res.total;

                    res.data.forEach((paragraph, index) => {
                        paragraphList[offset+index] = paragraph;
                    })
                    
                    setLoadingParagraphProgress(Math.round(paragraphList.length/total*100))

                    if ( res.data.length === limit ) {
                        const new_offset = offset+limit;
                        getParagraphList(new_offset);
                    } else {
                        download_meeting_handler("paragraph", format, record_details, paragraphList)

                        setTimeout(() => {
                            setLoadingParagraph(false)
                            props.onHide();
                        }, 1000)
                    }

                })
                .catch((err) => {
                    // console.log("ERROR")
                    // console.log(err)
                    
                    if ( retryGetParagraph === 3 ) {
                        // show error
                        return
                    }

                    retryGetParagraph++;
                    getParagraphList(offset);
                })
        }
        
        getParagraphList(0);
    }

    function handleSelectType(type) {
        // console.log("handleSelectType")
        var newSelectedType = selectedType.slice();
        var index = newSelectedType.indexOf(type);
        if ( index >= 0 ) {
            newSelectedType.splice(index, 1)
        } else {
            newSelectedType.push(type)
        }
        setSelectedType(newSelectedType)
        if ( newSelectedType.length === 0 ) {
            setSelectedFormat(null)
        }
    }
    
    const onPreviewClick = () => {
        // fetch statements based in inputted data and generate files
        setShowPreview(true)
    }

    const onDownloadClick = () => {
        // fetch statements based in inputted data and generate files
        // const data = {
        //     type: selectedType,
        //     format: selectedFormat,
        //     translation: translation,
        //     password: ( usePassword ? password : "" )
        // }
        
        // console.log(data)

        selectedType.forEach((type) => {
            if ( type === "chronology" ) {
                downloadChronology(selectedFormat)
            } else {
                downloadParagraph(selectedFormat)
            }
        })
    }

    return (
        <>
            <DownloadPreview
                show={showPreview}
                onHide={() => setShowPreview(false)}
                id_record={id_record}
                record_details={record_details}
                selectedType={selectedType}
                selectedFormat={selectedFormat}
            />
            <Modal
                {...props}
                size="md"
                centered
                className="modal-ct"
            >
                { ( loadingChronology || loadingParagraph ) &&
                    <ModalLoader text={"Loading data "+(loadingChronologyProgress+loadingParagraphProgress)/2+"%"} />
                }

                <div id="ct-container">
                    <div className="ct-top">
                        <h5>Download Transkrip</h5>
                        <img src={x} alt="close" id="close" onClick={props.onHide} />
                    </div>
                    <label>Select Type</label>
                    <div className="select-wrap">
                        <div 
                            className={"type " + (selectedType.indexOf("chronology") >= 0 ? "active" : "") }
                            onClick={() => handleSelectType("chronology")}
                        >
                            <img src={i} className="i" alt="info"/>
                            <img src={krono} className="icon" alt="icon-kronologi"/>
                            <p>Kronologi</p>
                        </div>
                        {/* <div 
                            className={"type " + (selectedType.indexOf("paragraph") >= 0 ? "active" : "") }
                            onClick={() => handleSelectType("paragraph")}
                        >
                            <img src={i} className="i" alt="info"/>
                            <img src={parag} className="icon" alt="icon-paragraf"/>
                            <p>Paragraf</p>
                        </div> */}
                    </div>
                
                    <label>Select Format</label>
                    <div className={"select-wrap "+(selectedType.length === 0 ? "disabled" : "")}>
                        <div className="format" onClick={()=> setSelectedFormat("pdf")}>
                            <img alt="icon-pdf" src={selectedFormat === "pdf" ? pdfact : pdf} />
                        </div>
                        <div className="format" onClick={()=> setSelectedFormat("word")}>
                            <img alt="icon-word" src={selectedFormat === "word" ? wordact : word} />
                        </div>
                        <div className="format" onClick={()=> setSelectedFormat("txt")}>
                            <img alt="icon-txt"  src={selectedFormat === "txt" ? textact : text} />
                        </div>
                    </div>
                    { window._translation_ &&
                        <div className="ct-option">
                            <div>
                                <label>Translation</label>
                                <p>Download your file with english translation</p>
                            </div>
                            <BKSwitch 
                                data-testid="toggle-translation"
                                id="translation"
                                checked={translation}
                                onChange={(e) => setTranslation(e.target.checked)}
                            />
                        </div>
                    }
                    {/* <div className="ct-option">
                        <div>
                            <label>Password</label>
                            <p>Protect your transcript with password</p>
                        </div>
                        <BKSwitch 
                            id="password"
                            checked={usePassword}
                            onChange={(e) => setUsePassword(e.target.checked)}
                        />
                    </div>
                    {
                        usePassword &&
                        <InputGroup className="add-password">
                            <Form.Control className="input-add-pass" value={password} placeholder="input your password" type={show_npass ? "input" : "password"} onChange={handlePassChange} />
                            <InputGroup.Append className="show-hide">
                                <Button className="primary" variant="none" onClick={showHideNew}> {show_npass === false ? <img src={blind} /> : <img src={eye} />}</Button>
                            </InputGroup.Append>
                        </InputGroup>
                    } */}
                    <div className="download-btn-wrap">
                        <Button className="secondary" onClick={onPreviewClick}
                            disabled={selectedType.length === 0 || selectedFormat === null ? true : false}
                        >
                            Preview
                        </Button>
                        <Button className="priamry" disabled={selectedType.length === 0 || selectedFormat === null ? true : false}
                            onClick={onDownloadClick}
                        >
                            Download
                        </Button>
                    </div>           
                </div> 
            </Modal>
        </>
    )

}

export default DownloadTranskrip;