import { useEffect, useState } from 'react';

import { Modal } from 'react-bootstrap'

import './DownloadTranskrip.scss'
// import x from '../../img/x-close.svg'
import x from '../../img/x-cancel.svg'
import { useDispatch } from 'react-redux';
import { fetchUtteranceList, fetchUtteranceListParagraph } from '../../../reducers/FetcherReducers';
import { download_meeting_handler } from '../../../utils/DownloadMeetingAsFileFunction';
import EmptyState from '../EmptyState/EmptyState';

function DownloadPreview(props) {
    const { id_record, record_details, selectedType, selectedFormat } = props;

    const [menuLayout, setMenuLayout] = useState(null);
    
    const dispatch = useDispatch();

    const [loadingChronology, setLoadingChronology] = useState(false);
    const [loadingParagraph, setLoadingParagraph] = useState(false);

    const [chronologyData, setChronologyData] = useState(null);
    const [paragraphData, setParagraphData] = useState(null);

    useEffect(() => {
        if ( props.show ) {
            if ( selectedType.length === 1 ) {
                setMenuLayout(selectedType[0])
            } else {
                setMenuLayout("chronology")
            }
            
            selectedType.forEach((type) => {
                if ( type === "chronology" ) {
                    getChronologyData(selectedFormat)
                } else {
                    getParagraphData(selectedFormat)
                }
            })

        } else {
            setChronologyData(null);
            setParagraphData(null);
        }
    }, [props.show])
    
    function getChronologyData() {
        setLoadingChronology(true);

        var retryGetChronology = 0;

        var chronologyList = [];
        const limit = 1000;

        const getChronologyList = (offset) => {
            const data = {
                id_record,
                offset,
                limit
            }
            dispatch(fetchUtteranceList(data))
                .then((res) => {
                    // console.log(res.data.length +"|"+ limit)
                    // console.log(res)

                    res.data.forEach((chronology, index) => {
                        chronologyList[offset+index] = chronology;
                    })
                    
                    if ( res.data.length === limit ) {
                        const new_offset = offset+limit;
                        getChronologyList(new_offset);
                    } else {
                        // download_meeting_handler("chronology", format, record_details, chronologyList)
                        setChronologyData(chronologyList)

                        // setTimeout(() => {
                            setLoadingChronology(false)
                            // props.onHide();
                        // }, 1000)
                    }

                })
                .catch((err) => {
                    // console.log("ERROR")
                    // console.log(err)
                    
                    if ( retryGetChronology === 3 ) {
                        // show error
                        return
                    }

                    retryGetChronology++;
                    getChronologyList(offset);
                })
        }
        
        getChronologyList(0);
    }

    function getParagraphData() {
        setLoadingParagraph(true);
        
        var retryGetParagraph = 0;

        var paragraphList = [];
        const limit = 1000;

        const getParagraphList = (offset) => {
            const data = {
                id_record,
                offset,
                limit
            }
            dispatch(fetchUtteranceListParagraph(data))
                .then((res) => {
                    // console.log(res.data.length +"|"+ limit)
                    // console.log(res)

                    res.data.forEach((paragraph, index) => {
                        paragraphList[offset+index] = paragraph;
                    })
                    
                    if ( res.data.length === limit ) {
                        const new_offset = offset+limit;
                        getParagraphList(new_offset);
                    } else {
                        // download_meeting_handler("paragraph", format, record_details, paragraphList)
                        setParagraphData(paragraphList)

                        // setTimeout(() => {
                            setLoadingParagraph(false)
                            // props.onHide();
                        // }, 1000)
                    }

                })
                .catch((err) => {
                    // console.log("ERROR")
                    // console.log(err)
                    
                    if ( retryGetParagraph === 3 ) {
                        // show error
                        return
                    }

                    retryGetParagraph++;
                    getParagraphList(offset);
                })
        }
        
        getParagraphList(0);
    }

    const getHTMLData = (data) => {
        var dataHTML = "";

        const title = record_details.name;
        
        dataHTML +=
        '<html style="background-color: #ffffff">'
       +    '<head>'
       +        '<title>' + title + '</title>'
       +    '</head>'
       +    '<body>'
       +        '<div style="font-family: Times New Roman;">'
       +	         '<div style="text-align: center;">' + title + '</div>'
       +	         '<br/>'
       +            '<br/>'
       +            '<table style="border-collapse: collapse; font-family: Times New Roman;">'
       +                '<thead>'
       +                    '<tr>'
       +				         '<th style="padding: 5px 8px; border:1px solid #555555;">No</th>'
       +                        '<th style="padding: 5px 8px; border:1px solid #555555;">Waktu</th>'
       +				         '<th style="padding: 5px 8px; border:1px solid #555555;">Pembicara</th>'
       +				         '<th style="padding: 5px 8px; border:1px solid #555555;">Teks</th>'
       +                    '</tr>'
       +                '</thead>'
       +                '<tbody>'
   
       for (var i = 0; i < data.length; i++) {
           const utterance_index   = i + 1;
           const created_date      = data[i].datetime;
           const speaker_name      = data[i].speaker.name;
           const utterance         = data[i].transcribe.text;
   
           dataHTML +=	     
                               '<tr>'
           +                        '<td style="padding: 5px 8px; border:1px solid #555555;">' + utterance_index + '</td>'
           +                        '<td style="padding: 5px 8px; border:1px solid #555555;">' + created_date + '</td>'
           +                        '<td style="padding: 5px 8px; border:1px solid #555555;">' + speaker_name + '</td>'
           +                        '<td style="padding: 5px 8px; border:1px solid #555555;">' + utterance + '</td>'
           +                   '</tr>';
       }
                 
       dataHTML += 		 
                       '</tbody>'
       +            '</table>'
       +        '</div>'
       +    '</body>'
       +'</html>'
   
       return dataHTML;
    } 

    const getTXTData = (data) => {
        var dataTXT = ""
    
        // const title = record_details.name;
        // const start_time = record_details.starting_date;
    
        for (var i = 0; i < data.length; i++) {
            // var utterance_index = i + 1;
            // var created_date = data[i].datetime;
            
            dataTXT = 
                dataTXT
                // + created_date + "\t" 
                + data[i].transcribe.text + "\r\n";
    
        }

        return dataTXT;
    }

    const onDownloadAllClick = () => {
        selectedType.forEach((type) => {
            if ( type === "chronology" ) {
                download_meeting_handler("chronology", selectedFormat, record_details, chronologyData)
            } else {
                download_meeting_handler("paragraph", selectedFormat, record_details, paragraphData)
            }
        })
    }

    var data = null;
    if ( menuLayout === "chronology" ) {
        data = chronologyData;
    } else {
        data = paragraphData;
    }

    return (
        <Modal
            {...props}
            className="dp-container"
        >
            <nav className="d-nav">
                <img id="close" src={x} alt="close-download-preview" 
                    onClick={() => {props.onHide()}}
                />
                <h5>Download Preview</h5>
                <button 
                    id="d-btn" 
                    disabled={loadingChronology || loadingParagraph ? true : false}
                    onClick={onDownloadAllClick}
                >Download All</button>
            </nav>
            <div className="dp-section">
                <div className="d-head-nav">
                    <div className="d-top-wrap">
                        { props.selectedType.indexOf("chronology") >= 0 &&
                            <button 
                                className={"d-btn-nav " + (menuLayout === "chronology" ? "active" : "")} 
                                onClick={() => setMenuLayout("chronology")}
                            >Kronologi</button>
                        }
                        { props.selectedType.indexOf("paragraph") >= 0 &&
                            <button 
                                className={"d-btn-nav " + (menuLayout === "paragraph" ? "active" : "")} 
                                onClick={() => setMenuLayout("paragraph")}
                            >Paragraf</button>
                        }
                    </div>
                </div>

                { data === null ?
                    <EmptyState 
                        state="loading"
                        loadingText="loading data"
                        bgColor="#ffffff"
                    />
                    :
                    <>
                        { ( selectedFormat === "pdf" || selectedFormat === "word" ) &&
                            <div data-testid="preview-page-download" className="preview-page-download" dangerouslySetInnerHTML={{__html: getHTMLData(data)}}>
                            </div>
                        }
                        { selectedFormat === "txt" &&
                            <div data-testid="preview-page-download" className="preview-page-download" dangerouslySetInnerHTML={{__html: getTXTData(data)}}>
                            </div>
                        }
                    </>
                }


                {/* <div className="preview-page-download">
                    <div className="preview-download-head">
                        <h5 id="left">Dikte.in</h5>
                        <h5>Design Sprint II</h5>
                        <p>Kronologi, 25 April 2021</p>
                    </div>
                    <div className="preview-table">
                        <div id="pt-head">
                            <p className="b-l">Time</p>
                            <p >Speaker</p>
                            <p className="b-r">Transcription</p>
                        </div>
                        <div id="pt-body">
                            <p className="b-r">00:34 - 00:45</p>
                            <p>Farhan Hadi Priatna</p>
                            <p className="b-l">Design sprint kali ini kita akan membahas tentang Produk Diktein. Di produk ini kita perlu validasi bagaimana kebutuhan user terkait produk ini. Di produk ini si user akan melakukan transkrip audio file mereka dan hasilnya akan langsung berupa text yang nantinya bisa di download oleh user. </p>
                        </div>
                        <div id="pt-body">
                            <p className="b-r">00:34 - 00:45</p>
                            <p className="b-r">Farhan Hadi Priatna</p>
                            <p>Design sprint kali ini kita akan membahas tentang Produk Diktein. Di produk ini kita perlu validasi bagaimana kebutuhan user terkait produk ini. Di produk ini si user akan melakukan transkrip audio file mereka dan hasilnya akan langsung berupa text yang nantinya bisa di download oleh user. </p>
                        </div>
                        <div id="pt-body">
                            <p className="b-r">00:34 - 00:45</p>
                            <p className="b-r">Farhan Hadi Priatna</p>
                            <p>Design sprint kali ini kita akan membahas tentang Produk Diktein. Di produk ini kita perlu validasi bagaimana kebutuhan user terkait produk ini. Di produk ini si user akan melakukan transkrip audio file mereka dan hasilnya akan langsung berupa text yang nantinya bisa di download oleh user. </p>
                        </div>                        
                        <div id="pt-body">
                            <p className="b-r">00:34 - 00:45</p>
                            <p className="b-r">Farhan Hadi Priatna</p>
                            <p>Design sprint kali ini kita akan membahas tentang Produk Diktein. Di produk ini kita perlu validasi bagaimana kebutuhan user terkait produk ini. Di produk ini si user akan melakukan transkrip audio file mereka dan hasilnya akan langsung berupa text yang nantinya bisa di download oleh user. </p>
                        </div>
                        <div id="pt-body">
                            <p className="b-r">00:34 - 00:45</p>
                            <p className="b-r">Farhan Hadi Priatna</p>
                            <p>Design sprint kali ini kita akan membahas tentang Produk Diktein. Di produk ini kita perlu validasi bagaimana kebutuhan user terkait produk ini. Di produk ini si user akan melakukan transkrip audio file mereka dan hasilnya akan langsung berupa text yang nantinya bisa di download oleh user. </p>
                        </div>
                        <div id="pt-body">
                            <p className="b-r">00:34 - 00:45</p>
                            <p className="b-r">Farhan Hadi Priatna</p>
                            <p>Design sprint kali ini kita akan membahas tentang Produk Diktein. Di produk ini kita perlu validasi bagaimana kebutuhan user terkait produk ini. Di produk ini si user akan melakukan transkrip audio file mereka dan hasilnya akan langsung berupa text yang nantinya bisa di download oleh user. </p>
                        </div>
                        <div id="pt-body">
                            <p className="b-r">00:34 - 00:45</p>
                            <p className="b-r">Farhan Hadi Priatna</p>
                            <p>Design sprint kali ini kita akan membahas tentang Produk Diktein. Di produk ini kita perlu validasi bagaimana kebutuhan user terkait produk ini. Di produk ini si user akan melakukan transkrip audio file mereka dan hasilnya akan langsung berupa text yang nantinya bisa di download oleh user. </p>
                        </div>
                        <div id="pt-body">
                            <p className="b-r">00:34 - 00:45</p>
                            <p className="b-r">Farhan Hadi Priatna</p>
                            <p>Design sprint kali ini kita akan membahas tentang Produk Diktein. Di produk ini kita perlu validasi bagaimana kebutuhan user terkait produk ini. Di produk ini si user akan melakukan transkrip audio file mereka dan hasilnya akan langsung berupa text yang nantinya bisa di download oleh user. </p>
                        </div>
                    </div>
                </div> */}
            </div>
        </Modal>
    )
}
export default DownloadPreview;