import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { history } from "../../../store";

//functions
import { utterance_list_data } from '../../../dummy/dummy';

//components
import { Col, Row } from 'react-bootstrap';
import NcloudPagination from '../../components/NcloudPagination';
import EmptyState from '../../components/EmptyState/EmptyState';
import NoTranscript from '../../img/no-transcript.svg'
import Utterance from '../../components/Utterance/Utterance';

//style
import '../DetailRecord/DetailRecord.scss'
import './Dashboard.scss';

//assets


function TranscriptList(props) {
    const { 
        searchTranscriptGlobally,
        latRange,
        lngRange,
        lobRange,
        selectedLatRange,
        selectedLngRange,
        selectedLobRange,
        onSaveFilterTranscriptGlobally
    } = props;

    const dispatch = useDispatch();

	const { fetchStatus, fetchData } = useSelector(state => state.FetcherReducers)
    // const utteranceList = useSelector(state => state.UtteranceReducers.utteranceList)
    const utteranceList = utterance_list_data;
    
    const [loading, setLoading] = useState(false);
    const [utteranceOnPlayAudio, setUtteranceOnPlayAudio] = useState(null);
    
    // Filter

    const limit = ( process.env.REACT_APP_TYPE === "PANORAMA" ? 60 : 59 );
    const [offset, setOffset] = useState(0);
    const [activePage, setActivePage] = useState(1);
    
    const [searchUrl, setSearchUrl] = useState("");

    useEffect(() => {
        // setLoading(true)

        return () => {
        }
    }, []);

    useEffect(() => {
        // if ( fetchStatus === "rcvAllRecordList" ) {
        //     if ( fetchData.status === "success" ) {
        //         setTimeout(() => {
        //             setLoading(false)
        //         }, 500)
        //     } else {
        //         setLoading("error")
        //     }
        // } 
    }, [fetchStatus]);
    
    useEffect(() => {
        // update searchUrl state
        const newSearchUrl = decodeURI(history.location.search);  
        if ( searchUrl !== newSearchUrl ) {  
            setSearchUrl(newSearchUrl);
            renderSearchUrl(newSearchUrl)
        }
    }, [history.location.search]);

    useEffect(() => {
        initSearchUrl();
    }, [
        searchTranscriptGlobally, 
        selectedLatRange,
        selectedLngRange,
        selectedLobRange,
        activePage
    ]);

    const initSearchUrl = () => {
        // console.log("initSearchUrl")

        var newSearchUrl = "?";
        var qg  = "";
        var lat = latRange;
        var lng = lngRange;
        var lob = lobRange;
        var page = 1;

        // keyword
        if ( searchTranscriptGlobally.replace(/\s+/g, '') !== "" ) {
            qg = searchTranscriptGlobally;
            newSearchUrl += `qg=${qg}&`
        }
        // lat
        if ( JSON.stringify(selectedLatRange) !== JSON.stringify(latRange) ) {
            lat = JSON.stringify(selectedLatRange);
            newSearchUrl += `lat=${lat}&`
        }
        // lng
        if ( JSON.stringify(selectedLngRange) !== JSON.stringify(lngRange) ) {
            lng = JSON.stringify(selectedLngRange);
            newSearchUrl += `lng=${lng}&`
        }
        // lob
        if ( JSON.stringify(selectedLobRange) !== JSON.stringify(lobRange) ) {
            lob = JSON.stringify(selectedLobRange);
            newSearchUrl += `lob=${lob}&`
        }
        // page
        if ( activePage !== 1 ) {
            page = activePage;
            
            // if searchWord changed, set page to 1
            const params = new URLSearchParams(searchUrl);    
            var qg = params.get('qg');
            qg = ( qg !== null ? qg : "" );

            if ( qg !== searchTranscriptGlobally ) {
                page = 1;
            }

            if ( page > 1 ) {
                newSearchUrl += `page=${page}&`;
            }
        }

        if ( newSearchUrl.replace('?', '') !== searchUrl.replace('?', '') ) {
            history.push(history.location.pathname+newSearchUrl)
        }
    }

    const renderSearchUrl = (search) => {
        // console.log("renderSearchUrl")

        const params = new URLSearchParams(search);
        
        // keyword
        var qg   = params.get('qg');
        qg = ( qg !== null ? qg : "" );
        // lat
        var lat  = params.get('lat'); 
        lat = ( lat !== null ? JSON.parse(lat) : latRange );
        // lng
        var lng  = params.get('lng'); 
        lng = ( lng !== null ? JSON.parse(lng) : lngRange);
        // lob
        var lob  = params.get('lob'); 
        lob = ( lob !== null ? JSON.parse(lob) : lobRange);
        // page
        var page  = params.get('page'); 
        page = ( page !== null ? parseInt(page) : 1 );
        
        if ( page !== activePage ) {
            handlePageChange(page);
        }

        onSaveFilterTranscriptGlobally(
            qg,
            lat,
            lng,
            lob
        )
        
        const data = {
            keyword: qg,
            lat,
            lng,
            lob,
            offset,
            limit
        }
        // console.log("REQUEST TRANSCRIPT GLOBAL DATA NOW")
        // console.log(data)
        // dispatch(fetchUtterancesGlobally(data))
        //     .then((res) => {
        //         if ( 
        //             res.request.keyword !== keyword ||
        //             res.request.lat     !== lat     ||
        //             res.request.lng     !== lng     ||
        //             res.request.lob     !== lob     ||
        //             res.request.offset  !== offset  ||
        //             res.request.limit   !== limit
        //         ) {
        //             return;
        //         }

        //         if ( res.data.length === 0 && res.total !== 0 ) {
        //             handlePageChange(1);
        //         }

        //     })
        //     .catch((err) => {

        //     })

    }

    const handlePageChange = (pageNumber) => {
        // console.log(pageNumber)
        setActivePage(pageNumber);
        setOffset((pageNumber - 1) * limit);
    }

    const onReloadClick = () => {
        // setLoading(true)
    }

    function TranscriptListData() {
        var utteranceListFiltered = utteranceList;

        // Apply filter by search word
        var keywords    = [];
        if (searchTranscriptGlobally.replace(/\s+/g, '') !== "") {
            keywords                = searchTranscriptGlobally.split(" ");
            utteranceListFiltered   = utteranceListFiltered.filter(utt => keywords.some(keyword => utt.transcribe.text.toLowerCase().includes(keyword.toLowerCase())));
        }

        // // Apply filter from ModalFilter
        // // selectedTranscriptStatus
        // if ( selectedTranscriptStatus.length !== 0 && selectedTranscriptStatus.length !== listStatus.length ) {
        //     utteranceListFiltered  = utteranceListFiltered.filter(rec => selectedTranscriptStatus.some( status => rec.state.toLowerCase() === status.toLowerCase() ) );
        // }
        // // selectedDate
        // const start = selectedDate.start !== null ? moment(selectedDate.start) : moment(-1);
        // const end   = selectedDate.end !== null ? moment(selectedDate.end).add(1, 'days') : moment(999999999999999);
        // utteranceListFiltered  = utteranceListFiltered.filter( ({datetime, end_date}) => moment(datetime) > start && moment(end_date) < end );

        // Sort by date desc
        utteranceListFiltered.sort((a,b) => (a.datetime < b.datetime) ? 1 : ((b.datetime < a.datetime) ? -1 : 0));

        const resultTotal       = utteranceListFiltered.length;
        utteranceListFiltered   = utteranceListFiltered.slice(offset, offset + limit);
    
        return (
            <div className="overflow-scroll-gradient">
                <div className="utterance-cont">
                    { utteranceListFiltered.length === 0 ?
                        <EmptyState 
                            state={loading === "error" ? "error" : ( loading ? "loading" : "loaded")}
                            onReloadClick={onReloadClick}
                            noDataImage={NoTranscript}
                            noDataText={"Belum ada transkrip"}
                            searchKeyword={searchTranscriptGlobally}
                        />
                        :
                        ( utteranceListFiltered.map((utt) => (
                            <Utterance
                                key={"utterance-"+utt.id}
                                id_record={utt.id_record}
                                utteranceData={utt}
                                // showKeyword={props.showKeyword}
                                // showToggleTranslationBtn={props.translateBtn}
                                // showAllTranslation={props.showAllTranslate}
                                enableAudio={utteranceOnPlayAudio === utt.id ? true : false}
                                setUtteranceOnPlayAudio={setUtteranceOnPlayAudio}
                            />
                        )) )
                    }
                    <div className="pagination-cont">
                        <NcloudPagination
                            activePage={activePage}
                            itemPerPage={limit}
                            totalItems={resultTotal}
                            onChange={handlePageChange}
                        />
                    </div>
                </div>
            </div>
        );
    }
    
    return(
        <>
            <Row className="dashboard-body">
                <Col className="col-max-1564">
                    {TranscriptListData()}
                </Col>
            </Row>
        </>
    )
}

export default TranscriptList;