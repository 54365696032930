import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';

//functions
import { reqAllUtteranceListParagraph } from '../../../../reducers/UtteranceReducers';

//components
import NcloudPagination from '../../../components/NcloudPagination'
import Utterance from '../../../components/Utterance/Utterance'
import EmptyState from '../../../components/EmptyState/EmptyState';
import NoTranscript from '../../../img/no-transcript.svg'
import { history } from '../../../../store';


function Paragraf(props) {
    const { id_record } = props;

    const dispatch = useDispatch();
    
    const { fetchStatus, fetchData } = useSelector(state => state.FetcherReducers)
    const utteranceListParagraph = useSelector(state => state.UtteranceReducers.utteranceListParagraph)
    
    const [loading, setLoading] = useState(false);

    const limit = 10
    const [offset, setOffset] = useState(0);
    const [activePage, setActivePage] = useState(1);

    const [searchUrl, setSearchUrl] = useState("");

    var refreshParagrafDataInterval = null;

    useEffect(() => {
        setLoading(true)
        
        // req utterance list paragraph 
        dispatch(reqAllUtteranceListParagraph(id_record));
        
        // refresh utterance list paragraph every 60s
        if ( refreshParagrafDataInterval === null ) {
            // set refresh data interval
            refreshParagrafDataInterval = setInterval(() => {
                // console.log("INTERVAL PARAGRAF "+id_record+" MASIH JALAN")
                dispatch(reqAllUtteranceListParagraph(id_record));
            }, 60000)
        }

        return () => {
            // clear refresh data interval
            clearInterval(refreshParagrafDataInterval);
            refreshParagrafDataInterval = null;
        }
    }, []);
    
    useEffect(() => {
        if ( fetchStatus === "rcvAllUtteranceListParagraph" ) {
            if ( fetchData.status === "success" ) {
                setTimeout(() => {
                    setLoading(false)
                }, 500)
            } else {
                setLoading("error")
            }
        }
    }, [fetchStatus]);

    useEffect(() => {
        // update searchUrl state
        const newSearchUrl = decodeURI(history.location.search);  
        if ( searchUrl !== newSearchUrl ) {  
            setSearchUrl(newSearchUrl);
            renderSearchUrl(newSearchUrl)
        }
    }, [history.location.search]);

    useEffect(() => {
        initSearchUrl();
    }, [props.searchWord, activePage]);

    const initSearchUrl = () => {
        // console.log("initSearchUrl")

        var newSearchUrl = "?";
        var q  = "";
        var page = 1;

        if ( props.searchWord.replace(/\s+/g, '') !== "" ) {
            q = props.searchWord;
            newSearchUrl += `q=${q}&`;
        }
        if ( activePage !== 1 ) {
            page = activePage;
            
            // if searchWord changed, set page to 1
            const params = new URLSearchParams(searchUrl);    
            var q = params.get('q');
            q = ( q !== null ? q : "" );

            if ( q !== props.searchWord ) {
                page = 1;
            }

            if ( page > 1 ) {
                newSearchUrl += `page=${page}&`;
            }
        }

        if ( newSearchUrl.replace('?', '') !== searchUrl.replace('?', '') ) {
            history.push(history.location.pathname+newSearchUrl)
        }
    }

    const renderSearchUrl = (search) => {
        // console.log("renderSearchUrl")

        const params = new URLSearchParams(search);
        
        // props.searchWord
        var q   = params.get('q');
        q = ( q !== null ? q : "" );

        if ( q !== props.searchWord) {
            props.setSearchWord(q);
        }

        // activePage
        var page  = params.get('page'); 
        page = ( page !== null ? parseInt(page) : 1 );
        
        if ( page !== activePage ) {
            handlePageChange(page);
        }
    }

    const handlePageChange = (pageNumber) => {
        // console.log(pageNumber)
        setActivePage(pageNumber);
        setOffset((pageNumber - 1) * limit);
    }

    const onReloadClick = () => {
        setLoading(true)
        
        // req utterance paragraph list
        dispatch(reqAllUtteranceListParagraph());
    }
    
    var utteranceListParagraphFiltered = utteranceListParagraph;

    // Apply filter by search word
    var keywords    = [];
    if (props.searchWord.replace(/\s+/g, '') !== "") {
        keywords                        = props.searchWord.split(" ");
        utteranceListParagraphFiltered  = utteranceListParagraphFiltered.filter(utt => keywords.some(keyword => utt.transcribe.text.toLowerCase().includes(keyword.toLowerCase())));
    }
    
    const resultTotal               = utteranceListParagraphFiltered.length;
    utteranceListParagraphFiltered  = utteranceListParagraphFiltered.slice(offset, offset + limit);

    return (
        <>
            <div className="overflow-scroll-gradient">
                <div className="utterance-cont">
                    { utteranceListParagraphFiltered.length === 0 ?
                        <EmptyState 
                            state={loading === "error" ? "error" : ( loading ? "loading" : "loaded")}
                            onReloadClick={onReloadClick}
                            noDataImage={NoTranscript}
                            noDataText={"Belum ada paragraf"}
                            searchKeyword={props.searchWord}
                        />
                        :        
                        ( utteranceListParagraphFiltered.map((utt) => {
                            return (
                                <Utterance
                                    key={"utterance-paragraph-"+utt.id}
                                    id_record={id_record}
                                    utteranceData={utt}
                                    showKeyword={props.showKeyword}
                                    showToggleTranslationBtn={props.translateBtn}
                                    showAllTranslation={props.showAllTranslate}
                                    enableAudio={false}
                                />
                            )
                        }) )
                    }
                </div>
            </div>
            <div className="pagination-cont">
                <NcloudPagination
                    activePage={activePage}
                    itemPerPage={limit}
                    totalItems={resultTotal}
                    onChange={handlePageChange}
                />
            </div>
        </>
    )
}
export default Paragraf;