import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

//functions
import { reqAllKeywordList } from '../../../../reducers/KeywordReducers'
import { fetchKeywordUpdate } from '../../../../reducers/FetcherReducers'

//components
import { Modal, InputGroup, Form, Spinner } from 'react-bootstrap'
import NotifAlert from '../../NotifAlert/NotifAlert'
import EmptyState from '../../EmptyState/EmptyState'
import ModalAddKeyword from './ModalAddKeyword'
import ModalDeleteKeyword from './ModalDeleteKeyword'

//assets
// import x from '../../img/x-close.svg'
import x from '../../../img/x-cancel.svg'
import search from '../../../img/search.svg'
import edit_blue from '../../../img/edit-blue.svg'
import delete_red from '../../../img/x-red.svg'
import check_green from '../../../img/check-circle-1.svg'

import './ModalKeywordMaster.scss'


function ModalKeywordMaster(props) {
    const dispatch = useDispatch();
    
    const { fetchStatus, fetchData } = useSelector(state => state.FetcherReducers)
    const keywordList = useSelector(state => state.KeywordReducers.keywordList)
    
    const [loading, setLoading] = useState(false);

    const [notifAlertData, setNotifAlertData] = useState(null);

    const [searchKeyword, setSearchKeyword] = useState("");

    const [showAddKeyword, setShowAddKeyword] = useState(false);
    const [showDeleteKeyword, setShowDeleteKeyword] = useState(false);
    
    const [keywordOnDelete, setKeywordOnDelete] = useState(null);
    
    useEffect(() => {
        if ( props.show ) {
            setLoading(true);

            dispatch(reqAllKeywordList());
        } else {
            setNotifAlertData(null);
            // dispatch(resetKeywordListData());
        }
    }, [props.show]);
    
    useEffect(() => {
        if ( fetchStatus === "rcvAllKeywordList" ) {
            if ( fetchData.status === "success" ) {
                setTimeout(() => {
                    setLoading(false)
                }, 500)
            } else {
                setLoading("error")
            }
        }
    }, [fetchStatus]);

    const onReloadClick = () => {
        setLoading(true)
        
        // req keyword list
        dispatch(reqAllKeywordList());
    }

    const keywordListFiltered = keywordList.filter(({keyword}) =>
        keyword.toLowerCase().includes(searchKeyword.toLowerCase())
    );
    
    return (
        <Modal 
            {...props} 
            size="lg" 
            centered 
            className="modal-my-account"
            id="modal-keyword-master"
        >
            { notifAlertData !== null &&
                <NotifAlert 
                    data={notifAlertData}
                />
            }

            <ModalAddKeyword
                show={showAddKeyword}
                onHide={()=>setShowAddKeyword(false)}
            />
            
            <ModalDeleteKeyword
                show={showDeleteKeyword}
                onHide={() => setShowDeleteKeyword(false)}
                setNotifAlertData={setNotifAlertData}
                keywordOnDelete={keywordOnDelete}
            />

            <div className="km-min-height">
                <div className="my-account-header">
                    <h5>Keyword Master</h5>
                    <img src={x} alt="close" onClick={() => props.handleClick()} />
                </div>
                <div className="km-cont">
                    <div className="km-top-wrapper">
                        <h5>Keyword List</h5>
                        <InputGroup className="search-wrap">
                            <img src={search} className="search-icon" />
                            <Form.Control
                                placeholder="Search Keyword"
                                value={searchKeyword}
                                onChange={(e) => setSearchKeyword(e.target.value)}
                            />
                        </InputGroup>
                        <label id="add-btn-label" onClick={()=>setShowAddKeyword(true)}>+ Add Keyword</label>
                    </div>
                    <div className="km-list-wrapper">
                        { keywordListFiltered.length === 0 ?
                            <EmptyState 
                                style={{height: "calc(100% - 280px)", top: "unset"}}
                                state={loading === "error" ? "error" : ( loading ? "loading" : "loaded")}
                                onReloadClick={onReloadClick}
                                noDataText={"Belum ada keyword"}
                            />
                            :
                            ( keywordListFiltered.map((item) => (
                                <KeywordItem 
                                    key={item.id}
                                    setNotifAlertData={setNotifAlertData}
                                    item={item}
                                    onDeleteClick={() => {
                                        setKeywordOnDelete(item); 
                                        setShowDeleteKeyword(true);
                                    }}
                                />
                            )) )
                        }
                    </div>
                </div>
            </div>
        </Modal>
    )
}

function KeywordItem(props) {
    const { item } = props;

    const [loadingSave, setLoadingSave] = useState(false);
    const [onEdit, setOnEdit] = useState(false);

    return (
        onEdit ?
            <KeywordItemOnEdit 
                {...props} 
                setOnEdit={setOnEdit}
                setLoadingSave={setLoadingSave}
            />
            :
            <div className="keyword-item">
                { !loadingSave &&
                    <div className="overlay-name">{item.keyword}</div>
                }
                <div className="content">
                    <span className="name">{item.keyword}</span>
                    <div className="button-container">
                        { loadingSave ?
                            <Spinner
                                className="icon-loading"
                                as="span"
                                animation="border"
                                variant="dark"
                                size="xs"
                                role="status"
                                aria-hidden="true"
                            />
                            :
                            <>
                                <img src={edit_blue} alt="edit-keyword" className="icon" onClick={() => setOnEdit(true)}/>
                                <img src={delete_red} alt={"icon-delete-keyword"} className="icon" onClick={props.onDeleteClick} />
                            </>
                        }
                    </div>
                </div>
            </div>
    )
}

function KeywordItemOnEdit(props) {
    const { 
        setNotifAlertData, 
        item, 
        setOnEdit,
        setLoadingSave 
    } = props;
    
    const dispatch = useDispatch();

    const [name, setName] = useState(item.keyword);

    var oneditRef = null;

    useEffect(() => {
        // console.log("componentDidMount")
        if ( oneditRef !== null ) {
            placeCaretAtEnd(oneditRef)
        }
    }, []);

    const placeCaretAtEnd = (el) => {
        el.focus();
        if ( 
            typeof window.getSelection != "undefined" && 
            typeof document.createRange != "undefined"
        ) {
            var range = document.createRange();
            range.selectNodeContents(el);
            range.collapse(false);
            var sel = window.getSelection();
            sel.removeAllRanges();
            sel.addRange(range);
        } else if (typeof document.body.createTextRange != "undefined") {
            var textRange = document.body.createTextRange();
            textRange.moveToElementText(el);
            textRange.collapse(false);
            textRange.select();
        }
    }

    const handleNameChange = (e) => {
        const value = e.currentTarget.textContent;

        // // Max keyword length is 32
        // if ( value.length > 32 ) {
        //     e.preventDefault();
        //     setNotifAlertData({
        //         status: "error",
        //         text: "Max 32 character",
        //         timeout: 3000
        //     })
        //     return;
        // } 
        
        setName(value)
    }

    const handleKeyDown = (e) => {
        // console.log(e)
        const key = e.key;
        const value = e.currentTarget.textContent;

        // AN ALPHABET
        if ( key.toString().length === 1 && !!key.match(/[a-z]/i) ) {
            // Max keyword length is 32
            if ( value.length >= 32 ) {
                e.preventDefault();
                setNotifAlertData({
                    status: "error",
                    text: "Max 32 character",
                    timeout: 3000
                })
                return;
            } 
        } 
        // CONTROL
        else if ( key.toString().length !== 1 ) {
            // Enter
            if ( e.keyCode === 13 ) {
                e.preventDefault();
                onSaveClick();
            }             
        }
        // NOT AN ALPHABET
        // might be number or punctuation
        else {
            e.preventDefault();
        }
    }
    
    const onSaveClick = () => {
        // check name
        if ( name.replace(/\s+/g, '') === "") {
            setNotifAlertData({
                status: "error",
                text: "keyword tidak boleh kosong",
                timeout: -1
            })
            return
        } 
        // Max keyword length is 32
        else if ( name.length > 32 ) {
            setNotifAlertData({
                status: "error",
                text: "Max 32 character",
                timeout: 3000
            })
            return;
        } 

        setLoadingSave(true);
        setOnEdit(false)

        var data = {
            id: item.id,
            keyword: name
        }

        dispatch(fetchKeywordUpdate(data))
            .then((res) => {
                setLoadingSave(false);

                // notif when success delete user
                setNotifAlertData({
                    status: "success",
                    text: "Keyword updated",
                    timeout: 3000
                })

                dispatch(reqAllKeywordList());
            })
            .catch((err) => {
                var error = err.error.toString();
                
                setLoadingSave(false);

                // notif when failed delete user
                setNotifAlertData({
                    status: "error",
                    text: "Failed update keyword. "+error,
                    timeout:3000
                })
            })
    }

    return (
        <div data-testid="keyword-item-onedit" className="keyword-item onedit">
            <div className="content">
                <span 
                    className="name"
                    data-testid="input-edit-keyword"
                    contentEditable={true}
                    ref={c => oneditRef = c}   
                    onInput={(e) => handleNameChange(e)}
                    onKeyDown={(e) => handleKeyDown(e)}
                    dangerouslySetInnerHTML={{__html: item.keyword}}
                >
                    {/* {name} */}
                </span>
                <div className="button-container">
                    <img src={check_green} alt="save-keyword" className="icon-check" onClick={onSaveClick}/>
                </div>
            </div>
        </div>
    )
}

export default ModalKeywordMaster;