//JS IMPORTS
import { clearLocalStorage, clearCookie } from "../utils/functions";

/**
 * NOTICE
 * ONLY PUT ACTIONS AND REDUCERS THAT DEAL WITH NATS API 
 * IN THIS MODULE
 */

/** 
Declare the type of action as constant.
WHY?
    - Help with reducing typos 
    - Help with reducing bugs and mistakes
    - If you make typos and dispatch an undefined constant,
      the app will throw an error to alert the mistake. 
*/

// IMPORT ACTIONS FROM OUTSIDE SECTION
/** Put your imported actions here */

// ACTION TYPES SECTION
const NATS_OPEN         = "NATS_OPEN";
const NATS_CLOSE        = "NATS_CLOSE";
const NATS_CONNECTING   = "NATS_CONNECTING";
const NATS_CONNECTED    = "NATS_CONNECTED";
const NATS_DISCONNECT   = "NATS_DISCONNECT";
const NATS_DISCONNECTED = "NATS_DISCONNECTED";
const NATS_MSG_SEND     = "NATS_MSG_SEND";
const NO_DATA_RECEIVED  = "NO_DATA_RECEIVED";


// DEFAULT ACTION NATS SECTION

/** Call this to open NATS connection */
export const connOpen = () => ({
    type: NATS_OPEN,
    nats_status: 'open'
});

/** Will be called when connecting to NATS */
export const connConnecting = () => ({
    type: NATS_CONNECTING,
    nats_status: 'connecting'
});

/** Will be called when connected to NATS */
export const connConnected = () => ({
    type: NATS_CONNECTED,
    nats_status: 'connected'
});

/** Call this to close NATS connection */
export const connDisconnect = () => ({
    type: NATS_DISCONNECT,
    nats_status: 'disconnecting'
});

/** Will be called when disconnected from NATS */
export const connDisconnected = () => ({
    type: NATS_DISCONNECTED,
    nats_status: 'disconnected'
});

// SIDE EFFECTS 

export const connDisconnectAndLogout = () => (dispatch) => {
    // remove user's data from local storage
    clearCookie();
    clearLocalStorage();
    
    return dispatch(connDisconnect());
}

/** Call this to send message via NATS */
export const messageSend = (topic, msg) => {
    // console.log("====== OUTGOING DATA ======");
    // console.log(msg);
    // console.log("===========================");
    return {
        type: NATS_MSG_SEND,
        request_topic: topic,
        message: msg
    };

};

// INITIAL STATE SECTION
const initialState = {
    nats_status     : 'disconnected',
    request_topic   : '',
    message         : {}
};


// REDUCERS SECTION

// You must only write pure function when trying to build the reducer! 

export default function NATSDefaultReducers(state = initialState, action) {
    switch (action.type) {
        case NATS_OPEN:
            return {
                ...state,
                nats_status: action.nats_status
            }
        case NATS_CONNECTING:
            return {
                ...state,
                nats_status: action.nats_status
            }
        case NATS_CONNECTED:
            return {
                ...state,
                nats_status: action.nats_status
            }
        case NATS_DISCONNECT:
            return {
                ...state,
                nats_status: action.nats_status
            }
        case NATS_DISCONNECTED:
            return {
                ...state,
                nats_status: action.nats_status
            }
        case NATS_CLOSE:
            return {
                ...state,
                nats_status: action.nats_status
            }
        case NATS_MSG_SEND:
            return {
                ...state,
                request_topic   : action.request_topic,
                message         : action.message
            }
        case NO_DATA_RECEIVED:
            return {
                ...state
            }
        default:
            return { ...state }
    }
}
