import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

//functions
import { rcvUtteranceUpdateSpeaker } from '../../../reducers/UtteranceReducers';
import { fetchUtteranceUpdateSpeaker } from '../../../reducers/FetcherReducers';
import { toastNotification } from '../ToastNotification/ToastNotification';
import { reqAllParticipantList } from '../../../reducers/SpeakerAndParticipantReducers';

//components
import { OverlayTrigger, Spinner } from 'react-bootstrap';

//style
import './Utterance.scss'

//assets
import check from '../../img/check-circle.svg'
import circle from '../../img/bx-circle.svg'
import EmptyState from '../EmptyState/EmptyState';


function UtteranceChangeParticipant(props) {
    const { 
        show, 
        toggleShow,
        speaker,
        setSpeakerLoading,
        id_record,
        id_utterance
    } = props;

    const id = id_utterance;
    
    const { fetchStatus, fetchData } = useSelector(state => state.FetcherReducers)
    const participantList = useSelector(state => state.SpeakerAndParticipantReducers.participantList)
    const utteranceList = useSelector(state => state.UtteranceReducers.utteranceList)

    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [participantListWithOcc, setParticipantListWithOcc] = useState([]);

    useEffect(() => {
        // console.log(show)
        if ( show ) {
            setLoading(true);
            dispatch(reqAllParticipantList(id_record));
        } else {
        }
    }, [show]);
    
    useEffect(() => {
        if ( fetchStatus === "rcvAllParticipantList" ) {
            if ( fetchData.status === "success" ) {
                setTimeout(() => {
                    setLoading(false)
                }, 1000)
            } else {
                setLoading("error")
            }

        }
    }, [fetchStatus]);
    
    useEffect(() => {
        // get participant's occurences in utterances
        var participantListWithOccurenceInUtterance = [];
        
        participantList.forEach(pt => {
            const participantOccurence = utteranceList.filter( ({speaker}) => speaker.id === pt.id_user ).length;

            var participant = JSON.parse(JSON.stringify(pt));
            participant.occ = participantOccurence;
            
            participantListWithOccurenceInUtterance.push(participant)
        });   
        
        // sort by occurences desc
        participantListWithOccurenceInUtterance.sort((a,b) => a.occ < b.occ ? 1 : -1);

        setParticipantListWithOcc(participantListWithOccurenceInUtterance)
    }, [participantList, utteranceList]);

    const onSelectSpeaker = (participant) => {
        setSpeakerLoading(true)
        
        const data = {
            id_utterance: id, 
            id_participant: participant.id,
            participant
        }

        dispatch(fetchUtteranceUpdateSpeaker(data))
            .then((res) => {
                setSpeakerLoading(false)

                dispatch(rcvUtteranceUpdateSpeaker(res))
            })
            .catch((err) => {
                var error = err.error.toString();
                
                setSpeakerLoading(false)

                toastNotification("error", "Update utterance speaker failed. "+error)
            })
        
            toggleShow();

    }

    const onReloadClick = () => {
        setLoading(true)
        
        // req participant list
        dispatch(reqAllParticipantList(id_record));
    }

    return (
        <OverlayTrigger
            show={show}
            trigger="click"
            key="bottom"
            placement="bottom"
            onToggle={toggleShow}
            rootClose={true}
            overlay={
                <div className="utt-change-speaker">
                    <h5>Change Participant</h5>
                    <div className="utt-speaker-list-cont">
                        <div className="utt-speaker-list-wrap">
                            { participantListWithOcc.length === 0 ? 
                            // participantList.length === 0 ? 
                                <EmptyState 
                                    state={loading === "error" ? "error" : ( loading ? "loading" : "loaded")}
                                    onReloadClick={onReloadClick}
                                    noDataText={"Belum ada partisipan"}
                                    spinnerSize="sm"
                                />
                                :
                                ( participantListWithOcc.map((pt) => 
                                // participantList.map((pt) => 
                                    <SpeakerItem 
                                        key={"participant-"+pt.id}
                                        speaker={pt.name}
                                        active={pt.id_user === speaker.id ? true : false}
                                        onSelect={() => onSelectSpeaker(pt)}
                                    />
                                ) ) 
                            }
                        </div>
                    </div>
                </div>
            }
        >
            <h4 data-testid="show-change-speaker" 
                onClick={toggleShow}
            >
                <span>{speaker.id === 0 ? <span style={{opacity: 0.2}}>Pembicara</span> : speaker.name}</span> 
                <span className={show ? "cs-btn op" : "cs-btn"}>Change Participant</span>
            </h4>
        </OverlayTrigger>
    )
}

function SpeakerItem(props) {
    const { active } = props;

    const [loading, setLoading] = useState(false);
    
    useEffect(() => {
        if ( active ) {
            setLoading(false)
        }
    }, [active]);

    const onSelectSpeaker = () => {
        setLoading(true)

        props.onSelect()
    }

    // const onPlayClick = (e) => {
    //     e.stopPropagation()
    // }

    return (
        <div className="utt-speaker-list-item" onClick={!active ? onSelectSpeaker : () => {}}>
            {/* <img
                alt="icon-play-select-speaker"  
                id="icon" 
                src={play} 
                onClick={onPlayClick} 
            />  */}
            <span className="speaker-name">{props.speaker}</span>
            { active ?
                <img alt="selected-speaker" id="icon-check" src={check}/>
                : 
                ( loading ?
                    <Spinner
                        id="icon-circle"
                        as="span"
                        animation="border"
                        size="xs"
                        role="status"
                        aria-hidden="true"
                    />
                    :
                    <img alt="select-speaker" id="icon-circle" src={circle}/>
                )
            }
             
        </div>
                                       
    )
}

export default UtteranceChangeParticipant;