import { useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// functions
import { get_empty_avatar } from '../../../../utils/functions'
import { 
    reqAllGroupList, 
    reqAllGroupMemberList, 
    reqAllUserList, 
    resetGroupListData, 
    resetGroupMemberListData, 
    resetUserListData, 
    updateIdGroupOfMemberList 
} from '../../../../reducers/UserAndGroupReducers'

//components
import { Modal, Row, Col } from 'react-bootstrap'
import NotifAlert from '../../NotifAlert/NotifAlert'
import ModalAddUser from './ModalAddUser'
import ModalDeleteUser from './ModalDeleteUser'
import ModalAddGroup from './ModalAddGroup'
import ModalEditGroupName from './ModalEditGroupName'
import ModalDeleteGroup from './ModalDeleteGroup'
import ModalDeleteGroupMember from './ModalDeleteGroupMember'

//style
import '../ModalProfile.scss'
import './UserAndGroup.scss'

//assets
import profile from '../../../img/profile.svg'
import profilewhite from '../../../img/profile-white.svg'
import xc from '../../../img/x-cancel.svg'
import arrow from '../../../img/arrow-right.svg'
import arrowleft from '../../../img/arrow-left.svg'
import folder from '../../../img/folder.svg'
import upload from '../../../img/upload-file.svg'
import PopoverAddMemberGroup from './PopoverAddMemberGroup'
import NcloudPagination from '../../NcloudPagination'
import EmptyState from '../../EmptyState/EmptyState'


function ModalUser(props) {
    const [notifAlertData, setNotifAlertData] = useState(null);

    const [showModal, setShowModal] = useState(1);
    
    const [selectedGroup, setSelectedGroup] = useState(null);
    
    useEffect(() => {
        if ( !props.show ) {
            setNotifAlertData(null)
        }

    }, [props.show]);
    
    function switchTab() {
        switch (showModal) {
            case 2: return (
                <TabGroupList 
                    setNotifAlertData={setNotifAlertData}
                    setShowModal={setShowModal}
                    selectedGroup={selectedGroup} 
                    setSelectedGroup={setSelectedGroup} 
                />
            )

            case 3: return (
                <TabGroupDetail 
                    setNotifAlertData={setNotifAlertData}
                    setShowModal={setShowModal}
                    selectedGroup={selectedGroup} 
                    setSelectedGroup={setSelectedGroup} 
                />
            )

            default: return ( 
                <TabUserList 
                    setNotifAlertData={setNotifAlertData}
                    setShowModal={setShowModal}
                    selectedGroup={selectedGroup} 
                    setSelectedGroup={setSelectedGroup} 
                />
            )
        }
    }

    return (
        <Modal 
            {...props} 
            size="lg" 
            centered 
            className="modal-my-account"
        >
            { notifAlertData !== null &&
                <NotifAlert
                    data={notifAlertData}
                />
            }

            <div>
                <div className="my-account-header">
                    <h5>User</h5>
                    <img src={xc} alt="close" onClick={() => props.handleClick()} />
                </div>
                <Row className="my-account-main">
                    <Col sm={3} className="left side-menu-myacc">
                        <button onClick={() => setShowModal(1)} className={showModal === 1 ? "btn-side-nav active" : "btn-side-nav"}> <img src={showModal === 1 ? profilewhite : profile} alt="icon-tab-profile" /> User</button>
                        <button onClick={() => setShowModal(2)} className={showModal === 2 || showModal === 3 ? "btn-side-nav active" : "btn-side-nav"}> <img src={showModal === 2 || showModal === 3 ? profilewhite : profile} alt="icon-tab-group" /> Group</button>
                    </Col>
                    <Col sm={9} className="right">
                        {switchTab()}
                    </Col>
                </Row>
            </div>
        </Modal>
    )
}

function TabUserList(props) {
    const { 
        setNotifAlertData
    } = props;

    const { fetchStatus, fetchData } = useSelector(state => state.FetcherReducers)
    const { 
        userList
    } = useSelector(state => state.UserAndGroupReducers)
	
    const dispatch = useDispatch();
    
    const [loading, setLoading] = useState(false);
    
    // User
    const [showAddUser, setShowAddUser] = useState(false);
    const [showDelUser, setShowDelUser] = useState(false);
    const [userOnDelete, setUserOnDelete] = useState(null);

    const limit = 12;
    const [offset, setOffset] = useState(0);
    const [activePage, setActivePage] = useState(1);

    useEffect(() => {
        setLoading(true)
        dispatch(reqAllUserList());

        return () => {
            dispatch(resetUserListData());
        }
    }, []);

    useEffect(() => {
        if ( fetchStatus === "rcvAllUserList" ) {
            if ( fetchData.status === "success" ) {
                setLoading(false)
            } else {
                setLoading("error")
            }
        }
    }, [fetchStatus]);

    const handlePageChange = (pageNumber) => {
        // console.log(pageNumber)
        setActivePage(pageNumber);
        setOffset((pageNumber - 1) * limit);
    }

    const onReloadClick = () => {
        setLoading(true)
        
        // req user list
        dispatch(reqAllUserList());
    }

    function ItemUser(props) {    
        const image = ( props.image === undefined || props.image === null || props.image.replace(/\s+/g, '') === "" ? 
        get_empty_avatar() : props.image )

        return (
            <div className="user-item">
                <img src={image} id="ava" />
                <p id="name">{props.name}</p>
                <div id="close" onClick={props.onDelete}>
                    <img id="icon" src={xc} alt={"icon-remove-user"} />
                </div>
            </div>
        )
    }

    const resultTotal       = userList.length;
    const userListFiltered  = userList.slice(offset, offset + limit);

    return (
        <>
        
            <ModalAddUser
                show={showAddUser}
                onHide={() => setShowAddUser(false)}
                setNotifAlertData={setNotifAlertData}
            />
            <ModalDeleteUser
                show={showDelUser}
                onHide={() => setShowDelUser(false)}
                setNotifAlertData={setNotifAlertData}
                userOnDelete={userOnDelete}
            />

            <div className="user-main-wrapper">
                <div className="user-head-wrapper">
                    <div className="add-btn" onClick={() => setShowAddUser(true)}>+Add User</div>
                </div>
                <div className="user-list-wrapper">
                    { userListFiltered.length === 0 ?
                        <EmptyState 
                            state={loading === "error" ? "error" : ( loading ? "loading" : "loaded")}
                            onReloadClick={onReloadClick}
                            noDataText={"Belum ada user"}
                        />
                        :
                        ( userListFiltered.map((item) => (
                            <ItemUser
                                key={"user-"+item.id}
                                name={item.name}
                                onDelete={() => {
                                    setUserOnDelete(item);
                                    setShowDelUser(true);
                                }}
                            />
                        )) )
                    }
                </div>
                <div className="pagination-cont">
                    <NcloudPagination
                        activePage={activePage}
                        itemPerPage={limit}
                        totalItems={resultTotal}
                        onChange={handlePageChange}
                    />
                </div>
            </div>
        </>
    )
}

function TabGroupList(props) {
    const { 
        setNotifAlertData, 
        setShowModal, 
        selectedGroup, 
        setSelectedGroup 
    } = props;

	const { fetchStatus, fetchData } = useSelector(state => state.FetcherReducers)
    const {
        groupList
    } = useSelector(state => state.UserAndGroupReducers)
    
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    
    const [showAddGroup, setShowAddGroup] = useState(false);

    const limit = 10;
    const [offset, setOffset] = useState(0);
    const [activePage, setActivePage] = useState(1);

    useEffect(() => {
        setLoading(true)
        dispatch(reqAllGroupList()); 
        
        return () => {
            dispatch(resetGroupListData())
        }
    }, []);

    useEffect(() => {
        if ( fetchStatus === "rcvAllGroupList" ) {
            if ( fetchData.status === "success" ) {
                setLoading(false)
            } else {
                setLoading("error")
            }
        }
    }, [fetchStatus]);

    const handlePageChange = (pageNumber) => {
        // console.log(pageNumber)
        setActivePage(pageNumber);
        setOffset((pageNumber - 1) * limit);
    }

    const onSelectGroup = (item) => {
        dispatch(updateIdGroupOfMemberList(item.id));
        setSelectedGroup(item);
        setShowModal(3);
    }

    const onReloadClick = () => {
        setLoading(true)
        
        // req user list
        dispatch(reqAllGroupList());
    }

    function ItemGroup(props) {
        return (
            <div className="group-item" onClick={props.onSelectGroup}>
                <img src={folder} id="icon" alt="icon-group" />
                <p id="name">
                    <b>{props.name}</b>
                    {/* <span>100 Members</span> */}
                </p>
                <img src={arrow} id="arrow" alt="arrow" />
            </div>
        )
    }

    const resultTotal       = groupList.length;
    const groupListFiltered = groupList.slice(offset, offset + limit);

    return (
        <>

            <ModalAddGroup
                show={showAddGroup}
                onHide={() => setShowAddGroup(false)}
                setNotifAlertData={setNotifAlertData}
            />

            <div className="user-main-wrapper">
                <div className="user-head-wrapper">
                    <div className="top-head">Group List</div>
                    <div className="add-btn" onClick={() => setShowAddGroup(true)}>+Create Group</div>
                </div>
                <div className="user-list-wrapper">
                    { groupListFiltered.length === 0 ?
                        <EmptyState 
                            state={loading === "error" ? "error" : ( loading ? "loading" : "loaded")}
                            onReloadClick={onReloadClick}
                            noDataText={"Belum ada grup"}
                        />
                        :
                        ( groupListFiltered.map((item) => (
                            <ItemGroup
                                key={"group-"+item.id}
                                name={item.name}
                                // member={item.member.length}
                                onSelectGroup={() => onSelectGroup(item)}
                            />
                        )) )
                    }
                </div>
                <div className="pagination-cont">
                    <NcloudPagination
                        activePage={activePage}
                        itemPerPage={limit}
                        totalItems={resultTotal}
                        onChange={handlePageChange}
                    />
                </div>
            </div>
        </>

    )
}

function TabGroupDetail(props) {   
    const { 
        setNotifAlertData, 
        setShowModal, 
        selectedGroup, 
        setSelectedGroup 
    } = props;

    const { fetchStatus, fetchData } = useSelector(state => state.FetcherReducers)
    const { 
        groupList,
        groupMemberList
    } = useSelector(state => state.UserAndGroupReducers)

    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);

    const [showDelGroup, setShowDelGroup] = useState(false);
    const [showEditGroup, setShowEditGroup] = useState(false);
    const [showAddMember, setShowAddMember] = useState(false);
    const [showDelGroupMember, setShowDelGroupMember] = useState(false);
    const [groupMemberOnDelete, setGroupMemberOnDelete] = useState(null);

    const limit = 12;
    const [offset, setOffset] = useState(0);
    const [activePage, setActivePage] = useState(1);

    const popoverTarget = useRef(null);

    useEffect(() => {
        setLoading(true)
        dispatch(reqAllGroupMemberList(selectedGroup.id));
        
        return () => {
            dispatch(resetGroupMemberListData());
        }
    }, []);

    useEffect(() => {
        if ( fetchStatus === "rcvAllGroupMemberList" ) {
            if ( fetchData.status === "success" ) {
                setTimeout(() => {
                    setLoading(false)
                }, 500)
            } else {
                setLoading("error")
            }
        }
    }, [fetchStatus]);

    useEffect(() => {
        // update selectedGroup if its name changed in group list
        const index = groupList.findIndex(({id, name}) => id === selectedGroup.id && name !== selectedGroup.name);
        if ( index > -1 ) {
            setSelectedGroup(groupList[index])
        }
    }, [groupList]);

    const onBackToGroupList = () => {
        setShowModal(2);
    }

    const handlePageChange = (pageNumber) => {
        // console.log(pageNumber)
        setActivePage(pageNumber);
        setOffset((pageNumber - 1) * limit);
    }

    const onReloadClick = () => {
        setLoading(true)
        
        // req group member list
        dispatch(reqAllGroupMemberList(selectedGroup.id));
    }

    function ItemGroupMember(props) {    
        const image = ( props.image === undefined || props.image === null || props.image.replace(/\s+/g, '') === "" ? 
        get_empty_avatar() : props.image )

        return (
            <div className="user-item">
                <img src={image} id="ava" />
                <p id="name">{props.name}</p>
                <div id="close" onClick={props.onDelete}>
                    <img id="icon" src={xc} alt="icon-remove-group-member" />
                </div>
            </div>
        )
    }

    const resultTotal               = groupMemberList.length;
    const groupMemberListFiltered   = groupMemberList.slice(offset, offset + limit);

    return (
        <>
        
            <ModalEditGroupName
                show={showEditGroup}
                onHide={() => setShowEditGroup(false)}
                setNotifAlertData={setNotifAlertData}
                selectedGroup={selectedGroup}
            />
            <ModalDeleteGroup
                show={showDelGroup}
                onHide={() => setShowDelGroup(false)}
                setNotifAlertData={setNotifAlertData}
                selectedGroup={selectedGroup}
                onBackToGroupList={() => setShowModal(2)}
            />
            <ModalDeleteGroupMember
                show={showDelGroupMember}
                onHide={() => setShowDelGroupMember(false)}
                setNotifAlertData={setNotifAlertData}
                idGroup={selectedGroup !== null ? selectedGroup.id : null}
                groupName={selectedGroup.name}
                groupMemberOnDelete={groupMemberOnDelete}
            />
            <PopoverAddMemberGroup
                show={showAddMember}
                onHide={() => setShowAddMember(false)}
                setNotifAlertData={setNotifAlertData}
                target={popoverTarget}
                idGroup={selectedGroup.id}
            />

            <div className="user-main-wrapper">
                <div className="user-head-wrapper">
                    <div className="top-head">
                        <img alt="back-to-list-group" id="arrow" src={arrowleft} onClick={onBackToGroupList} />
                        <span className="title">{selectedGroup.name}</span>
                    </div>
                    <div className="add-btn" ref={popoverTarget} onClick={() => setShowAddMember(true)}>+Add Member</div>
                </div>
                <div className="user-list-wrapper">
                    { groupMemberListFiltered.length === 0 ?
                        <EmptyState 
                            state={loading === "error" ? "error" : ( loading ? "loading" : "loaded")}
                            onReloadClick={onReloadClick}
                            noDataImage={upload}
                            noDataText={"Belum Ada Member"}
                        >
                            {/* <div className="no-items">
                                <img src={upload} alt="icon" id="icon" />
                                <label>Belum Ada Member</label>
                                <p>Silahkan tambahkan anggota group</p>
                            </div> */}
                        </EmptyState>
                        : 
                        ( groupMemberListFiltered.map((item) => (
                                <ItemGroupMember
                                    key={"group-member-"+item.id}
                                    image={item.image}
                                    name={item.name}
                                    onDelete={() => {
                                        setGroupMemberOnDelete(item);
                                        setShowDelGroupMember(true);
                                    }}
                                />
                        )) )
                    }
                </div>
                <div className="pagination-cont">
                    <NcloudPagination
                        activePage={activePage}
                        itemPerPage={limit}
                        totalItems={resultTotal}
                        onChange={handlePageChange}
                    />
                </div>
                <div className="user-footer-wrapper">
                    <p id="edit" onClick={() => setShowEditGroup(true)}>Edit Group</p>
                    <p id="delete" onClick={() => setShowDelGroup(true)}>Delete Group</p>
                </div>
            </div>
        </>

    )
}

export default ModalUser;