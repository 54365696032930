
/** 
Declare the type of action as constant.
WHY?
    - Help reduce typos 
    - Help reduce bugs and mistake
    - If you make typo and dispatch an undefined constants,
      the app will throw error to alert the mistake. 
*/

import { toastNotification } from "../apps/components/ToastNotification/ToastNotification";
// import { people_list_data, team_list_data, team_member_list_data } from "../dummy/dummy";
import { fetchGroupList, fetchGroupListMember, fetchUserList, updateFetchStatusAndData } from "./FetcherReducers";

// Import

// Functions

// Action Types
const UPDATE_USER_LIST_OFFSET  				= "UserAndGroupReducers/UPDATE_USER_LIST_OFFSET";
const UPDATE_USER_LIST_AND_TOTAL  			= "UserAndGroupReducers/UPDATE_USER_LIST_AND_TOTAL";
const RESET_USER_LIST_DATA  				= "UserAndGroupReducers/RESET_USER_LIST_DATA";
const UPDATE_GROUP_LIST_OFFSET  			= "UserAndGroupReducers/UPDATE_GROUP_LIST_OFFSET";
const UPDATE_GROUP_LIST_AND_TOTAL  			= "UserAndGroupReducers/UPDATE_GROUP_LIST_AND_TOTAL";
const RESET_GROUP_LIST_DATA  				= "UserAndGroupReducers/RESET_GROUP_LIST_DATA";
const UPDATE_ID_GROUP_OF_MEMBER_LIST  		= "UserAndGroupReducers/UPDATE_ID_GROUP_OF_MEMBER_LIST";
const UPDATE_GROUP_MEMBER_LIST_OFFSET		= "UserAndGroupReducers/UPDATE_GROUP_MEMBER_LIST_OFFSET";
const UPDATE_GROUP_MEMBER_LIST_AND_TOTAL	= "UserAndGroupReducers/UPDATE_GROUP_MEMBER_LIST_AND_TOTAL";
const RESET_GROUP_MEMBER_LIST_DATA  		= "UserAndGroupReducers/RESET_GROUP_MEMBER_LIST_DATA";

export const updateUserListAndTotal = data => ({
	type: UPDATE_USER_LIST_AND_TOTAL,
    payload: {
        userList: data.userList,
        userListTotal: data.userListTotal
    }
});

export const resetUserListData = () => ({
	type: RESET_USER_LIST_DATA
});

export const updateGroupListAndTotal = data => ({
	type: UPDATE_GROUP_LIST_AND_TOTAL,
    payload: {
        groupList: data.groupList,
        groupListTotal: data.groupListTotal
    }
});

export const resetGroupListData = () => ({
	type: RESET_GROUP_LIST_DATA
});

export const updateIdGroupOfMemberList = id_group => ({
	type: UPDATE_ID_GROUP_OF_MEMBER_LIST,
    payload: {
        idGroupOfMemberList: id_group
    }
});

export const updateGroupMemberListAndTotal = data => ({
	type: UPDATE_GROUP_MEMBER_LIST_AND_TOTAL,
    payload: {
        groupMemberList: data.groupMemberList,
        groupMemberListTotal: data.groupMemberListTotal
    }
});

export const resetGroupMemberListData = () => ({
	type: RESET_GROUP_MEMBER_LIST_DATA
});

// You can put impure functions within your action creators

// Reducers
export const reqAllUserList = (offset = 0) => (dispatch, getState) => { 
    dispatch(updateFetchStatusAndData("reqAllUserList"));

	const limit = getState().UserAndGroupReducers.userListLimit;

	const data = {
		offset,
		limit
	}

	dispatch(fetchUserList(data))
		.then((res) => {
			dispatch(rcvAllUserList(res))
		})
		.catch((resErr) => {
			dispatch(rcvAllUserList(resErr))
		})
};

export const rcvAllUserList = (msg) => (dispatch, getState) => {
    const offset = getState().UserAndGroupReducers.userListOffset;
    const limit  = getState().UserAndGroupReducers.userListLimit;
    const total  = getState().UserAndGroupReducers.userListTotal;
	
	const request       = msg.request;
    const res_offset    = request.offset;
	const res_total 	= msg.total;

    // RETURN if not the response of last requested user list
    if ( res_offset !== offset ) 
        return;

    // clear user list if received total is not equal to current total 
	if ( offset === 0 && total !== res_total ) {
		dispatch(resetUserListData())
	}

    dispatch(updateFetchStatusAndData("rcvAllUserList", msg));

    if ( msg.status === "failed" ) {
        toastNotification("error", "Gagal memuat daftar user. "+msg.error)
        return;
    }

	var userList = getState().UserAndGroupReducers.userList.slice();
	msg.data.forEach((user, index) => {
		userList[offset+index] = user;
	})

	const userListAndTotal = {
		userList: userList,
		userListTotal: res_total
	}
	dispatch(updateUserListAndTotal(userListAndTotal));

	if ( msg.data.length === limit ) {
		dispatch(reqAllUserList((offset+limit)));
	}
};

export const reqAllGroupList = (offset = 0) => (dispatch, getState) => { 
    dispatch(updateFetchStatusAndData("reqAllGroupList"));

	const limit = getState().UserAndGroupReducers.groupListLimit;

	const data = {
		offset,
		limit
	}

	dispatch(fetchGroupList(data))
		.then((res) => {
			dispatch(rcvAllGroupList(res))
		})
		.catch((resErr) => {
			dispatch(rcvAllGroupList(resErr))
		})
};

export const rcvAllGroupList = (msg) => (dispatch, getState) => {
    const offset = getState().UserAndGroupReducers.groupListOffset;
    const limit  = getState().UserAndGroupReducers.groupListLimit;
    const total  = getState().UserAndGroupReducers.groupListTotal;

	const request       = msg.request;
    const res_offset    = request.offset;
	const res_total 	= msg.total;

    // RETURN if not the response of last requested group list
    if ( res_offset !== offset ) 
        return;

    // clear group list if received total is not equal to current total 
    if ( offset === 0 && total !== res_total ) {
        dispatch(resetGroupListData())
    }

    dispatch(updateFetchStatusAndData("rcvAllGroupList", msg));

    if ( msg.status === "failed" ) {
        toastNotification("error", "Gagal memuat daftar group. "+msg.error)
        return;
    }

	var groupList = getState().UserAndGroupReducers.groupList.slice();
	msg.data.forEach((group, index) => {
		groupList[offset+index] = group;
	})

	const groupListAndTotal = {
		groupList: groupList,
		groupListTotal: res_total
	}
	dispatch(updateGroupListAndTotal(groupListAndTotal));

	if ( msg.data.length === limit ) {
		dispatch(reqAllGroupList((offset+limit)));
	}
};

export const reqAllGroupMemberList = (id_group, offset = 0) => (dispatch, getState) => { 
	// RETURN if received member list list not belong to current opened group
	const idGroupOfMemberList = getState().UserAndGroupReducers.idGroupOfMemberList;
    if ( id_group !== idGroupOfMemberList ) return;
    
	dispatch(updateFetchStatusAndData("reqAllGroupMemberList"));

	const limit = getState().UserAndGroupReducers.groupMemberListLimit;

	const data = {
		id_group,
		offset,
		limit
	}

	dispatch(fetchGroupListMember(data))
		.then((res) => {
			dispatch(rcvAllGroupMemberList(res))
		})
		.catch((resErr) => {
			dispatch(rcvAllGroupMemberList(resErr))
		})
};

export const rcvAllGroupMemberList = (msg) => (dispatch, getState) => {
    const offset = getState().UserAndGroupReducers.groupMemberListOffset;
    const limit  = getState().UserAndGroupReducers.groupMemberListLimit;
    const total  = getState().UserAndGroupReducers.groupMemberListTotal;

	const request   	= msg.request;
    const { id_group } 	= request;
    const res_offset    = request.offset;
	const res_total 	= msg.total;
	
	// RETURN if received group member list list not belong to current opened group
    const idGroupOfMemberList = getState().UserAndGroupReducers.idGroupOfMemberList;
    if ( id_group !== idGroupOfMemberList ) return;

    // RETURN if not the response of last requested group member list
    if ( res_offset !== offset ) 
        return;
		
    // clear group member list if received total is not equal to current total 
	if ( offset === 0 && total !== res_total ) {
		dispatch(resetGroupMemberListData())
	}

	dispatch(updateFetchStatusAndData("rcvAllGroupMemberList", msg));

    if ( msg.status === "failed" ) {
        toastNotification("error", "Gagal memuat daftar member group. "+msg.error)
        return;
    }

	var groupMemberList = getState().UserAndGroupReducers.groupMemberList.slice();
	msg.data.forEach((group, index) => {
		groupMemberList[offset+index] = group;
	})

	const groupMemberListAndTotal = {
		groupMemberList: groupMemberList,
		groupMemberListTotal: res_total
	}
	dispatch(updateGroupMemberListAndTotal(groupMemberListAndTotal));

	if ( msg.data.length === limit ) {
		dispatch(reqAllGroupMemberList((offset+limit)));
	}
};

// Reducer's initial state
const initialState = {
	userList		: [],
	userListOffset	: 0,
    userListLimit	: 1000,
	userListTotal	: 0,
	
	groupList		: [],
	groupListOffset	: 0,
    groupListLimit	: 1000,
	groupListTotal	: 0,

	idGroupOfMemberList		: null,
	groupMemberList			: [],
	groupMemberListOffset	: 0,
    groupMemberListLimit	: 1000,
	groupMemberListTotal	: 0,
};


// You must only write pure function when trying to build the reducer! 

export default function UserAndGroupReducers(state = initialState, action) {
	switch (action.type) {
		case UPDATE_USER_LIST_OFFSET:
			return {
				...state,
				userListOffset: action.payload.userListOffset
			};
        case UPDATE_USER_LIST_AND_TOTAL:
            return {
                ...state,
                userList: action.payload.userList,
                userListTotal: action.payload.userListTotal
            };
		case RESET_USER_LIST_DATA:
			return {
				...state,
                userList         : [],
                userListOffset   : 0,
                userListTotal    : 0,
			};
		case UPDATE_GROUP_LIST_OFFSET:
			return {
				...state,
				groupListOffset: action.payload.groupListOffset
			};
		case UPDATE_GROUP_LIST_AND_TOTAL:
			return {
				...state,
				groupList: action.payload.groupList,
				groupListTotal: action.payload.groupListTotal
			};
		case RESET_GROUP_LIST_DATA:
			return {
				...state,
				groupList         : [],
				groupListOffset   : 0,
				groupListTotal    : 0,
			};
		case UPDATE_ID_GROUP_OF_MEMBER_LIST:
			return {
				...state,
				idGroupOfMemberList: action.payload.idGroupOfMemberList,
			};
		case UPDATE_GROUP_MEMBER_LIST_OFFSET:
			return {
				...state,
				groupMemberListOffset: action.payload.groupMemberListOffset
			};
		case UPDATE_GROUP_MEMBER_LIST_AND_TOTAL:
			return {
				...state,
				groupMemberList: action.payload.groupMemberList,
				groupMemberListTotal: action.payload.groupMemberListTotal
			};
		case RESET_GROUP_MEMBER_LIST_DATA:
			return {
				...state,
				groupMemberList         : [],
				groupMemberListOffset   : 0,
				groupMemberListTotal    : 0,
			};
		default:
			return state;
  }
}



// Side effects, only as applicable
// e.g. thunks,epics, etc