import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

//functions
import { handlerParticipantAdd } from '../../../reducers/SpeakerAndParticipantReducers';

//components
import { Modal } from 'react-bootstrap';
import FormSelectUserAndGroup from '../FormSelectUserAndGroup/FormSelectUserAndGroup';

//style
import './ModalSpeakerAndParticipant.scss'

//assets
// import x from '../../img/x-close.svg'
import x from '../../img/x-cancel.svg'


function ModalAddParticipant(props) {
    const { setNotifAlertData, id_record } = props
    
    const dispatch = useDispatch();

    const [selectedUsersAndGroups, setSelectedUsersAndGroups] = useState([]);
    
    useEffect(() => {
        if ( !props.show ) {
            resetStates()
        } else {
        }
    }, [props.show]);
    
    const resetStates = () => {
        setNotifAlertData(null)

        setSelectedUsersAndGroups([]); 
    }
    
    const onSelectedUsersAndGroupsChange = (listData) => {
        // console.log(listData)
        setSelectedUsersAndGroups(listData)
    }

    const onSaveClick = () => {
        props.onHide();

        dispatch(handlerParticipantAdd(id_record, selectedUsersAndGroups));
    }

    return (
        <Modal
            {...props}
            size="md"
            centered
            id="modal-es"
            className="add-participant"
        >
            <div id="es-container">
                <div className="es-top">
                    <h5>Add New Partisipan</h5>
                    <img src={x} alt="close" onClick={props.onHide} />
                </div>
                <div className="es-opt">
                    <label>Nama Partisipan</label>
                    <FormSelectUserAndGroup 
                        onSelectedUsersAndGroupsChange={onSelectedUsersAndGroupsChange}
                    />
                </div>
                <button className="primary" disabled={selectedUsersAndGroups.length === 0 ? true : false} onClick={onSaveClick}>Simpan</button>
            </div>
        </Modal>
    )
}

export default ModalAddParticipant;