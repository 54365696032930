import $ from 'jquery'
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router'
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "./auth/Keycloak"
import store, { history } from './store';

import reportWebVitals from './reportWebVitals';

import { updateIdToken } from './reducers/AccountManagementReducers';
import { fetchAuthLogin } from './reducers/FetcherReducers';

import App from './App';
import EmptyState from './apps/components/EmptyState/EmptyState';

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import './apps/style/_button.scss';
import './apps/style/_label.scss';
import './apps/style/_progress.scss';


// print version
console.log(`${process.env.REACT_APP_NAME} version ${process.env.REACT_APP_VERSION}`);

window._translation_ = false

// to get cursor last position
$(window).on('mousemove', function (e) { 
	window._mousePosition_ = {
		x: e.clientX,
		y: e.clientY
	}
});

// to get index of clicked character
// and use it to decide where pointer placed in utterance textarea
$(window).on('click', function (e) { 
	if ( 
		e.target.className !== "space" && 
		e.target.getAttribute("index") === null 
	) {
		window._charIndex_ = null;
		return;
	}

	let index = null;
	// console.log(e.target) 

	// console.log(window._mousePosition_.x)
	
	const width = $(e.target).width();
	const start = $(e.target).offset().left;
	const end 	= start + width;

	// console.log(start)
	// console.log(window._mousePosition_.x)
	// console.log(end)
	// console.log(width)

	const leftDistance = window._mousePosition_.x - start;
	const rightDistance = end - window._mousePosition_.x;
	// console.log(leftDistance)
	// console.log(rightDistance)

	if ( e.target.className === "space" ) {
		index = parseInt(e.target.nextSibling.firstChild.getAttribute("index"))-1;
	} else {
		index = parseInt(e.target.getAttribute("index"));
	}

	if ( leftDistance < rightDistance ) {
		index = index - 1;
	}

	// console.log(index)
	window._charIndex_ = index;
});

export const PANORAMA_AUTO_LOGIN_DATA = {
	auth_name: "admin@email.com",
	// auth_password: "panorama",
	auth_password: "password",
	auth_method: 1,
	// auth_name: "adzkiafa@gmail.com",
	// auth_password: "adamlevine",
	// auth_method: 1,
	req_id: ""
};

if ( process.env.REACT_APP_TYPE === "PANORAMA" ) {
	ReactDOM.render(
		<EmptyState 
			state="loading"
			loadingText="Loading page"
			bgColor="#ffffff"
		/>,
		document.getElementById('root')
	);
	
	store.dispatch(fetchAuthLogin(PANORAMA_AUTO_LOGIN_DATA))
		.then((res) => {
			const token = res.token;
	
			store.dispatch(updateIdToken(token));
			localStorage.setItem("keycloakToken", JSON.stringify(res.keycloakToken))
			
			loadApp();
	
		}).catch((err) => {
			// console.log(error)
			var error = err.error.toString();

			ReactDOM.render(
				<EmptyState 
					state={"error"}
					onReloadClick={() => window.location.reload()}
				>
					<>
						<div>Can't open app. Error: {error}</div>
						<div>Please contact administrator.</div>
					</>
				</EmptyState>,
				document.getElementById('root')
			);
	
		});

} else {
	loadApp();
}

function loadApp() {	
	// SET token from localStorage
	let token = localStorage.getItem("token");
	store.dispatch(updateIdToken(token));
	
	let initOptions = {
		// onLoad: 'login-required',
		onLoad:'check-sso',
		// silentCheckSsoFallback: false,
		checkLoginIframe: false,
		promiseType: 'legacy'
	}
	
	let keycloakToken = localStorage.getItem("keycloakToken");
	if ( 
		keycloakToken !==  null  && keycloakToken !==  undefined  && 
		keycloakToken !== "null" && keycloakToken !== "undefined" 
	) {
		keycloakToken = JSON.parse(keycloakToken);
		initOptions.token 		 = keycloakToken.access_token;
		initOptions.refreshToken = keycloakToken.refresh_token;
	}
	
	// console.log(keycloakToken)
	
	// console.log("=== initOptions ===")
	// console.log(initOptions)
	
	const onTokens = (tokens) => {
		// console.log("=== onTokens ===")
		// console.log(tokens)

		const keycloakToken = {
			access_token: tokens.token,
			refresh_token: tokens.refreshToken
		}
		// console.log(JSON.stringify(keycloakToken))
	
		if ( tokens.token === undefined ) {
			store.dispatch(updateIdToken(null));
		}
	
		localStorage.setItem("keycloakToken", JSON.stringify(keycloakToken))
	}

	ReactDOM.render(
		<ReactKeycloakProvider 
			authClient={keycloak} 
			initOptions={initOptions}
			onTokens={onTokens}
			// onEvent={(e, err) => console.log(e, err)}
		>
			<Provider store={store}>
				<ConnectedRouter history={history}>
					<App />
				</ConnectedRouter>
			</Provider>
		</ReactKeycloakProvider>
		,
		document.getElementById('root')
	);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
