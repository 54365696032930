import { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { reqAllUtteranceList } from '../../../reducers/UtteranceReducers'
import { 
    reqAllKeywordList, 
    reqAllKeywordListOfRecord, 
    resetKeywordListData, 
    resetKeywordListOfRecordData 
} from '../../../reducers/KeywordReducers'
import { fetchKeywordAdd, fetchKeywordAddToRecord, fetchKeywordDeleteFromRecord } from '../../../reducers/FetcherReducers'

//component
import styled from 'styled-components'
import { Modal, Row, Col, Form } from 'react-bootstrap'
import EmptyState from '../EmptyState/EmptyState'
import ButtonPrimaryLoading from '../Buttons/ButtonPrimaryLoading'

//assets
// import x from '../../img/x-close.svg'
import x from '../../img/x-cancel.svg'
import delete_red from '../../img/x-red.svg'

//styles
import './ModalKeyword.scss'
import ModalAddKeyword from '../ModalProfile/KeywordMaster/ModalAddKeyword'


function ModalKeyword(props) {
    const { id_record } = props;
    const setNotifAlertData = props.setNotifAlertData

    const dispatch = useDispatch();
    
    const { fetchStatus, fetchData } = useSelector(state => state.FetcherReducers)
    const keywordList = useSelector(state => state.KeywordReducers.keywordList)
    const keywordListOfRecord = useSelector(state => state.KeywordReducers.keywordListOfRecord)

    const [loadingKeywordListOfRecord, setLoadingKeywordListOfRecord] = useState(false);
    const [loadingKeywordList, setLoadingKeywordList] = useState(false);
 
    const [saving, setSaving] = useState(false);
    
    const [showAddKeyword, setShowAddKeyword] = useState(false);
    
    const [keywordListTemp, setKeywordListTemp] = useState([]);
    const [keywordListOfRecordTemp, setKeywordListOfRecordTemp] = useState([]);
    
    useEffect(() => {
        if ( props.show ) {
            setLoadingKeywordList(true);
            setLoadingKeywordListOfRecord(true);

            dispatch(reqAllKeywordList());
            dispatch(reqAllKeywordListOfRecord(id_record));

        } else {
            dispatch(resetKeywordListData());
            dispatch(resetKeywordListOfRecordData());
        }
        
    }, [props.show]);

    useEffect(() => {
        if ( fetchStatus === "rcvAllKeywordList" ) {
            if ( fetchData.status === "success" ) {
                setLoadingKeywordList(false);
            } else {
                setLoadingKeywordList("error")
            }
        } else if ( fetchStatus === "rcvAllKeywordListOfRecord" ) {
            if ( fetchData.status === "success" ) {
                setLoadingKeywordListOfRecord(false);
            } else {
                setLoadingKeywordListOfRecord("error")
            }
        }

    }, [fetchStatus]);

    useEffect(() => {
        setKeywordListTemp(keywordList)
    }, [keywordList]);
    
    useEffect(() => {
        setKeywordListOfRecordTemp(keywordListOfRecord)
    }, [keywordListOfRecord]);

    function handleSelect(keyword) {
        // console.log(keyword);
        const keyword_record_index = keywordListOfRecordTemp.findIndex(({id}) => keyword.id === id);
        if ( keyword_record_index === -1 ) {
            setKeywordListOfRecordTemp([...keywordListOfRecordTemp, keyword])
        }
        var newKeywordList = keywordListTemp.slice();
        const keyword_index = keywordListTemp.findIndex(({id}) => keyword.id === id);
        if ( keyword_index > -1 ) {
            newKeywordList.splice(keyword_index, 1)
            setKeywordListTemp(newKeywordList)
        }
    }

    function handleDelete(keyword) {
        const keyword_index = keywordListTemp.findIndex(({id}) => keyword.id === id);
        if ( keyword_index === -1 ) {
            setKeywordListTemp([...keywordListTemp, keyword])
        }
        
        var newKeywordListOfRecordTemp = keywordListOfRecordTemp.slice();
        const keyword_record_index = keywordListOfRecordTemp.findIndex(({id}) => keyword.id === id);
        if ( keyword_record_index > -1 ) {
            newKeywordListOfRecordTemp.splice(keyword_record_index, 1)
            setKeywordListOfRecordTemp(newKeywordListOfRecordTemp)
        }
    }

    const onReloadKeywordListOfRecordClick = () => {
        // console.log("onReloadKeywordListOfRecordClick")
        setLoadingKeywordListOfRecord(true)
        
        // req keyword list record
        dispatch(reqAllKeywordListOfRecord(id_record));
    }
    
    const onReloadKeywordListClick = () => {
        setLoadingKeywordList(true)
        
        // req keyword list
        dispatch(reqAllKeywordList());
    }

    const onAddKeywordSuccess = (keywordData) => {        
        // setKeywordListTemp([...keywordListTemp, keywordData])
        handleSelect(keywordData)
    }

    const onSaveClick = async () => {
        setSaving(true)
        
        const listAllId     = keywordListOfRecord.map(({ id, ...item }) => id)
        const listAllIdTemp = keywordListOfRecordTemp.map(({ id, ...item }) => id)
        
        const listAdd = listAllIdTemp.filter( id => listAllId.indexOf(id) === -1 );
        const listDel = listAllId.filter( id => listAllIdTemp.indexOf(id) === -1 );

        var addResult = "success";
        var delResult = "success";

        if ( listAdd.length > 0 ) {
            const data_add = {
                id: listAdd,
                id_record: id_record
            }
            await dispatch(fetchKeywordAddToRecord(data_add))
                .then((res) => {
                    addResult = "success"
                })
                .catch((err) => {
                    var error = err.error.toString();
                    
                    addResult = "error"

                    setNotifAlertData({
                        status: "error",
                        text: "Failed update keyword. "+error,
                        timeout:3000
                    })
                })
        }

        if ( listDel.length > 0 ) {
            const data_del = {
                id: listDel,
                id_record: id_record
            }
            await dispatch(fetchKeywordDeleteFromRecord(data_del))
                .then((res) => {
                    delResult = "success"
                })
                .catch((err) => {
                    var error = err.error.toString();
                    
                    delResult = "error"

                    setNotifAlertData({
                        status: "error",
                        text: "Failed update keyword. "+error,
                        timeout:3000
                    })
                })
        }

        setSaving(false)

        if ( addResult === "success" && delResult === "success" ) {
            props.onHide();

            // notif when success delete keyword
            setNotifAlertData({
                status: "success",
                text: "Keyword updated",
                timeout: 3000
            })
        }
        
        dispatch(reqAllUtteranceList(id_record));
    }

    const keywordListOfRecordTempId = keywordListOfRecordTemp.map(key => key.id)
    const keywordListFiltered = keywordListTemp.filter( key => 
        keywordListOfRecordTempId.indexOf(key.id) === -1
    );

    const emptyStateStyleData = {
        position: "relative", 
        display: "block", 
        paddingBottom: "20px",
        marginTop: "-20px"
    }

    return (
        <Modal
            {...props}
            size="md"
            centered
            className="modal-ct"
            id="modal-keyword"
        >
            
            <ModalAddKeyword
                show={showAddKeyword}
                onHide={() => setShowAddKeyword(false)}     
                onAddKeywordSuccess={onAddKeywordSuccess}
            />

            <div id="ct-container">
                
                <div className="ct-top">
                    <h5>Keyword</h5>
                    <img src={x} id="close" alt="close" onClick={props.onHide} />
                </div>

                <label>Keyword Active</label>
                <Row className="kl-cont">
                    <Col className="kl-list-wrapper">
                        { keywordListOfRecordTemp.length === 0 ?
                            <EmptyState 
                                style={emptyStateStyleData}
                                state={loadingKeywordListOfRecord === "error" ? "error" : ( loadingKeywordListOfRecord ? "loading" : "loaded")}
                                onReloadClick={onReloadKeywordListOfRecordClick}
                                noDataText={"Belum ada keyword"}
                                spinnerSize="sm" 
                            />
                            :
                            ( keywordListOfRecordTemp.map((item) => (
                                <KeywordItem
                                    key={"keyword-active-"+item.id}
                                    keyword={item.keyword}
                                    active={true}
                                    onDelete={()=>handleDelete(item)}
                                />
                            )) )
                        }
                    </Col>
                </Row>

                <div className="kl-wrap">
                    <label>List Keyword</label> 
                    <div id="kl-add-btn-label" onClick={()=>setShowAddKeyword(true)}>+ Add Keyword</div>            
                </div>                  
                <Row className="kl-cont">
                    <Col className="kl-list-wrapper">
                        { keywordListFiltered.length === 0 ?
                            <EmptyState 
                                style={emptyStateStyleData}
                                state={loadingKeywordList === "error" ? "error" : ( loadingKeywordList ? "loading" : "loaded")}
                                onReloadClick={onReloadKeywordListClick}
                                noDataText={"Belum ada keyword"}
                                spinnerSize="sm" 
                            />
                            :
                            ( keywordListFiltered.map((item) => (
                                <KeywordItem
                                    key={"keyword-"+item.id}
                                    keyword={item.keyword}
                                    onSelect={()=>handleSelect(item)}
                                />
                            )) )
                        }
                    </Col>
                </Row>

                { saving ?
                    <ButtonPrimaryLoading />
                    :
                    <button 
                        className="primary"
                        onClick={onSaveClick}
                    >
                        Simpan
                    </button>
                }
                
            </div>
        </Modal>
    )
}


function KeywordItem(props) {
    return (
        <div
            data-testid={ props.active ? "keyword-active" : "keyword-not-active" } 
            className={"keyword-list-item "+ (props.active ? "active" : "not-active")} 
            onClick={!props.active ? props.onSelect : null}
        >
            <div className="overlay-name">{props.keyword}</div>
            <div className="content">
                <span className="name">{props.keyword}</span>
                <div className="button-container">
                    { props.active && 
                        <img alt="delete"
                            id="icon"
                            src={delete_red} 
                            onClick={(e) => {e.stopPropagation(); props.onDelete();}} 
                        />
                    }
                </div>
            </div>
        </div>
    )
}

export default ModalKeyword;