import { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// functions
import { get_empty_avatar } from '../../../../utils/functions'
import { reqProfileData } from '../../../../reducers/AccountManagementReducers'
import { fetchAuthActorUpdate, fetchAuthUpdate } from '../../../../reducers/FetcherReducers'

//components
import { Modal, Row, Col, Form, Spinner } from 'react-bootstrap'
import NotifAlert from '../../NotifAlert/NotifAlert'
import ChangePass from './ChangePass'

//assets
// import x from '../../../img/x-close.svg'
import x from '../../../img/x-cancel.svg'
import profile from '../../../img/profile.svg'
import lock from '../../../img/lock.svg'
import profilewhite from '../../../img/profile-white.svg'
import lockwhite from '../../../img/lock-white.svg'

//style
import '../ModalProfile.scss'


function ModalAccount(props) {
    const dispatch= useDispatch();

    const profile_data = useSelector(state => state.AccountManagementReducers.profile_data)
    
    const [notifAlertData, setNotifAlertData] = useState(null);

    const [loadingSave, setLoadingSave] = useState(false);

    const [showModal, setShowModal] = useState(1);
    const [showBtn, setShowBtn] = useState(false);

    const [photo, setPhoto] = useState(null);
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");

    const [pass, setPass] = useState('');
    const [showModalPass, setShowModalPass] = useState(false);

    const fileInputRef = useRef();
    const inputPassConfRef = useRef();

    useEffect(() => {
        // console.log("ModalAccount")
        dispatch(reqProfileData());
        
        return () => {
            setNotifAlertData(null)
        }
    }, []);

    useEffect(() => {   
        setToDefaultData() 
    }, [profile_data]);

    const setToDefaultData = () => {
        if ( profile_data !== null ) {
            const actor = profile_data.actor[0];
            const auth = profile_data.auth[0];

            // photo
            if ( 
                actor.avatar !== null        && 
                actor.avatar !== undefined   && 
                actor.avatar.replace(/\s+/g, '') !== "" 
            ) { 
                setPhoto(actor.avatar);
            }

            // username
            setUsername(actor.username)

            // email
            setEmail(auth.auth_name)
        }
    }

    // const onChangePhotoClick = () => {
    //     fileInputRef.current.click();
    // }

    // const onPhotoSelected = (e) => {
    //     var file = e.target.files[0];

    //     if ( file.type.match(/image.*/) ) {	
    //         // Max. file size is 3MB
    //         if ( file.size > 3000000 ) {
    //             setNotifAlertData({
    //                 status: "error",
    //                 text: "Max. file size is 3MB",
    //                 timeout: 3000
    //             })
    //             return;
    //         }

    //         setPhoto(file)

    //     } else {
    //         setNotifAlertData({
    //             status: "error",
    //             text: "Please select only image file",
    //             timeout: 3000
    //         })
    //         return;
    //     }

    //     setShowBtn(true)

    //     e.target.value = null;
    // }

    // const onRemovePhotoClick = () => {
    //     setPhoto(null)
    //     setShowBtn(true)
    // }

    const changeUsername = (e) => {
        setUsername(e.target.value);
        setShowBtn(true)
    }

    const changeEmail = (e) => {
        setEmail(e.target.value);
        setShowBtn(true)
    }

    const onCancelClick = () => {
        // console.log("onCancelClick")
        setToDefaultData();

        setShowBtn(false);
    }

    const onSaveClick = (e) => {
        e.preventDefault();
        
        setShowModalPass(true);
        setTimeout(() => {
            inputPassConfRef.current.focus();
        }, 100);
    }

    const onConfirmPasswordChange = (e) => {
        setPass(e.target.value);
    }

    const onConfirmSave = async () => {  
        setLoadingSave(true)      
        setShowModalPass(false);
        
        const actor = profile_data.actor[0];
        const auth = profile_data.auth[0];
        
        var update_actor_status;
        var update_auth_status;

        // update pgoto & username
        const dataActor = {
            id_actor: actor.id,
            // username: actor.username,
            username: username,
            avatar: (actor.photo === null ? "" : actor.photo),
            organisation: actor.organisation,
            title: actor.title,
            password: pass
        };

        await dispatch(fetchAuthActorUpdate(dataActor))
            .then((res) => {
                update_actor_status = "success"
            })
            .catch((err) => {
                var error = err.error.toString();
                
                update_actor_status = "error"

                setNotifAlertData({
                    status: "error",
                    text: "Update profile failed. "+error,
                    timeout: 3000
                })
            })
     

        // update email
        const dataAuth = {
            method: auth.method, 
            auth_name: email, 
            new_auth_pass: "", 
            old_auth_pass: pass
        };

        await dispatch(fetchAuthUpdate(dataAuth))
            .then((res) => {
                update_auth_status = "success"
                
            })
            .catch((err) => {
                var error = err.error.toString();
                
                update_auth_status = "error"

                setNotifAlertData({
                    status: "error",
                    text: "Update profile failed. "+error,
                    timeout: 3000
                })

            })
        
        setLoadingSave(false)

        if ( update_actor_status === "success" && update_auth_status === "success" ) {
            setShowBtn(false);

            setNotifAlertData({
                status: "success",
                text: "Profile updated",
                timeout: 3000
            })

            dispatch(reqProfileData())
        }
    }

    function switchModal() {
        switch (showModal) {
            case 2: return TabPassword();

            default: return TabPersonal();

        }
    }

    function TabPersonal() {    
        var avatar = photo;
        if ( avatar === null ) {
            avatar = get_empty_avatar()
        } else if ( typeof photo === 'object') {
            avatar = URL.createObjectURL(photo)
        } else {
            avatar = get_empty_avatar()
        }

        return (
            <div className="my-account-main-wrapper">
                { notifAlertData !== null &&
                    <NotifAlert 
                        data={notifAlertData}
                    />
                }
                <div className="my-account-main-header">
                    <h5>Personal</h5>
                    <p>Feel free to edit your basic information such as name, email,etc.</p>
                </div>
                <div className="my-account-padding">
                    {/* Hide temporarily */}
                    {/* <div className="edit-detail-wrap">  
                        <input
                            alt="select-profile-image"
                            style={{display: "none"}}
                            ref={fileInputRef}
                            type="file"
                            accept="image/*"
                            onChange={onPhotoSelected}
                        />
                        <img className="img-ava" src={avatar} alt="avatar-icon" />
                        <div className="edit-detail-aside">
                            <div className="edit-detail-btn-wrap">
                                <button className="btn-edit-profile add" onClick={onChangePhotoClick}><img src={cam} alt="ico" />Change Photo</button>
                                { photo !== null &&
                                    <button className="btn-edit-profile remove" onClick={onRemovePhotoClick}>Remove</button>
                                }
                            </div>
                            <p>Image format with maximum size 3mb</p>
                        </div>
                    </div> */}
                    <Form>
                        <Form.Row>
                            <Form.Group className="other-form" as={Col} controlId="formGridUsername">
                                <Form.Label>Username</Form.Label>
                                <Form.Control 
                                    alt="input-username" 
                                    type="text" 
                                    placeholder="Username" 
                                    value={username}
                                    onChange={(e) => changeUsername(e)}
                                />
                            </Form.Group>
                            <Form.Group className="other-form" as={Col} controlId="formGridEmail">
                                <Form.Label>Email</Form.Label>
                                <Form.Control 
                                    alt="input-email" 
                                    type="email" 
                                    placeholder="Email" 
                                    value={email}
                                    onChange={(e) => changeEmail(e)} 
                                    disabled 
                                />
                            </Form.Group>
                        </Form.Row>                   
                        { showBtn &&
                            <Form.Group style={{ position: "relative" }}>
                                { showModalPass &&
                                    <div className="modal-input-pass">
                                        <div className="modal-input-pass-top">
                                            <div id="head5">Your Password</div>
                                            <img src={x} alt="close" id="img-c" onClick={()=>setShowModalPass(false)}/>
                                        </div>
                                        <p>Masukan password anda untuk mengubah data pengguna</p>                                        
                                        <Form.Control 
                                            ref={inputPassConfRef}
                                            alt="input-confirm-password"
                                            id="f-control"
                                            type="password" 
                                            placeholder="" 
                                            value={pass}
                                            onChange={(e) => onConfirmPasswordChange(e)}
                                        />
                                        <button className="btn-edit-profile pass" onClick={onConfirmSave}>Submit</button>
                                    </div>
                                }  
                                { loadingSave ?
                                    <button className="btn-form-profile">
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    </button>
                                    :                                
                                    <>
                                        <button className="btn-form-profile primary" type="submit" onClick={onSaveClick}>
                                            Simpan
                                        </button>
                                        <button className="btn-form-profile secondary" onClick={onCancelClick}>
                                            Cancel
                                        </button>
                                    </>
                                }
                            </Form.Group>
                        }

                    </Form>

                </div>
            </div>
        )
    }

    function TabPassword() {
        return (
            <ChangePass />
        )
    }

    return (
        <Modal 
            {...props} 
            size="lg" 
            centered
            className="modal-my-account"
        >
            <div>
                <div className="my-account-header">
                    <h5>My Account</h5>
                    <img src={x} alt="close" onClick={() => props.handleClick()} />

                </div>
                <Row className="my-account-main">
                    <Col sm={3} className="left side-menu-myacc">
                        <button onClick={() => setShowModal(1)} className={showModal === 1 ? "btn-side-nav active" : "btn-side-nav"}> <img src={showModal === 1 ? profilewhite : profile} alt="tab-profile" /> Personal</button>
                        <button onClick={() => setShowModal(2)} className={showModal === 2 ? "btn-side-nav active" : "btn-side-nav"}> <img src={showModal === 2 ? lockwhite : lock} alt="tab-password" /> Password</button>
                    </Col>
                    <Col sm={9} className="right">
                        {switchModal()}
                    </Col>
                </Row>
            </div>
        </Modal>
    )
}

export default ModalAccount;