/** 
Declare the type of action as constant.
WHY?
    - Help reduce typos 
    - Help reduce bugs and mistake
    - If you make typo and dispatch an undefined constants,
      the app will throw error to alert the mistake. 
*/

// Import

// Functions
import { toastNotification } from "../apps/components/ToastNotification/ToastNotification";
// import { model_asr_list_data, record_list_data } from "../dummy/dummy";
import { fetchModelAsrList } from "./FetcherReducers";

// Action Types
const UPDATE_MODEL_ASR_LIST_AND_TOTAL  = "ModelAsrReducers/UPDATE_MODEL_ASR_LIST_AND_TOTAL";

// Action Creators
/* 
 *  !LEGEND! 
 *      req = request
 *      rcv = receive
 *      fch = fetch
 *      snd = send
 */


// Actions

export const updateModelAsrListAndTotal = data => ({
	type: UPDATE_MODEL_ASR_LIST_AND_TOTAL,
    payload: {
        modelAsrList: data.modelAsrList,
        modelAsrListTotal: data.modelAsrListTotal
    }
});

// You can put impure functions within your action creators

// Reducers
export const reqAllModelAsrList = () => (dispatch, getState) => { 
    const data = {
        filter: ""
    }
    
    dispatch(fetchModelAsrList(data))
        .then((res) => {
            dispatch(rcvAllModelAsrList(res))
        })
        .catch((resErr) => {
            dispatch(rcvAllModelAsrList(resErr))
        })
};

export const rcvAllModelAsrList = (msg) => (dispatch, getState) => {
    if ( msg.status === "failed" ) {
        toastNotification("error", "Gagal memuat daftar model asr. "+msg.error)
        return;
    }

	const modelAsrListAndTotal = {
		modelAsrList: msg.data,
		modelAsrListTotal: msg.data.length
	}

	dispatch(updateModelAsrListAndTotal(modelAsrListAndTotal));
};

// Reducer's initial state
const initialState = {
    modelAsrList          : [],
    modelAsrListLimit     : 1000,
    modelAsrListTotal     : 0,
};


// You must only write pure function when trying to build the reducer! 

export default function ModelAsrReducers(state = initialState, action) {
    switch (action.type) {
        case UPDATE_MODEL_ASR_LIST_AND_TOTAL:
            return {
                ...state,
                modelAsrList: action.payload.modelAsrList,
                modelAsrListTotal: action.payload.modelAsrListTotal
            };
        default:
            return state;
    }
}



// Side effects, only as applicable
// e.g. thunks,epics, etc