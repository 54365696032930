import { useState } from 'react'
//components
import { Modal } from 'react-bootstrap'
//assets
import { useDispatch } from 'react-redux'
import { reqAllKeywordList } from '../../../../reducers/KeywordReducers'
import { fetchKeywordDelete } from '../../../../reducers/FetcherReducers'
import ButtonDangerLoading from '../../Buttons/ButtonDangerLoading'


function ModalDeleteKeyword(props) {
    const { setNotifAlertData, keywordOnDelete } = props

    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    
    const onDeleteBtnClick =() => {
        setLoading(true)

        const data = {
            id: keywordOnDelete.id
        }

        dispatch(fetchKeywordDelete(data))
            .then((res) => {
                setLoading(false)

                props.onHide();

                // notif when success delete user
                setNotifAlertData({
                    status: "success",
                    text: "Keyword removed",
                    timeout: 3000
                })

                dispatch(reqAllKeywordList());

            })
            .catch((err) => {
                var error = err.error.toString();
                
                setLoading(false)

                // notif when failed delete user
                setNotifAlertData({
                    status: "error",
                    text: "Failed delete keyword. "+error,
                    timeout:3000
                })
            })
    }

    if ( keywordOnDelete === null ) {
        return null;
    }

    return (
        <Modal
            {...props}
            size="sm"
            centered
            backdrop="static"
            id="modal-add-user"
        >
            <div id="add">
                <div className="top">
                    Delete Keyword
                </div>
                <div className="caption">
                    Apakah anda yakin ingin menghapus keyword <b>{keywordOnDelete.keyword}</b>?
                </div>
                <div className="btn-wrap">
                { loading ?
                    <ButtonDangerLoading />
                    :
                    <>
                        <button 
                            className="error" 
                            onClick={onDeleteBtnClick}
                        >
                            Delete
                        </button>
                        <button
                            className="primary" 
                            onClick={() => props.onHide()}
                        >
                            Cancel
                        </button>
                    </>
                }
                </div>
            </div>
        </Modal>
    )
}

export default ModalDeleteKeyword;