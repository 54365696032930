import { Spinner } from "react-bootstrap"

import './EmptyState.scss'

import NoRecord from '../../img/no-record.svg'
import Reload from '../../img/reload.svg'


function EmptyState(props) {
    const { 
        children        = null,
        style           = {},
        state           = "loading", 
        searchKeyword   = "", 
        loadingText     = "Loading data",
        noDataImage     = null, 
        noDataText      = "Belum ada data",
        onReloadClick   = null,
        spinnerSize     = "md",
        bgColor         = "transparent"
    } = props;

    const handleReloadClick = () => {
        // if ( onReloadClick !== null ) {
        //     onReloadClick();
        // }
        
        window.location.reload();
    }

    const style_data = JSON.parse(JSON.stringify(style));
    style_data.backgroundColor = bgColor;

    return (
        <div 
            className={"empty-state "+spinnerSize} 
            style={style_data}
        >
            { state === "loading" &&
                <LoadingState 
                    spinnerSize={spinnerSize}
                    text={loadingText}
                />
            }
            { state === "error" &&
                <ReloadState onReloadClick={handleReloadClick} />
            }
            { state === "loaded" &&
                <LoadedState                 
                    noDataImage={noDataImage}
                    noDataText={noDataText}
                    searchKeyword={searchKeyword}
                />
            }

            {children}
        </div>
    )
}

function LoadingState(props) {
    const { 
        spinnerSize     = "lg",
        text            = "", 
    } = props;
    
    return (
        <div className="loading-state-container">
            <div className="image-container">
                <Spinner className="spinner" animation="border" variant="dark" size={spinnerSize} />
            </div>
            <label>{text}</label>
        </div>
    )
}

function LoadedState(props) {
    const { 
        noDataImage,
        noDataText      = "",
        searchKeyword   = "", 
    } = props;
    
    return (
        <div className="loaded-state-container">
            { noDataImage !== undefined && noDataImage !== null &&
                <div className="image-container">
                    <img alt="norecord" src={NoRecord} /> 
                </div>
            }
            { searchKeyword !== "" ?
                <label>Pencarian "{searchKeyword}" tidak ditemukan</label>
                :
                <label>{noDataText}</label>
            }
        </div>
    )
}

function ReloadState(props) {
    const { onReloadClick } = props;

    const handleClick = () => {
        if ( onReloadClick !== undefined ) {
            onReloadClick();
        }
    }

    return (
        <div className="reload-state-container" onClick={handleClick}>
            <div className="image-container">
                <img alt="reload" src={Reload} />
            </div>
            <label>Try reload data</label>
        </div>
    )
}

export default EmptyState;