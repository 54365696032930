import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";

import './BKSwitch.scss'


function BKSwitch(props) {
    const { className = "" } = props;
    const pChecked   = props.checked
    const pOnChange  = props.onChange

    const [checked, setChecked] = useState(false);

    useEffect(() => {
        if ( typeof pChecked === "boolean" ) {
            setChecked(pChecked);
        }
    }, [pChecked]);

    const handleOnChange = (e) => {        
        const new_checked = e.target.checked;
        
        if ( typeof pChecked !== "boolean" ) {
            setChecked(new_checked);
        }
        if ( typeof pOnChange === "function") {
            pOnChange(e);
        }
    }

    const new_props = JSON.parse(JSON.stringify(props));
    delete new_props.className;
    delete new_props.checked;
    delete new_props.onChange;
    
    return (
        <Form.Switch
            {...new_props}
            className={"bk-switch "+className}
            checked={checked}
            onChange={handleOnChange}
        />
    )
}

export default BKSwitch;