
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import EmptyState from '../../EmptyState/EmptyState';
import { reqAllParticipantList } from '../../../../reducers/SpeakerAndParticipantReducers';
import ModalDeleteParticipant from '../../ModalSpeakerAndParticipant/ModalDeleteParticipant';
import ModalEditParticipant from '../../ModalSpeakerAndParticipant/ModalEditParticipant';

//assets
import edit from '../../../img/edit-blue.png'
import deletebin from '../../../img/delete-red.png'


function ParticipantList(props) {
    const { setNotifAlertData, id_record } = props;
    
    const dispatch = useDispatch();

    const { fetchStatus, fetchData } = useSelector(state => state.FetcherReducers)
    const { participantList } = useSelector(state => state.SpeakerAndParticipantReducers)
    
    const [loadingParticipantList, setLoadingParticipantList] = useState(false);

    useEffect(() => {
        setLoadingParticipantList(true);
        dispatch(reqAllParticipantList(id_record));

        return () => {
            setLoadingParticipantList(false);
        }
    }, []);
    
    useEffect(() => {
        if ( fetchStatus === "rcvAllParticipantList" ) {
            if ( fetchData.status === "success" ) {
                setTimeout(() => {
                    setLoadingParticipantList(false)
                }, 1000)
            } else {
                setLoadingParticipantList("error")
            }
        }     
    }, [fetchStatus]);

    const onReloadParticipantListClick = () => {
        setLoadingParticipantList(true)
        
        // req participant list
        dispatch(reqAllParticipantList(id_record));
    }

    return (
        <>
            { participantList.length === 0 ?
                <EmptyState 
                    state={loadingParticipantList === "error" ? "error" : ( loadingParticipantList ? "loading" : "loaded")}
                    onReloadClick={onReloadParticipantListClick}
                    noDataText={"Belum ada partisipan"}
                    spinnerSize="sm"
                />
                :
                ( participantList.map((participant) =>
                    <ParticipantItem 
                        key={"participant-"+participant.id}
                        setNotifAlertData={setNotifAlertData}
                        id_record={id_record}
                        participant={participant} 
                    />
                ) )
            }
        </>        
    )
}

function ParticipantItem(props) {
    const { id_record, participant, setNotifAlertData } = props;
    const [showEditParticipant, setShowEditParticipant] = useState(false);
    const [showDeleteParticipant, setShowDeleteParticipant] = useState(false);

    const onEditParticipantClick = () => {
        setShowEditParticipant(true)
    }

    const onDeletePartisipanClick = (partcipant) => {
        setShowDeleteParticipant(true)
    }

    return (
        <>
            <ModalEditParticipant
                show={showEditParticipant}
                onHide={() => setShowEditParticipant(false)}
                setNotifAlertData={setNotifAlertData}
                id_record={id_record}
                participant={participant}                
            />
            <ModalDeleteParticipant
                show={showDeleteParticipant}
                onHide={() => setShowDeleteParticipant(false)}          
                setNotifAlertData={setNotifAlertData}
                id_record={id_record}
                participant={participant}      
            />
            
            <div className="speaker-list-item">
                <div id="u-icon" alt="icon">{participant.name.charAt(0)}</div>
                <span>{participant.name}</span>
                <div className="tools-container">
                    <img id="edit" src={edit} alt="edit-participant" onClick={onEditParticipantClick} />
                    <img id="delete" src={deletebin} alt="delete-participant" onClick={onDeletePartisipanClick} />
                </div>
            </div>
        </>
    )
}

export default ParticipantList;