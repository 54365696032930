/** 
Declare the type of action as constant.
WHY?
    - Help reduce typos 
    - Help reduce bugs and mistake
    - If you make typo and dispatch an undefined constants,
      the app will throw error to alert the mistake. 
*/

// Import
import { toastNotification } from '../apps/components/ToastNotification/ToastNotification';
import { RECORD_STATUS } from '../utils/helpers';

// Functions
import { fetchRecordDetail, fetchRecordList, fetchRecordStartTranscribe, updateFetchStatusAndData } from "./FetcherReducers";

// Action Types
const UPDATE_CURRENT_OPENED_ID_RECORD   = "RecordReducers/UPDATE_CURRENT_OPENED_ID_RECORD";
const UPDATE_RECORD_DETAILS             = "RecordReducers/UPDATE_RECORD_DETAILS";
const UPDATE_RECORD_LIST_OFFSET         = "RecordReducers/UPDATE_RECORD_LIST_OFFSET";
const UPDATE_RECORD_LIST_AND_TOTAL      = "RecordReducers/UPDATE_RECORD_LIST_AND_TOTAL";
const RESET_RECORD_LIST_DATA            = "RecordReducers/RESET_RECORD_LIST_DATA"
const UPDATE_IS_UPLOADING               = "RecordReducers/UPDATE_IS_UPLOADING";
const UPDATE_UPLOADING_ERROR            = "RecordReducers/UPDATE_UPLOADING_ERROR";
const UPDATE_UPLOADING_PROGRESS         = "RecordReducers/UPDATE_UPLOADING_PROGRESS";
const UPDATE_LIST_FILE_UPLOAD           = "RecordReducers/UPDATE_LIST_FILE_UPLOAD";

// Action Creators
/* 
 *  !LEGEND! 
 *      req = request
 *      rcv = receive
 *      fch = fetch
 *      snd = send
 */


// Actions

export const updateCurrentOpenedIdRecord = id_record => ({
	type: UPDATE_CURRENT_OPENED_ID_RECORD,
    payload: {
        currentOpenedIdRecord: id_record
    }
});

export const updateRecordDetails = data => ({
	type: UPDATE_RECORD_DETAILS,
    payload: {
        recordDetails: data
    }
});

export const updateRecordListOffset = offset => ({
	type: UPDATE_RECORD_LIST_OFFSET,
    payload: {
        recordListOffset: offset
    }
});

export const updateRecordListAndTotal = data => ({
	type: UPDATE_RECORD_LIST_AND_TOTAL,
    payload: {
        recordList: data.recordList,
        recordListTotal: data.recordListTotal
    }
});

export const resetRecordListData = () => ({
	type: RESET_RECORD_LIST_DATA
});

export const updateIsUploading = (bool) => ({
    type: UPDATE_IS_UPLOADING,
    payload: {
        isUploading: bool
    }
});

export const updateUploadingError = (bool) => ({
    type: UPDATE_UPLOADING_ERROR,
    payload: {
        uploadingError: bool
    }
});

export const updateUploadingProgress = (int) => ({
    type: UPDATE_UPLOADING_PROGRESS,
    payload: {
        uploadingProgress: int
    }
});

export const updateListFileUpload = (files) => ({
    type: UPDATE_LIST_FILE_UPLOAD,
    payload: {
        listFileUpload: files
    }
});


// You can put impure functions within your action creators

// Reducers
export const reqRecordDetails = (id) => (dispatch, getState) => { 
    const data = {
        id
    }

    dispatch(fetchRecordDetail(data))
        .then((res) => {
            dispatch(rcvRecordDetails(res))
        })
        .catch((resErr) => {
            dispatch(rcvRecordDetails(resErr))
        })
};

export const rcvRecordDetails = (msg) => (dispatch, getState) => {

    if ( msg.status === "failed" ) {
        toastNotification("error", "Gagal memuat data rekaman. "+msg.error);
        dispatch(updateRecordDetails(msg));
        return;
    }

    // update record detail data if record currently being opened
    const _id_record = msg.data.id_record;
    const currentOpenedIdRecord = getState().RecordReducers.currentOpenedIdRecord;
    if ( _id_record === currentOpenedIdRecord ) {
        dispatch(updateRecordDetails(msg.data));
    };
    
    // update record data in recordList
	const recordListTotal = getState().RecordReducers.recordListTotal;
    let recordList = getState().RecordReducers.recordList.slice();
	const index = recordList.findIndex(({id_record}) => id_record === msg.data.id_record);
    if ( index > -1 ) {
        recordList[index] = msg.data;

        const recordListAndTotal = {
            recordList: recordList,
            recordListTotal: recordListTotal
        }
        dispatch(updateRecordListAndTotal(recordListAndTotal));
    }
};

export const reqAllRecordList = (offset = 0) => (dispatch, getState) => { 
    dispatch(updateRecordListOffset(offset));

    dispatch(updateFetchStatusAndData("reqAllRecordList"));
    
    const limit = getState().RecordReducers.recordListLimit;

    const data = {
        offset,
        limit
    }

    dispatch(fetchRecordList(data))
        .then((res) => {
            dispatch(rcvAllRecordList(res))
        })
        .catch((resErr) => {
            dispatch(rcvAllRecordList(resErr))
        })
};

export const rcvAllRecordList = (msg) => (dispatch, getState) => {
    const offset = getState().RecordReducers.recordListOffset;
    const limit  = getState().RecordReducers.recordListLimit;
    const total  = getState().RecordReducers.recordListTotal;

	const request       = msg.request;
    const res_offset    = request.offset;
	const res_total 	= msg.total;
    
    // RETURN if not the response of last requested record list
    if ( res_offset !== offset )
        return;

    // clear record list if received total is not equal to current total 
    if ( offset === 0 && total !== res_total ) {
        dispatch(resetRecordListData())
    }

    dispatch(updateFetchStatusAndData("rcvAllRecordList", msg));

    if ( msg.status === "failed" ) {
        toastNotification("error", "Gagal memuat daftar rekaman. "+msg.error)
        return;
    }

	var recordList = getState().RecordReducers.recordList.slice();
	msg.data.forEach((record, index) => {
		recordList[offset+index] = record;
	})

	const recordListAndTotal = {
		recordList: recordList,
		recordListTotal: res_total
	}
	dispatch(updateRecordListAndTotal(recordListAndTotal));

	if ( msg.data.length === limit ) {
		dispatch(reqAllRecordList((offset+limit)));
	}
};

export const reqStartRecordTranscribe = (id_record) => (dispatch, getState) => { 
    const data = {
        id_record
    }

    dispatch(fetchRecordStartTranscribe(data))
        .then((res) => {
            dispatch(rcvStartRecordTranscribe(res))
        })
        .catch((resErr) => {
            dispatch(rcvStartRecordTranscribe(resErr))

        })
};

export const rcvStartRecordTranscribe = (msg) => (dispatch, getState) => {

    if ( msg.status === "failed" ) {
        toastNotification("error", "Gagal memulai transkripsi. "+msg.error)
        return;
    }

	const request       = msg.request;
    const res_id_record = request.id_record;

	var recordList      = getState().RecordReducers.recordList.slice();
    var recordListTotal = getState().RecordReducers.recordListTotal;
    
    const index = recordList.findIndex(({id_record}) => id_record === res_id_record);
    if ( index > -1 ) {
        recordList[index].state = RECORD_STATUS.TRANSCRIPTING

        const recordListAndTotal = {
            recordList: recordList,
            recordListTotal: recordListTotal
        }
        dispatch(updateRecordListAndTotal(recordListAndTotal));
    }

};


// Reducer's initial state
const initialState = {
    currentOpenedIdRecord : null,
    recordDetails       : null,

    recordList          : [],
    recordListOffset    : 0,
    recordListLimit     : 1000,
    recordListTotal     : 0,
    
    isUploading         : false,
    uploadingError    : false,
    uploadingProgress   : 0,
    listFileUpload      : [],
};


// You must only write pure function when trying to build the reducer! 

export default function RecordReducers(state = initialState, action) {
    switch (action.type) {
        case UPDATE_CURRENT_OPENED_ID_RECORD:
            return {
                ...state,
                currentOpenedIdRecord: action.payload.currentOpenedIdRecord
            };
        case UPDATE_RECORD_DETAILS:
            return {
                ...state,
                recordDetails: action.payload.recordDetails
            };
        case UPDATE_RECORD_LIST_OFFSET:
            return {
                ...state,
                recordListOffset: action.payload.recordListOffset
            };
        case UPDATE_RECORD_LIST_AND_TOTAL:
            return {
                ...state,
                recordList: action.payload.recordList,
                recordListTotal: action.payload.recordListTotal
            };
        case RESET_RECORD_LIST_DATA:
            return {
                ...state,
                recordList          : [],
                recordListOffset    : 0,
                recordListTotal     : 0,
            };
        case UPDATE_IS_UPLOADING:
            return {
                ...state,
                isUploading: action.payload.isUploading
            };
        case UPDATE_UPLOADING_ERROR:
            return {
                ...state,
                uploadingError: action.payload.uploadingError
            };
        case UPDATE_UPLOADING_PROGRESS:
            return {
                ...state,
                uploadingProgress: action.payload.uploadingProgress
            };
        case UPDATE_LIST_FILE_UPLOAD:
            return {
                ...state,
                listFileUpload: action.payload.listFileUpload
            };
        default:
            return state;
    }
}



// Side effects, only as applicable
// e.g. thunks,epics, etc