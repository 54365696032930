import { useState } from 'react'
import { useDispatch, } from 'react-redux'

// functions
import { reqAllRecordList } from '../../../reducers/RecordReducers'
import { fetchRecordDelete } from '../../../reducers/FetcherReducers'

//components
import { Modal } from 'react-bootstrap'

//style
// import '../ModalProfile.scss'
// import './UserAndGroup.scss'
import ButtonDangerLoading from '../Buttons/ButtonDangerLoading'
import { toastNotification } from '../ToastNotification/ToastNotification'

//assets


function ModalDeleteRecord(props) {
    const { id=null, title=null } = props;

    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);

    const onDeleteBtnClick = (e) => {
        e.stopPropagation();

        setLoading(true)

        dispatch(fetchRecordDelete(id))
            .then((res) => {
                setLoading(false)

                props.onHide();
        
                // notif when success delete record                
                toastNotification('success', 'Record removed')

                dispatch(reqAllRecordList());

            })
            .catch((err) => {
                var error = err.error.toString();

                setLoading(false)

                // notif when failed delete record
                toastNotification('error', 'Failed delete record. '+error)
            })
    }

    const onCancelBtnClick = (e) => {
        e.stopPropagation();

        props.onHide();
    }

    if ( id === null ) {
        return null
    }

    return (
        <>
            <Modal
                {...props}
                size="sm"
                centered
                backdrop="static"
                id="modal-add-user"
            >
                <div id="add">
                    <div className="top">
                        Delete Record
                    </div>
                    <div className="caption">
                        Apakah anda yakin ingin menghapus rekaman <b>{title}</b>?
                    </div>
                    <div className="btn-wrap">
                        { loading ?
                            <ButtonDangerLoading />
                            :
                            <>
                                <button 
                                    className="error" 
                                    onClick={onDeleteBtnClick}
                                >
                                    Delete
                                </button>
                                <button
                                    className="primary" 
                                    onClick={onCancelBtnClick}
                                >
                                    Cancel
                                </button>
                            </>
                        }
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default ModalDeleteRecord