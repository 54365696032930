import $ from 'jquery'
import { useEffect, useRef, useState } from "react";
import { useDispatch } from 'react-redux';
import { fetchUtteranceUpdateSpeaker, fetchUtteranceUpdateText } from '../../../reducers/FetcherReducers';
import { toastNotification } from '../ToastNotification/ToastNotification';

function UtteranceEditor(props) {
    const { utterance, setTextOnEditing, textOnSaving, setTextOnSaving } = props;

    const dispatch = useDispatch();

    const {
        id,
        transcribe,
    } = utterance


    const [value, setValue] = useState(transcribe.text);

    const ref = useRef();

    useEffect(() => {
        autoGrow()
        ref.current.focus();
        
        if ( window._charIndex_ !== null && window._charIndex_ !== undefined ) {
            ref.current.selectionStart = window._charIndex_+1;
            ref.current.selectionEnd = window._charIndex_+1;
        } else {
            ref.current.selectionStart = value.length;
            ref.current.selectionEnd = value.length;
        }

    }, [])


	const autoGrow = () => {
        ref.current.style.height = "5px";
        ref.current.style.height = (ref.current.scrollHeight) + "px";
	}

    const onKeyDown = (e) => {
        if ( e.keyCode === 13 ) {
            e.preventDefault();
            onBlur();
        }
    }

    const onFocus = () => {
    }

    const onBlur = () => {
        // console.log("onBlur")
        // console.log(id)

        if ( value === transcribe.text ) {   
            setTextOnEditing(false);
            return;
        }

        setTextOnSaving(true);

        const data = {
            id_utterance: id, 
            text: value
        }

        dispatch(fetchUtteranceUpdateText(data))
            .then((res) => {
                setTextOnEditing(false)
                setTextOnSaving(false);

                // dispatch(rcvUtteranceUpdateText(res))
            })
            .catch((err) => {
                var error = err.error.toString();
                
                setTextOnSaving(false);

                toastNotification("error", "Update utterance text failed. "+error)
            })
    }

    return (
        <textarea 
            ref={ref} 
            value={value} 
            onChange={(e) => setValue(e.target.value)}
            onKeyUp={autoGrow}
            onKeyDown={onKeyDown}
            onFocus={onFocus}
            onBlur={onBlur} 
            // onClick={e => e.target.focus()}
            disabled={textOnSaving?true:false} 
        />
    )
}

export default UtteranceEditor;