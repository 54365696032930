import React from 'react';
import { Spinner } from 'react-bootstrap';
import styled from 'styled-components'

// CSS

const LoaderContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(255,255,255,0.9);
	text-align: center;
	z-index: 1033;

	span {
		margin-bottom: 10px;
	}
`;

class ModalLoader extends React.Component {
	render() {
		return (
			<LoaderContainer>
				<span>{this.props.text}</span>
				<Spinner 
					as="span"
					animation="border"
					size="lg"
					role="status"
					aria-hidden="true"
				/>
			</LoaderContainer>
		)
	}
}

export default ModalLoader