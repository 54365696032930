import FileSaver from 'file-saver';
import moment from 'moment';
// import HtmlToRtfBrowser from 'html-to-rtf-browser'

var jsPDF = require('jspdf');
require('jspdf-autotable');

export function download_meeting_handler(contentType, fileType, record_details, data) {
    // console.log("download_meeting_handler")
    // console.log(record_details)

    if ( contentType === "chronology") {
        if ( fileType === "pdf" ) {
            downloadChronologyAsPdf(record_details, data);

        } else if ( fileType === "word" ) {
            downloadChronologyAsDoc(record_details, data);

        } else if ( fileType === "txt" ) {
            downloadChronologyAsTxt(record_details, data);
        
        }

    } else {                
        if ( fileType === "pdf" ) {
            downloadParagraphAsPdf(record_details, data);

        }  else if ( fileType === "word" ) {
            downloadParagraphAsDoc(record_details, data);
            

        } else if ( fileType === "txt" ) {
            downloadParagraphAsTxt(record_details, data);
        
        }
    }
}

function downloadChronologyAsPdf(record_details, data){
    // console.log("downloadChronologyAsPdf")
    
    const title = record_details.name;
    
    var thead = [
        ["No", "Waktu", "Pembicara", "Teks"]
    ];
    var tbody = [];

    for (var i = 0; i < data.length; i++) {
        var utterance_index = i+1;
        var created_date    = data[i].datetime;
        created_date        = moment(created_date).format("DD MMMM YYYY, hh:mm A");
        var speaker_name    = data[i].speaker.name;
        var utterance       = data[i].transcribe.text;

        tbody.push([
            // created_date, 
            utterance_index, created_date, speaker_name, utterance
        ]);

    }

    var doc = new jsPDF();

    // doc.autoTable([[""]], [[title]], {
    doc.autoTable({
        head: [[""]], 
        body: [[title]], 
        theme: 'plain',
        showHead: 'firstPage',
        styles: {
            font: "times",
            fontSize: 12,
            textColor: 0,
            overflow: 'linebreak',
            cellWidth: 'auto',
            valign: 'top',
            halign: 'center',
        },
    });

    // doc.autoTable(thead, tbody, {
    doc.autoTable({
        head: thead,
        body: tbody,
        startY: doc.previousAutoTable.finalY + 5,
        theme: 'grid',
        showHead: 'firstPage',
        tableWidth: 'auto',
        styles: {
            lineColor: 0,
            lineWidth: 0.1,
            font: "times",
            fontSize: 12,
            fillColor: 255,
            textColor: 0,
            overflow: 'linebreak',
            cellWidth: 'auto',
            valign: 'top',
        },
        headStyles: {
            halign: 'center'
        },
        columnStyles: {
            0: {cellWidth: 15},
            1: {cellWidth: 30},
            2: {cellWidth: 35},
            3: {cellWidth: 'auto'},
        },
        rowPageBreak: 'avoid'
    });

    doc.save(title + "_kronologi.pdf");
    
}

function downloadChronologyAsDoc(record_details, data) {
    // console.log("downloadChronologyAsDoc")

    var dataHTML = ""
    
    const title = record_details.name;
    // const start_time = record_details.starting_date;
    // const utterances_length = record_details.total_statement;

    dataHTML +=
     '<html>'
    +    '<head>'
    +        '<title>' + title + '</title>'
    +    '</head>'
    +    '<body>'
    +        '<div style="font-family: Times New Roman;">'
    +	         '<div style="text-align: center;">' + title + '</div>'
    +	         '<br/>'
    +            '<br/>'
    +            '<table style="border-collapse: collapse; font-family: Times New Roman;">'
    +                '<thead>'
    +                    '<tr>'
    +				         '<th style="padding: 5px 8px; border:1px solid #555555;">No</th>'
    +                        '<th style="padding: 5px 8px; border:1px solid #555555;">Waktu</th>'
    +				         '<th style="padding: 5px 8px; border:1px solid #555555;">Pembicara</th>'
    +				         '<th style="padding: 5px 8px; border:1px solid #555555;">Teks</th>'
    +                    '</tr>'
    +                '</thead>'
    +                '<tbody>';

    for (var i = 0; i < data.length; i++) {
        const utterance_index   = i + 1;
        let created_date      = data[i].datetime;
        created_date            = moment(created_date).format("DD MMMM YYYY, hh:mm A");
        const speaker_name      = data[i].speaker.name;
        const utterance         = data[i].transcribe.text;

        dataHTML +=	     
                            '<tr>'
        +                        '<td style="padding: 5px 8px; border:1px solid #555555;">' + utterance_index + '</td>'
        +                        '<td style="padding: 5px 8px; border:1px solid #555555;">' + created_date + '</td>'
        +                        '<td style="padding: 5px 8px; border:1px solid #555555;">' + speaker_name + '</td>'
        +                        '<td style="padding: 5px 8px; border:1px solid #555555;">' + utterance + '</td>'
        +                   '</tr>';
    }
              
    dataHTML += 		 
                    '</tbody>'
    +            '</table>'
    +        '</div>'
    +    '</body>'
    +'</html>';

    var blob = new Blob(['\ufeff', dataHTML], {
        type: 'application/msword'
    });

    FileSaver.saveAs(blob, title + "_kronologi.doc");

}

function downloadChronologyAsTxt(record_details, data){
    // console.log("downloadChronologyAsTxt")

    var dataTXT = ""
    
    const title = record_details.name;
    // const start_time = record_details.starting_date;

    for (var i = 0; i < data.length; i++) {
        // var utterance_index = i + 1;
        // var created_date = data[i].datetime;
        // created_date     = moment(created_date).format("DD MMMM YYYY, hh:mm A");
        
        dataTXT = 
        dataTXT
            // + created_date + "\t" 
            + data[i].transcribe.text + "\r\n";

    }

    var blob = new Blob([dataTXT], { encoding: "UTF-8", type: "text/plain;charset=UTF-8" });
    FileSaver.saveAs(blob, title + "_kronologi.txt");

}

function downloadParagraphAsPdf(record_details, data){
    // console.log("downloadParagraphAsPdf")

    const title = record_details.name;

    var doc = new jsPDF();

    // doc.autoTable([[""]], [[title]], {
    doc.autoTable({
        head: [[""]], 
        body: [[title]], 
        theme: 'plain',
        showHead: 'firstPage',
        styles: {
            font: "times",
            fontSize: 12,
            textColor: 0,
            overflow: 'linebreak',
            cellWidth: 'auto',
            valign: 'top',
            halign: 'center',
        },
    });

    for (var i = 0; i < data.length; i++) {
        var created_date    = data[i].datetime;
        created_date        = moment(created_date).format("DD MMMM YYYY, hh:mm A");
        var speaker_name    = data[i].speaker.name;
        var paragraph       = data[i].transcribe.text;

        // doc.autoTable([[speaker_name + " ("+created_date+")"]], [[paragraph]], {
        doc.autoTable({
            head: [[speaker_name + " ("+created_date+")"]], 
            body: [[paragraph]], 
            startY: doc.previousAutoTable.finalY + 5,
            theme: 'plain',
            showHead: 'firstPage',
            styles: {
                font: "times",
                fontSize: 12,
                textColor: 0,
                overflow: 'linebreak',
                cellWidth: 'auto',
                valign: 'top',
                halign: 'justify'
            }
        });

    }

    doc.save(title + "_paragraf.pdf");
}

function downloadParagraphAsDoc(record_details, data) {
    var dataHTML = ""
    
    const title = record_details.name;
    // const start_time = record_details.starting_date;
    // const utterances_length = record_details.total_statement;

    dataHTML +=
     '<html>'
    +    '<head>'
    +        '<title>' + title + '</title>'
    +    '</head>'
    +    '<body>'
    +        '<div style="font-family: Times New Roman;">'
    +	         '<div style="text-align: center;">' + title + '</div>'
    +	         '<br/>'
    +            '<br/>'
    +            '<table style="border-collapse: collapse; font-family: Times New Roman;">'
    +                '<thead>'
    +                    '<tr>'
    +				         '<th style="padding: 5px 8px; border:1px solid #555555;">No</th>'
    +                        '<th style="padding: 5px 8px; border:1px solid #555555;">Waktu</th>'
    +				         '<th style="padding: 5px 8px; border:1px solid #555555;">Pembicara</th>'
    +				         '<th style="padding: 5px 8px; border:1px solid #555555;">Teks</th>'
    +                    '</tr>'
    +                '</thead>'
    +                '<tbody>'

    for (var i = 0; i < data.length; i++) {
        const utterance_index   = i + 1;
        let created_date      = data[i].datetime;
        created_date            = moment(created_date).format("DD MMMM YYYY, hh:mm A");
        const speaker_name      = data[i].speaker.name;
        const utterance         = data[i].transcribe.text;

        dataHTML +=	     
                            '<tr>'
        +                        '<td style="padding: 5px 8px; border:1px solid #555555;">' + utterance_index + '</td>'
        +                        '<td style="padding: 5px 8px; border:1px solid #555555;">' + created_date + '</td>'
        +                        '<td style="padding: 5px 8px; border:1px solid #555555;">' + speaker_name + '</td>'
        +                        '<td style="padding: 5px 8px; border:1px solid #555555;">' + utterance + '</td>'
        +                   '</tr>';
    }
              
    dataHTML += 		 
                    '</tbody>'
    +            '</table>'
    +        '</div>'
    +    '</body>'
    +'</html>'

    var blob = new Blob(['\ufeff', dataHTML], {
        type: 'application/msword'
    });
        
    FileSaver.saveAs(blob, title + "_paragraf.doc");

}

function downloadParagraphAsTxt(record_details, data){
    // console.log("downloadParagraphAsTxt")

    var dataTXT = ""
    
    const title = record_details.name;
    // const start_time = record_details.starting_date;

    for (var i = 0; i < data.length; i++) {
        // var utterance_index = i + 1;
        // var created_date = data[i].datetime;
        // created_date     = moment(created_date).format("DD MMMM YYYY, hh:mm A");
        
        dataTXT = 
            dataTXT
            // + created_date + "\t" 
            + data[i].transcribe.text + "\r\n";

    }

    var blob = new Blob([dataTXT], { encoding: "UTF-8", type: "text/plain;charset=UTF-8" });
    FileSaver.saveAs(blob, title + "_paragraf.txt");

}