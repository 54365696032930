import $ from 'jquery'
import React, { useEffect } from 'react';
import { useDispatch } from "react-redux";

// Styles
import './App.scss';

// Components
// import { NotificationContainer } from 'react-notifications';
import FloatingUploadCard from './apps/components/FloatingUploadCard/FloatingUploadCard';
import store from './store';
import { connOpen } from './reducers/NATSDefaultReducers';
import { useKeycloak } from '@react-keycloak/web';
import LoadingFullPage from './apps/components/LoadingFullPage';
import { updateIdToken } from './reducers/AccountManagementReducers';
import PublicRoutes from './routes/PublicRoutes';
import PrivateRoutes from './routes/PrivateRoutes';


function App(props) {
	const dispatch = useDispatch();
	const { keycloak, initialized } = useKeycloak();

	useEffect(() => {	
		// Open websocket-nats connection
		store.dispatch(connOpen());

        const lcTheme = localStorage.getItem('theme');
        if ( lcTheme === 'dark' || lcTheme === 'light' ) {
			$('body').removeClass('dark');
			$('body').removeClass('light');
	
			$('body').addClass(lcTheme);
        }

	}, []);

    useEffect(() => {
		dispatch(updateIdToken(keycloak.token));	
    }, [keycloak.token])

	// show loading page if keycloak has not initialized
	if ( !initialized ) { 
		return <LoadingFullPage info="Loading app" />;
	}

	return (
		<div className="App">
			{/* <NotificationContainer /> */}
			<div id="notification-container"></div>
			<>
				<FloatingUploadCard />								
				{ !keycloak.authenticated ?
					<PublicRoutes />
					:
					<PrivateRoutes />
				}
			</>
		</div>
	);
}

export default App;