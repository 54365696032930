/** 
Declare the type of action as constant.
WHY?
    - Help reduce typos 
    - Help reduce bugs and mistake
    - If you make typo and dispatch an undefined constants,
      the app will throw error to alert the mistake. 
*/

// Import
import { toastNotification } from '../apps/components/ToastNotification/ToastNotification';
import { fetchUtteranceList, fetchUtteranceListParagraph, fetchUtteranceUpdateSpeaker, updateFetchStatusAndData } from './FetcherReducers';
import { reqRecordDetails } from './RecordReducers';

// Functions

// Action Types
const UPDATE_UTTERANCE_LIST_AND_TOTAL   = "UtteranceReducers/UPDATE_UTTERANCE_LIST_AND_TOTAL";
const UPDATE_UTTERANCE_LIST_OFFSET      = "UtteranceReducers/UPDATE_UTTERANCE_LIST_OFFSET"
const RESET_UTTERANCE_LIST_DATA         = "UtteranceReducers/RESET_UTTERANCE_LIST_DATA"
const UPDATE_UTTERANCE_LIST_PARAGRAPH_AND_TOTAL   = "UtteranceReducers/UPDATE_UTTERANCE_LIST_PARAGRAPH_AND_TOTAL";
const UPDATE_UTTERANCE_LIST_PARAGRAPH_OFFSET      = "UtteranceReducers/UPDATE_UTTERANCE_LIST_PARAGRAPH_OFFSET"
const RESET_UTTERANCE_LIST_PARAGRAPH_DATA         = "UtteranceReducers/RESET_UTTERANCE_LIST_PARAGRAPH_DATA"

// Action Creators
/* 
 *  !LEGEND! 
 *      req = request
 *      rcv = receive
 *      fch = fetch
 *      snd = send
 */


// Actions

export const updateUtteranceListOffset = offset => ({
	type: UPDATE_UTTERANCE_LIST_OFFSET,
    payload: {
        utteranceListOffset: offset
    }
});

export const updateUtteranceListAndTotal = data => ({
	type: UPDATE_UTTERANCE_LIST_AND_TOTAL,
    payload: {
        utteranceList: data.utteranceList,
        utteranceListTotal: data.utteranceListTotal
    }
});

export const resetUtteranceListData = () => ({
	type: RESET_UTTERANCE_LIST_DATA
});


export const updateUtteranceListParagraphOffset = offset => ({
	type: UPDATE_UTTERANCE_LIST_PARAGRAPH_OFFSET,
    payload: {
        utteranceListParagraphOffset: offset
    }
});

export const updateUtteranceListParagraphAndTotal = data => ({
	type: UPDATE_UTTERANCE_LIST_PARAGRAPH_AND_TOTAL,
    payload: {
        utteranceListParagraph: data.utteranceListParagraph,
        utteranceListParagraphTotal: data.utteranceListParagraphTotal
    }
});

export const resetUtteranceListParagraphData = () => ({
	type: RESET_UTTERANCE_LIST_PARAGRAPH_DATA
});

// You can put impure functions within your action creators

// Reducers

export const rcvUtteranceListen = (data) => (dispatch, getState) => {
    const id_utterance = data.id;
    
    var utteranceList       = getState().UtteranceReducers.utteranceList.slice();
    var utteranceListTotal  = getState().UtteranceReducers.utteranceListTotal;
    
    const index = utteranceList.findIndex(({id}) => id === id_utterance);
    if ( index > -1 ) {
        utteranceList[index] = data;
    } else {
        utteranceList.push(data);
        utteranceListTotal += 1
    }
    
    const utteranceListAndTotal = {
        utteranceList: utteranceList,
        utteranceListTotal: utteranceListTotal
    }
    dispatch(updateUtteranceListAndTotal(utteranceListAndTotal))

    // refresh record detail incase total_transcribe has been updated
    const id_record = getState().RecordReducers.currentOpenedIdRecord;
	if ( id_record !== null ) {
        dispatch(reqRecordDetails(id_record))
    }

}

export const reqAllUtteranceList = (id_record, offset = 0) => (dispatch, getState) => { 
    const currentOpenedIdRecord = getState().RecordReducers.currentOpenedIdRecord;
    if ( id_record !== currentOpenedIdRecord ) return;

    dispatch(updateFetchStatusAndData("reqAllUtteranceList"));

    dispatch(updateUtteranceListOffset(offset))

    const limit = getState().UtteranceReducers.utteranceListLimit;

    const data = {
        id_record,
        offset,
        limit
    }

    dispatch(fetchUtteranceList(data))
        .then((res) => {
            dispatch(rcvAllUtteranceList(res))
        })
        .catch((resErr) => {
            dispatch(rcvAllUtteranceList(resErr))
        })
};

export const rcvAllUtteranceList = (msg) => (dispatch, getState) => {
    const offset = getState().UtteranceReducers.utteranceListOffset;
    const limit  = getState().UtteranceReducers.utteranceListLimit;
    const total  = getState().UtteranceReducers.utteranceListTotal;

	const request       = msg.request;
	const { id_record } = request;
    const res_offset    = request.offset;
	const res_total 	= msg.total;

    // RETURN if received utterance list not belong to current opened record
    const currentOpenedIdRecord = getState().RecordReducers.currentOpenedIdRecord;
    if ( id_record !== currentOpenedIdRecord ) 
        return;

    // RETURN if not the response of last requested utterance list
    if ( res_offset !== offset ) 
        return;
		
    // clear utterance list if received total is not equal to current total 
    if ( offset === 0 && total !== res_total ) {
        dispatch(resetUtteranceListData())
    }
    
    dispatch(updateFetchStatusAndData("rcvAllUtteranceList", msg));

    if ( msg.status === "failed" ) {
        toastNotification("error", "Gagal memuat daftar transkrip. "+msg.error)
        return;
    }

	var utteranceList = getState().UtteranceReducers.utteranceList.slice();
	msg.data.forEach((utterance, index) => {
		utteranceList[offset+index] = utterance;
	})

	const utteranceListAndTotal = {
		utteranceList: utteranceList,
		utteranceListTotal: res_total
	}
	dispatch(updateUtteranceListAndTotal(utteranceListAndTotal));

	if ( msg.data.length === limit ) {
		dispatch(reqAllUtteranceList((offset+limit)));
	}
};

export const reqUtteranceUpdateSpeaker = (id_utterance, participant ) => (dispatch, getState) => { 
    const data = {
        id_utterance, 
        id_participant: participant.id,
        participant
    }

    dispatch(fetchUtteranceUpdateSpeaker(data))
        .then((res) => {
            dispatch(rcvUtteranceUpdateSpeaker(res))
        })
        .catch((resErr) => {
            dispatch(rcvUtteranceUpdateSpeaker(resErr))
        })
};

export const rcvUtteranceUpdateSpeaker = (msg) => (dispatch, getState) => {

    if ( msg.status === "failed" ) {
        toastNotification("error", "Update utterance speaker failed. "+msg.error)
        return;
    }

    const { id_utterance, participant } = msg.request;
    
    var utteranceList       = getState().UtteranceReducers.utteranceList.slice();
    var utteranceListTotal  = getState().UtteranceReducers.utteranceListTotal;
    
    const index = utteranceList.findIndex(({id}) => id === id_utterance);
    if ( index > -1 ) {
        utteranceList[index].speaker = participant;

        const utteranceListAndTotal = {
            utteranceList: utteranceList,
            utteranceListTotal: utteranceListTotal
        }
        dispatch(updateUtteranceListAndTotal(utteranceListAndTotal))
    }
};

export const reqAllUtteranceListParagraph = (id_record, offset = 0) => (dispatch, getState) => { 
    const currentOpenedIdRecord = getState().RecordReducers.currentOpenedIdRecord;
    if ( id_record !== currentOpenedIdRecord ) return;

    dispatch(updateFetchStatusAndData("reqAllUtteranceListParagraph"));

    dispatch(updateUtteranceListParagraphOffset(offset))

    const limit = getState().UtteranceReducers.utteranceListParagraphLimit;

    const data = {
        id_record,
        offset,
        limit
    }

    dispatch(fetchUtteranceListParagraph(data))
        .then((res) => {
            dispatch(rcvAllUtteranceListParagraph(res))
        })
        .catch((resErr) => {
            dispatch(rcvAllUtteranceListParagraph(resErr))
        })
};

export const rcvAllUtteranceListParagraph = (msg) => (dispatch, getState) => {
    const request   = msg.request;
    const { id_record, offset, limit } = request;
    const total = msg.total;

    // RETURN if received utterance list not belong to current opened record
    const currentOpenedIdRecord = getState().RecordReducers.currentOpenedIdRecord;
    if ( id_record !== currentOpenedIdRecord ) return;

    // RETURN if not the response of last requested utterance list
    const utteranceListParagraphOffset = getState().UtteranceReducers.utteranceListParagraphOffset;
    if ( offset !== utteranceListParagraphOffset ) return;
    
    dispatch(updateFetchStatusAndData("rcvAllUtteranceListParagraph", msg));

    if ( msg.status === "failed" ) {
        toastNotification("error", "Gagal memuat daftar paragraf. "+msg.error)
        return;
    }

	var utteranceListParagraph = getState().UtteranceReducers.utteranceListParagraph.slice();
    
	msg.data.forEach((utterance, index) => {
		utteranceListParagraph[offset+index] = utterance;
	})

	const utteranceListParagraphAndTotal = {
		utteranceListParagraph: utteranceListParagraph,
		utteranceListParagraphTotal: total
	}
	dispatch(updateUtteranceListParagraphAndTotal(utteranceListParagraphAndTotal));

	if ( msg.data.length === limit ) {
		dispatch(reqAllUtteranceListParagraph((offset+limit)));
	}
};

// Reducer's initial state
const initialState = {
    utteranceList       : [],
    utteranceListOffset : 0,
    utteranceListLimit  : 1000,
    utteranceListTotal  : 0,

    utteranceListParagraph       : [],
    utteranceListParagraphOffset : 0,
    utteranceListParagraphLimit  : 1000,
    utteranceListParagraphTotal  : 0,
};


// You must only write pure function when trying to build the reducer! 

export default function UtteranceReducers(state = initialState, action) {
    switch (action.type) {
        case UPDATE_UTTERANCE_LIST_OFFSET:
            return {
                ...state,
                utteranceListOffset: action.payload.utteranceListOffset
            };
        case UPDATE_UTTERANCE_LIST_AND_TOTAL:
            return {
                ...state,
                utteranceList: action.payload.utteranceList,
                utteranceListTotal: action.payload.utteranceListTotal
            };
        case RESET_UTTERANCE_LIST_DATA:
            return {
                ...state,
                utteranceList       : [],
                utteranceListOffset : 0,
                utteranceListTotal  : 0,
            };
        case RESET_UTTERANCE_LIST_PARAGRAPH_DATA:
            return {
                ...state,
                utteranceListParagraph       : [],
                utteranceListParagraphOffset : 0,
                utteranceListParagraphTotal  : 0,
            };
        case UPDATE_UTTERANCE_LIST_PARAGRAPH_OFFSET:
            return {
                ...state,
                utteranceListParagraphOffset: action.payload.utteranceListParagraphOffset
            };
        case UPDATE_UTTERANCE_LIST_PARAGRAPH_AND_TOTAL:
            return {
                ...state,
                utteranceListParagraph: action.payload.utteranceListParagraph,
                utteranceListParagraphTotal: action.payload.utteranceListParagraphTotal
            };
        default:
            return state;
    }
}



// Side effects, only as applicable
// e.g. thunks,epics, etc