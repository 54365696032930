import { useEffect, useRef, useState } from 'react'
//components
import { Modal, Form } from 'react-bootstrap'
//assets
// import x from '../../img/x-close.svg'
import x from '../../../img/x-cancel.svg'
import { useDispatch } from 'react-redux'
import { reqAllKeywordList } from '../../../../reducers/KeywordReducers'
import { fetchKeywordAdd } from '../../../../reducers/FetcherReducers'
import ButtonPrimaryLoading from '../../Buttons/ButtonPrimaryLoading'
import NotifAlert from '../../NotifAlert/NotifAlert'


function ModalAddKeyword(props) {
    const { show, onAddKeywordSuccess } = props;

    const dispatch = useDispatch();

    const firstInputRef = useRef();

    const [notifAlertData, setNotifAlertData] = useState(null);
    const [loading, setLoading] = useState(false);

    const [keyword, setKeyword] = useState("");

    useEffect(() => {
        if ( show ) {
            firstInputRef.current.focus();
        }
    }, [show]);

    useEffect(() => {
        setNotifAlertData(null)
    }, [keyword]);

    const handleKeywordChange = (e) => {
        const value = e.target.value;

        // Max keyword length is 32
        if ( value.length > 32 ) {
            setNotifAlertData({
                status: "error",
                text: "Max 32 character",
                timeout: 3000
            })
            return;
        } 
        
        setKeyword(value)
    }

    const onAddClick = () => {
        // check name
        if ( keyword.replace(/\s+/g, '') === "") {
            setNotifAlertData({
                status: "error",
                text: "please fill all column",
                timeout: -1
            })
            return
        } 
        // Max keyword length is 32
        else if ( keyword.length > 32 ) {
            setNotifAlertData({
                status: "error",
                text: "Max 32 character",
                timeout: 3000
            })
            return;
        } 

        setLoading(true)

        var data = {
            keyword: keyword
        }

        dispatch(fetchKeywordAdd(data))
            .then((res) => {
                setLoading(false)

                setKeyword("")

                props.onHide();

                if ( onAddKeywordSuccess !== undefined ) {
                    const keywordData = {
                        id: res.id,
                        keyword
                    }
                    onAddKeywordSuccess(keywordData)
                }

                // notif when success delete user
                setNotifAlertData({
                    status: "success",
                    text: "Keyword '"+keyword+"' added",
                    timeout: 3000
                })

                dispatch(reqAllKeywordList());
            })
            .catch((err) => {
                var error = err.error.toString();
                
                setLoading(false)

                // notif when failed delete user
                setNotifAlertData({
                    status: "error",
                    text: "Failed add keyword. "+error,
                    timeout:3000
                })
            })
    }

    return (
        <Modal
            {...props}
            size="sm"
            centered
            id="modal-add-keyword"
        >
            <div id="add">
                { notifAlertData !== null &&
                    <NotifAlert 
                        data={notifAlertData}
                    />
                }

                <div className="top">
                    Add Keyword
                    <img className="close-btn" src={x} alt="close" onClick={() => props.onHide()} />
                </div>
                <Form className="form" 
                    onSubmit={(e) => {
                        e.preventDefault(); 
                        onAddClick();
                    }}
                >
                    <input
                        ref={firstInputRef}
                        placeholder="Keyword Name"
                        type="text"
                        value={keyword} 
                        onChange={e => handleKeywordChange(e)}
                    />
                </Form>
                { loading ?
                    <ButtonPrimaryLoading />
                    :
                    <button 
                        className="primary" 
                        onClick={onAddClick}
                    >
                        Add
                    </button>
                }
            </div>
        </Modal>
    )
}

export default ModalAddKeyword;