import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

//functions
import { fetchParticipantDelete } from '../../../reducers/FetcherReducers';
import { reqAllParticipantList } from '../../../reducers/SpeakerAndParticipantReducers';

//components
import { Modal } from 'react-bootstrap';
import ButtonDangerLoading from '../Buttons/ButtonDangerLoading';

//assets
import './ModalSpeakerAndParticipant.scss'


function ModalDeleteParticipant(props) {
    const { setNotifAlertData, id_record, participant } = props;

    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);

    const [name, setName] = useState("")
    
    useEffect(() => {
        if ( participant !== null && participant !== undefined ) {
            setName(participant.name)
        }
    }, [participant]);

    const onDel = () => {

        setLoading(true)
        
        const data = {
            id_record: id_record, 
            id_participant: participant.id
        }
    
        dispatch(fetchParticipantDelete(data))
            .then((res) => {
                setLoading(false)

                props.onHide();
                
                setNotifAlertData({
                    status: "success",
                    text: "Participant deleted",
                    timeout: 3000
                })

                dispatch(reqAllParticipantList(id_record))
            })
            .catch((err) => {
                var error = err.error.toString();

                setLoading(false)

                setNotifAlertData({
                    status: "error",
                    text: "Failed delete participant. "+error,
                    timeout: 3000
                })
            })
    }

    return (
        <Modal
            {...props}
            size="sm"
            centered
            backdrop="static"
            id="modal-add-user"
        >
            <div id="add">
                <div className="top">
                    Delete Participant
                </div>
                <div className="caption">
                    Apakah anda yakin ingin menghapus participant <b>{name}</b>?
                </div>
                <div className="btn-wrap">
                { loading ?
                    <ButtonDangerLoading />
                    :
                    <>
                        <button 
                            className="error" 
                            onClick={onDel}
                        >
                            Delete
                        </button>
                        <button 
                            className="primary" 
                            onClick={() => props.onHide()}
                        >
                            Cancel
                        </button>
                    </>
                }
                </div>
            </div>
        </Modal>
    )
}

export default ModalDeleteParticipant;