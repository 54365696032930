import { useState } from 'react'
import { useDispatch } from 'react-redux';

//functions
import { threshold } from '../../../../threshold';
import { fetchUtteranceReload } from '../../../../reducers/FetcherReducers';

//components
import { OverlayTrigger, Popover } from 'react-bootstrap'

//assets
import transkrip from '../../../img/transkrip-quality.png'
import info from '../../../img/info-circle.svg'


function CardRecordInfo(props) {
    const { setNotifAlertData, recordData } = props;
    const { 
        id_record, 
        total_statement, 
        total_transcribe,
        transcribe_quality,
    } = recordData;
    
    const dispatch          = useDispatch();

    const [reload, setReload] = useState(false);

    const total_not_transcribed = total_statement-total_transcribe;

    const onReloadAllStatementClick = (e) => {
        setReload(true)
        
        const data = {
            id_record: id_record,
            id_utterance: -1 // reload all
        }

        dispatch(fetchUtteranceReload(data))
            .then(() => {
                setReload(false)
                
                setNotifAlertData({
                    status: "success",
                    text: "Reload request sent",
                    timeout: 3000
                })
            })
            .catch((err) => {
                var error = err.error.toString();
                
                setReload(false)
                
                setNotifAlertData({
                    status: "error",
                    text: "Request Reload failed. "+error,
                    timeout: 3000
                })
            })
    }

    // get transcribe_quality_label
    var transcribe_quality_label = "unknown";
    const label_index = threshold.transcribe_quality.findIndex((q) => transcribe_quality >= q.minValue);
    if ( label_index > -1 ) {
        transcribe_quality_label = threshold.transcribe_quality[label_index].label
    }

    return (
        <div className="item-cont transkrip-info">
            <div className="transkrip-info-wrap">
                <img className="icon" src={transkrip} alt="icon" />
                <div className={ "transcribe-quality " +
                        ( transcribe_quality_label === "good" ? "label-info" : 
                            transcribe_quality_label === "standard" ? "label-warning" :
                            transcribe_quality_label === "bad" ? "label-danger" : 
                            "label-warning" )
                    }
                >
                    <h5>Transkrip Quality</h5>
                    <label>{transcribe_quality_label}</label>
                </div>
                <OverlayTrigger
                    trigger="click"
                    key="top"
                    placement="top"
                    rootClose={true}
                    overlay={
                        <Popover id={`popover-positioned-top`}>
                            <Popover.Content>
                                Kualitas transkrip dari sistem
                            </Popover.Content>
                        </Popover>
                    }
                >
                    <img className="i" src={info} alt="info" />
                </OverlayTrigger>
            </div>
            { total_not_transcribed > 0 &&
                <div className="statement-info">
                    <p><b>{total_not_transcribed}</b> Statement terdapat issue saat transkripsi, silahkan reload ulang.</p>
                    {
                        // reload ?
                        // <div className="progress-wrap">
                        //     <ProgressBar now={progress} /> <span>{progress}%</span>                         
                        // </div>
                        // :
                        
                        reload ?
                            <span>Sending reload request...</span>
                            :
                            <span onClick={onReloadAllStatementClick}>Reload Statement</span>
                    }
                </div>
            }

        </div>
    )
}

export default CardRecordInfo;