import { useState } from 'react'

//components
import ModalAddParticipant from '../../ModalSpeakerAndParticipant/ModalAddParticipant';
import ParticipantList from './ParticipantList';
import SpeakerList from './SpeakerList';

function CardSpeakerAndParticipant(props) {
    const { setNotifAlertData, id_record } = props;

    const [menuActive, setMenu] = useState(1);

    const [showAddPartisipan, setShowAddPartisipan] = useState(false);

    return (
        <>
            <ModalAddParticipant
                show={showAddPartisipan}
                onHide={() => setShowAddPartisipan(false)}
                setNotifAlertData={setNotifAlertData}
                id_record={id_record}
            />

            <div className="item-cont speaker-list">
                <div className="speaker-nav">
                    {/* <p
                        id="p" 
                        className={menuActive === 0 ? "active b-border" : ""} 
                        onClick={() => setMenu(0)}
                    >Speaker</p> */}
                    <p 
                        id="p" 
                        className={menuActive === 1 ? "active b-border" : ""} 
                        onClick={() => setMenu(1)}
                    >Partisipan</p>
                </div>
                <div className={"speaker-list-cont expand"}>
                    <div className={"speaker-list-wrap expand"}>
                        <div className={"section"}>
                            { menuActive === 0 ?
                                <SpeakerList 
                                    setNotifAlertData={setNotifAlertData}
                                    id_record={id_record} 
                                />
                                :
                                <ParticipantList 
                                    setNotifAlertData={setNotifAlertData}
                                    id_record={id_record} 
                                />
                            }
                        </div>
                    </div>
                </div>
                { menuActive === 1 &&
                    <div className="speaker-item-add" onClick={() => setShowAddPartisipan(true)}>
                        + Add New Partisipan
                    </div>
                }
            </div>
        </>
    )
}

export default CardSpeakerAndParticipant;