import moment from 'moment';
import folder from '../apps/img/folder.svg'
import ava from '../apps/img/ava.svg'

export var profile_data = {
    "status": "success",
    "error": "",
    "id_user":"admin",
    "username":"Administrator",
    "actor": [{
        "id" : 1,
        "username": "Administrator",
        "avatar": "/img/ava-empty.svg",
        "organisation": "Bahasa Kita",
        "title": "admin",
        "default": true
      }],
    "actor_length": 0,
    "auth": [
      {
        "method": 1,
        "auth_name": "admin@email.com",
        "default":true
      }
    ],
    "auth_length": 1,
    "req_id":""
}

export var record_list_data = [
    {
        id: 1,
        state: "uploaded",
        name: "Record 1",
        starting_date: moment().format(),
        end_date: moment().format(),
        total_statement: 0,
        total_transcribe: 0,
    },
    {
        id: 2,
        state: "uploaded",
        name: "Record 2",
        starting_date: moment().format(),
        end_date: moment().format(),
        total_statement: 0,
        total_transcribe: 0,
    },
    {
        id: 3,
        state: "transcripted",
        name: "Record 3",
        starting_date: moment().format(),
        end_date: moment().format(),
        total_statement: 10,
        total_transcribe: 10,
    },
    {
        id: 4,
        state: "transcripting",
        name: "Now Recording",
        starting_date: moment().format(),
        end_date: moment().format(),
        total_statement: 10,
        total_transcribe: 8,
    }
]

export const people_list_data = [
    {id:1, name: "Farhan", image: ava},
    {id:2, name: "Ica", image: ""},
    {id:3, name: "Ridwan", image: ava},
    {id:4, name: "Rizky", image: ava},
    {id:5, name: "Chuba", image: ava}
];

export const team_list_data = [
    {id:1, name: "Marketing", image: folder},
    {id:2, name: "Product", image: folder},
    {id:3, name: "R&D", image: folder},
    {id:4, name: "Operation", image: folder}
];

export const team_member_list_data = [
    {id:1, id_user:1, name: "Farhan", image: ava},
    {id:2, id_user:3, name: "Ridwan", image: ava},
    {id:3, id_user:5, name: "Chuba", image: ava}
];

export const model_asr_list_data = [
    {id:1, name: "Meeting Online", description: ""},
    {id:2, name: "Meeting Offline", description: ""},
    {id:3, name: "Telephony", description: ""},
    {id:4, name: "Webinar", description: ""},
]

export const utterance_list = [
    {
        "id": 1,
        "index": 1,
        "speaker": {
            "id":2,
            "name":"Farhan Hadi",
            "image":"",
            "title":"",
            "organisation":""
        },
        "translation": {
            "id": 1,
            "text": "The Governor of West Java, Ridwan Kamil, stated that he is ready to fully support the process of digitizing the Sundanese script which is currently being pursued by Pandi and other Sundanese script activist communities. This support is deemed necessary in facing the challenges ahead, especially related to the diverse cultural richness of the archipelago."
        },
        "transcribe": {
            "text": "Gubernur Jawa Barat, Ridwan Kamil menyatakan siap mendukung penuh proses kegiatan digitalisasi aksara Sunda yang saat ini tengah diupayakan Pandi dan komunitas pegiat aksara Sunda lainnya. Dukungan ini dirasa perlu dalam menghadapi tantangan ke depan terutama terkait kekayaan budaya Nusantara yang beragam.",
            "score": "good",
            "orig": true,
            "corrector":"",
            "state":"final"
        },
        "typo": [
            {
                "word": "Pandi",
                "suggestion": []
            },{
                "word": "slmea",
                "suggestion": []
            },{
                "word": "Jek nap",
                "suggestion": []
            },{
                "word": "haptke",
                "suggestion": []
            },{
                "word": "smplit",
                "suggestion": []
            }
        ],
        "keyword": ["digitalisasi","sunda","budaya"],
        "audio": "/dummy_audio/berita.wav",
        "timeline": {
            "from": "00:00:00",
            "to": "00:00:34",
            "length": 34
        },
        "datetime":"2021/10/24 22:26:08 +07",
        "language": "indonesia"
    },{
        "id": 2,
        "index": 1,
        "speaker": {
            "id":6,
            "name":"Samantha",
            "image":"",
            "title":"",
            "organisation":""
        },
        "translation": {
            "id": 1,
            "text": "Ladies and gentlemen, If we maintain cleanliness and then imitated by our children and those close to us, then we will get the benefits. Therefore, let's teach children as early as possible about the importance of maintaining cleanliness so that later they become healthy, intelligent, and like to do good."
        },
        "transcribe": {
            "text": "Hadirin sekalian, Jika kita menjaga kebersihan lalu ditiru oleh anak-anak kita dan orang-orang dekat kita, maka kita pun akan mendapt manfaatnya. Oleh karenanya, mari ajarkan sedini mungkin terhadap anak-anak tentang pentingnya menjaga kebersihan agar kelak mereka menjadi anak yang sehat, cerdas, dan suka berbuat kebaikan.",
            "score": "low",
            "orig": true,
            "corrector":"",
            "state":"final"
        },
        "typo": [
            {
                "word": "mendapt",
                "suggestion": []
            },{
                "word": "slmea",
                "suggestion": []
            },{
                "word": "Jek nap",
                "suggestion": []
            },{
                "word": "haptke",
                "suggestion": []
            },{
                "word": "smplit",
                "suggestion": []
            }
        ],
        "keyword": ["kebersihan","sehat","cerdas","kebaikan"],
        "audio": "/dummy_audio/pidato.wav",
        "timeline": {
            "from": "00:00:00",
            "to": "00:00:34",
            "length": 34
        },
        "datetime":"2021/10/24 22:26:08 +07",
        "language": "indonesia"
    },{
        "id": 3,
        "index": 1,
        "speaker": {
            "id":6,
            "name":"Samantha",
            "image":"",
            "title":"",
            "organisation":""
        },
        "translation": {
            "id": 1,
            "text": ""
        },
        "transcribe": {
            "text": "",
            "score": "good",
            "orig": true,
            "corrector":"",
            "state":"failed"
        },
        "typo": [
            {
                "word": "spritke",
                "suggestion": []
            },{
                "word": "slmea",
                "suggestion": []
            },{
                "word": "Jek nap",
                "suggestion": []
            },{
                "word": "haptke",
                "suggestion": []
            },{
                "word": "smplit",
                "suggestion": []
            }
        ],
        "keyword": ["Sprint","Ideas"],
        "audio": "",
        "timeline": {
            "from": "00:00:00",
            "to": "00:00:34",
            "length": 34
        },
        "datetime":"2021/10/24 22:26:08 +07",
        "language": "indonesia"
    },{
        "id": 4,
        "index": 1,
        "speaker": {
            "id":2,
            "name":"Farhan Hadi",
            "image":"",
            "title":"",
            "organisation":""
        },
        "translation": {
            "id": 1,
            "text": "The Governor of West Java, Ridwan Kamil, stated that he is ready to fully support the process of digitizing the Sundanese script which is currently being pursued by Pandi and other Sundanese script activist communities. This support is deemed necessary in facing the challenges ahead, especially related to the diverse cultural richness of the archipelago."
        },
        "transcribe": {
            "text": "Gubernur Jawa Barat, Ridwan Kamil menyatakan siap mendukung penuh proses kegiatan digitalisasi aksara Sunda yang saat ini tengah diupayakan Pandi dan komunitas pegiat aksara Sunda lainnya. Dukungan ini dirasa perlu dalam menghadapi tantangan ke depan terutama terkait kekayaan budaya Nusantara yang beragam.",
            "score": "good",
            "orig": true,
            "corrector":"",
            "state":"final"
        },
        "typo": [
            {
                "word": "Pandi",
                "suggestion": []
            },{
                "word": "slmea",
                "suggestion": []
            },{
                "word": "Jek nap",
                "suggestion": []
            },{
                "word": "haptke",
                "suggestion": []
            },{
                "word": "smplit",
                "suggestion": []
            }
        ],
        "keyword": ["digitalisasi","sunda","budaya"],
        "audio": "/dummy_audio/berita.wav",
        "timeline": {
            "from": "00:00:00",
            "to": "00:00:34",
            "length": 34
        },
        "datetime":"2021/10/24 22:26:08 +07",
        "language": "indonesia"
    },{
        "id": 5,
        "index": 1,
        "speaker": {
            "id":6,
            "name":"Samantha",
            "image":"",
            "title":"",
            "organisation":""
        },
        "translation": {
            "id": 1,
            "text": "Ladies and gentlemen, If we maintain cleanliness and then imitated by our children and those close to us, then we will get the benefits. Therefore, let's teach children as early as possible about the importance of maintaining cleanliness so that later they become healthy, intelligent, and like to do good."
        },
        "transcribe": {
            "text": "Hadirin sekalian, Jika kita menjaga kebersihan lalu ditiru oleh anak-anak kita dan orang-orang dekat kita, maka kita pun akan mendapt manfaatnya. Oleh karenanya, mari ajarkan sedini mungkin terhadap anak-anak tentang pentingnya menjaga kebersihan agar kelak mereka menjadi anak yang sehat, cerdas, dan suka berbuat kebaikan.",
            "score": "good",
            "orig": true,
            "corrector":"",
            "state":"final"
        },
        "typo": [
            {
                "word": "mendapt",
                "suggestion": []
            },{
                "word": "slmea",
                "suggestion": []
            },{
                "word": "Jek nap",
                "suggestion": []
            },{
                "word": "haptke",
                "suggestion": []
            },{
                "word": "smplit",
                "suggestion": []
            }
        ],
        "keyword": ["kebersihan","sehat","cerdas","kebaikan"],
        "audio": "/dummy_audio/pidato.wav",
        "timeline": {
            "from": "00:00:00",
            "to": "00:00:34",
            "length": 34
        },
        "datetime":"2021/10/24 22:26:08 +07",
        "language": "indonesia"
    },{
        "id": 6,
        "index": 1,
        "speaker": {
            "id":6,
            "name":"Samantha",
            "image":"",
            "title":"",
            "organisation":""
        },
        "translation": {
            "id": 1,
            "text": ""
        },
        "transcribe": {
            "text": "",
            "score": "good",
            "orig": true,
            "corrector":"",
            "state":"failed"
        },
        "typo": [
            {
                "word": "spritke",
                "suggestion": []
            },{
                "word": "slmea",
                "suggestion": []
            },{
                "word": "Jek nap",
                "suggestion": []
            },{
                "word": "haptke",
                "suggestion": []
            },{
                "word": "smplit",
                "suggestion": []
            }
        ],
        "keyword": ["Sprint","Ideas"],
        "audio": "",
        "timeline": {
            "from": "00:00:00",
            "to": "00:00:34",
            "length": 34
        },
        "datetime":"2021/10/24 22:26:08 +07",
        "language": "indonesia"
    },{
        "id": 7,
        "index": 1,
        "speaker": {
            "id":2,
            "name":"Farhan Hadi",
            "image":"",
            "title":"",
            "organisation":""
        },
        "translation": {
            "id": 1,
            "text": "The Governor of West Java, Ridwan Kamil, stated that he is ready to fully support the process of digitizing the Sundanese script which is currently being pursued by Pandi and other Sundanese script activist communities. This support is deemed necessary in facing the challenges ahead, especially related to the diverse cultural richness of the archipelago."
        },
        "transcribe": {
            "text": "Gubernur Jawa Barat, Ridwan Kamil menyatakan siap mendukung penuh proses kegiatan digitalisasi aksara Sunda yang saat ini tengah diupayakan Pandi dan komunitas pegiat aksara Sunda lainnya. Dukungan ini dirasa perlu dalam menghadapi tantangan ke depan terutama terkait kekayaan budaya Nusantara yang beragam.",
            "score": "good",
            "orig": true,
            "corrector":"",
            "state":"final"
        },
        "typo": [
            {
                "word": "Pandi",
                "suggestion": []
            },{
                "word": "slmea",
                "suggestion": []
            },{
                "word": "Jek nap",
                "suggestion": []
            },{
                "word": "haptke",
                "suggestion": []
            },{
                "word": "smplit",
                "suggestion": []
            }
        ],
        "keyword": ["digitalisasi","sunda","budaya"],
        "audio": "/dummy_audio/berita.wav",
        "timeline": {
            "from": "00:00:00",
            "to": "00:00:34",
            "length": 34
        },
        "datetime":"2021/10/24 22:26:08 +07",
        "language": "indonesia"
    },{
        "id": 8,
        "index": 1,
        "speaker": {
            "id":6,
            "name":"Samantha",
            "image":"",
            "title":"",
            "organisation":""
        },
        "translation": {
            "id": 1,
            "text": "Ladies and gentlemen, If we maintain cleanliness and then imitated by our children and those close to us, then we will get the benefits. Therefore, let's teach children as early as possible about the importance of maintaining cleanliness so that later they become healthy, intelligent, and like to do good."
        },
        "transcribe": {
            "text": "Hadirin sekalian, Jika kita menjaga kebersihan lalu ditiru oleh anak-anak kita dan orang-orang dekat kita, maka kita pun akan mendapt manfaatnya. Oleh karenanya, mari ajarkan sedini mungkin terhadap anak-anak tentang pentingnya menjaga kebersihan agar kelak mereka menjadi anak yang sehat, cerdas, dan suka berbuat kebaikan.",
            "score": "good",
            "orig": true,
            "corrector":"",
            "state":"final"
        },
        "typo": [
            {
                "word": "mendapt",
                "suggestion": []
            },{
                "word": "slmea",
                "suggestion": []
            },{
                "word": "Jek nap",
                "suggestion": []
            },{
                "word": "haptke",
                "suggestion": []
            },{
                "word": "smplit",
                "suggestion": []
            }
        ],
        "keyword": ["kebersihan","sehat","cerdas","kebaikan"],
        "audio": "/dummy_audio/pidato.wav",
        "timeline": {
            "from": "00:00:00",
            "to": "00:00:34",
            "length": 34
        },
        "datetime":"2021/10/24 22:26:08 +07",
        "language": "indonesia"
    },{
        "id": 9,
        "index": 1,
        "speaker": {
            "id":6,
            "name":"Samantha",
            "image":"",
            "title":"",
            "organisation":""
        },
        "translation": {
            "id": 1,
            "text": ""
        },
        "transcribe": {
            "text": "",
            "score": "good",
            "orig": true,
            "corrector":"",
            "state":"failed"
        },
        "typo": [
            {
                "word": "spritke",
                "suggestion": []
            },{
                "word": "slmea",
                "suggestion": []
            },{
                "word": "Jek nap",
                "suggestion": []
            },{
                "word": "haptke",
                "suggestion": []
            },{
                "word": "smplit",
                "suggestion": []
            }
        ],
        "keyword": ["Sprint","Ideas"],
        "audio": "",
        "timeline": {
            "from": "00:00:00",
            "to": "00:00:34",
            "length": 34
        },
        "datetime":"2021/10/24 22:26:08 +07",
        "language": "indonesia"
    },{
        "id": 10,
        "index": 1,
        "speaker": {
            "id":2,
            "name":"Farhan Hadi",
            "image":"",
            "title":"",
            "organisation":""
        },
        "translation": {
            "id": 1,
            "text": "The Governor of West Java, Ridwan Kamil, stated that he is ready to fully support the process of digitizing the Sundanese script which is currently being pursued by Pandi and other Sundanese script activist communities. This support is deemed necessary in facing the challenges ahead, especially related to the diverse cultural richness of the archipelago."
        },
        "transcribe": {
            "text": "Gubernur Jawa Barat, Ridwan Kamil menyatakan siap mendukung penuh proses kegiatan digitalisasi aksara Sunda yang saat ini tengah diupayakan Pandi dan komunitas pegiat aksara Sunda lainnya. Dukungan ini dirasa perlu dalam menghadapi tantangan ke depan terutama terkait kekayaan budaya Nusantara yang beragam.",
            "score": "good",
            "orig": true,
            "corrector":"",
            "state":"final"
        },
        "typo": [
            {
                "word": "Pandi",
                "suggestion": []
            },{
                "word": "slmea",
                "suggestion": []
            },{
                "word": "Jek nap",
                "suggestion": []
            },{
                "word": "haptke",
                "suggestion": []
            },{
                "word": "smplit",
                "suggestion": []
            }
        ],
        "keyword": ["digitalisasi","sunda","budaya"],
        "audio": "/dummy_audio/berita.wav",
        "timeline": {
            "from": "00:00:00",
            "to": "00:00:34",
            "length": 34
        },
        "datetime":"2021/10/24 22:26:08 +07",
        "language": "indonesia"
    },{
        "id": 11,
        "index": 1,
        "speaker": {
            "id":6,
            "name":"Samantha",
            "image":"",
            "title":"",
            "organisation":""
        },
        "translation": {
            "id": 1,
            "text": "Ladies and gentlemen, If we maintain cleanliness and then imitated by our children and those close to us, then we will get the benefits. Therefore, let's teach children as early as possible about the importance of maintaining cleanliness so that later they become healthy, intelligent, and like to do good."
        },
        "transcribe": {
            "text": "Hadirin sekalian, Jika kita menjaga kebersihan lalu ditiru oleh anak-anak kita dan orang-orang dekat kita, maka kita pun akan mendapt manfaatnya. Oleh karenanya, mari ajarkan sedini mungkin terhadap anak-anak tentang pentingnya menjaga kebersihan agar kelak mereka menjadi anak yang sehat, cerdas, dan suka berbuat kebaikan.",
            "score": "good",
            "orig": true,
            "corrector":"",
            "state":"final"
        },
        "typo": [
            {
                "word": "mendapt",
                "suggestion": []
            },{
                "word": "slmea",
                "suggestion": []
            },{
                "word": "Jek nap",
                "suggestion": []
            },{
                "word": "haptke",
                "suggestion": []
            },{
                "word": "smplit",
                "suggestion": []
            }
        ],
        "keyword": ["kebersihan","sehat","cerdas","kebaikan"],
        "audio": "/dummy_audio/pidato.wav",
        "timeline": {
            "from": "00:00:00",
            "to": "00:00:34",
            "length": 34
        },
        "datetime":"2021/10/24 22:26:08 +07",
        "language": "indonesia"
    },{
        "id": 12,
        "index": 1,
        "speaker": {
            "id":6,
            "name":"Samantha",
            "image":"",
            "title":"",
            "organisation":""
        },
        "translation": {
            "id": 1,
            "text": ""
        },
        "transcribe": {
            "text": "",
            "score": "good",
            "orig": true,
            "corrector":"",
            "state":"failed"
        },
        "typo": [
            {
                "word": "spritke",
                "suggestion": []
            },{
                "word": "slmea",
                "suggestion": []
            },{
                "word": "Jek nap",
                "suggestion": []
            },{
                "word": "haptke",
                "suggestion": []
            },{
                "word": "smplit",
                "suggestion": []
            }
        ],
        "keyword": ["Sprint","Ideas"],
        "audio": "",
        "timeline": {
            "from": "00:00:00",
            "to": "00:00:34",
            "length": 34
        },
        "datetime":"2021/10/24 22:26:08 +07",
        "language": "indonesia"
    },
    // {
    //     id: 2,
    //     name: "Samantha",
    //     duration: "00:30 - 01:05",
    //     quality: "low",
    //     utterance: "Utterance 2 lmea lima hari mengikuti metode yang diajarkaan oleh Jek nap dalam bukunya Design Sprint How To solve big Problem and Test new ideas in just five days. Sprint kita akan dimulai dari haptke Inisiate smplit dengan Usability Testing.",
    //     translation: "lmea lima hari mengikuti metode yang diajarkaan oleh Jek nap dalam bukunya Design Sprint How To solve big Problem and Test new ideas in just five days. Sprint kita akan dimulai dari haptke Inisiate smplit dengan Usability Testing.",
    //     typo: ["spritke","slmea","Jek nap","haptke","smplit"],
    //     keyword: ["Sprint","Ideas"],
    //     language: "Indonesia",
    //     status: "done"
    // },
    // {
    //     id: 3,
    //     name: "Farhan Hadi",
    //     duration: "00:64",
    //     quality: "good",
    //     utterance: "Utterance 3 mengikuti metode yang diajarkaan oleh Jek nap dalam bukunya Design Sprint How To solve big Problem and Test new ideas in just five days. Sprint kita akan dimulai dari haptke Inisiate smplit dengan Usability Testing.",
    //     translation: "mengikuti metode yang diajarkaan oleh Jek nap dalam bukunya Design Sprint How To solve big Problem and Test new ideas in just five days. Sprint kita akan dimulai dari haptke Inisiate smplit dengan Usability Testing.",
    //     keyword: ["Sprint","Ideas"],
    //     typo: ["spritke","slmea","Jek nap","haptke","smplit"],
    //     language: "Indonesia",
    //     status: "fail"   
    // },
    // {
    //     id: 4,
    //     name: "Farhan Hadi",
    //     duration: "02:00",
    //     quality: "good",
    //     utterance: "Utterance 4 Design spritke kita akan dilakukan slmea lima hari mengikuti metode yang diajarkaan oleh Jek nap dalam bukunya Design Sprint How To solve big Problem and Test new ideas in just five days. Sprint kita akan dimulai dari haptke Inisiate smplit dengan Usability Testing.",
    //     translation: "Design spritke kita akan dilakukan slmea lima hari mengikuti metode yang diajarkaan oleh Jek nap dalam bukunya Design Sprint How To solve big Problem and Test new ideas in just five days. Sprint kita akan dimulai dari haptke Inisiate smplit dengan Usability Testing.",
    //     typo: ["spritke","slmea","Jek nap","haptke","smplit"],
    //     keyword: ["Sprint","Ideas"],
    //     language: "Indonesia",
    //     status: "done"   
    // },
    // {
    //     id: 5,
    //     name: "Samantha",
    //     duration: "01:34 - 02:32",
    //     quality: "low",
    //     utterance: "Utterance 5 Design spritke kita akan dilakukan slmea lima hari mengikuti metode yang diajarkaan oleh Jek nap dalam bukunya Design Sprint How To solve big Problem and Test new ideas in just five days. Sprint kita akan dimulai dari haptke Inisiate smplit dengan Usability Testing.",
    //     translation: "Design spritke kita akan dilakukan slmea lima hari mengikuti metode yang diajarkaan oleh Jek nap dalam bukunya Design Sprint How To solve big Problem and Test new ideas in just five days. Sprint kita akan dimulai dari haptke Inisiate smplit dengan Usability Testing.",
    //     keyword: ["Sprint","Ideas"],
    //     typo: ["spritke","slmea","Jek nap","haptke","smplit"],
    //     language: "Indonesia",
    //     status: "done"   
    // },
    // {
    //     id: 6,
    //     name: "Farhan Hadi",
    //     duration: "00:34  - 00:54",
    //     quality: "good",
    //     utterance: "Utterance 6 Design spritke kita akan dilakukan slmea lima hari mengikuti metode yang diajarkaan oleh Jek nap dalam bukunya Design Sprint How To solve big Problem and Test new ideas in just five days. Sprint kita akan dimulai dari haptke Inisiate smplit dengan Usability Testing.",
    //     translation: "Design spritke kita akan dilakukan slmea lima hari mengikuti metode yang diajarkaan oleh Jek nap dalam bukunya Design Sprint How To solve big Problem and Test new ideas in just five days. Sprint kita akan dimulai dari haptke Inisiate smplit dengan Usability Testing.",
    //     typo: ["spritke","slmea","Jek nap","haptke","smplit"],
    //     keyword: ["Sprint","Ideas"],
    //     language: "Indonesia",
    //     status: "done"  
    // },
    // {
    //     id: 7,
    //     name: "Samantha",
    //     duration: "00:30 - 01:05",
    //     quality: "low",
    //     utterance: "Utterance 7 lmea lima hari mengikuti metode yang diajarkaan oleh Jek nap dalam bukunya Design Sprint How To solve big Problem and Test new ideas in just five days. Sprint kita akan dimulai dari haptke Inisiate smplit dengan Usability Testing.",
    //     translation: "lmea lima hari mengikuti metode yang diajarkaan oleh Jek nap dalam bukunya Design Sprint How To solve big Problem and Test new ideas in just five days. Sprint kita akan dimulai dari haptke Inisiate smplit dengan Usability Testing.",
    //     typo: ["spritke","slmea","Jek nap","haptke","smplit"],
    //     keyword: ["Sprint","Ideas"],
    //     language: "Indonesia",
    //     status: "done"
    // },
    // {
    //     id: 8,
    //     name: "Farhan Hadi",
    //     duration: "00:64",
    //     quality: "good",
    //     utterance: "Utterance 8 mengikuti metode yang diajarkaan oleh Jek nap dalam bukunya Design Sprint How To solve big Problem and Test new ideas in just five days. Sprint kita akan dimulai dari haptke Inisiate smplit dengan Usability Testing.",
    //     translation: "mengikuti metode yang diajarkaan oleh Jek nap dalam bukunya Design Sprint How To solve big Problem and Test new ideas in just five days. Sprint kita akan dimulai dari haptke Inisiate smplit dengan Usability Testing.",
    //     keyword: ["Sprint","Ideas"],
    //     typo: ["spritke","slmea","Jek nap","haptke","smplit"],
    //     language: "Indonesia",
    //     status: "fail"   
    // },
    // {
    //     id: 9,
    //     name: "Farhan Hadi",
    //     duration: "02:00",
    //     quality: "good",
    //     utterance: "Utterance 9 Design spritke kita akan dilakukan slmea lima hari mengikuti metode yang diajarkaan oleh Jek nap dalam bukunya Design Sprint How To solve big Problem and Test new ideas in just five days. Sprint kita akan dimulai dari haptke Inisiate smplit dengan Usability Testing.",
    //     translation: "Design spritke kita akan dilakukan slmea lima hari mengikuti metode yang diajarkaan oleh Jek nap dalam bukunya Design Sprint How To solve big Problem and Test new ideas in just five days. Sprint kita akan dimulai dari haptke Inisiate smplit dengan Usability Testing.",
    //     typo: ["spritke","slmea","Jek nap","haptke","smplit"],
    //     keyword: ["Sprint","Ideas"],
    //     language: "Indonesia",
    //     status: "done"   
    // },
    // {
    //     id: 10,
    //     name: "Samantha",
    //     duration: "01:34 - 02:32",
    //     quality: "low",
    //     utterance: "Utterance 10 Design spritke kita akan dilakukan slmea lima hari mengikuti metode yang diajarkaan oleh Jek nap dalam bukunya Design Sprint How To solve big Problem and Test new ideas in just five days. Sprint kita akan dimulai dari haptke Inisiate smplit dengan Usability Testing.",
    //     translation: "Design spritke kita akan dilakukan slmea lima hari mengikuti metode yang diajarkaan oleh Jek nap dalam bukunya Design Sprint How To solve big Problem and Test new ideas in just five days. Sprint kita akan dimulai dari haptke Inisiate smplit dengan Usability Testing.",
    //     keyword: ["Sprint","Ideas"],
    //     typo: ["spritke","slmea","Jek nap","haptke","smplit"],
    //     language: "Indonesia",
    //     status: "done"   
    // },
    // {
    //     id: 11,
    //     name: "Farhan Hadi",
    //     duration: "00:34  - 00:54",
    //     quality: "good",
    //     utterance: "Utterance 11 Design spritke kita akan dilakukan slmea lima hari mengikuti metode yang diajarkaan oleh Jek nap dalam bukunya Design Sprint How To solve big Problem and Test new ideas in just five days. Sprint kita akan dimulai dari haptke Inisiate smplit dengan Usability Testing.",
    //     translation: "Design spritke kita akan dilakukan slmea lima hari mengikuti metode yang diajarkaan oleh Jek nap dalam bukunya Design Sprint How To solve big Problem and Test new ideas in just five days. Sprint kita akan dimulai dari haptke Inisiate smplit dengan Usability Testing.",
    //     typo: ["spritke","slmea","Jek nap","haptke","smplit"],
    //     keyword: ["Sprint","Ideas"],
    //     language: "Indonesia",
    //     status: "done"  
    // },
    // {
    //     id: 12,
    //     name: "Samantha",
    //     duration: "00:30 - 01:05",
    //     quality: "low",
    //     utterance: "Utterance 12 lmea lima hari mengikuti metode yang diajarkaan oleh Jek nap dalam bukunya Design Sprint How To solve big Problem and Test new ideas in just five days. Sprint kita akan dimulai dari haptke Inisiate smplit dengan Usability Testing.",
    //     translation: "lmea lima hari mengikuti metode yang diajarkaan oleh Jek nap dalam bukunya Design Sprint How To solve big Problem and Test new ideas in just five days. Sprint kita akan dimulai dari haptke Inisiate smplit dengan Usability Testing.",
    //     typo: ["spritke","slmea","Jek nap","haptke","smplit"],
    //     keyword: ["Sprint","Ideas"],
    //     language: "Indonesia",
    //     status: "done"
    // },
    // {
    //     id: 13,
    //     name: "Farhan Hadi",
    //     duration: "00:64",
    //     quality: "good",
    //     utterance: "Utterance 13 mengikuti metode yang diajarkaan oleh Jek nap dalam bukunya Design Sprint How To solve big Problem and Test new ideas in just five days. Sprint kita akan dimulai dari haptke Inisiate smplit dengan Usability Testing.",
    //     translation: "mengikuti metode yang diajarkaan oleh Jek nap dalam bukunya Design Sprint How To solve big Problem and Test new ideas in just five days. Sprint kita akan dimulai dari haptke Inisiate smplit dengan Usability Testing.",
    //     keyword: ["Sprint","Ideas"],
    //     typo: ["spritke","slmea","Jek nap","haptke","smplit"],
    //     language: "Indonesia",
    //     status: "fail"   
    // },
    // {
    //     id: 14,
    //     name: "Farhan Hadi",
    //     duration: "02:00",
    //     quality: "good",
    //     utterance: "Utterance 14 Design spritke kita akan dilakukan slmea lima hari mengikuti metode yang diajarkaan oleh Jek nap dalam bukunya Design Sprint How To solve big Problem and Test new ideas in just five days. Sprint kita akan dimulai dari haptke Inisiate smplit dengan Usability Testing.",
    //     translation: "Design spritke kita akan dilakukan slmea lima hari mengikuti metode yang diajarkaan oleh Jek nap dalam bukunya Design Sprint How To solve big Problem and Test new ideas in just five days. Sprint kita akan dimulai dari haptke Inisiate smplit dengan Usability Testing.",
    //     typo: ["spritke","slmea","Jek nap","haptke","smplit"],
    //     keyword: ["Sprint","Ideas"],
    //     language: "Indonesia",
    //     status: "done"   
    // },
    // {
    //     id: 15,
    //     name: "Samantha",
    //     duration: "01:34 - 02:32",
    //     quality: "low",
    //     utterance: "Utterance 15 Design spritke kita akan dilakukan slmea lima hari mengikuti metode yang diajarkaan oleh Jek nap dalam bukunya Design Sprint How To solve big Problem and Test new ideas in just five days. Sprint kita akan dimulai dari haptke Inisiate smplit dengan Usability Testing.",
    //     translation: "Design spritke kita akan dilakukan slmea lima hari mengikuti metode yang diajarkaan oleh Jek nap dalam bukunya Design Sprint How To solve big Problem and Test new ideas in just five days. Sprint kita akan dimulai dari haptke Inisiate smplit dengan Usability Testing.",
    //     keyword: ["Sprint","Ideas"],
    //     typo: ["spritke","slmea","Jek nap","haptke","smplit"],
    //     language: "Indonesia",
    //     status: "done"   
    // },
]

export var utterance_list_data = utterance_list;

export function setUtteranceListData() {
    // utterance_list_data = [];
    // while(utterance_list_data.length < 1000) {
    //     utterance_list.forEach((utt, index) => {
    //         const new_utt = utt;
    //         new_utt["id"] = utterance_list_data.length;

    //         utterance_list_data.push(new_utt)     
    //     })
    // }

    utterance_list_data = utterance_list;
}
export function setUtteranceListDataEmpty() {
    utterance_list_data = [];
}

export const utterance_list_paragraph = [
    {
        "id": 1,
        "index": 1,
        "speaker": {
            "id":2,
            "name":"Farhan Hadi",
            "image":"",
            "title":"",
            "organisation":""
        },
        "translation": {
            "id": 1,
            "text": "Design spritke kita akan dilakukan slmea lima hari mengikuti metode yang diajarkaan oleh Jek nap dalam bukunya Design Sprint How To solve big Problem and Test new ideas in just five days. Sprint kita akan dimulai dari haptke Inisiate smplit dengan Usability Testing."
        },
        "transcribe": {
            "text": "Utterance 1 Design spritke kita akan dilakukan slmea lima hari mengikuti metode yang diajarkaan oleh Jek nap dalam bukunya Design Sprint How To solve big Problem and Test new ideas in just five days. Sprint kita akan dimulai dari haptke Inisiate smplit dengan Usability Testing.",
            "score": "good",
            "orig": true,
            "corrector":"",
            "state":"final"
        },
        "typo": [
            {
                "word": "spritke",
                "suggestion": []
            },{
                "word": "slmea",
                "suggestion": []
            },{
                "word": "Jek nap",
                "suggestion": []
            },{
                "word": "haptke",
                "suggestion": []
            },{
                "word": "smplit",
                "suggestion": []
            }
        ],
        "keyword": ["Sprint","Ideas"],
        "audio": "",
        "timeline": {
            "from": "00:00:00",
            "to": "00:00:34",
            "length": 34
        },
        "datetime":"2021/10/24 22:26:08 +07"
    },{
        "id": 2,
        "index": 1,
        "speaker": {
            "id":2,
            "name":"Farhan Hadi",
            "image":"",
            "title":"",
            "organisation":""
        },
        "translation": {
            "id": 1,
            "text": "Design spritke kita akan dilakukan slmea lima hari mengikuti metode yang diajarkaan oleh Jek nap dalam bukunya Design Sprint How To solve big Problem and Test new ideas in just five days. Sprint kita akan dimulai dari haptke Inisiate smplit dengan Usability Testing."
        },
        "transcribe": {
            "text": "Utterance 1 Design spritke kita akan dilakukan slmea lima hari mengikuti metode yang diajarkaan oleh Jek nap dalam bukunya Design Sprint How To solve big Problem and Test new ideas in just five days. Sprint kita akan dimulai dari haptke Inisiate smplit dengan Usability Testing.",
            "score": "good",
            "orig": true,
            "corrector":"",
            "state":"final"
        },
        "typo": [
            {
                "word": "spritke",
                "suggestion": []
            },{
                "word": "slmea",
                "suggestion": []
            },{
                "word": "Jek nap",
                "suggestion": []
            },{
                "word": "haptke",
                "suggestion": []
            },{
                "word": "smplit",
                "suggestion": []
            }
        ],
        "keyword": ["Sprint","Ideas"],
        "audio": "",
        "timeline": {
            "from": "00:00:00",
            "to": "00:00:34",
            "length": 34
        },
        "datetime":"2021/10/24 22:26:08 +07"
    },{
        "id": 3,
        "index": 1,
        "speaker": {
            "id":2,
            "name":"Farhan Hadi",
            "image":"",
            "title":"",
            "organisation":""
        },
        "translation": {
            "id": 1,
            "text": "Design spritke kita akan dilakukan slmea lima hari mengikuti metode yang diajarkaan oleh Jek nap dalam bukunya Design Sprint How To solve big Problem and Test new ideas in just five days. Sprint kita akan dimulai dari haptke Inisiate smplit dengan Usability Testing."
        },
        "transcribe": {
            "text": "Utterance 1 Design spritke kita akan dilakukan slmea lima hari mengikuti metode yang diajarkaan oleh Jek nap dalam bukunya Design Sprint How To solve big Problem and Test new ideas in just five days. Sprint kita akan dimulai dari haptke Inisiate smplit dengan Usability Testing.",
            "score": "good",
            "orig": true,
            "corrector":"",
            "state":"final"
        },
        "typo": [
            {
                "word": "spritke",
                "suggestion": []
            },{
                "word": "slmea",
                "suggestion": []
            },{
                "word": "Jek nap",
                "suggestion": []
            },{
                "word": "haptke",
                "suggestion": []
            },{
                "word": "smplit",
                "suggestion": []
            }
        ],
        "keyword": ["Sprint","Ideas"],
        "audio": "",
        "timeline": {
            "from": "00:00:00",
            "to": "00:00:34",
            "length": 34
        },
        "datetime":"2021/10/24 22:26:08 +07"
    },{
        "id": 4,
        "index": 1,
        "speaker": {
            "id":2,
            "name":"Farhan Hadi",
            "image":"",
            "title":"",
            "organisation":""
        },
        "translation": {
            "id": 1,
            "text": "Design spritke kita akan dilakukan slmea lima hari mengikuti metode yang diajarkaan oleh Jek nap dalam bukunya Design Sprint How To solve big Problem and Test new ideas in just five days. Sprint kita akan dimulai dari haptke Inisiate smplit dengan Usability Testing."
        },
        "transcribe": {
            "text": "Utterance 1 Design spritke kita akan dilakukan slmea lima hari mengikuti metode yang diajarkaan oleh Jek nap dalam bukunya Design Sprint How To solve big Problem and Test new ideas in just five days. Sprint kita akan dimulai dari haptke Inisiate smplit dengan Usability Testing.",
            "score": "good",
            "orig": true,
            "corrector":"",
            "state":"final"
        },
        "typo": [
            {
                "word": "spritke",
                "suggestion": []
            },{
                "word": "slmea",
                "suggestion": []
            },{
                "word": "Jek nap",
                "suggestion": []
            },{
                "word": "haptke",
                "suggestion": []
            },{
                "word": "smplit",
                "suggestion": []
            }
        ],
        "keyword": ["Sprint","Ideas"],
        "audio": "",
        "timeline": {
            "from": "00:00:00",
            "to": "00:00:34",
            "length": 34
        },
        "datetime":"2021/10/24 22:26:08 +07"
    },{
        "id": 5,
        "index": 1,
        "speaker": {
            "id":2,
            "name":"Farhan Hadi",
            "image":"",
            "title":"",
            "organisation":""
        },
        "translation": {
            "id": 1,
            "text": "Design spritke kita akan dilakukan slmea lima hari mengikuti metode yang diajarkaan oleh Jek nap dalam bukunya Design Sprint How To solve big Problem and Test new ideas in just five days. Sprint kita akan dimulai dari haptke Inisiate smplit dengan Usability Testing."
        },
        "transcribe": {
            "text": "Utterance 1 Design spritke kita akan dilakukan slmea lima hari mengikuti metode yang diajarkaan oleh Jek nap dalam bukunya Design Sprint How To solve big Problem and Test new ideas in just five days. Sprint kita akan dimulai dari haptke Inisiate smplit dengan Usability Testing.",
            "score": "good",
            "orig": true,
            "corrector":"",
            "state":"final"
        },
        "typo": [
            {
                "word": "spritke",
                "suggestion": []
            },{
                "word": "slmea",
                "suggestion": []
            },{
                "word": "Jek nap",
                "suggestion": []
            },{
                "word": "haptke",
                "suggestion": []
            },{
                "word": "smplit",
                "suggestion": []
            }
        ],
        "keyword": ["Sprint","Ideas"],
        "audio": "",
        "timeline": {
            "from": "00:00:00",
            "to": "00:00:34",
            "length": 34
        },
        "datetime":"2021/10/24 22:26:08 +07"
    },{
        "id": 6,
        "index": 1,
        "speaker": {
            "id":2,
            "name":"Farhan Hadi",
            "image":"",
            "title":"",
            "organisation":""
        },
        "translation": {
            "id": 1,
            "text": "Design spritke kita akan dilakukan slmea lima hari mengikuti metode yang diajarkaan oleh Jek nap dalam bukunya Design Sprint How To solve big Problem and Test new ideas in just five days. Sprint kita akan dimulai dari haptke Inisiate smplit dengan Usability Testing."
        },
        "transcribe": {
            "text": "Utterance 1 Design spritke kita akan dilakukan slmea lima hari mengikuti metode yang diajarkaan oleh Jek nap dalam bukunya Design Sprint How To solve big Problem and Test new ideas in just five days. Sprint kita akan dimulai dari haptke Inisiate smplit dengan Usability Testing.",
            "score": "good",
            "orig": true,
            "corrector":"",
            "state":"final"
        },
        "typo": [
            {
                "word": "spritke",
                "suggestion": []
            },{
                "word": "slmea",
                "suggestion": []
            },{
                "word": "Jek nap",
                "suggestion": []
            },{
                "word": "haptke",
                "suggestion": []
            },{
                "word": "smplit",
                "suggestion": []
            }
        ],
        "keyword": ["Sprint","Ideas"],
        "audio": "",
        "timeline": {
            "from": "00:00:00",
            "to": "00:00:34",
            "length": 34
        },
        "datetime":"2021/10/24 22:26:08 +07"
    },{
        "id": 7,
        "index": 1,
        "speaker": {
            "id":2,
            "name":"Farhan Hadi",
            "image":"",
            "title":"",
            "organisation":""
        },
        "translation": {
            "id": 1,
            "text": "Design spritke kita akan dilakukan slmea lima hari mengikuti metode yang diajarkaan oleh Jek nap dalam bukunya Design Sprint How To solve big Problem and Test new ideas in just five days. Sprint kita akan dimulai dari haptke Inisiate smplit dengan Usability Testing."
        },
        "transcribe": {
            "text": "Utterance 1 Design spritke kita akan dilakukan slmea lima hari mengikuti metode yang diajarkaan oleh Jek nap dalam bukunya Design Sprint How To solve big Problem and Test new ideas in just five days. Sprint kita akan dimulai dari haptke Inisiate smplit dengan Usability Testing.",
            "score": "good",
            "orig": true,
            "corrector":"",
            "state":"final"
        },
        "typo": [
            {
                "word": "spritke",
                "suggestion": []
            },{
                "word": "slmea",
                "suggestion": []
            },{
                "word": "Jek nap",
                "suggestion": []
            },{
                "word": "haptke",
                "suggestion": []
            },{
                "word": "smplit",
                "suggestion": []
            }
        ],
        "keyword": ["Sprint","Ideas"],
        "audio": "",
        "timeline": {
            "from": "00:00:00",
            "to": "00:00:34",
            "length": 34
        },
        "datetime":"2021/10/24 22:26:08 +07"
    },{
        "id": 8,
        "index": 1,
        "speaker": {
            "id":2,
            "name":"Farhan Hadi",
            "image":"",
            "title":"",
            "organisation":""
        },
        "translation": {
            "id": 1,
            "text": "Design spritke kita akan dilakukan slmea lima hari mengikuti metode yang diajarkaan oleh Jek nap dalam bukunya Design Sprint How To solve big Problem and Test new ideas in just five days. Sprint kita akan dimulai dari haptke Inisiate smplit dengan Usability Testing."
        },
        "transcribe": {
            "text": "Utterance 1 Design spritke kita akan dilakukan slmea lima hari mengikuti metode yang diajarkaan oleh Jek nap dalam bukunya Design Sprint How To solve big Problem and Test new ideas in just five days. Sprint kita akan dimulai dari haptke Inisiate smplit dengan Usability Testing.",
            "score": "good",
            "orig": true,
            "corrector":"",
            "state":"final"
        },
        "typo": [
            {
                "word": "spritke",
                "suggestion": []
            },{
                "word": "slmea",
                "suggestion": []
            },{
                "word": "Jek nap",
                "suggestion": []
            },{
                "word": "haptke",
                "suggestion": []
            },{
                "word": "smplit",
                "suggestion": []
            }
        ],
        "keyword": ["Sprint","Ideas"],
        "audio": "",
        "timeline": {
            "from": "00:00:00",
            "to": "00:00:34",
            "length": 34
        },
        "datetime":"2021/10/24 22:26:08 +07"
    },{
        "id": 9,
        "index": 1,
        "speaker": {
            "id":2,
            "name":"Farhan Hadi",
            "image":"",
            "title":"",
            "organisation":""
        },
        "translation": {
            "id": 1,
            "text": "Design spritke kita akan dilakukan slmea lima hari mengikuti metode yang diajarkaan oleh Jek nap dalam bukunya Design Sprint How To solve big Problem and Test new ideas in just five days. Sprint kita akan dimulai dari haptke Inisiate smplit dengan Usability Testing."
        },
        "transcribe": {
            "text": "Utterance 1 Design spritke kita akan dilakukan slmea lima hari mengikuti metode yang diajarkaan oleh Jek nap dalam bukunya Design Sprint How To solve big Problem and Test new ideas in just five days. Sprint kita akan dimulai dari haptke Inisiate smplit dengan Usability Testing.",
            "score": "good",
            "orig": true,
            "corrector":"",
            "state":"final"
        },
        "typo": [
            {
                "word": "spritke",
                "suggestion": []
            },{
                "word": "slmea",
                "suggestion": []
            },{
                "word": "Jek nap",
                "suggestion": []
            },{
                "word": "haptke",
                "suggestion": []
            },{
                "word": "smplit",
                "suggestion": []
            }
        ],
        "keyword": ["Sprint","Ideas"],
        "audio": "",
        "timeline": {
            "from": "00:00:00",
            "to": "00:00:34",
            "length": 34
        },
        "datetime":"2021/10/24 22:26:08 +07"
    },{
        "id": 10,
        "index": 1,
        "speaker": {
            "id":2,
            "name":"Farhan Hadi",
            "image":"",
            "title":"",
            "organisation":""
        },
        "translation": {
            "id": 1,
            "text": "Design spritke kita akan dilakukan slmea lima hari mengikuti metode yang diajarkaan oleh Jek nap dalam bukunya Design Sprint How To solve big Problem and Test new ideas in just five days. Sprint kita akan dimulai dari haptke Inisiate smplit dengan Usability Testing."
        },
        "transcribe": {
            "text": "Utterance 1 Design spritke kita akan dilakukan slmea lima hari mengikuti metode yang diajarkaan oleh Jek nap dalam bukunya Design Sprint How To solve big Problem and Test new ideas in just five days. Sprint kita akan dimulai dari haptke Inisiate smplit dengan Usability Testing.",
            "score": "good",
            "orig": true,
            "corrector":"",
            "state":"final"
        },
        "typo": [
            {
                "word": "spritke",
                "suggestion": []
            },{
                "word": "slmea",
                "suggestion": []
            },{
                "word": "Jek nap",
                "suggestion": []
            },{
                "word": "haptke",
                "suggestion": []
            },{
                "word": "smplit",
                "suggestion": []
            }
        ],
        "keyword": ["Sprint","Ideas"],
        "audio": "",
        "timeline": {
            "from": "00:00:00",
            "to": "00:00:34",
            "length": 34
        },
        "datetime":"2021/10/24 22:26:08 +07"
    },{
        "id": 11,
        "index": 1,
        "speaker": {
            "id":2,
            "name":"Farhan Hadi",
            "image":"",
            "title":"",
            "organisation":""
        },
        "translation": {
            "id": 1,
            "text": "Design spritke kita akan dilakukan slmea lima hari mengikuti metode yang diajarkaan oleh Jek nap dalam bukunya Design Sprint How To solve big Problem and Test new ideas in just five days. Sprint kita akan dimulai dari haptke Inisiate smplit dengan Usability Testing."
        },
        "transcribe": {
            "text": "Utterance 1 Design spritke kita akan dilakukan slmea lima hari mengikuti metode yang diajarkaan oleh Jek nap dalam bukunya Design Sprint How To solve big Problem and Test new ideas in just five days. Sprint kita akan dimulai dari haptke Inisiate smplit dengan Usability Testing.",
            "score": "good",
            "orig": true,
            "corrector":"",
            "state":"final"
        },
        "typo": [
            {
                "word": "spritke",
                "suggestion": []
            },{
                "word": "slmea",
                "suggestion": []
            },{
                "word": "Jek nap",
                "suggestion": []
            },{
                "word": "haptke",
                "suggestion": []
            },{
                "word": "smplit",
                "suggestion": []
            }
        ],
        "keyword": ["Sprint","Ideas"],
        "audio": "",
        "timeline": {
            "from": "00:00:00",
            "to": "00:00:34",
            "length": 34
        },
        "datetime":"2021/10/24 22:26:08 +07"
    },{
        "id": 12,
        "index": 1,
        "speaker": {
            "id":2,
            "name":"Farhan Hadi",
            "image":"",
            "title":"",
            "organisation":""
        },
        "translation": {
            "id": 1,
            "text": "Design spritke kita akan dilakukan slmea lima hari mengikuti metode yang diajarkaan oleh Jek nap dalam bukunya Design Sprint How To solve big Problem and Test new ideas in just five days. Sprint kita akan dimulai dari haptke Inisiate smplit dengan Usability Testing."
        },
        "transcribe": {
            "text": "Utterance 1 Design spritke kita akan dilakukan slmea lima hari mengikuti metode yang diajarkaan oleh Jek nap dalam bukunya Design Sprint How To solve big Problem and Test new ideas in just five days. Sprint kita akan dimulai dari haptke Inisiate smplit dengan Usability Testing.",
            "score": "good",
            "orig": true,
            "corrector":"",
            "state":"final"
        },
        "typo": [
            {
                "word": "spritke",
                "suggestion": []
            },{
                "word": "slmea",
                "suggestion": []
            },{
                "word": "Jek nap",
                "suggestion": []
            },{
                "word": "haptke",
                "suggestion": []
            },{
                "word": "smplit",
                "suggestion": []
            }
        ],
        "keyword": ["Sprint","Ideas"],
        "audio": "",
        "timeline": {
            "from": "00:00:00",
            "to": "00:00:34",
            "length": 34
        },
        "datetime":"2021/10/24 22:26:08 +07"
    },
]

export var utterance_list_paragraph_data = utterance_list_paragraph;

export function setUtteranceListParagraphData() {
    // utterance_list_paragraph_data = [];
    // while(utterance_list_paragraph_data.length < 1000) {
    //     utterance_list_paragraph.forEach((utt, index) => {
    //         const new_utt = utt;
    //         new_utt["id"] = utterance_list_paragraph_data.length;

    //         utterance_list_paragraph_data.push(new_utt)     
    //     })
    // }

    utterance_list_paragraph_data = utterance_list_paragraph;
}
export function setUtteranceListParagraphDataEmpty() {
    utterance_list_paragraph_data = [];
}

export const participant_list_data = [
    {id: 1, name: "Arunika Denisov"},
    {id: 2, name: "Farhan Hadi"},
    {id: 3, name: "Speaker 1"},
    {id: 4, name: "Speaker 2"},
    {id: 5, name: "Speaker 3"},
    {id: 6, name: "Samantha"},
]

export const speaker_list_data = [
    {id: 1, name: "Samantha", id_participant: 6, audio: "/dummy_audio/samanta.wav"},
    {id: 2, name: "Farhan Hadi", id_participant: 2, audio: "/dummy_audio/farhan.wav"},
    {id: 3, name: "Speaker 1", id_participant: 3, audio: "/dummy_audio/suara1.wav"},
    {id: 4, name: "Speaker 2", id_participant: 4, audio: "/dummy_audio/suara2.wav"},
    {id: 5, name: "Speaker 3", id_participant: 5, audio: "/dummy_audio/suara3.wav"},
]

export const keyword_list_data = [
    {
        keyword: "Sprint",
        id: 1
    },
    {
        keyword: "Design",
        id: 2
    },
    {
        keyword: "Operation",
        id: 3
    },
    {
        keyword: "Quality",
        id: 4
    },
    {
        keyword: "Statemnet",
        id: 5
    },
    {
        keyword: "Research and Development",
        id: 6
    },
    {
        keyword: "Weekly",
        id: 7
    },
    {
        keyword: "Meeting",
        id: 8
    },
    {
        keyword: "Huddle",
        id: 9
    },
    {
        keyword: "Jakarta",
        id: 10
    },
    {
        keyword: "Minit",
        id: 11
    },
    {
        keyword: "Notula",
        id: 12
    },
    {
        keyword: "Ideas",
        id: 13
    },

]

export const keyword_list_record_data = [
    {
        keyword: "Sprint",
        id: 1
    },
    {
        keyword: "Ideas",
        id: 13
    },
]