import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

//FUNCTIONS

//COMPONENTS
import Login from '../apps/pages/Landing/Login';
import ResetPass from '../apps/pages/Landing/ResetPass';
import Register from '../apps/pages/Landing/Register';
import NewPassword from '../apps/pages/Landing/NewPass';
import VerifyRegister from '../apps/pages/Landing/VerifyRegister';

//CSS IMPORTS


function PublicRoutes() {
	return (
		<>
            <Switch>
				<Redirect exact from="/" to="/login" />
				{/* <Route exact path="/" component={Redirector} /> */}
				<Route exact path="/login" component={Login} />
				<Route exact path="/forgot-password" component={ResetPass} />
				<Route exact path="/verif" component={NewPassword} />
				<Route exact path="/register" component={Register} />
				<Route exact path="/verif-register" component={VerifyRegister} />
				<Redirect from="/:someroute" to="/" />
            </Switch>
		</>
	)
}

export default PublicRoutes;
