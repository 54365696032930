import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

//functions
import { threshold } from '../../../threshold';
import { doesFileExist, downloadFromURL } from '../../../utils/functions';
import { fetchUtteranceReload, urlHead } from '../../../reducers/FetcherReducers';

//components
import Highlighter from "react-highlight-words";
import { OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import BKAudioPlayer from "../BKAudioPlayer/BKAudioPlayer";
import UtteranceChangeParticipant from './UtteranceChangeParticipant';

//style
import styled from 'styled-components'
import './Utterance.scss'

//assets
import reload from '../../img/reload.svg'
import spinner from '../../img/spinner.svg'
import locationIcon from '../../img/location-red.svg'
import downloadIcon from '../../img/download-black.svg'
import { toastNotification } from '../ToastNotification/ToastNotification';
import ModalMaps from '../ModalMaps/ModalMaps';
import UtteranceEditor from './UtteranceEditor';
import { endpoints } from '../../../endpoints';
import FileSaver from 'file-saver';


const getRandomNumberBetweenNumbers = (minValue, maxValue) => {
    return Math.floor(Math.random() * (maxValue - minValue + 1)) + minValue
}

const Label = styled.label`
    display: unset !important;
    height: unset !important;
    overflow: unset !important;
    line-height: unset !important;

    border-radius: 7px;
    padding: 4px 15px;
    font-size: 10px;
    font-weight: 700;
    text-align: center;
    text-transform: capitalize;
    margin: 0;
`

function Utterance(props) {
    const { 
        id_record,
        utteranceData, 
        showKeyword = false,
        showToggleTranslationBtn = false,
        showAllTranslation = false,
        enableAudio,
        setUtteranceOnPlayAudio,
        currentTabIndex,
        setEnableWordScoringShortcuts
    } = props;

    // console.log(utteranceData)
    
    const {
        id,
        speaker,
        translation,
        transcribe,
        typo,
        keyword,
        audio,
        timeline,
        datetime,
        language,
        // location = {lat: 0, lng: 0, lob: 0}
        location = {
            lat: getRandomNumberBetweenNumbers(-90, 90), 
            lng: getRandomNumberBetweenNumbers(-180, 180)
        }
    } = utteranceData

    // const origin  = window.location.origin+"/";
    // const origin  = "https://devdiktein.bahasakita.co.id/";
    // const audio_url = origin+audio;
    const audio_url = urlHead + "/"+ endpoints.utterance_audio + audio;

	const token = useSelector(state => state.AccountManagementReducers.token)

    const [loading, setLoading] = useState(false);

    const [pauseAudio, setPauseAudio] = useState(false);
    const [showTranslation, setShowTranslation] = useState(false);
    const [showSpeakerOptions, setShowSpeakerOptions] = useState(false);
    const [showMaps, setShowMaps] = useState(false);
    
    const [textOnEditing, setTextOnEditing] = useState(false);
    const [textOnSaving, setTextOnSaving] = useState(false);
    const [textCursorPosition, setTextCursorPosition] = useState(0);

    useEffect(() => {
        setShowTranslation(showAllTranslation)
    }, [showAllTranslation]);
    
    useEffect(() => {
        if ( !enableAudio ) {
            setPauseAudio(true)
        } else {
            setPauseAudio(false)
        }
    }, [enableAudio]);

    useEffect(() => {
        if ( textOnEditing ) {
            setEnableWordScoringShortcuts(false)
        } else {
            setEnableWordScoringShortcuts(true)
        }
    }, [textOnEditing]);

    const onAudioPlayStateChange = (value) => {
        if ( value ) {
            setUtteranceOnPlayAudio(id)
        } 
    }

    const handleDownloadAudioClick = async () => {        
        var options = {
            headers: {
                'Authorization': 'Bearer '+ token
            },
            method: "GET"
        }

        fetch(audio_url, options)
            .then(response => {
                if (response.status === 200) {
                    response.clone().blob().then(blob => {
                        // console.log(blob)
                        FileSaver.saveAs(blob, id+".wav");
                    });
                }
                return response;
            })
            .catch((err) => {
                toastNotification('error', 'Download audio gagal. '+err.toString());
            });
    }
    
    // get transcribe_quality_label
    var transcribe_quality_label = "unknown";
    const label_index = threshold.transcribe_quality.findIndex((q) => transcribe.score >= q.minValue);
    if ( label_index > -1 ) {
        transcribe_quality_label = threshold.transcribe_quality[label_index].label
    }

    // highlight words by index start-end
    const findHighlightChunks = () => {
        let chunks = [];

        const typos             = typo.map(({word}) => word);
        const keywords          = showKeyword ? keyword : [];
        const transcribe_words  = transcribe.words;
        
        /*** Highlight word by scoring ***/
        chunks = transcribe_words.map((props) => {
            const { 
                quality 
            } = props;
            
            return {
                ...props,
                className   : "word "+quality,
            }
        })
        /*********************************/

        /*** Highlight typos ***/
        typos.forEach((typo) => {
            transcribe_words.forEach(({word}, index) => {
                if ( word === typo ) {
                    chunks[index].className = chunks[index].className + " " + "highlight-typo";
                }
            })
        });
        /***********************/

        /*** Highlight keywords ***/
        keywords.forEach((keyword) => {
            transcribe_words.forEach(({word}, index) => {
                if ( word === keyword ) {
                    chunks[index].className = chunks[index].className + " " + "highlight-keyword";
                }
            })
        });
        /**************************/

        // Sort chunks by start asc
        chunks = chunks.sort((a, b) => a.start > b.start ? 1 : -1);
        
        return chunks
    };
    
    let chunks_result = findHighlightChunks();
    // console.log(chunks_result)


    const textToSpans = (startIndex, text) => {
        const charArray = text.split("");
        // console.log(startIndex)
        return (
            <>
                { charArray.map((char, index) => 
                    <span key={id+"-"+index} index={(startIndex+index)}>{char}</span>
                ) }
            </>
        )
    } 

    // Custom Element for Highlighter tag 
    const HighlightTag = ({ children, highlightIndex, ...restprops }) => {
        // console.log("----------------------")
        // console.log(children)
        // console.log(highlightIndex)
        // console.log(restprops)

        const chunk     = chunks_result[highlightIndex];
        const tabIndex  = chunk.tabIndex;
        const className = chunk.className;
        
        // console.log(chunk)

        let score       = chunk.score*100;
        const isInteger = ( score % 1 === 0 );
        if ( !isInteger ) {
            score = score.toFixed(2)
        }
        
        let quality = chunk.quality;
        
        let text = textToSpans(chunk.start, children);

        if ( tabIndex === undefined ) {
            return (
                <span 
                    {...restprops} 
                    className={className}
                >{text}</span>
            )
        }

        if ( quality === "good" ) {
            return (
                <span 
                    {...restprops} 
                    ref={el => currentTabIndex === tabIndex && el && el.focus()}
                    className={className + " " + (currentTabIndex === tabIndex ? "focused" : "")} 
                    tabIndex={parseInt(tabIndex)}
                >{text}</span>
            )
        }

        return (
            <OverlayTrigger
                // show={true}
                key={"word-tooltip-"+highlightIndex}
                placement="top"
                overlay={
                    <Tooltip className="tooltip-word-confident light">
                        <div style={{marginTop: "3px", fontSize: "12px"}}>
                            <Label 
                                className={( 
                                    quality === "good"     ? "default"  : 
                                    quality === "standard" ? "progress" :
                                    quality === "bad"      ? "warning"  : 
                                    "primary"
                                )}
                            >{quality}</Label>
                        </div>
                        <div style={{marginTop: "3px", fontSize: "12px", fontWeight: "bold"}}>
                            score: {score}%
                        </div>
                    </Tooltip>
                }
            >
                <span {...restprops} 
                    ref={el => currentTabIndex === tabIndex && el && el.focus()}
                    className={className + " " + (currentTabIndex === tabIndex ? "focused" : "")} 
                    tabIndex={parseInt(tabIndex)}
                >{text}</span>
            </OverlayTrigger>
        )
    };

    
    // const transcribe_state = transcribe.state;
    const transcribe_state = (transcribe.text === "" && transcribe.score === 0 ? "failed" : "");

    return (
        <>
            {/* <ModalMaps
                show={showMaps}
                onHide={() => setShowMaps(false)}
                location={location}
                // lat={location.lat}
                // lng={location.lng}
                // lob={location.lob}
            /> */}

            <div className="utt-card-cont">
                <div className="utt-top">
                    { loading ?
                        <h4 data-testid="show-change-speaker" className="loading">
                            <span>{speaker.name}</span>
                            <Spinner
                                className="icon-loading"
                                as="span"
                                animation="border"
                                size="xs"
                                role="status"
                                aria-hidden="true"
                            />
                        </h4>
                        :
                        <UtteranceChangeParticipant 
                            show={showSpeakerOptions}
                            toggleShow={() => setShowSpeakerOptions(!showSpeakerOptions)}
                            id_record={id_record}
                            id_utterance={id}
                            speaker={speaker}
                            setSpeakerLoading={setLoading}
                        />
                    }
                    
                    { transcribe_state !== "failed" &&
                        <>
                            <h3>Transkrip Quality</h3>
                            <Label className={"utt-tr-quality " +
                                ( transcribe_quality_label === "good" ? "default" : 
                                    transcribe_quality_label === "standard" ? "progress" :
                                    transcribe_quality_label === "bad" ? "warning" : 
                                    "label-warning" )}
                            >{transcribe_quality_label}
                            </Label>
                        </>
                    }

                    {/* { process.env.REACT_APP_TYPE === "PANORAMA" && 
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip className="light">
                                    Click to show location
                                </Tooltip>
                            }
                        >
                            <a className="location" onClick={() => setShowMaps(true)}>
                                <img className="icon" src={locationIcon} />
                            </a>
                        </OverlayTrigger>
                    } */}

                </div>
                <div className="utt">
                    {/* { transcribe_state === "failed" ?
                        <ReloadUtterance {...props} />
                        : */}
                        <div className="text">
                            <p className="viewer noselect"
                                style={{display: (!textOnEditing?"block":"none")}}
                                onClick={() => setTimeout(() => {setTextOnEditing(true)}, 100)}
                            >
                                {/* // utterance */}
                                { transcribe.words.length === 0 ?
                                    <div>
                                        { textToSpans(0, transcribe.text) }
                                    </div>
                                    :
                                    <Highlighter
                                        className="highlighter"
                                        autoEscape={true}
                                        highlightTag={(props) => <HighlightTag {...props} />}
                                        // highlightClassName={allClasses}
                                        searchWords={chunks_result.map(({word}) => word)}
                                        textToHighlight={transcribe.text}
                                        findChunks={findHighlightChunks}
                                        unhighlightClassName="space"
                                    />
                                }
                            </p>
                            { textOnEditing &&
                                <p className="editor">
                                    <UtteranceEditor 
                                        utterance={utteranceData} 
                                        setTextOnEditing={setTextOnEditing} 
                                        textOnSaving={textOnSaving}
                                        setTextOnSaving={setTextOnSaving}
                                    /> 
                                </p>
                            }
                        </div>
                    {/* } */}
                    
                    <div className={"utt-bottom "+(enableAudio?"show-player":"")}>
                        { !enableAudio &&
                            <span>{timeline.from}
                            {/* | language: <span style={{textTransform: "capitalize"}}>{language}</span> */}
                            </span>
                        }
                        
                        { // enableAudio &&
                            <div className="audio-utt">
                                <BKAudioPlayer
                                    demo={true}
                                    pauseAudio={pauseAudio} //for triggering audio pause
                                    audioFile={audio_url}
                                    onAudioPlayStateChange={onAudioPlayStateChange}
                                    // onAudioEnded={(e) => audioEnded(e)}
                                />
                                <a className="download-audio" onClick={handleDownloadAudioClick}>
                                    <img className="icon" src={downloadIcon} />
                                </a>
                            </div>
                        }

                        { showToggleTranslationBtn &&
                            <div className="show-translate" onClick={() => setShowTranslation(!showTranslation)}>
                                {showTranslation ? "Hide Translation" : "Show Translation"}
                            </div>
                        }
                    </div>

                    { showTranslation &&
                        ( translation.text !== "" ?
                            <div className="translation">
                                <p><i>
                                    {translation.text}
                                </i></p>
                            </div>
                            :
                            <div className="translation none">
                                <p><i>
                                    belum ada hasil translasi
                                </i></p>
                            </div>
                        )
                    }                    
                </div>
            </div>
        </>
    )
}

function ReloadUtterance(props){
    const {
        id_record,
        id,
        speaker,
    } = props.utteranceData
    
    const dispatch = useDispatch();

    const [transcripting, setTranscripting] = useState(false);
    const [transcriptingFailed, setTranscriptingFailed] = useState(false);

    const onReloadClick = () => {
        setTranscriptingFailed(false)
        setTranscripting(true)

        const data = {
            id_record: id_record,
            id_utterance: id
        }

        dispatch(fetchUtteranceReload(data))
            .then(() => {})
            .catch((err) => {
                setTranscriptingFailed(true)
                setTranscripting(false)
            })
    }

    return (
        // <div className="utt-card-cont">
        //     <div className="utt-top">
        //         <h4>{speaker.name}</h4>
        //     </div>
        //     { 
            transcripting ?
                <div className="utt-reload">
                    <div className="image-reload">
                      <img src={spinner} alt="loading"/>                  
                    </div>
                    <label>Sedang mentranskrip ulang …</label>
                </div> 
                :       
                <div className="utt-reload" onClick={onReloadClick} style={{cursor: "pointer"}}>
                    <div className="image-reload">
                        <img src={reload} alt="reload" id="reload"/>
                    </div>  
                    { !transcriptingFailed ?
                        <label>Reload Transcribe</label>
                        :
                        <label>Transcribe Failed. Try Reload Again</label>
                    }
                </div>
        //     }            
        // </div>
    )
}

export default Utterance;