import { sha256 } from "js-sha256";
import store from "../store";

import { nats } from "./NATSMiddleware";
import { QUOTA_LISTEN, RECORD_SPEAKER_LISTEN, RECORD_UTTERANCE_LISTEN } from "./NATSTopics";
import { rcvUtteranceListen } from "../reducers/UtteranceReducers";
import { rcvSpeakerListen } from "../reducers/SpeakerAndParticipantReducers";
import { rcvAvailableQuotaData } from "../reducers/QuotaReducers";


export var NATSRequester = (natss, topic, msg) => {     
    natss.request(topic, msg, {'max':1}, (response) => {
    });
}

var newQuotaSubscriber     = null;
var recordUtteranceSubscriber   = null;
var recordSpeakerSubscriber     = null;

export var NATSNewQuotaSubscribers = (id_user) => {
    if ( process.env.REACT_APP_SYSTEM_STATUS === "dev" ) {
        console.log("NATSNewQuotaSubscribers " + id_user);
    }
    
    const quota_listen_topic = `${QUOTA_LISTEN}.${sha256(id_user)}`;
    console.log(quota_listen_topic);
    newQuotaSubscriber = nats.subscribe(quota_listen_topic, (response) => {
        if ( process.env.REACT_APP_SYSTEM_STATUS === "dev" ) {
            console.log("--- INCOMING DATA ---")
            console.log(quota_listen_topic);
            console.log(response)
            console.log("---------------------")
        }

        const res = JSON.parse(response);
        store.dispatch(rcvAvailableQuotaData({
            status: res.status,
            kuota: res.quota
        }));
    });
}

export var NATSNewQuotaUnsubscribers = (id_user) => {
    if ( process.env.REACT_APP_SYSTEM_STATUS === "dev" ) {
        console.log("NATSNewQuotaUnsubscribers " + id_user);
    }

    if ( newQuotaSubscriber !== null ) {
        // console.log(newQuotaSubscriber);
        nats.unsubscribe(newQuotaSubscriber);
        newQuotaSubscriber   = null;
    } 
}

export var NATSRecordSubscribers = (id_record) => {
    if ( process.env.REACT_APP_SYSTEM_STATUS === "dev" ) {
        console.log("NATSRecordSubscribers " + id_record);
    }
    
    const utterance_listen_topic = `${RECORD_UTTERANCE_LISTEN}.${sha256(id_record.toString())}`;
    console.log(utterance_listen_topic);
    recordUtteranceSubscriber = nats.subscribe(utterance_listen_topic, (response) => {
        if ( process.env.REACT_APP_SYSTEM_STATUS === "dev" ) {
            console.log("--- INCOMING DATA ---")
            console.log(utterance_listen_topic);
            console.log(response)
            console.log("---------------------")
        }
        store.dispatch(rcvUtteranceListen(JSON.parse(response)));
    });
    
    const speaker_listen_topic = `${RECORD_SPEAKER_LISTEN}.${sha256(id_record.toString())}`;
    console.log(speaker_listen_topic)
    recordSpeakerSubscriber = nats.subscribe(speaker_listen_topic, (response) => {
        if ( process.env.REACT_APP_SYSTEM_STATUS === "dev" ) {
            console.log("--- INCOMING DATA ---")
            console.log(speaker_listen_topic)
            console.log(response)
            console.log("---------------------")
        }
        store.dispatch(rcvSpeakerListen(JSON.parse(response)));
    });
}

export var NATSRecordUnsubscribers = (id_record) => {
    if ( process.env.REACT_APP_SYSTEM_STATUS === "dev" ) {
        console.log("NATSRecordUnsubscribers " + id_record);
    }

    if ( recordUtteranceSubscriber !== null ) {
        // console.log(recordUtteranceSubscriber);
        nats.unsubscribe(recordUtteranceSubscriber);
        recordUtteranceSubscriber   = null;
    }
    if ( recordSpeakerSubscriber !== null ) {
        // console.log(recordSpeakerSubscriber);
        nats.unsubscribe(recordSpeakerSubscriber);
        recordSpeakerSubscriber     = null;
    }    
}