import $ from 'jquery';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { OverlayTrigger, ProgressBar, Spinner, Tooltip } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

// functions
import { reqAllRecordList, reqRecordDetails, reqStartRecordTranscribe } from '../../../reducers/RecordReducers';

// components
import styled from 'styled-components';

// styles
import './CardStyle.scss'
import { RECORD_STATUS } from '../../../utils/helpers';

// assets
import delete_icon from '../../img/delete-red.png'
import { fetchRecordDelete } from '../../../reducers/FetcherReducers';
import { toastNotification } from '../ToastNotification/ToastNotification';
import ModalDeleteRecord from '../ModalDelete/ModalDeleteRecord';

const Button = styled.button`
    border-radius: 3px;
    font-weight: 700;
    padding: 13px;
    border-radius: 8px;
    margin-left: auto;
    margin-top: auto;
`;

const Label = styled.label`
    display: unset !important;
    height: unset !important;
    overflow: unset !important;
    line-height: unset !important;

    border-radius: 7px;
    padding: 5px;
    width: 86px;
    font-size: 10px;
    font-weight: 600;
    text-align: center;
    text-transform: capitalize;

    img {
        margin-right: 5px;
        margin-top: -2px;
    }
`;


function RecordListCard(props) {
    const { 
        id=null, 
        title="",
        status="",
        date=(null),
        warning="",
        quota="",
        transcribeProgress=0,
        onCardClick=()=>{}
    } = props;

    const _transcribeProgress = Math.floor( isNaN(transcribeProgress) ? 0 : transcribeProgress );

    const dispatch = useDispatch();
    
    const [windowWidth, setWindowWidth] = useState(false);

    const [showDelRecord, setShowDelRecord] = useState(false);
    
    const [onReqStartTranscript, setOnReqStartTranscript] = useState(false);

    const [
        isTitleContentLargerThanTitleContainer, 
        setIsTitleContentLargerThanTitleContainer
    ] = useState(false);

    const titleRef = useRef();
    const statusRef = useRef();
    
    let recordProgressRefresher = null;
    const startRecordProgressRefresher = () => {
        if ( recordProgressRefresher !== null ) return;

        const current_date = new Date();
        const record_date = new Date(date);

        // To calculate the time difference of two dates
        var difference_in_time = current_date.getTime() - record_date.getTime();
        // To calculate the no. of hours between two dates
        var difference_in_days = difference_in_time / (1000 * 60 * 60 * 24);

        // refresh record data every 5 seconds
        if ( difference_in_days < 1 ) {
            // console.log("startRecordProgressRefresher")
            // console.log(id)
            recordProgressRefresher = setInterval(() => {
                if ( statusRef.current !== RECORD_STATUS.TRANSCRIPTING ) {
                    stopRecordProgressRefresher();
                    return;
                }

                if ( id === null ) return;
                
                dispatch(reqRecordDetails(id));
            }, 5000)
        }

    }
    const stopRecordProgressRefresher = () => {
        // if ( recordProgressRefresher === null ) return;
        // console.log("stopRecordProgressRefresher")
        // console.log(id)
        clearInterval(recordProgressRefresher);
        recordProgressRefresher = null;
    }
    
    useEffect(() => {
        // checkTitleSize();

        window.addEventListener('resize', onWindowResize);

        return () => {
            window.removeEventListener('resize', onWindowResize);

            stopRecordProgressRefresher();
        }

    }, []);

    useEffect(() => {
        statusRef.current = status;

        if ( status === RECORD_STATUS.TRANSCRIPTING ) {  
            startRecordProgressRefresher();
        } else {
            stopRecordProgressRefresher();
        }

    }, [status]);

    useEffect(() => {
        checkTitleSize();
    }, [windowWidth, title]);

    const onWindowResize = () => {
        setWindowWidth($(window).outerWidth())
    }

    const checkTitleSize = () => {
        // console.log("checkTitleSize")

        const titleContainer = titleRef.current;
        const titleContainerWidth = $(titleContainer).outerWidth();
        const titleContent = $(titleContainer).children()[0];
        const titleContentWidth = $(titleContent).outerWidth();
        
        // console.log("-----------------")
        // console.log(titleContainerWidth)
        // console.log(titleContentWidth)
        
        if ( titleContentWidth > titleContainerWidth ) {
            setIsTitleContentLargerThanTitleContainer(true)
        } else {
            setIsTitleContentLargerThanTitleContainer(false)
        }
    } 

    const onDeleteClick = (e) => {
        e.stopPropagation();
        
        setShowDelRecord(true);
    }

    const onTranscriptClick = () => {
        setOnReqStartTranscript(true)
        
        dispatch(reqStartRecordTranscribe(id))
    }

    // console.log(isTitleContentLargerThanTitleContainer)

    return (
        <div data-testid="record-card" className={"record-card "+status} onClick={onCardClick}>
            <>
                <OverlayTrigger
                    key={"tooltip-delete-record-"+id}
                    placement="top"
                    overlay={
                        <Tooltip className="tooltip-record-title light">
                            Delete record
                        </Tooltip>
                    }
                >
                    <img className="delete-icon" src={delete_icon} onClick={onDeleteClick} />
                </OverlayTrigger>
                <ModalDeleteRecord
                    show={showDelRecord}
                    onHide={() => setShowDelRecord(false)}
                    // setNotifAlertData={setNotifAlertData}
                    id={id}
                    title={title}
                />
            </>

            <>
                { 
                    status === RECORD_STATUS.TRANSCRIPTING ? 
                        <Label className="progress">{status}</Label> :
                    status === RECORD_STATUS.TRANSCRIPTED ? 
                        // <Label className="primary">{status}</Label>
                        <Label className="default">Done</Label> :
                    <Label className="primary">{status}</Label>
                }
            </>

            { isTitleContentLargerThanTitleContainer ?
                <OverlayTrigger
                    key={"tooltip-"+id}
                    placement="top"
                    overlay={
                        <Tooltip className="tooltip-record-title light">
                            {title}
                        </Tooltip>
                    }
                >
                    <h4 ref={titleRef} onClick={e => e.stopPropagation()}><span>{title}</span></h4>
                </OverlayTrigger>
                : 
                <h4 ref={titleRef}><span>{title}</span></h4>
            }

            <p>{moment(date).format("DD MMMM YYYY, hh:mm A")}</p>
            {/* { warning !== undefined &&
                <Label className="warning"><img src={warning} alt="warning" />{warning}</Label> 
            } */}
            {/* { status === RECORD_STATUS.UPLOADED && quota !== undefined && (
                quota === 0 ? 
                    <Button className="primary" onClick={()=>window.open(window.link.webconsole, "_blank")}>Buy Quota</Button>
                    : 
                    <Button className="primary" onClick={onTranscriptClick}>Transcript</Button>
            ) } */}
            { status === RECORD_STATUS.UPLOADED && 
                <Button 
                    className="primary start-transcript-btn"
                    onClick={onTranscriptClick}
                >
                    { onReqStartTranscript ?
                        <Spinner animation="border" variant="light" size="sm" />
                        : "Transcript"
                    }
                </Button>
            }
            { status === RECORD_STATUS.TRANSCRIPTING &&
                <div className="progress-wrap">
                    <ProgressBar now={_transcribeProgress} />
                    <div className="label">{_transcribeProgress}%</div>
                </div>
            }
        </div>
    )
}
export default RecordListCard;