import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useKeycloak } from "@react-keycloak/web";

//functions
import { fetchConsoleGetDikteinQuota } from '../../../reducers/FetcherReducers'
import { toastNotification } from '../ToastNotification/ToastNotification'

//components
import { OverlayTrigger, Popover } from 'react-bootstrap'

//style
import './NavStyle.scss'
import { reqAvailableQuotaData } from '../../../reducers/QuotaReducers';
import { NATSNewQuotaSubscribers, NATSNewQuotaUnsubscribers } from '../../../middleware/NATSMethods';

//assets


function AvailableQuota(props) {
	const profile_data = useSelector(state => state.AccountManagementReducers.profile_data)
    const available_quota = useSelector(state => state.QuotaReducers.available_quota)

    const dispatch = useDispatch();
    
    const [availableQuota, setAvailableQuota] = useState("-");

    useEffect(() => {
        // get kuota
        dispatch(reqAvailableQuotaData())

        // dispatch(fetchConsoleGetDikteinQuota())
        //     .then((kuota) => {
        //         let kuota_hou = Math.floor(kuota/3600);
        //         let kuota_min = Math.floor((kuota%3600)/60);
        //         let kuota_sec = kuota%60;
        //         // console.log(kuota)
        //         // console.log((kuota_hou*3600)+(kuota_min*60)+kuota_sec)
        //         // console.log(`${kuota_hou} Jam ${kuota_min} Menit ${kuota_sec} Detik`)

        //         let newAvailableQuota = "";
        //         if ( kuota_hou > 0 ) {
        //             newAvailableQuota += `${kuota_hou} Jam `
        //         }
        //         if ( kuota_min > 0 ) {
        //             newAvailableQuota += `${kuota_min} Menit `
        //         }
        //         if ( kuota_sec > 0 ) {
        //             newAvailableQuota += `${kuota_sec} Detik `
        //         }
        //         setAvailableQuota(newAvailableQuota)
                
        //     })
        //     .catch((err) => {
        //         // console.log(err)
        //         toastNotification("error", "Gagal memuat data kuota. "+err.toString())
        //     })
    }, []);
    
	useEffect(() => {
		if ( profile_data === null || profile_data === undefined ) 
			return;

        NATSNewQuotaUnsubscribers();
        NATSNewQuotaSubscribers(profile_data.username);
	}, [profile_data])

    useEffect(() => {
        let kuota_hou = Math.floor(available_quota/3600);
        let kuota_min = Math.floor((available_quota%3600)/60);
        let kuota_sec = available_quota%60;
        // console.log(available_quota)
        // console.log((kuota_hou*3600)+(kuota_min*60)+kuota_sec)
        // console.log(`${kuota_hou} Jam ${kuota_min} Menit ${kuota_sec} Detik`)

        let newAvailableQuota = "";
        if ( kuota_hou > 0 ) {
            newAvailableQuota += `${kuota_hou} Jam `
        }
        if ( kuota_min > 0 ) {
            newAvailableQuota += `${kuota_min} Menit `
        }
        if ( kuota_sec > 0 ) {
            newAvailableQuota += `${kuota_sec} Detik `
        }
        setAvailableQuota(newAvailableQuota)
    }, [available_quota]);

    return(
        <div className="detail-quota">
            <label>
                <span>Sisa Kuota</span>
                {/* 500 Menit */}
                { availableQuota }
            </label>
            <OverlayTrigger
                    trigger={['hover', 'focus']}
                    key="bottom"
                    placement="bottom"
                    // rootClose={true}
                    overlay={
                        <Popover id={`popover-positioned-top`}>
                            <Popover.Content>
                            Top Up Quota
                            </Popover.Content>
                        </Popover>
                    }
            >                    
                <button alt="add-quota" onClick={()=>window.location.href=window.link.webconsole_diktein} className="add-quota">+</button>
            </OverlayTrigger>
        </div>     
    )
}
export default AvailableQuota;