import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

//functions
import { fetchRecordFiles } from '../../../reducers/FetcherReducers';
import { toastNotification } from '../ToastNotification/ToastNotification';

//components
import { Modal } from 'react-bootstrap';
import EmptyState from '../EmptyState/EmptyState';

//assets
// import x from '../../img/x-close.svg'
import x from '../../img/x-cancel.svg'
import audio_file from '../../img/file_audio.png'

//style
import './ModalAudioFile.scss'


function ModalAudioFile(props) {
    const { id_record } = props;
    
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [recordFiles, setRecordFiles] = useState([]);
    
    useEffect(() => {
        if ( !props.show ) {
        } else {
            setLoading(true)
            reqRecordFiles()
        }
    }, [props.show]);

    const reqRecordFiles = () => {
        dispatch(fetchRecordFiles({id_record: id_record}))
            .then((res) => {
                setRecordFiles(res.data)
                setLoading(false)
            })
            .catch((err) => {
                var error = err.error.toString();
                
                toastNotification("error", "Gagal memuat daftar file rekaman. "+error)
                setLoading(false)
            })
    }

    const onReloadClick = () => {
        setLoading(true)
        
        // req file list
        dispatch(reqRecordFiles());
    }

    // console.log(loading)
    return (
        <Modal
            {...props}
            size="md"
            centered
        >
            <div id="af-container">
                <div className="af-top">
                    <h5>Audio File</h5>
                    <img src={x} alt="close" onClick={props.onHide} />
                </div>
                <div className="af-list">
                    { recordFiles.length === 0 ?
                        <EmptyState 
                            state={loading === "error" ? "error" : ( loading ? "loading" : "loaded")}
                            onReloadClick={onReloadClick}
                            noDataText="Belum ada file"
                            // spinnerSize="sm"
                        />
                        : 
                        ( recordFiles.map((file, index) => (
                            <AudioItem
                                key={"file-"+file.name+"-"+index}
                                name={file.name}
                                size={file.size}
                            />
                        )) )
                    }
                </div>
            </div>
        </Modal>
    )
}

function AudioItem(props) {
    return (
        <div className="af-item">
            <img id="icon" src={audio_file} alt="audio-icon" />
            <div className="af-info">
               <h5>{props.name}</h5>
            <span>{props.size}</span>
            </div>
        </div>
    )
}

export default ModalAudioFile;