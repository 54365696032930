import { useEffect, useState } from 'react'
import Datepicker from 'react-datepicker'
//component
import { Modal } from 'react-bootstrap'
import './ModalFilterRecordList.scss'
// import x from '../../img/x-close.svg'
import x from '../../img/x-cancel.svg'

import "react-datepicker/dist/react-datepicker.css";
import { RECORD_STATUS } from '../../../utils/helpers'


function ModalFilterRecordList(props){
    const { show, listStatus, selectedTranscriptStatus, selectedDate } = props;

    const [transcriptStatus, setTranscriptStatus] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate ] = useState(null);
    
    useEffect(() => {
        if ( show ) {
            setTranscriptStatus(selectedTranscriptStatus);
            setStartDate(selectedDate.start)
            setEndDate(selectedDate.end);
        }
    }, [show]);

    const updateTranscriptStatus = (status) => {
        var newTranscriptStatus = transcriptStatus.slice();

        const index =  newTranscriptStatus.indexOf(status);
        if ( index > -1 ) {
            newTranscriptStatus.splice(index, 1);
        } else {
            newTranscriptStatus.push(status)
        }
        setTranscriptStatus(newTranscriptStatus);
    }

    const onStartDateChange = (date) => {
        setStartDate(date);
    }

    const onEndDateChange = (date) => {
        if ( date < startDate ) {
            setEndDate(startDate);
            return;
        }
        setEndDate(date);
    }

    const resetAll = () => {
        setTranscriptStatus(listStatus);
        setStartDate(null);
        setEndDate(null);

        // props.onSave([], {start: null, end: null})
    }

    const applyFilter = () => {
        props.onSave(transcriptStatus, {start: startDate, end: endDate})
    }

    return (
        <Modal
            {...props}
            size="md"
            centered
            className="modal-ct"
            id="modal-filter-record"
        >
            <div id="ct-container">
                <div className="ct-top">
                    <h5>Filter</h5>
                    <img src={x} alt="close" id="close" onClick={props.onHide} />
                </div>
                
                <label>Status</label>     
                <div className="filter-tag">
                    { listStatus.map((status) => {
                        return (
                            <div
                                data-testid={"filter-status-"+status} 
                                key={"status-"+status}
                                className={transcriptStatus.indexOf(status) > -1 && "active"} 
                                onClick={()=>updateTranscriptStatus(status)}
                            >{status===RECORD_STATUS.TRANSCRIPTED?"done":status}</div>
                        )
                    }) }
                </div>
                <label>Date</label> 
                <div className="filter-date">
                    <Datepicker
                        selected={startDate}
                        onChange={onStartDateChange}
                        placeholderText="Start Date"
                        dateFormat="dd/MM/yyyy"
                    />
                    <Datepicker
                        selected={endDate}
                        onChange={onEndDateChange}
                        placeholderText="End Date"
                        dateFormat="dd/MM/yyyy"
                    />
                </div>
                <div className="footer">
                    <span id="reset-btn" onClick={resetAll}>reset</span>
                    <button id="save-btn" onClick={applyFilter}>
                        Simpan
                    </button>
                </div>
            </div>
        </Modal>

    )
}

export default ModalFilterRecordList;