import { toastNotification } from "../apps/components/ToastNotification/ToastNotification";

/** 
Declare the type of action as constant.
WHY?
    - Help reduce typos 
    - Help reduce bugs and mistake
    - If you make typo and dispatch an undefined constants,
      the app will throw error to alert the mistake. 
*/

// Import

import store from '../store';
import { fetchAuthProfile } from "./FetcherReducers";

// Functions

// Action Types
const UPDATE_ID_TOKEN           = "AccountManagementReducers/UPDATE_ID_TOKEN";
const UPDATE_REGISTER_STATUS    = "AccountManagementReducers/UPDATE_REGISTER_STATUS";
const UPDATE_PROFILE_DATA       = "AccountManagementReducers/UPDATE_PROFILE_DATA";

// Action Creators
/* 
 *  !LEGEND! 
 *      req = request
 *      rcv = receive
 *      fch = fetch
 *      snd = send
 */


// Actions


// You can put impure functions within your action creators

export const updateIdToken = (token) => (dispatch) => {
    // console.log("updateIdToken "+token)
    if ( token === null ) {
        localStorage.removeItem("token");
    }  else {
        localStorage.setItem("token", token);
    }

    dispatch({
        type: UPDATE_ID_TOKEN,
        payload: {
            token: token
        }
    })
};

export const updateRegister = reg_status => ({
    type: UPDATE_REGISTER_STATUS,
    payload: {
        reg_status: reg_status
    }

})

export const updateProfileData = data => ({
    type: UPDATE_PROFILE_DATA,
    payload: {
        profile_data: data
    }

})


//reducers

// export const fetchRefreshToken = () => {
//     // Put outgoing requester here
//     const body = {
//         token: store.getState().AccountManagementReducers.token
//     };

//     return fetch(process.env.REACT_APP_REST_API_URL + `/app/diktein/${process.env.REACT_APP_SYSTEM_STATUS}/user/login/refresh/`, {
//         headers: {
//             accept: "application/json",
//             'Content-Type': 'application/json; charset=utf-8'
//         },
//         method: "POST",
//         body: JSON.stringify(body)
//     })
//         .then(response => {
//             return response.json();
//         })
//         .then(result => {
//             if (result.status === "success") {
//                 store.dispatch(updateIdToken(result.token));
//			       localStorage.setItem("keycloakToken", JSON.stringify(result.keycloakToken))

//                 return Promise.resolve(result);

//             } else {
//                 store.dispatch(updateIdToken(null));

//                 throw (result.error);
//             }

//         })
//         .catch(err => {
//             toastNotification(
//                 'error',
//                 'Gagal memperbaharui sesi login. '
//                 // +err.toString()
//             );

//             history.push("/login");

//             return Promise.reject(err)
//         });
// };

export const reqProfileData = () => dispatch => {
    return new Promise((resolve, reject) => {
        // Put outgoing requester here  

        dispatch(fetchAuthProfile())
            .then(res => {
                store.dispatch(rcvProfileData(res));
                resolve(res);
            }).catch(resErr => {
                store.dispatch(rcvProfileData(resErr));
                reject(resErr);
            });
    })
};

export const rcvProfileData = (msg) => (dispatch) => {

    if ( msg.status === "failed" ) {
        toastNotification("error", "Gagal memuat data profil. "+msg.error)
        return;
    }

    dispatch(updateProfileData(msg))
}

// Reducer's initial state
const initialState = {
    data: null,
    token: null,
    username: '',
    reg_status: null,
    profile_data: null
};


// Reducers

// You must only write pure function when trying to build the reducer! 

export default function AccountManagementReducers(state = initialState, action) {
    switch (action.type) {
        case UPDATE_ID_TOKEN:
            return {
                ...state,
                token: action.payload.token
            };
        case UPDATE_REGISTER_STATUS:
            return {
                ...state,
                reg_status: action.payload.reg_status
            }
        case UPDATE_PROFILE_DATA:
            return {
                ...state,
                profile_data: action.payload.profile_data
            }
        default:
            return state;
    }
}



// Side effects, only as applicable
// e.g. thunks,epics, etc