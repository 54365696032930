export const endpoints = {
    auth_register: "/auth/register",
    auth_actor_add: "/auth/actor/add",
    auth_actor_update: "/auth/actor/update",
    auth_actor_delete: "/auth/actor/delete",
    auth_actor_list: "/auth/actor/list",
    auth_actor_pair_accept: "/auth/actor/pair/accept",
    auth_add: "/auth/add",
    auth_update: "/auth/update",
    auth_reset: "/auth/reset",
    auth_delete: "/auth/delete",
    auth_list: "/auth/list",
    auth_login: "/auth/login",
    auth_logout: "/auth/logout",
    auth_list_organisation: "/auth/list/organisation",
    auth_list_title: "/auth/list/title",
    auth_list_method: "/auth/list/method",
    auth_find: "/auth/find",
    auth_user_delete: "/auth/user/delete",
    auth_verify_password: "/auth/verify/password",
    auth_verify_register: "/auth/verify/register",
    auth_refresh_token: "/auth/refresh/token",
    auth_update_token: "/auth/update/token",
    auth_pair_list: "/auth/pair/list",
    auth_profile: "/auth/profile",
    language_list: "/language/list",
    record_add: "/record/add",
    record_add_file: "/record/add/file",
    record_update: "/record/update",
    record_delete: "/record/delete",
    record_list: "/record/list",
    record_find: "/record/find",
    record_shared_add_owner: "/record/shared/add_owner",
    record_shared_delete_owner: "/record/shared/delete_owner",
    record_files: "/record/files",
    record_start: "/record/start",
    participant_add_exist: "/participant/add/exist",
    participant_add_new: "/participant/add/new",
    participant_delete: "/participant/delete",
    participant_list: "/participant/list",
    participant_pair_account: "/participant/pair/account",
    group_add: "/group/add",
    group_add_record: "/group/add/record",
    group_delete: "/group/delete",
    group_update: "/group/update",
    group_list: "/group/list",
    group_list_member: "/group/list/member",
    group_add_member: "/group/add/member",
    group_del_member: "/group/del/member",
    utterance_audio: "utterance/download/file?file=",
    utterance_add: "/utterance/add",
    utterance_delete: "/utterance/delete",
    utterance_lock: "/utterance/lock",
    utterance_list: "/utterance/list",
    utterance_listen: "/utterance/listen/", //<sha256(id_record)>
    utterance_update_text: "/utterance/update/text",
    utterance_update_speaker: "/utterance/update/speaker",
    utterance_reload: "/utterance/reload",
    utterance_list_paragraph: "/utterance/list/paragraph",
    utterance_stream_start: "/utterance/stream/start",
    utterance_stream_stop: "/utterance/stream/stop",
    utterance_stream_audio: "/utterance/stream/audio",
    utterance_segmentor_request: "/utterance/segmentor/request",
    utterance_segmentor_response: "/utterance/segmentor/response",
    utterance_sid_request: "/utterance/sid/request",
    utterance_sid_response: "/utterance/sid/response",
    utterance_ld_request: "/utterance/ld/request",
    utterance_ld_response: "/utterance/ld/response",
    utterance_asr_request: "/utterance/asr/request",
    utterance_asr_response: "/utterance/asr/response",
    model_asr_list: "/model/asr/list",
    user_add: "/user/add",
    user_delete: "/user/delete",
    user_update: "/user/update",
    user_list: "/user/list",
    keyword_add: "/keyword/add",
    keyword_add_record: "/keyword/add/record",
    keyword_update: "/keyword/update",
    keyword_delete: "/keyword/delete",
    keyword_delete_record: "/keyword/delete/record",
    keyword_list: "/keyword/list",
    keyword_list_record: "/keyword/list/record",
    speaker_add: "/speaker/add",
    speaker_delete: "/speaker/delete",
    speaker_update: "/speaker/update",
    speaker_list: "/speaker/list",
    speaker_listen: "/speaker/listen/", //<sha256(id_record)>
    speaker_pair_participant: "/speaker/pair/participant"	
}