import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch } from 'react-router-dom';

//FUNCTIONS
import { reqProfileData } from '../reducers/AccountManagementReducers';

//COMPONENTS
import Dashboard from '../apps/pages/Dashboard/Dashboard';
import DetailRecord from '../apps/pages/DetailRecord/DetailRecord';
import DownloadPreview from '../apps/components/ModalDownloadTranskrip/DownloadPreview';
import LoadingFullPage from '../apps/components/LoadingFullPage';
import EmptyState from '../apps/components/EmptyState/EmptyState';

//CSS IMPORTS


function PrivateRoutes() {
	const dispatch = useDispatch();
	
	const { token, profile_data } = useSelector(state => state.AccountManagementReducers);
    
    const [tokenExist, setTokenExist] = useState(false);

    useEffect(() => {
		if ( tokenExist || token === null || token === undefined ) 
            return;

        setTokenExist(true);
    }, [token])

	useEffect(() => {
		if ( !tokenExist ) return;

        dispatch(reqProfileData())
            .then(() => {})
            .catch((err) => {
                // console.log(err)
                var error = err.error.toString();
    
                ReactDOM.render(
                    <EmptyState 
                        state={"error"}
                        onReloadClick={() => window.location.reload()}
                    >
                        <>
                            <div>Can't open app. Error: {error}</div>
                            <div>Please contact administrator.</div>
                        </>
                    </EmptyState>,
                    document.getElementById('root')
                );
            });
	}, [tokenExist])	


	// show loading page if token not updated yet
	if ( !tokenExist || profile_data === null || profile_data === undefined ) { 
		return <LoadingFullPage info="" />;
	}

	return (
		<>
            <Switch>
				<Route exact path="/" component={Dashboard} />
				<Route path="/record/:id_record" component={DetailRecord} />
				<Route exact path="/preview" component={DownloadPreview} />
				<Redirect from="/:someroute" to="/" />
            </Switch>
		</>
	)
}

export default PrivateRoutes;
