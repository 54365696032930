import { useEffect, useState } from 'react'

//component
import { Modal } from 'react-bootstrap'
import MultiRangeSlider from "multi-range-slider-react";

//style
import './ModalFilterTranscriptListGlobal.scss'

//assets
// import x from '../../img/x-close.svg'
import x from '../../img/x-cancel.svg'


function ModalFilterTranscriptListGlobal(props){
    const { 
        show,
        onHide, 
        searchTranscriptGlobally,
        latRange,
        lngRange,
        lobRange,
        selectedLatRange,
        selectedLngRange,
        selectedLobRange,
        onSaveFilterTranscriptGlobally
    } = props;

    const [cSelectedLatRange, setCSelectedLatRange] = useState(latRange);
    const [cSelectedLngRange, setCSelectedLngRange] = useState(lngRange);
    const [cSelectedLobRange, setCSelectedLobRange] = useState(lobRange);

    useEffect(() => {
        if ( show ) {
            setCSelectedLatRange(selectedLatRange);
            setCSelectedLngRange(selectedLngRange);
            setCSelectedLobRange(selectedLobRange);
        }
    }, [show]);

    const handleResetClick = () => {
        setCSelectedLatRange(latRange);
        setCSelectedLngRange(lngRange);
        setCSelectedLobRange(lobRange);
    }

    const applyFilter = () => {
        onSaveFilterTranscriptGlobally(
            searchTranscriptGlobally,
            cSelectedLatRange,
            cSelectedLngRange,
            cSelectedLobRange
        )
        
        onHide();
    }

    const handleLatRangeChange = (e) => {
        setCSelectedLatRange({
            min: e.minValue,
            max: e.maxValue
        })
    };

    const handleLngRangeChange = (e) => {
        setCSelectedLngRange({
            min: e.minValue,
            max: e.maxValue
        })
    };

    const handleLobRangeChange = (e) => {
        setCSelectedLobRange({
            min: e.minValue,
            max: e.maxValue
        })
    };

    return (
        <Modal
            // {...props}
            show={show}
            onHide={onHide}
            size="md"
            centered
            className="modal-ct"
            id="modal-filter-transcript-global"
        >
            <div id="ct-container">
                <div className="ct-top">
                    <h5>Filter</h5>
                    <img src={x} alt="close" id="close" onClick={props.onHide} />
                </div>
                
                <label>Latitude</label>
                <div className="range-input-container">
                    <input 
                        type="number" 
                        min={latRange.min}
                        max={latRange.max}
                        value={cSelectedLatRange.min}
                        onChange={(e) => setCSelectedLatRange({min: e.target.value, max: cSelectedLatRange.max})}
                    />
                    <span>-</span>
                    <input 
                        type="number" 
                        min={latRange.min}
                        max={latRange.max}
                        value={cSelectedLatRange.max}
                        onChange={(e) => setCSelectedLatRange({min: cSelectedLatRange.min, max: e.target.value})}
                    />
                </div>
                <div className="multi-range-slider-container">
                    <MultiRangeSlider
                        min={latRange.min}
                        max={latRange.max}
                        step={1}
                        ruler={false}
                        label={true}
                        preventWheel={false}
                        minValue={cSelectedLatRange.min}
                        maxValue={cSelectedLatRange.max}
                        onInput={(e) => handleLatRangeChange(e)}
                    />
                </div>
                
                <label>Longitude</label>
                <div className="range-input-container">
                    <input 
                        type="number" 
                        min={lngRange.min}
                        max={lngRange.max}
                        value={cSelectedLngRange.min}
                        onChange={(e) => setCSelectedLngRange({min: e.target.value, max: cSelectedLngRange.max})}
                    />
                    <span>-</span>
                    <input 
                        type="number" 
                        min={lngRange.min}
                        max={lngRange.max}
                        value={cSelectedLngRange.max}
                        onChange={(e) => setCSelectedLngRange({min: cSelectedLngRange.min, max: e.target.value})}
                    />
                </div>
                <div className="multi-range-slider-container">
                    <MultiRangeSlider
                        min={lngRange.min}
                        max={lngRange.max}
                        step={1}
                        ruler={false}
                        label={true}
                        preventWheel={false}
                        minValue={cSelectedLngRange.min}
                        maxValue={cSelectedLngRange.max}
                        onInput={(e) => handleLngRangeChange(e)}
                    />
                </div>
                
                <label>LOB</label>
                <div className="range-input-container">
                    <input 
                        type="number" 
                        min={lobRange.min}
                        max={lobRange.max}
                        value={cSelectedLobRange.min}
                        onChange={(e) => setCSelectedLobRange({min: e.target.value, max: cSelectedLobRange.max})}
                    />
                    <span>-</span>
                    <input 
                        type="number" 
                        min={lobRange.min}
                        max={lobRange.max}
                        value={cSelectedLobRange.max}
                        onChange={(e) => setCSelectedLobRange({min: cSelectedLobRange.min, max: e.target.value})}
                    />
                </div>
                <div className="multi-range-slider-container">
                    <MultiRangeSlider
                        min={lobRange.min}
                        max={lobRange.max}
                        step={1}
                        ruler={false}
                        label={true}
                        preventWheel={false}
                        minValue={cSelectedLobRange.min}
                        maxValue={cSelectedLobRange.max}
                        onInput={(e) => handleLobRangeChange(e)}
                    />
                </div>
                
                <div className="footer">
                    <span id="reset-btn" onClick={handleResetClick}>reset</span>
                    <button id="save-btn" onClick={applyFilter}>
                        Simpan
                    </button>
                </div>
            </div>
        </Modal>

    )
}

export default ModalFilterTranscriptListGlobal;