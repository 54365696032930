import store from "../../../store";

/**
 * This function is used for caching requirement of the fetch
 */
export const FetchBlobCached = (url, signal) => {
    // // Use the URL as the cache key to sessionStorage 
    // let cacheKey    = url;
    // let cached      = sessionStorage.getItem(cacheKey);
    // let used;

    // if (sessionStorage.length > 1) {
    //     used = getUsedSessionStorageSpace();
    //     if(used > 4500000) {
    //         sessionStorage.clear();
    //     }
    // }

    // if (cached !== null) {
    //     // console.log("Cached!");
    //     let blob        = dataURItoBlob(cached);
    //     let response    = new Response(blob);

    //     return Promise.resolve(response);
    // }
    
    var options = {
        headers: {
            'Authorization': 'Bearer '+ store.getState().AccountManagementReducers.token
        },
        method: "GET",
        signal
    }

    return fetch(url, options)
        .then(response => {
            if (response.status === 200) {
                response.clone().blob().then(blob => {
                    let reader = new window.FileReader();
                    reader.readAsDataURL(blob);
                    reader.onloadend = () => {
                        // sessionStorage.setItem(cacheKey, reader.result);
                    }
                });
            }
            return response;
        });
}

// function getUsedSessionStorageSpace() {
//     return Object.keys(window.sessionStorage).map(function (key) { return sessionStorage[key].length; }).reduce(function (a, b) { return a + b; });
// };

// // Thank you https://gist.github.com/fupslot/5015897#gistcomment-1580216
// function dataURItoBlob(dataURI, callback) {
//     // convert base64 to raw binary data held in a string
//     // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
//     var byteString = atob(dataURI.split(',')[1]);
  
//     // separate out the mime component
//     var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
  
//     // write the bytes of the string to an ArrayBuffer
//     var ab = new ArrayBuffer(byteString.length);
//     var ia = new Uint8Array(ab);
//     for (var i = 0; i < byteString.length; i++) {
//       ia[i] = byteString.charCodeAt(i);
//     }
  
//     // write the ArrayBuffer to a blob, and you're done
//     var bb = new Blob([ab]);
//     return bb;
// }