/** 
 * !README FIRST BEFORE EDITING THIS MODULE!
 * 
 * You can import actions from another modules into this nats middleware.
 * 1. If the action needs to send request from the client,
 *    please put a case within the return function inside the MIDDLEWARE SECTION.
 * 2. If the action is a server response, please put a case within onMessage 
 *    variable inside the  CONST VARIABLE SECTION.
 * 
 * further information about redux middleware =>
 * https://redux.js.org/advanced/middleware
 * 
*/
//JS IMPORTS
import {
    connOpen,
    connConnected,
    connConnecting,
    connDisconnected,
    connDisconnect,
    messageSend
} from '../reducers/NATSDefaultReducers';
import { NATSRequester } from './NATSMethods';
import { toastNotification } from '../apps/components/ToastNotification/ToastNotification';


let NATS = require('websocket-nats');

// MIDDLEWARE SECTION 
/** 
 * NATS Middleware 
 * */
export var nats = null 

const NATSMiddleware = (function () {
    /** CONFIGURATION */
    var url = "";
    
    let REACT_APP_URL_USING_PORT = process.env.REACT_APP_URL_USING_PORT;
    if (typeof REACT_APP_URL_USING_PORT !== "boolean") {
        REACT_APP_URL_USING_PORT = ( REACT_APP_URL_USING_PORT === "true" ? true : false );
    }

    if ( REACT_APP_URL_USING_PORT ) {
        const hostname  = window.location.hostname;
        const port      = process.env.REACT_APP_NATS_RELAY_PORT;
        url             = `wss://${hostname}:${port}`;
    } else {
        url = process.env.REACT_APP_NATS_RELAY_URL;
    }

    const onConnected = (store) => {   
        // console.log("NATS connected")

        // Tell the store we're connected
        store.dispatch(connConnected());
    }
    
    const onError = (store) => {
        // console.log("NATS error")

        // Disconnect nats
        store.dispatch(connDisconnect());

        toastNotification(
            "error", 
            "Tidak dapat terkoneksi dengan server. "
                + "Coba refresh halaman ini beberapa saat lagi. "
                + "Jika kendala masih terjadi, harap segera hubungi admin.",
            -1
        );
    }

    const onClose = (store) => {    
        // console.log("NATS close")
            
        // Tell the store we've disconnected
        store.dispatch(connDisconnected());

        // Try to reconnect in 30 seconds
        setTimeout(() => {
            store.dispatch(connOpen());
        }, 30000);
    }

    // CORE MIDDLEWARE SECTION 
    return store => next => action => {
        switch (action.type) {
            // Create new connection to server
            case 'NATS_OPEN':
                if ( nats != null ) {
                    nats.close();
                }

                // Send an action that shows a "connecting..." status for now
                store.dispatch(connConnecting());
                
                // console.log("Connecting to NATS")
                if ( process.env.REACT_APP_SYSTEM_STATUS === "dev" ) {
                    console.log("Connecting to NATS server...")
                    console.log(url);
                }

                // Run nats API to connect to server
                // [begin connect_url]
                nats = NATS.connect(url);

                // connect callback provides a reference to the connection as an argument
                nats.on('connect', function(c) {
                    if ( process.env.REACT_APP_SYSTEM_STATUS === "dev" ) {
                        console.log("NATS connected")
                    }

                    onConnected(store);
                });
                
                // emitted whenever there's an error. if you don't implement at least
                // the error handler, your program will crash if an error is emitted.
                nats.on('error', function(err) {
                    if ( process.env.REACT_APP_SYSTEM_STATUS === "dev" ) {
                        console.log("NATS error: "+err.toString())
                    }

                    onError(store);
                });
                
                // emitted whenever the client disconnects from a server
                nats.on('disconnect', function() {
                    if ( process.env.REACT_APP_SYSTEM_STATUS === "dev" ) {
                        console.log("NATS disconnect")
                    }
                });
                
                // emitted whenever the client is attempting to reconnect
                nats.on('reconnecting', function() {
                    if ( process.env.REACT_APP_SYSTEM_STATUS === "dev" ) {
                        console.log("NATS reconnecting")
                    }
                });
                
                // emitted whenever the client reconnects
                // reconnect callback provides a reference to the connection as an argument
                nats.on('reconnect', function(nats) {
                    if ( process.env.REACT_APP_SYSTEM_STATUS === "dev" ) {
                        console.log("NATS reconnect")
                    }
                });
                
                // emitted when the connection is closed - once a connection is closed
                // the client has to create a new connection.
                nats.on('close', function() {
                    if ( process.env.REACT_APP_SYSTEM_STATUS === "dev" ) {
                        console.log("NATS close")
                    }

                    onClose(store);
                });
                
                // emitted whenever the client unsubscribes
                nats.on('unsubscribe', function(sid, subject) {
                    // console.log("unsubscribed subscription", sid, "for subject", subject);
                    if ( 
                        subject.indexOf("new.utt") >= 0       ||
                        subject.indexOf("result.text") >= 0   ||
                        subject.indexOf("result.speaker") >= 0
                    ) {
                        // console.log("unsubscribed subscription", sid, "for subject", subject);
                    }
                });
                
                // emitted whenever the server returns a permission error for
                // a publish/subscription for the current user. This sort of error
                // means that the client cannot subscribe and/or publish/request
                // on the specific subject
                nats.on("permission_error", function(err) {
                    if ( process.env.REACT_APP_SYSTEM_STATUS === "dev" ) {
                        console.log("NATS got a permission error: ", err.message.toString())
                    }
                });

                break;

            // Disconnection process
            case 'NATS_DISCONNECT':
                if (nats != null) {
                    nats.close();
                }
                nats = null;

                // Set state to disconnected
                store.dispatch(connDisconnected());

                // Open websocket-nats connection
                store.dispatch(connOpen());
                break;

            // Send message process
            case 'NATS_MSG_SEND':
                if ( store.getState().NATSDefaultReducers.nats_status !== "connected" ) {
                    setTimeout(() => {
                        store.dispatch(messageSend(action.request_topic, action.message));
                    }, 100)
                    return;
                }
                NATSRequester(nats, action.request_topic, action.message);
                break;

            // This is mandatory for passing the state to the next middleware
            default:
                return next(action);
        }
    }
})();

export default NATSMiddleware;