import { Spinner } from 'react-bootstrap';
import './Buttons.scss'

function ButtonDangerLoading() {
    return (
        <button className="error">
            <Spinner animation="border" variant="danger" size="sm" />
        </button>
    )
}

export default ButtonDangerLoading;