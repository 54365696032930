import React, { useEffect, useState } from 'react';

import './NotifAlert.scss'

import check from '../../img/check-circle-1.svg'
import x from '../../img/x-warning.svg'

export default function NotifAlert(props) {
    const data = (props.data !== null && props.data !== undefined ? props.data : null)
    const status = (data !== null && data.status !== undefined ? data.status : "success")
    const text = (data !== null && data.text !== undefined ? data.text : "success")
    const timeout = (data !== null && data.timeout !== undefined ? data.timeout : 3000)

    const [show, setShow] = useState(false);

    var showTimeout = null;

    useEffect(() => {
        return () => {
            clearTimeout(showTimeout)
            showTimeout = null;
        }
    }, []);

    useEffect(() => {
        if ( data === null ) {
            setShow(false)
        } else {
            clearTimeout(showTimeout)
            showTimeout = null;
            
            setShow(true)
    
            // if timeout -1 then always show alert
            if ( timeout !== -1 ) {
                showTimeout = setTimeout( () => {
                    setShow(false)
                }, timeout );
            }
        }
    }, [data]);

    if ( data === null || data === undefined || !show ) {
        return null;
    }
        
    return (
        <div className="notif-alert" onClick={() => setShow(false)}>
            { status === "success" &&
                <div>
                    <img src={check} alt="success-dismissible" />
                    {text}
                </div>
            }
            { status === "error" &&
                <div>
                    <img src={x} alt="error-dismissible" />
                    {text}
                </div>
            }
        </div>
    )
}