import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

// functions
import { reqAllUserList } from '../../../../reducers/UserAndGroupReducers'
import { fetchUserAdd } from '../../../../reducers/FetcherReducers'

//components
import { Form, Modal } from 'react-bootstrap'

//style
import '../ModalProfile.scss'
import './UserAndGroup.scss'

//assets
import xc from '../../../img/x-cancel.svg'
import ButtonPrimaryLoading from '../../Buttons/ButtonPrimaryLoading'


function ModalAddUser(props) {
    const { setNotifAlertData, show } = props;

    const dispatch = useDispatch();
    
    const firstInputRef = useRef();

    const [loading, setLoading] = useState(false);

    const [name, setName] = useState("");

    useEffect(() => {
        if ( show ) {
            firstInputRef.current.focus();
        }
    }, [show]);

    useEffect(() => {
        setNotifAlertData(null)
    }, [name]);

    const onAddBtnClick = () => {
        // check name
        if ( name.replace(/\s+/g, '') === "") {
            setNotifAlertData({
                status: "error",
                text: "please fill all column",
                timeout: -1
            })
            return
        }

        setLoading(true)

        var data = {
            name: name
        }

        dispatch(fetchUserAdd(data))
            .then((res) => {
                setLoading(false)

                setName("")

                props.onHide();

                // notif when success delete user
                setNotifAlertData({
                    status: "success",
                    text: "User created",
                    timeout: 3000
                })

                dispatch(reqAllUserList());
            })
            .catch((err) => {
                var error = err.error.toString();
                
                setLoading(false)

                // notif when failed delete user
                setNotifAlertData({
                    status: "error",
                    text: "Failed create user. "+error,
                    timeout:3000
                })
            })
    }


    return (
        <>
            <Modal
                {...props}
                size="sm"
                centered
                id="modal-add-user"
            >
                <div id="add">
                    <div className="top">
                        Add User
                        <img className="close-btn" src={xc} alt="close" onClick={() => props.onHide()} />
                    </div>
                    <Form className="form" 
                        onSubmit={(e) => {
                            e.preventDefault(); 
                            onAddBtnClick();
                        }}
                    >
                        <input
                            ref={firstInputRef}
                            placeholder="User Name"
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </Form>
                    { loading ?
                        <ButtonPrimaryLoading />
                        :
                        <button 
                            className="primary"
                            onClick={onAddBtnClick}
                        >
                            Add
                        </button>
                    }
                </div>
            </Modal>
        </>
    )
}

export default ModalAddUser;