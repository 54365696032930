import { useEffect, useRef, useState } from "react";
import { toastNotification } from "../../components/ToastNotification/ToastNotification";

function ShortcutsForWordScoring(props) {
    const { 
        children, 
        activePage, 
        totalPage, 
        utteranceList, 
        triggerChangePage,
        onCurrentTabIndexChanged
    } = props;
   
    const activePageRef = useRef(activePage);
    const totalPageRef = useRef(totalPage);

    const [allUtteranceListWords, setAllUtteranceListWords] = useState([]);
    const allUtteranceListWordsRef = useRef(allUtteranceListWords);

    const [currentTabIndex, setCurrentTabIndex] = useState(null);
    const currentTabIndexRef = useRef(currentTabIndex);

    useEffect(() => {
        document.addEventListener("keydown", handleKeyDown);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        }
    }, [])

    useEffect(() => {
        activePageRef.current = activePage;
        totalPageRef.current = totalPage;
    }, [activePage, totalPage])

    useEffect(() => {
        let utteranceListWords = [];

        // Save all transcribes words that has tabIndex to allUtteranceListWords
        utteranceList.forEach(({transcribe}) => {
            transcribe.words.forEach((w) => {
                if ( w.tabIndex !== undefined ) {
                    utteranceListWords.push(w);
                }
            })
        })
        
        allUtteranceListWordsRef.current = utteranceListWords
        setAllUtteranceListWords(utteranceListWords)

    }, [utteranceList])
    
    useEffect(() => {
        if ( onCurrentTabIndexChanged !== undefined ) {
            onCurrentTabIndexChanged(currentTabIndex);
        }
    }, [currentTabIndex]);

    const handleKeyDown = (event) => {
        // console.log(event)
        // console.log(event.key)

        // Focus to next highlighted word
        if ( 
            event.ctrlKey && 
            event.shiftKey && 
            // ( event.key === 'n' || event.key === 'N' )
            event.key === 'ArrowRight'
        ) {
            // console.log('Search next word');
            event.preventDefault();

            // NO WORD HIGHLIGHTED IN CURRENT RECORD
            if ( allUtteranceListWordsRef.current.length === 0 ) {
                toastNotification("error", "Highlighted words not found in this record")
                return;
            }

            findNextWord();
        
        } 
        // Focus to prev highlighted word
        else if ( 
            event.ctrlKey && 
            event.shiftKey && 
            // ( event.key === 'p' || event.key === 'P' )
            event.key === 'ArrowLeft'
        ) {
            // console.log('Search prev word');
            event.preventDefault();

            // NO WORD HIGHLIGHTED IN CURRENT RECORD
            if ( allUtteranceListWordsRef.current.length === 0 ) {
                toastNotification("error", "Highlighted words not found in this record")
                return;
            }

            findPrevWord();
        }
    }

    const findNextWord = () => {
        let currentTabIndex = currentTabIndexRef.current;
        let currentPage = activePageRef.current;

        let highlightedWordsOfRecord = allUtteranceListWordsRef.current;
        let highlightedWordsOfPage = allUtteranceListWordsRef.current.filter(({page}) => page === currentPage);
        
        let nextIndex, nextTabIndex, nextPage;
        
        
        let currentIndexInRecord = highlightedWordsOfRecord.findIndex(({tabIndex}) => tabIndex === currentTabIndex);
        let currentIndexInPage   = highlightedWordsOfPage.findIndex(({tabIndex}) => tabIndex === currentTabIndex);

        if ( 
            currentTabIndex !== null && 
            ( currentIndexInRecord === -1 || currentIndexInPage === -1 )
        ) {
            currentTabIndexRef.current = null
            setCurrentTabIndex(null);

            findNextWord();

            return;
        }

        
        if ( currentTabIndex === null ) {
            nextIndex = highlightedWordsOfRecord.findIndex(({page}) => page === currentPage)

            if ( nextIndex === -1 ) {
                nextIndex = highlightedWordsOfRecord.findIndex(({page}) => page > currentPage);

                if ( nextIndex === -1 ) {
                    nextIndex = 0;
                }
            }

        } else {
            nextIndex = currentIndexInRecord+1;

            if ( nextIndex > highlightedWordsOfRecord.length - 1 ) {
                nextIndex = 0;
            }

        }
        
        nextTabIndex = highlightedWordsOfRecord[nextIndex].tabIndex;
        nextPage = highlightedWordsOfRecord[nextIndex].page;

        currentTabIndexRef.current = nextTabIndex
        setCurrentTabIndex(nextTabIndex);

        if ( nextPage !== currentPage ) {
            triggerChangePage(nextPage);
        }

        return;
    }

    const findPrevWord = () => {
        let currentTabIndex = currentTabIndexRef.current;
        let currentPage = activePageRef.current;

        // let highlightedWordsOfRecord = allUtteranceListWordsRef.current;
        let highlightedWordsOfRecordReversed = allUtteranceListWordsRef.current.slice().reverse();
        let highlightedWordsOfPageReversed = highlightedWordsOfRecordReversed.filter(({page}) => page === currentPage);
        
        let prevIndex, prevTabIndex, prevPage;
        
        
        let currentIndexInRecord = highlightedWordsOfRecordReversed.findIndex(({tabIndex}) => tabIndex === currentTabIndex);
        let currentIndexInPage   = highlightedWordsOfPageReversed.findIndex(({tabIndex}) => tabIndex === currentTabIndex);

        if ( 
            currentTabIndex !== null && 
            ( currentIndexInRecord === -1 || currentIndexInPage === -1 )
        ) {
            currentTabIndexRef.current = null
            setCurrentTabIndex(null);

            findPrevWord();

            return;
        }

        
        if ( currentTabIndex === null ) {
            prevIndex = highlightedWordsOfRecordReversed.findIndex(({page}) => page < currentPage)
            
            if ( prevIndex === -1 ) {
                prevIndex = 0;
            }

        } else {
            prevIndex = currentIndexInRecord+1;

            if ( prevIndex > highlightedWordsOfRecordReversed.length - 1 ) {
                prevIndex = 0;
            }

        }
        
        prevTabIndex = highlightedWordsOfRecordReversed[prevIndex].tabIndex;
        prevPage = highlightedWordsOfRecordReversed[prevIndex].page;

        currentTabIndexRef.current = prevTabIndex
        setCurrentTabIndex(prevTabIndex);

        if ( prevPage !== currentPage ) {
            triggerChangePage(prevPage);
        }

        return;
    }

    return (
        <>
            {children}
        </>
    )
}

export default ShortcutsForWordScoring;