import axios from 'axios'
import { history } from '../store';
import { updateIdToken } from './AccountManagementReducers';
import { toastNotification } from '../apps/components/ToastNotification/ToastNotification';
import { PANORAMA_AUTO_LOGIN_DATA } from '..';
import { endpoints } from '../endpoints';

const UPDATE_FETCH_STATUS_AND_DATA = "FetcherReducers/UPDATE_FETCH_STATUS_AND_DATA"

var REACT_APP_REST_API_URL    = "";
const SYSTEM_NAME   = "diktein";
const REACT_APP_SYSTEM_STATUS = process.env.REACT_APP_SYSTEM_STATUS;


let REACT_APP_URL_USING_PORT = process.env.REACT_APP_URL_USING_PORT;
if (typeof REACT_APP_URL_USING_PORT !== "boolean") {
    REACT_APP_URL_USING_PORT = ( REACT_APP_URL_USING_PORT === "true" ? true : false );
}

if ( REACT_APP_URL_USING_PORT ) {
    const hostname  = window.location.hostname;
    const port      = process.env.REACT_APP_REST_API_PORT;
    REACT_APP_REST_API_URL    = `https://${hostname}:${port}`;
} else {
    REACT_APP_REST_API_URL = process.env.REACT_APP_REST_API_URL;
}

export var urlHead = `${REACT_APP_REST_API_URL}/app/${SYSTEM_NAME}/${REACT_APP_SYSTEM_STATUS}`;
var defaultOptions = {
    headers: {
        "Content-Type"  : 'application/json'
    },
    method: "POST"
}

export const sendFetch = (endpoint, body) => (dispatch, getState) => {
    // var trySendFetchCount = 0;

    return new Promise((resolve, reject) => {
        var options     = defaultOptions;
        options.body    = JSON.stringify(body)
        
        const fetchStatus   = endpoint;
        const fetchData     = body;
        dispatch(updateFetchStatusAndData("req"+fetchStatus, fetchData));

        const url = urlHead + endpoint;

        fetch(url, options)
            .then(response => {
                if ( response.ok ) {
                    return response.json();
                } else {
                    throw(response.status+" "+response.statusText)
                }
            })
            .then(result => {
                if (result.status === "success") {
                    result.request = body;

                    dispatch(updateFetchStatusAndData("rcv"+fetchStatus, result));
                    
                    resolve(result)

                } else {
                    const token = getState().AccountManagementReducers.token;

                    if ( token !== null && ( result.error.toLowerCase() === "token expired" || result.result === -1 ) ) {
                        if ( process.env.REACT_APP_TYPE !== "PANORAMA" ) {
                            dispatch(updateIdToken(null));
                            history.push("/login");
                        
                        } else {                            
                            dispatch(fetchAuthLogin(PANORAMA_AUTO_LOGIN_DATA))
                                .then((res) => {
                                    const token = res.token;
                                    dispatch(updateIdToken(token));
                                    
                                    localStorage.setItem("keycloakToken", JSON.stringify(res.keycloakToken))
                                    
                                    dispatch(sendFetch(url, body));
                                    // trySendFetchCount++;

                                }).catch((err) => {
                                    var error = err.error.toString();
                                    
                                    toastNotification("error", "Can't refresh your session. "+ error +". Please contact administrator.")
                                });
                        }

                    } else {
                        throw(result.error)
                    }
                }
            })
            .catch(err => {
                const resultErr = {
                    status: "failed",
                    error: err.toString(),
                    request: body
                }
                dispatch(updateFetchStatusAndData("rcv"+fetchStatus, resultErr));
                
                reject(resultErr);
            });
    })
}

export const updateFetchStatusAndData = (fetchStatus = "", fetchData = null) => (
    {
        type: UPDATE_FETCH_STATUS_AND_DATA,
        payload: {
            fetchStatus: fetchStatus,
            fetchData: fetchData
        }
    }
)


export const fetchLanguageList = (data) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {

        const body = {
            "token"     : getState().AccountManagementReducers.token,
            "req_id"    : ""
        }

        const endpoint = endpoints.language_list;
        
        dispatch(sendFetch(endpoint, body))
            .then(result => {
                if ( result.data === null ) { result.data = [] };
                resolve(result)
            })
            .catch(err => {
                reject(err)
            });
    });
};

/************ START - RECORD ************/
export const fetchRecordList = (data) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {

        const { offset, limit } = data;

        const body = {
            "token"     : getState().AccountManagementReducers.token,
            "role"      : "owner", // owner|corrector|participant
            "keyword"   : "",
            "speaker"   : [], // "id_speaker"
            "text"      : "",
            "offset"    : offset,
            "limit"     : limit,
            "state"     : "all", // uploaded|transcripting|transcribted|all
            "time_from" : "",
            "time_to"   : "",
            "req_id"    : ""
        }

        const endpoint = endpoints.record_list;
        
        dispatch(sendFetch(endpoint, body))
            .then(result => {
                if ( result.data === null ) { result.data = [] };
                resolve(result)
            })
            .catch(err => {
                reject(err)
            });
    });
};

export const fetchRecordDetail = (data) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {

        const { id } = data;

        const body = {
            "token"     : getState().AccountManagementReducers.token,
            "id_record" : id,
            "req_id"    : ""
        }

        const endpoint = endpoints.record_find;
        
        dispatch(sendFetch(endpoint, body))
            .then(result => {
                resolve(result)
            })
            .catch(err => {
                reject(err)
            });
    });
};

export const fetchRecordAdd = (data, progressCallback) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        const token = getState().AccountManagementReducers.token;
        const { name, use_transcribe, use_translate, files, select_language } = data;

        let audio = data.audio.slice();
        audio = audio.map(({model, ...restProps}) => ({...restProps, model:1}))
        
        const formdata = new FormData();
        formdata.append('token', token); 
        formdata.append('name', name); 
        formdata.append('use_transcribe', use_transcribe); 
        formdata.append('use_translate', use_translate); 
        formdata.append('use_sid', true); 
        formdata.append('use_chunking', true); 
        formdata.append('use_lang_id', true);  
        files.forEach((file) => {
            formdata.append('files', file.file);
        });
        formdata.append('audio', JSON.stringify(audio)); 
        formdata.append('select_language', select_language);     
        formdata.append('req_id', "");

        const body = {
            "token"             : token,
            "name"              : name,
            "use_transcribe"    : use_transcribe,
            "use_translate"     : use_translate,
            "use_sid"           : true, 
            "use_chunking"      : true, 
            "use_lang_id"       : true,
            "audio"             : audio, //[{"file":"filename","model":"id_model"}]
            "files"             : files, //[{"file":"file"}]
            "req_id"            : ""
        }

        var options     = defaultOptions;
        options.body    = formdata

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer '+ token
            },
            // cancelToken: cancelTokenSource.token,
            onUploadProgress: progressEvent => {
                const progress = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));
                progressCallback(progress)
            }
        }
    
        const endpoint      = endpoints.record_add;
        
        const fetchStatus   = endpoint;
        const fetchData     = body;
        dispatch(updateFetchStatusAndData("req"+fetchStatus, fetchData));

        const url = urlHead + endpoint;

        axios.post(url, formdata, config)
            .then(response => {
                return response.data;
            })
            .then(result => {
                if (result.status === "success") {
                    result.request = body;
                    
                    dispatch(updateFetchStatusAndData("rcv"+fetchStatus, result));

                    resolve(result)

                } else {
                    if ( result.error.toLowerCase() === "token expired" || result.result === -1 ) {
                        dispatch(updateIdToken(null));
                        history.push("/login");
                        
                    } else {
                        throw(result.error)
                    }
                }
            })
            .catch(err => {
                const resultErr = {
                    status: "failed",
                    error: err.toString(),
                    request: body
                }
                dispatch(updateFetchStatusAndData("rcv"+fetchStatus, resultErr));

                reject(resultErr)
                
            });
    });
};

export const fetchRecordAddFile = (data, progressCallback) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        const token = getState().AccountManagementReducers.token;
        const { id_record, files, use_transcribe, use_translate, select_language } = data;
        
        let audio = data.audio.slice();
        audio = audio.map(({model, ...restProps}) => ({...restProps, model:1}))

        const formdata = new FormData();
        formdata.append('token', token); 
        formdata.append('id_record', id_record); 
        formdata.append('processing_type', 2); 
        // formdata.append('use_transcribe', use_transcribe); 
        formdata.append('use_transcribe', true); 
        formdata.append('use_translate', use_translate); 
        formdata.append('use_sid', true); 
        formdata.append('use_chunking', true); 
        formdata.append('use_lang_id', true); 
        files.forEach((file) => {
            formdata.append('files', file.file);
        });
        formdata.append('audio', JSON.stringify(audio));  
        formdata.append('select_language', select_language);   
        formdata.append('req_id', "");   

        const body = {
            "token"             : token,
            "id_record"         : id_record,
            "use_transcribe"    : use_transcribe,
            "use_translate"     : use_translate,
            "use_sid"           : true, 
            "use_chunking"      : true, 
            "use_lang_id"       : true,
            "processing_type"   : 2,
            "audio"             : audio, //[{"file":"filename","model":"id_model"}]
            "files"             : files, //[{"file":"file"}]
            "req_id"            : ""
        }
             
        var options     = defaultOptions;
        options.body    = JSON.stringify(body)

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer '+ token
            },
            // cancelToken: cancelTokenSource.token,
            onUploadProgress: progressEvent => {
                const progress = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));
                progressCallback(progress)
            }
        }

        const endpoint      = endpoints.record_add_file;

        const fetchStatus   = endpoint;
        const fetchData     = body;
        dispatch(updateFetchStatusAndData("req"+fetchStatus, fetchData));

        const url = urlHead + endpoint;

        axios.post(url, formdata, config)
            .then(response => {
                return response.data;
            })
            .then(result => {
                if (result.status === "success") {
                    result.request = body;
                    
                    resolve(result)

                } else {
                    if ( result.error.toLowerCase() === "token expired" || result.result === -1 ) {
                        dispatch(updateIdToken(null));
                        history.push("/login");
                        
                    } else {
                        throw(result.error)
                    }
                }
            })
            .catch(err => {
                const resultErr = {
                    status: "failed",
                    error: err.toString(),
                    request: body
                }
                dispatch(updateFetchStatusAndData("rcv"+fetchStatus, resultErr));
        
                reject(resultErr)
            });
    });
};

export const fetchRecordUpdate = (data) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        
        const { 
            id_record, 
            name, 
            starting_date,
            end_date,
            place,
            lock,
            use_transcribe, 
            use_translate
        } = data;

        const body = {
            "token"             : getState().AccountManagementReducers.token,
            "id_record"         : id_record,
            "name"              : name,
            "starting_date"     : starting_date,
            "end_date"          : end_date,
            "place"             : place,
            "lock"              : lock,
            "use_transcribe"    : use_transcribe,
            "use_translate"     : use_translate,
            "req_id"            : ""
        }
        
        const endpoint = endpoints.record_update;
        
        dispatch(sendFetch(endpoint, body))
            .then(result => {
                resolve(result)
            })
            .catch(err => {
                reject(err)
            });
    });
};

export const fetchRecordDelete = (id_record) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {

        const body = {
            "token"             : getState().AccountManagementReducers.token,
            "id_record"         : id_record,
            "req_id"            : ""
        }
        
        const endpoint = endpoints.record_delete;
        
        dispatch(sendFetch(endpoint, body))
            .then(result => {
                resolve(result)
            })
            .catch(err => {
                reject(err)
            });
    });
};

export const fetchRecordFiles = (data) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        
        const { id_record } = data;

        const body = {
            "token"     : getState().AccountManagementReducers.token,
            "id_record" : id_record,
            "req_id"    : ""
        }
          
        const endpoint = endpoints.record_files;
        
        dispatch(sendFetch(endpoint, body))
            .then(result => {
                resolve(result)
            })
            .catch(err => {
                reject(err)
            });
    });
};

export const fetchRecordStartTranscribe = (data) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        
        const { id_record } = data;

        const body = {
            "token"     : getState().AccountManagementReducers.token,
            "id_record" : id_record,
            "req_id"    : ""
        }
         
        const endpoint = endpoints.record_start;
        
        dispatch(sendFetch(endpoint, body))
            .then(result => {
                resolve(result)
            })
            .catch(err => { 
                reject(err)
            });
    });
};
/************ END - RECORD ************/


/************ START - UTTERANCE ************/
export const fetchUtteranceList = (data) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        
        const { id_record, offset, limit } = data;

        const body = {
            "token"     : getState().AccountManagementReducers.token,
            "id_record" : id_record,
            "offset"    : offset,
            "limit"     : limit,
            "req_id"    : ""
        }
          
        const endpoint = endpoints.utterance_list;
        
        dispatch(sendFetch(endpoint, body))
            .then(result => {
                if ( result.data === null ) { result.data = [] };
                resolve(result)
            })
            .catch(err => {
                reject(err)
            });
    });
};

export const fetchUtteranceUpdateSpeaker = (data) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        
        const { id_utterance, id_participant, participant } = data;

        const body = {
            "token"             : getState().AccountManagementReducers.token,
            "id_utterance"      : id_utterance,
            "id_participant"    : id_participant,
            "participant"       : participant,
            "req_id"            : ""
        }
        
        const endpoint = endpoints.utterance_update_speaker;
        
        dispatch(sendFetch(endpoint, body))
            .then(result => {
                resolve(result)
            })
            .catch(err => {
                reject(err)
            });
    });
};

export const fetchUtteranceUpdateText = (data) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        
        const { id_utterance, text } = data;

        const body = {
            "token"         : getState().AccountManagementReducers.token,
            "id_utterance"  : id_utterance,
            "text"          : text,
            "state"         : "",
            "req_id"        : ""
        }
        
        const endpoint = endpoints.utterance_update_text;
        
        dispatch(sendFetch(endpoint, body))
            .then(result => {
                resolve(result)
            })
            .catch(err => {
                reject(err)
            });
    });
};

export const fetchUtteranceReload = (data) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        
        const { id_record, id_utterance } = data;

        const body = {
            "token"         : getState().AccountManagementReducers.token,
            "id_record"     : id_record,
            "id_utterance"  : id_utterance, //-1 for reload all
            "req_id"    : ""
        }
          
        const endpoint = endpoints.utterance_reload;
        
        dispatch(sendFetch(endpoint, body))
            .then(result => {
                resolve(result)
            })
            .catch(err => {
                reject(err)
            });
    });
};

export const fetchUtteranceListParagraph = (data) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        
        const { id_record, offset, limit } = data;

        const body = {
            "token"     : getState().AccountManagementReducers.token,
            "id_record" : id_record,
            "offset"    : offset,
            "limit"     : limit,
            "req_id"    : ""
        }
          
        const endpoint = endpoints.utterance_list_paragraph;
        
        dispatch(sendFetch(endpoint, body))
            .then(result => {
                if ( result.data === null ) { result.data = [] };
                resolve(result)
            })
            .catch(err => {
                reject(err)
            });
    });
};

/************ END - UTTERANCE ************/


/************ START - PARTICIPANT ************/
export const fetchParticipantList = (data) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        
        const { id_record, offset, limit } = data;

        const body = {
            "token"     : getState().AccountManagementReducers.token,
            "id_record" : id_record,
            "offset"    : offset,
            "limit"     : limit,
            "req_id"    : ""
        }
          
        const endpoint = endpoints.participant_list;
        
        dispatch(sendFetch(endpoint, body))
            .then(result => {
                if ( result.data === null ) { result.data = [] };
                resolve(result)
            })
            .catch(err => {
                reject(err)
            });
    });
};

export const fetchParticipantDelete = (data) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        
        const { id_record, id_participant } = data;

        const body = {
            "token"             : getState().AccountManagementReducers.token,
            "id_record"         : id_record,
            "id_participant"    : id_participant,
            "req_id"            : ""
        }
          
        const endpoint = endpoints.participant_delete;
        var options     = defaultOptions;
        options.body    = JSON.stringify(body)
  
        dispatch(sendFetch(endpoint, body))
            .then(result => {
                resolve(result)
            })
            .catch(err => {
                reject(err)
            });
    });
};

export const fetchParticipantAddExist = (data) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        
        const { id_record, id_user } = data;

        const body = {
            "token"     : getState().AccountManagementReducers.token,
            "id_record" : id_record,
            "id_user"   : id_user, //[<id_user>]
            "req_id"    : ""
        }
            
        const endpoint = endpoints.participant_add_exist;
        
        dispatch(sendFetch(endpoint, body))
            .then(result => {
                resolve(result)
            })
            .catch(err => {
                reject(err)
            });
    });
};

export const fetchParticipantAddNew = (data) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        
        const { id_record, name } = data;

        const body = {
            "token"     : getState().AccountManagementReducers.token,
            "id_record" : id_record,
            "name"      : name, //[<name>]
            "req_id"    : ""
        }
            
        const endpoint = endpoints.participant_add_new;
        
        dispatch(sendFetch(endpoint, body))
            .then(result => {
                resolve(result)
            })
            .catch(err => {
                reject(err)
            });
    });
};
/************ END -  ************/

/************ START - USER AUTH ************/

export const fetchAuthLogin = (data) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        
        const { auth_name, auth_method, auth_password, req_id } = data;

        const body = {
            "auth_name": auth_name, //emailnya apa
            "auth_method" : auth_method, //email/facebook/gmail/etc
            "auth_password": auth_password,
            "req_id": req_id
        }
          
        const endpoint = endpoints.auth_login
        
        dispatch(sendFetch(endpoint, body))
            .then(result => {
                if (result.status === "success") {
                    result.request = body;
                    
                    resolve(result)

                } else {
                    var error = result.error;
                    var errRes = result.result;

                    if ( error === "token expired" || errRes === -1 ) {
                        dispatch(updateIdToken(null));
                        history.push("/login");
                        
                    } else {
                        if ( error === "unauthorized" || errRes === -2 ) {
                            error = "username atau password salah"
                        }
                        
                        throw(error)
                    }
                }
            })
            .catch(err => {
                reject(err)
            });
    });
};

export const fetchAuthLogout = (data) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        
        const { token, req_id } = data;

        const body = {
            "token": token,
            "req_id": req_id
        }
          
        const endpoint = endpoints.auth_logout;
        
        dispatch(sendFetch(endpoint, body))
            .then(result => {
                resolve(result)
            })
            .catch(err => {
                reject(err)
            });
    });
};

export const fetchAuthRegister = (data) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        
        const { 
            username,
            method,
            auth_name,
            auth_pass,
            req_id } = data;

        const body = {
            "username": username,
            "method": method,
            "auth_name": auth_name,
            "auth_pass": auth_pass,
            "req_id": req_id
        }
          
        const endpoint = endpoints.auth_register;
        var options     = defaultOptions;
        options.body    = JSON.stringify(body)
        
        dispatch(sendFetch(endpoint, body))
            .then(result => {
                resolve(result)
            })
            .catch(err => {
                reject(err)
            });
    });
};

export const fetchAuthReset = (data) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        
        const { auth_name, method, req_id } = data;

        const body = {
            // "token" : getState().AccountManagementReducers.token, //<optional>
            "method": method,
            "auth_name": auth_name,
            "req_id": req_id
        }
         
        const endpoint = endpoints.auth_reset;
        
        dispatch(sendFetch(endpoint, body))
            .then(result => {
                resolve(result)
            })
            .catch(err => {
                reject(err)
            });
    });
};

export const fetchAuthVerifyPassword = (data) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        
        const { password, token, req_id } = data;

        const body = {
            // "token" : getState().AccountManagementReducers.token,
            "token" : token,
            "password": password,
            "req_id": req_id
        }
          
        const endpoint = endpoints.auth_verify_password;
        
        dispatch(sendFetch(endpoint, body))
            .then(result => {
                resolve(result)
            })
            .catch(err => {
                reject(err)
            });
    });
};


export const fetchAuthVerifyRegister = (data) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        const { token, req_id } = data;

        const body = {
            "token" : token,
            "req_id": req_id
        }

        const endpoint = endpoints.auth_verify_register;
        
        dispatch(sendFetch(endpoint, body))
            .then(result => {
                resolve(result)
            })
            .catch(err => {
                reject(err)
            });
    });
};

export const fetchUpdateToken = (old_id_token, new_id_token) => dispatch => {
    return new Promise((resolve, reject) => {
        // Put outgoing requester here
        const body = {
            old_id_token: old_id_token,
            new_id_token: new_id_token
        };
    
        const endpoint = endpoints.auth_update_token;

        dispatch(sendFetch(endpoint, body))
            .then(result => {
        
                if ( result.status === "success" ) {
                    const old_id_token = localStorage.getItem("token") !== null ? localStorage.getItem("token") : "";
                    const res_old_id_token = result.old_id_token;
                    const res_new_id_token = result.new_id_token;
                    
                    // console.log("old_id_token: "+old_id_token)
                    // console.log("res_old_id_token: "+res_old_id_token)

                    if ( old_id_token === res_old_id_token ) {
                        dispatch(updateIdToken(res_new_id_token));
                    }

                    resolve(result);

                } else {
                    // localStorage.removeItem("token");
                    // dispatch(updateIdToken(null));
        
                    throw(result.error);
                }
            })
            .catch(err => {
                reject(err);
            });
    });  
};


export const fetchAuthActorUpdate = (data) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        
        const { 
            id_actor,
            username,
            avatar,
            organisation,
            title,
            password 
        } = data;

        const body = {
            "token"         : getState().AccountManagementReducers.token, //<optional>
            "id_actor"      : id_actor,
            "username"      : username,
            "avatar"        : avatar,
            "organisation"  : organisation,
            "title"         : title,
            "password"      : password,
            "req_id"        :""
        }
            
        const endpoint = endpoints.auth_actor_update;
        
        dispatch(sendFetch(endpoint, body))
            .then(result => {
                resolve(result)
            })
            .catch(err => {
                reject(err)
            });
    });
};

export const fetchAuthUpdate = (data) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        
        const { method, auth_name, new_auth_pass, old_auth_pass } = data;

        const body = {
            "token"         : getState().AccountManagementReducers.token, //<optional>
            "method"        : method,
            "auth_name"     : auth_name,
            "new_auth_pass" : new_auth_pass, // isi kalau mau ganti password aja
            "old_auth_pass" : old_auth_pass, // wajib diisi,
            "req_id"        : ""
        }
            
        const endpoint = endpoints.auth_update;
        
        dispatch(sendFetch(endpoint, body))
            .then(result => {
                resolve(result)
            })
            .catch(err => {
                reject(err)
            });
    });
};

export const fetchAuthProfile = () => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        
        const body = {
            "token"         : getState().AccountManagementReducers.token, //<optional>
            "req_id"        : ""
        }
          
        const endpoint = endpoints.auth_profile;
        
        dispatch(sendFetch(endpoint, body))
            .then(result => {
                resolve(result)
            })
            .catch(err => {
                reject(err)
            });
    });
};


/************ END - USER AUTH ************/




/************ START - GROUP ************/
export const fetchGroupAddToRecord = (data) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        
        const { id_record, id_group } = data;

        const body = {
            "token"     : getState().AccountManagementReducers.token,
            "id_record" : id_record,
            "id_group"  : id_group, //[<id_group>]
            "req_id"    : ""
        }
            
        const endpoint = endpoints.group_add_record;
        
        dispatch(sendFetch(endpoint, body))
            .then(result => {
                resolve(result)
            })
            .catch(err => {
                reject(err)
            });
    });
};

export const fetchGroupAdd = (data) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        
        const { name } = data;

        const body = {
            "token"     : getState().AccountManagementReducers.token,
            "name"      : name,
            "req_id"    : ""
        }
         
        const endpoint = endpoints.group_add;
        
        dispatch(sendFetch(endpoint, body))
            .then(result => {
                resolve(result)
            })
            .catch(err => {
                reject(err)
            });
    });
};

export const fetchGroupDelete = (data) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        
        const { id_group } = data;

        const body = {
            "token"      : getState().AccountManagementReducers.token,
            "id_group"   : id_group,
            "req_id"     : ""
        }
          
        const endpoint = endpoints.group_delete;
        
        dispatch(sendFetch(endpoint, body))
            .then(result => {
                resolve(result)
            })
            .catch(err => {
                reject(err)
            });
    });
};

export const fetchGroupList = (data) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        
        const { offset, limit } = data;

        const body = {
            "token"      : getState().AccountManagementReducers.token,
            "offset"     : offset,
            "limit"      : limit,
            "req_id"     : ""
        }
         
        const endpoint = endpoints.group_list;
        
        dispatch(sendFetch(endpoint, body))
            .then(result => {
                if ( result.data === null ) { result.data = [] };
                resolve(result)
            })
            .catch(err => {
                reject(err)
            });
    });
};

export const fetchGroupUpdate = (data) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        
        const { id_group, name } = data;

        const body = {
            "token"      : getState().AccountManagementReducers.token,
            "id_group"   : id_group,
            "name"       : name,
            "req_id"     : ""
        }
          
        const endpoint = endpoints.group_update;
        
        dispatch(sendFetch(endpoint, body))
            .then(result => {
                resolve(result)
            })
            .catch(err => {
                reject(err)
            });
    });
};


export const fetchGroupListMember = (data) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        
        const { offset, limit, id_group } = data;

        const body = {
            "token"      : getState().AccountManagementReducers.token,
            "id_group"   : id_group,
            "offset"     : offset,
            "limit"      : limit,
            "req_id"     : ""
        }
          
        const endpoint = endpoints.group_list_member;
        
        dispatch(sendFetch(endpoint, body))
            .then(result => {
                if ( result.data === null ) { result.data = [] };
                resolve(result)
            })
            .catch(err => {
                reject(err)
            });
    });
};


export const fetchGroupAddMember = (data) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        
        const { id_group, id_user } = data;

        const body = {
            "token"     : getState().AccountManagementReducers.token,
            "id_group"  : id_group,
            "id_user"   : id_user,
            "req_id"    : ""
        }
           
        const endpoint = endpoints.group_add_member;
        
        dispatch(sendFetch(endpoint, body))
            .then(result => {
                resolve(result)
            })
            .catch(err => {
                reject(err)
            });
    });
};

export const fetchGroupDeleteMember = (data) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        
        const { id_member_group } = data;

        const body = {
            "token"             : getState().AccountManagementReducers.token,
            "id_member_group"   : id_member_group,
            "req_id"            : ""
        }
           
        const endpoint = endpoints.group_del_member;
        
        dispatch(sendFetch(endpoint, body))
            .then(result => {
                resolve(result)
            })
            .catch(err => {
                reject(err)
            });
    });
};


/************ END - GROUP ************/


/************ START - KEYWORD ************/

export const fetchKeywordAdd = (data) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        const { keyword } = data;
        
        const body = {
            "token"     : getState().AccountManagementReducers.token,
            "keyword"   : keyword,
            "req_id"    : ""
        }
           
        const endpoint = endpoints.keyword_add;
        
        dispatch(sendFetch(endpoint, body))
            .then(result => {
                resolve(result)
            })
            .catch(err => {
                reject(err)
            });
    });
};

export const fetchKeywordDelete = (data) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        const { id } = data;
        
        const body = {
            "token"     : getState().AccountManagementReducers.token,
            "id"        : id,
            "req_id"    : ""
        }
           
        const endpoint = endpoints.keyword_delete;
        
        dispatch(sendFetch(endpoint, body))
            .then(result => {
                resolve(result)
            })
            .catch(err => { 
                reject(err)
            });
    });
};

export const fetchKeywordList = (data) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        const { filter, offset, limit } = data;
        
        const body = {
            "token"     : getState().AccountManagementReducers.token,
            "filter"    : filter,
            "offset"    : offset,
            "limit"     : limit,
            "req_id"    : ""
        }
          
        const endpoint = endpoints.keyword_list;
        
        dispatch(sendFetch(endpoint, body))
            .then(result => {
                if ( result.data === null ) { result.data = [] };
                resolve(result)
            })
            .catch(err => {
                reject(err)
            });
    });
};

export const fetchKeywordUpdate = (data) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        const { id, keyword } = data;
        
        const body = {
            "token"     : getState().AccountManagementReducers.token,
            "id"        : id,
            "keyword"   : keyword,
            "req_id"    : ""
        }
          
        const endpoint = endpoints.keyword_update;
        
        dispatch(sendFetch(endpoint, body))
            .then(result => {
                resolve(result)
            })
            .catch(err => {
                reject(err)
            });
    });
};

/************ END - KEYWORD ************/


/************ START - KEYWORD RECORD ************/

export const fetchKeywordAddToRecord = (data) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        const { id, id_record } = data;
        
        const body = {
            "token"     : getState().AccountManagementReducers.token,
            "id"        : id,       // [<id_keyword>]
            "id_record" : id_record,
            "req_id"    : ""
        }
            
        const endpoint = endpoints.keyword_add_record;
        
        dispatch(sendFetch(endpoint, body))
            .then(result => {
                resolve(result)
            })
            .catch(err => {
                reject(err)
            });
    });
};

export const fetchKeywordDeleteFromRecord = (data) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        const { id, id_record } = data;
        
        const body = {
            "token"     : getState().AccountManagementReducers.token,
            "id"        : id,       // [<id_keyword>]
            "id_record" : id_record,
            "req_id"    : ""
        }
            
        const endpoint = endpoints.keyword_delete_record;
        
        dispatch(sendFetch(endpoint, body))
            .then(result => {
                resolve(result)
            })
            .catch(err => {
                reject(err)
            });
    });
};

export const fetchKeywordListOfRecord = (data) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        const { filter, id_record, offset, limit } = data;

        const body = {
            "token"     : getState().AccountManagementReducers.token,
            "filter"    : filter,       // [<id_keyword>]
            "id_record" : id_record,
            "offset"    : offset,
            "limit"     : limit,
            "req_id"    : ""
        }
          
        const endpoint = endpoints.keyword_list_record;
        
        dispatch(sendFetch(endpoint, body))
            .then(result => {
                if ( result.data === null ) { result.data = [] };
                resolve(result)
            })
            .catch(err => {
                reject(err)
            });
    });
};

/************ END - KEYWORD RECORD ************/


/************ START - USER ************/

export const fetchUserAdd = (data) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        
        const { name } = data;

        const body = {
            "token"     : getState().AccountManagementReducers.token,
            "name"      : name,
            "req_id"    : ""
        }
          
        const endpoint = endpoints.user_add;
        
        dispatch(sendFetch(endpoint, body))
            .then(result => {
                resolve(result)
            })
            .catch(err => {
                reject(err)
            });
    });
};

export const fetchUserDelete = (data) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        
        const { id } = data;

        const body = {
            "token"     : getState().AccountManagementReducers.token,
            "id"        : id,
            "req_id"    : ""
        }
          
        const endpoint = endpoints.user_delete;
        
        dispatch(sendFetch(endpoint, body))
            .then(result => {
                resolve(result)
            })
            .catch(err => {
                reject(err)
            });
    });
};

export const fetchUserUpdate = (data) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        
        const { id, name } = data;

        const body = {
            "token"     : getState().AccountManagementReducers.token,
            "id"        : id,
            "name"      : name,
            "req_id"    : ""
        }
          
        const endpoint = endpoints.user_update;
        
        dispatch(sendFetch(endpoint, body))
            .then(result => {
                resolve(result)
            })
            .catch(err => {
                reject(err)
            });
    });
};

export const fetchUserList = (data) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        
        const { offset, limit } = data;

        const body = {
            "token"     : getState().AccountManagementReducers.token,
            "offset"    : offset,
            "limit"     : limit,
            "req_id"    : ""
        }
          
        const endpoint = endpoints.user_list;
        
        dispatch(sendFetch(endpoint, body))
            .then(result => {
                if ( result.data === null ) { result.data = [] };
                resolve(result)
            })
            .catch(err => {
                reject(err)
            });
    });
};

/************ END - USER ************/

/************ START - SPEAKER ************/
export const fetchSpeakerList = (data) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        
        const { id_record, offset, limit } = data;
        
        const body = {
            "token"     : getState().AccountManagementReducers.token,
            "id_record" : id_record,
            "offset"    : offset,
            "limit"     : limit,
            "req_id"    : ""
        }
          
        const endpoint = endpoints.speaker_list;
        
        dispatch(sendFetch(endpoint, body))
            .then(result => {
                if ( result.data === null ) { result.data = [] };
                resolve(result)
            })
            .catch(err => {
                reject(err)
            });
    });
};

export const fetchSpakerPairParticipant = (data) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        
        const { id_speaker, id_participant } = data;

        const body = {
            "token"             : getState().AccountManagementReducers.token,
            "id_speaker"        : id_speaker,
            "id_participant"    : id_participant,
            "req_id"            : ""
        }
         
        const endpoint = endpoints.speaker_pair_participant;
        
        dispatch(sendFetch(endpoint, body))
            .then(result => {
                resolve(result)
            })
            .catch(err => { 
                reject(err)
            });
    });
};

/************ END - SPEAKER ************/


/************ START - MODEL ASR ************/

export const fetchModelAsrList = (data) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        const { filter } = data;
        
        const body = {
            "token"     : getState().AccountManagementReducers.token,
            "filter"    : filter,
            "req_id"    : ""
        }
          
        const endpoint = endpoints.model_asr_list;
        
        dispatch(sendFetch(endpoint, body))
            .then(result => {
                if ( result.data === null ) { result.data = [] };
                resolve(result)
            })
            .catch(err => {
                reject(err)
            });
    });
};

/************ END - MODEL ASR ************/

// CONSOLE
export const fetchConsoleUpdateToken = (token) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {    
        const body = {
            "old_id_token" : "",
            "new_id_token" : token
        }

        var options     = defaultOptions;
        options.body    = JSON.stringify(body)
        
        const url = `${process.env.REACT_APP_CONSOLE_API_URL}/app/console/${REACT_APP_SYSTEM_STATUS}/auth/update/`;

        fetch(url, options)
            .then(response => {
                if ( response.ok ) {
                    return response.json();
                } else {
                    throw(response.status+" "+response.statusText)
                }
            })
            .then(result => {
                // console.log(result)
                resolve(result)
            })
            .catch(err => {
                // console.log(err)
                reject(err)
            });
    })
};

export const fetchConsolePackageList = (token) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {    
        const body = {
            "id_token" : token
        }

        var options     = defaultOptions;
        options.body    = JSON.stringify(body)
        
        const url = `${process.env.REACT_APP_CONSOLE_API_URL}/app/console/${REACT_APP_SYSTEM_STATUS}/userpackage/list/`;

        fetch(url, options)
            .then(response => {
                if ( response.ok ) {
                    return response.json();
                } else {
                    throw(response.status+" "+response.statusText)
                }
            })
            .then(result => {
                // console.log(result)
                resolve(result)
            })
            .catch(err => {
                // console.log(err)
                reject(err)
            });
    })
};

export const fetchConsoleGetDikteinQuota = () => (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {    
        const token = getState().AccountManagementReducers.token;

        // // let tokenUpdated = false;
        // await dispatch(fetchConsoleUpdateToken(token))
        //     .then((res) => {
        //         // tokenUpdated = true;
        //     })
        //     .catch((err) => {
        //         // console.log(err)
        //         reject(err);
        //         return;
        //     })

                
        // console.log(res)
        let packages = [];
        await dispatch(fetchConsolePackageList(token))	
            .then((res) => {
                // console.log(res.packages)
                packages = res.packages;
            })
            .catch((err) => {
                // console.log(err)
                reject(err);
                return;
            })

        const index = packages.findIndex(({service_name}) => service_name.replace(/\./g, '').indexOf("diktein") > -1);
        if ( index > -1 ) {
            const id_user_package = packages[index].id_user_package;
            const body = {
                "id_token"          : token,
                "id_user_package"   : id_user_package
            }
    
            var options     = defaultOptions;
            options.body    = JSON.stringify(body)
            
            const url = `${process.env.REACT_APP_CONSOLE_API_URL}/app/console/${REACT_APP_SYSTEM_STATUS}/userpackage/detail/`;
    
            fetch(url, options)
                .then(response => {
                    if ( response.ok ) {
                        return response.json();
                    } else {
                        throw(response.status+" "+response.statusText)
                    }
                })
                .then(result => {
                    const kuota = result.volume_base.kuota;
                    resolve(kuota)
                })
                .catch(err => {
                    // console.log(err)
                    reject(err);
                    return;
                });

        } else {
            const err = new Error("service not available")
            // console.log(err)
            reject(err);
            return;
        }
    })
};


// Reducer's initial state
const initialState = {
    fetchStatus : "",
    fetchData   : null
};

export default function FetcherReducers(state = initialState, action) {
    switch (action.type) {
        case UPDATE_FETCH_STATUS_AND_DATA:
            return {
                ...state,
                fetchStatus : action.payload.fetchStatus,
                fetchData   : action.payload.fetchData
            };
        default:
            return state;
    }
}