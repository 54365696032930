import { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'

// functions
import { reqAllGroupList } from '../../../../reducers/UserAndGroupReducers'
import { fetchGroupUpdate } from '../../../../reducers/FetcherReducers'

//components
import { Form, Modal } from 'react-bootstrap'

//style
import '../ModalProfile.scss'
import './UserAndGroup.scss'

//assets
import x from '../../../img/x-cancel.svg'
import ButtonPrimaryLoading from '../../Buttons/ButtonPrimaryLoading'


function ModalEditGroupName(props) {
    const { show, setNotifAlertData, selectedGroup } = props;

    const dispatch = useDispatch();
    
    const firstInputRef = useRef();
    
    const [loading, setLoading] = useState(false);
    
    const [name, setName] = useState("");

    useEffect(() => {
        if ( show ) {
            firstInputRef.current.focus();
        }
    }, [show]);

    useEffect(() => {
        if ( selectedGroup !== null ) {
            setName(selectedGroup.name)
        }
    }, [selectedGroup]);

    useEffect(() => {
        setNotifAlertData(null)
    }, [name]);

    const onSaveClick = () => {
        // check name
        if ( name.replace(/\s+/g, '') === "") {
            setNotifAlertData({
                status: "error",
                text: "please fill all column",
                timeout: -1
            })
            return
        }

        setLoading(true)

        var data = {
            id_group: selectedGroup.id,
            name: name
        }

        dispatch(fetchGroupUpdate(data))
            .then((res) => {
                setLoading(false)

                props.onHide();

                // notif when success delete user
                setNotifAlertData({
                    status: "success",
                    text: "Group updated",
                    timeout: 3000
                })

                dispatch(reqAllGroupList());
            })
            .catch((err) => {
                var error = err.error.toString();
                
                setLoading(false)

                // notif when failed delete user
                setNotifAlertData({
                    status: "error",
                    text: "Failed update group. "+error,
                    timeout:3000
                })
            })
    }

    const allInputDisabled = ( loading ? true : false );

    return (
        <>
            <Modal
                {...props}
                size="md"
                centered
                id="modal-es"
            >
                <div id="es-container">
                    <div className="es-top">
                        <h5>Edit Group Name</h5>
                        <img src={x} alt="close-edit-group" onClick={() => props.onHide()} />
                    </div>
                    <Form className="form" 
                        onSubmit={(e) => {
                            e.preventDefault(); 
                            onSaveClick();
                        }}
                    >
                        <div className="es-opt">
                            <label>Group Name</label>
                            <input
                                ref={firstInputRef}
                                placeholder="Group Name"
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                disabled={allInputDisabled}
                            />
                        </div>
                    </Form>
                    { loading ?
                        <ButtonPrimaryLoading />
                        :
                        <button className="primary" onClick={onSaveClick}>Simpan</button>
                    }
                </div>
            </Modal>
        </>
    )
}

export default ModalEditGroupName;