//JS IMPORTS
// import $ from 'jquery';
import ReactDOM from 'react-dom';
// import { NotificationManager } from 'react-notifications';

//CSS IMPORTS
// import 'react-notifications/lib/notifications.css';
import NotifAlert from '../NotifAlert/NotifAlert';
import './ToastNotification.scss';

export function toastNotification(type, text, timeout = 3000) {
    if ( type === "success" || type === "error" ) {
        const data = {
            status: type,
            text: text,
            timeout: timeout
        }

        const notif_container = document.getElementById('notification-container');

        if ( notif_container !== null ) {
            ReactDOM.render(
                <NotifAlert data={data} />,
                document.getElementById('notification-container')
            );
        }
    }
}

// export function toastNotificationBAK(type, title, desc) {
    
//     if ( $(window).width() > 767 ) {
//         if ( $(".notification-container .notification").length > 1 ) {
//             for ( var i = 0; i < $(".notification-container .notification").length - 1; i++ ) {
//                 $(".notification-container .notification")[$(".notification-container .notification").length-i-1].click();
//             }
//         }
//     } else {
//         if ( $(".notification-container .notification").length > 0 ) {
//             for ( var i = 0; i < $(".notification-container .notification").length; i++ ) {
//                 $(".notification-container .notification")[$(".notification-container .notification").length-i-1].click();
//             }
//         }

//     }
    
//     switch (type) {
//         case 'info':
//             NotificationManager.info(desc, title, 3000, null, true);
//             break;
//         case 'success':
//             NotificationManager.success(desc, title, 3000, null, true);
//             break;
//         case 'warning':
//             NotificationManager.warning(desc, title, 3000, null, true);
//             break;
//         case 'error':
//             NotificationManager.error(desc, title, 6000, null, true);
//             // NotificationManager.error(desc, title, 86400000, null, true);
//             break;
//         default:
//             break;
//     }
// };