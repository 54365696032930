import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

//functions
import { reqAllParticipantList, reqSpeakerPairParticipant } from '../../../reducers/SpeakerAndParticipantReducers';

//components
import { Overlay, Spinner } from 'react-bootstrap'
import EmptyState from '../EmptyState/EmptyState';

//styles
import './ModalSpeakerAndParticipant.scss'

//assets
import check from '../../img/check-circle.svg'
import circle from '../../img/bx-circle.svg'


function OverlayEditSpeaker(props) {
    const { show, id_record, speaker, participantList } = props;

    const { fetchStatus, fetchData } = useSelector(state => state.FetcherReducers)

    const dispatch  = useDispatch();

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // console.log(show)
        if ( show ) {
            setLoading(true);
            dispatch(reqAllParticipantList(id_record));
        } else {
        }
    }, [show]);
    
    useEffect(() => {
        if ( fetchStatus === "rcvAllParticipantList" ) {
            if ( fetchData.status === "success" ) {
                setTimeout(() => {
                    setLoading(false)
                }, 1000)
            } else {
                setLoading("error")
            }

        }
    }, [fetchStatus]);

    const pairParticipantWithSpeaker = (participant) => {
        dispatch(reqSpeakerPairParticipant(speaker.id, participant.id))
    }

    const onReloadClick = () => {
        setLoading(true)
        
        // req participant list
        dispatch(reqAllParticipantList(id_record));
    }

    return (
        <Overlay
            target={props.target.current}
            show={show}
            onHide={props.onHide}
            placement="right-start"
            rootClose={true}
        >
            {(propss) => (
                <div {...propss}>
                    <div className="utt-change-speaker">
                        <h5>Change Speaker</h5>
                        <div className="utt-speaker-list-cont">
                            <div className="utt-speaker-list-wrap">
                                { participantList.length === 0 ? 
                                    <EmptyState 
                                        state={loading === "error" ? "error" : ( loading ? "loading" : "loaded")}
                                        onReloadClick={onReloadClick}
                                        noDataText={"Belum ada partisipan"}
                                        spinnerSize="sm"
                                    />
                                    :
                                    ( participantList.map((pt) => 
                                        <EditSpeakerItem
                                            key={"participant-"+pt.id}
                                            speaker={pt.name}
                                            active={pt.id === speaker.id_participant ? true : false}
                                            onSelect={() => pairParticipantWithSpeaker(pt)}
                                        />
                                    ) )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Overlay>
    )
}

function EditSpeakerItem(props) {
    const { active } = props;

    const [loading, setLoading] = useState(false);
    
    useEffect(() => {
        if ( active ) {
            setLoading(false)
        }
    }, [active]);

    const onSelectSpeaker = () => {
        setLoading(true)

        props.onSelect()
    }

    // const onPlayClick = (e) => {
    //     e.stopPropagation()
    // }
    
    return (
        <div className="utt-speaker-list-item" onClick={!active ? onSelectSpeaker : () => {}}>
            {/* <img
                alt="icon-play-select-speaker"  
                id="icon" 
                src={play} 
                onClick={onPlayClick} 
            />  */}
            <span className="speaker-name">{props.speaker}</span>
            { active ?
                <img alt="selected-speaker" id="icon-check" src={check} />
                : 
                ( loading ?
                    <Spinner
                        id="icon-circle"
                        as="span"
                        animation="border"
                        size="xs"
                        role="status"
                        aria-hidden="true"
                    />
                    :
                    <img alt="select-speaker" id="icon-circle" src={circle} />
                )
            }
        </div>

    )
}

export default OverlayEditSpeaker;