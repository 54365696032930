import moment from 'moment';
import { useState } from 'react'

//functions

//components
import ModalEditRecordTitle from './RecordInfo/ModalEditRecordTitle';
import CardRecordInfo from './RecordInfo/CardRecordInfo';
import CardSpeakerAndParticipant from './SpeakerAndParticipant/CardSpeakerAndParticipant';

//styles
import './Sidebar.scss'

//assets
import arrow_right from '../../img/arrow-forward.svg'
import edit from '../../img/edit-blue.png'
import CardKeyword from './Keyword/CardKeyword';
import CardRecordTranscriptProgress from './RecordInfo/CardRecordTranscriptProgress';
import { RECORD_STATUS } from '../../../utils/helpers';


function Sidebar(props) {
    const { setNotifAlertData, recordData } = props;
    const { 
        id_record, 
        state="",
        name, 
        starting_date,
        total_statement, 
        total_file_uploaded,
        total_duration,
    } = recordData;
    
    const [showModalEdit, setShowModalEdit] = useState(false);

    function DetailDocument() {
        return (
            <>
                <div data-testid="detail-document-card" className="item-cont detail-document">
                    <h4>{name}
                        <p>{moment(starting_date).format("DD MMMM YYYY")}</p>
                        <img src={edit} alt="icon-edit-record-name" onClick={()=>setShowModalEdit(true)}/>
                    </h4>
                    <div className="detail-stat">
                        <div className="stat right-border">
                            <h3>
                                <span style={{ color: "#2BC5A8" }}>⬤</span>
                                {total_statement}
                            </h3>
                            <label>Statement</label>
                        </div>
                        <div className="stat right-border">
                            <h3>
                                <span style={{ color: "#0189FF" }}>⬤</span>
                                {total_file_uploaded}
                            </h3>
                            <label>Audio Files</label>
                        </div>
                        <div className="stat">
                            <h3>
                                <span style={{ color: "#FE9004" }}>⬤</span>
                                {Math.round(moment.duration(total_duration).asHours())}
                            </h3>
                            <label>Hours</label>
                        </div>
                    </div>
                    <div className="show-file" onClick={props.showAudio}>Show All Files <img src={arrow_right} alt="show" /></div>
                </div>
            </>
        )
    }

    return (
        <div className="sidebar-cont">
            <ModalEditRecordTitle 
                show={showModalEdit}
                onHide={() => setShowModalEdit(false)}
                recordData={recordData}
                setNotifAlertData={setNotifAlertData}
            />
            { process.env.REACT_APP_TYPE !== "PANORAMA" && 
                DetailDocument() 
            }
            { state !== RECORD_STATUS.TRANSCRIPTED &&
                <CardRecordTranscriptProgress
                    recordData={recordData} 
                />
            }
            <CardRecordInfo 
                setNotifAlertData={setNotifAlertData}
                recordData={recordData} 
            />
            <CardSpeakerAndParticipant
                setNotifAlertData={setNotifAlertData}
                id_record={id_record}
            />
            <CardKeyword
                setNotifAlertData={setNotifAlertData}
                id_record={id_record}
                showKeyword={props.showKeyword}
                setShowKeyword={props.setShowKeyword}
            />
        </div>
    )
}

export default Sidebar;