/** 
Declare the type of action as constant.
WHY?
    - Help reduce typos 
    - Help reduce bugs and mistake
    - If you make typo and dispatch an undefined constants,
      the app will throw error to alert the mistake. 
*/

// Import
import { toastNotification } from '../apps/components/ToastNotification/ToastNotification';
import { fetchKeywordList, fetchKeywordListOfRecord, updateFetchStatusAndData } from './FetcherReducers';

// Functions

// Action Types
const UPDATE_KEYWORD_LIST_AND_TOTAL             = "KeywordReducers/UPDATE_KEYWORD_LIST_AND_TOTAL";
const UPDATE_KEYWORD_LIST_OFFSET                = "KeywordReducers/UPDATE_KEYWORD_LIST_OFFSET"
const RESET_KEYWORD_LIST_DATA                   = "KeywordReducers/RESET_KEYWORD_LIST_DATA";
const UPDATE_KEYWORD_LIST_OF_RECORD_AND_TOTAL   = "KeywordReducers/UPDATE_KEYWORD_LIST_OF_RECORD_AND_TOTAL";
const UPDATE_KEYWORD_LIST_OF_RECORD_OFFSET      = "KeywordReducers/UPDATE_KEYWORD_LIST_OF_RECORD_OFFSET"
const RESET_KEYWORD_LIST_OF_RECORD_DATA         = "KeywordReducers/RESET_KEYWORD_LIST_OF_RECORD_DATA";

// Action Creators
/* 
 *  !LEGEND! 
 *      req = request
 *      rcv = receive
 *      fch = fetch
 *      snd = send
 */


// Actions

export const updateKeywordListOffset = offset => ({
	type: UPDATE_KEYWORD_LIST_OFFSET,
    payload: {
        keywordListOffset: offset
    }
});

export const updateKeywordListAndTotal = data => ({
	type: UPDATE_KEYWORD_LIST_AND_TOTAL,
    payload: {
        keywordList: data.keywordList,
        keywordListTotal: data.keywordListTotal
    }
});

export const resetKeywordListData = () => ({
	type: RESET_KEYWORD_LIST_DATA
});

export const updateKeywordListOfRecordOffset = offset => ({
	type: UPDATE_KEYWORD_LIST_OF_RECORD_OFFSET,
    payload: {
        keywordListOfRecordOffset: offset
    }
});

export const updateKeywordListOfRecordAndTotal = data => ({
	type: UPDATE_KEYWORD_LIST_OF_RECORD_AND_TOTAL,
    payload: {
        keywordListOfRecord: data.keywordListOfRecord,
        keywordListOfRecordTotal: data.keywordListOfRecordTotal
    }
});

export const resetKeywordListOfRecordData = () => ({
	type: RESET_KEYWORD_LIST_OF_RECORD_DATA
});


// You can put impure functions within your action creators

// Reducers

export const reqAllKeywordList = (offset = 0) => (dispatch, getState) => { 
    dispatch(updateFetchStatusAndData("reqAllKeywordList"));

    dispatch(updateKeywordListOffset(offset))

    const limit = getState().KeywordReducers.keywordListLimit;

    const data = {
        filter: "",
        offset,
        limit
    }

    dispatch(fetchKeywordList(data))
        .then((res) => {
            dispatch(rcvAllKeywordList(res))
        })
        .catch((resErr) => {
            dispatch(rcvAllKeywordList(resErr))
        })
};

export const rcvAllKeywordList = (msg) => (dispatch, getState) => {
    const offset = getState().KeywordReducers.keywordListOffset;
    const limit  = getState().KeywordReducers.keywordListLimit;
    const total  = getState().KeywordReducers.keywordListTotal;

	const request       = msg.request;
    const res_offset    = request.offset;
	const res_total 	= msg.total;

    // RETURN if not the response of last requested utterance list
    if ( res_offset !== offset ) 
        return;

    // clear utterance list if received total is not equal to current total 
    if ( offset === 0 && total !== res_total ) {
        dispatch(resetKeywordListData())
    }
    
    dispatch(updateFetchStatusAndData("rcvAllKeywordList", msg));

    if ( msg.status === "failed" ) {
        toastNotification("error", "Gagal memuat daftar keyword master. "+msg.error)
        return;
    }

	var keywordList = getState().KeywordReducers.keywordList.slice();
	msg.data.forEach((utterance, index) => {
		keywordList[offset+index] = utterance;
	})

	const keywordListAndTotal = {
		keywordList: keywordList,
		keywordListTotal: res_total
	}
	dispatch(updateKeywordListAndTotal(keywordListAndTotal));

	if ( msg.data.length === limit ) {
		dispatch(reqAllKeywordList((offset+limit)));
	}
};

export const reqAllKeywordListOfRecord = (id_record, offset = 0) => (dispatch, getState) => { 
    const currentOpenedIdRecord = getState().RecordReducers.currentOpenedIdRecord;
    if ( id_record !== currentOpenedIdRecord ) return;

    dispatch(updateFetchStatusAndData("reqAllKeywordListOfRecord"));
    
    dispatch(updateKeywordListOfRecordOffset(offset))

    const limit = getState().KeywordReducers.keywordListOfRecordLimit;

    const data = {
        filter: "",
        id_record,
        offset,
        limit
    }

    dispatch(fetchKeywordListOfRecord(data))
        .then((res) => {
            dispatch(rcvAllKeywordListOfRecord(res))
        })
        .catch((resErr) => {
            dispatch(rcvAllKeywordListOfRecord(resErr))
        })
};

export const rcvAllKeywordListOfRecord = (msg) => (dispatch, getState) => {
    const offset = getState().KeywordReducers.keywordListOfRecordOffset;
    const limit  = getState().KeywordReducers.keywordListOfRecordLimit;
    const total  = getState().KeywordReducers.keywordListOfRecordTotal;

    const request   = msg.request;
    const { id_record } = request;
    const res_offset    = request.offset;
	const res_total 	= msg.total;

    // RETURN if received utterance list not belong to current opened record
    const currentOpenedIdRecord = getState().RecordReducers.currentOpenedIdRecord;
    if ( id_record !== currentOpenedIdRecord ) return;

    // RETURN if not the response of last requested utterance list
    if ( res_offset !== offset ) return;
    
    if ( offset === 0 && total !== res_total ) {
        dispatch(resetKeywordListOfRecordData())
    }
    
    dispatch(updateFetchStatusAndData("rcvAllKeywordListOfRecord", msg));

    if ( msg.status === "failed" ) {
        toastNotification("error", "Gagal memuat daftar keyword record. "+msg.error)
        return;
    }

	var keywordListOfRecord = getState().KeywordReducers.keywordListOfRecord.slice();
	msg.data.forEach((utterance, index) => {
		keywordListOfRecord[offset+index] = utterance;
	})

	const keywordListOfRecordAndTotal = {
		keywordListOfRecord: keywordListOfRecord,
		keywordListOfRecordTotal: res_total
	}
	dispatch(updateKeywordListOfRecordAndTotal(keywordListOfRecordAndTotal));

	if ( msg.data.length === limit ) {
		dispatch(reqAllKeywordListOfRecord((offset+limit)));
	}
};

// Reducer's initial state
const initialState = {
    keywordList       : [],
    keywordListOffset : 0,
    keywordListLimit  : 1000,
    keywordListTotal  : 0,

    keywordListOfRecord       : [],
    keywordListOfRecordOffset : 0,
    keywordListOfRecordLimit  : 1000,
    keywordListOfRecordTotal  : 0,
};


// You must only write pure function when trying to build the reducer! 

export default function KeywordReducers(state = initialState, action) {
    switch (action.type) {
        case UPDATE_KEYWORD_LIST_OFFSET:
            return {
                ...state,
                keywordListOffset: action.payload.keywordListOffset
            };
        case UPDATE_KEYWORD_LIST_AND_TOTAL:
            return {
                ...state,
                keywordList: action.payload.keywordList,
                keywordListTotal: action.payload.keywordListTotal
            };
        case RESET_KEYWORD_LIST_DATA:
            return {
                ...state,
                keywordList          : [],
                keywordListOffset    : 0,
                keywordListTotal     : 0,
            };
        case UPDATE_KEYWORD_LIST_OF_RECORD_OFFSET:
            return {
                ...state,
                keywordListOfRecordOffset: action.payload.keywordListOfRecordOffset
            };
        case UPDATE_KEYWORD_LIST_OF_RECORD_AND_TOTAL:
            return {
                ...state,
                keywordListOfRecord: action.payload.keywordListOfRecord,
                keywordListOfRecordTotal: action.payload.keywordListOfRecordTotal
            };
        case RESET_KEYWORD_LIST_OF_RECORD_DATA:
            return {
                ...state,
                keywordListOfRecord         : [],
                keywordListOfRecordOffset   : 0,
                keywordListOfRecordTotal    : 0,
            };
        default:
            return state;
    }
}



// Side effects, only as applicable
// e.g. thunks,epics, etc