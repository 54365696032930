import React, { useState, useEffect, useRef } from 'react';
import './DropFile.scss'
import upload from '../../img/upload_file.png'
import file_audio from '../../img/file_audio.png'
import x from '../../img/x-cancel.svg'
import { get_random_uuid } from '../../../utils/functions';
import { useSelector } from 'react-redux';


function DropFile(props) {
    const modelAsrList = useSelector(state => state.ModelAsrReducers.modelAsrList)
    
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');

    const fileInputRef = useRef();

    useEffect(() => {
        // console.log(selectedFiles)
        if ( props.onFileSelected !== undefined ) {
            props.onFileSelected(selectedFiles)
        }
    }, [selectedFiles]);


    const fileInputClicked = () => {
        fileInputRef.current.click();
    }

    const fileDrop = (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        if (files.length > 0) {
            handleFiles(files);
        }
    }

    const onFilesSelected = (e) => {
        var files = e.target.files;
        if ( files.length > 0 ) {
            handleFiles(files);    
        }
        e.target.value = null;
    }

    const handleFiles = (files) => {
		for (let i = 0, file; file = files[i]; i++) {
            if (validateFile(file)) {
                // add to an array so we can display the name of file
                setSelectedFiles(prevArray => [
                    ...prevArray, 
                    {
                        id: get_random_uuid(),
                        file: file,
                        model: 0
                    }
                ]);
            }
        }
    }

    const validateFile = (file) => {
        // const validTypes = ['audio/mpeg', 'audio/ogg', 'audio/wav'];
        // if (validTypes.indexOf(file.type) === -1) {
        //     return false;
        // }
        // return false;

        if ( file.type.match(/audio.*/) || file.type.match(/video.*/) ) {	
            return true;
        }
        return false;
    }

    const getFileSize = (size) => {
        if (size === 0) return '0 Bytes';
        const k = 1024;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        const i = Math.floor(Math.log(size) / Math.log(k));
        return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    }

    const onModelChange = (id, model) => {
        // console.log(id)
        // console.log(model)

        var newSelectedFiles = selectedFiles.slice();
        // find the index of the item
        const selectedFileIndex = newSelectedFiles.findIndex(e => e.id === id);
        // update model of the item in array
        newSelectedFiles[selectedFileIndex].model = parseInt(model);
        // update selectedFiles array
        setSelectedFiles(newSelectedFiles);
    }

    const removeFile = (id) => {
        var newSelectedFiles = selectedFiles.slice();
        // find the index of the item
        const selectedFileIndex = newSelectedFiles.findIndex(e => e.id === id);
        // remove the item from array
        newSelectedFiles.splice(selectedFileIndex, 1);
        // update selectedFiles array
        setSelectedFiles(newSelectedFiles);
    }

    return (
        <div className="dropzone">
            <div className="dropzone-top">
                <label id="label">
                    Attachment
                </label>
                {selectedFiles.length !== 0 &&
                    <div id="add-file"
                        onClick={fileInputClicked}>
                        + Add Attachment
                    </div>
                }

            </div>
            {
                selectedFiles.length === 0 &&
                 <div data-testid="dropfile-container" className="dropfile-container"
                    // onDragOver={dragOver}
                    // onDragEnter={dragEnter}
                    // onDragLeave={dragLeave}
                    onDrop={fileDrop}
                    onClick={fileInputClicked}
                >
                    <img id="icon" src={upload} alt="icon" />
                    <p>Drop your files here or click here</p>
                    <span>upload anything you want</span>
                </div>
              }
            <input
                alt="select-attachment"
                ref={fileInputRef}
                className="file-input"
                type="file"
                accept="audio/*,video/*"
                multiple
                onChange={onFilesSelected}
            />
            {
                selectedFiles.length !== 0 ?

                    <div className="showfile-container">
                        {
                            selectedFiles.map((data) =>

                                <div key={"file-"+data.id} className="file-item">
                                    <img id="icon" src={file_audio} alt="icon" />
                                    <div className="file-info">
                                        <div className="file-name">{data.file.name}</div>
                                        <div className="file-size">({getFileSize(data.file.size)})</div>
                                    </div>
                                    {/* <div className="quality-option">
                                        <select name="select-model-asr" onChange={(e) => onModelChange(data.id, e.target.value)}>
                                            <option value={0}>File Type:</option>
                                            { modelAsrList.map((model) => {
                                                return (
                                                    <option key={"model-asr-"+model.id} value={model.id}>{model.name}</option>
                                                )
                                            })}
                                        </select>
                                    </div> */}
                                    <img onClick={() => removeFile(data.id)} id="remove" src={x} alt="remove-file" />
                                </div>
                            )}
                    </div>
                    : null}
        </div>
    )
}

export default DropFile;