import { useState } from 'react';
import { ProgressBar } from 'react-bootstrap';
//assets
import './FloatingUploadCard.scss'
import uploading from '../../img/uploading-file.png'
import close from '../../img/x-cancel.svg'
import mini from '../../img/minimize-2.svg'
import maxi from '../../img/maximize-2.svg'
import { useDispatch, useSelector } from 'react-redux';
import { updateIsUploading } from '../../../reducers/RecordReducers';

function FloatingUploadCard() {
    const isUploading = useSelector(state => state.RecordReducers.isUploading)
    const uploadingError = useSelector(state => state.RecordReducers.uploadingError)
    const uploadingProgress = useSelector(state => state.RecordReducers.uploadingProgress)
    // const listFileUpload = useSelector(state => state.RecordReducers.listFileUpload)

    const dispatch = useDispatch();

    // const [progress, setProgress] = useState(0);
    // const [showFile, setShowFile] = useState(false);
    const [minimize, setMinimize] = useState(false);

    const closeCard = () => {
        dispatch(updateIsUploading(false))
    }

    function Uploading() {
        return (
            <div className={"uploading-modal "+(minimize?"minimized":"")}>
                <div className="toolbar">
                    { minimize ?
                        <img src={maxi} id="expand" alt="maximize" onClick={()=>setMinimize(false)}/>
                        :
                        <img src={mini} id="expand" alt="minimize" onClick={()=>setMinimize(true)}/>
                    }
                    { uploadingError !== null &&
                        <img src={close} id="close" alt="close" onClick={closeCard}/>
                    }
                </div>
                { !minimize && <img src={uploading} id="icon" alt="uploading" /> }

                { uploadingError === null ?
                    <>
                        <label>Uploading your file ...</label>
                        <div className="progress-wrapper">
                            <ProgressBar now={uploadingProgress} />
                            <p>{uploadingProgress}%</p>
                            <img src={close} alt={close} />
                        </div>
                    </>
                    : 
                    <>
                        <label>Upload file error</label>
                        <div className="error">Error: {uploadingError}</div>
                    </>
                } 
                {/* { !minimize && <div onClick={()=> setShowFile(true)} className="show-all">Show all files</div> } */}
            </div>
        )
    }

    // function FileShow() {
    //     return (
    //         <div className="uploading-modal">
    //             <div className="file-show">
    //                 <div className="file-show-top">
    //                     <p>Audio Files</p><img src={x} alt="close" onClick={()=> setShowFile(false)}/>
    //                 </div>
    //                 <div className="file-show-list">
    //                     { listFileUpload.map((file, index) => {
    //                         return (
    //                             <FileUploadItem key={"file-"+file.name+"-"+index} file={file} />
    //                         )
    //                     })}
    //                 </div>
    //             </div>
    //         </div>
    //     )
    // }

    // function FileUploadItem(props) {
    //     const file = props.file;

    //     return (
    //         <div className="file-upload-item">
    //             <img id="icon" src={file_audio} alt="icon" />
    //             <div id="info">
    //                 <label>{file.name}</label>
    //                 <div className="progress-file-wrapper">
    //                     <ProgressBar now={progress} />
    //                     <img src={close} alt={close} />
    //                 </div>
    //             </div>
    //         </div>
    //     )
    // }

    function ShowFile() {
        return (
            // showFile ?
            //     FileShow()
            //     : 
                Uploading()
        )
    }

    return (
        isUploading ?
            ShowFile()
            : null
    )
}

export default FloatingUploadCard;