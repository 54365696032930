// Import Javascript
import React from 'react';
import { withRouter } from 'react-router'; // react-router v4
import { FetchBlobCached } from './FetchBlobCached';
import "abortcontroller-polyfill";

// Components
import BKAudioSlider from './BKAudioSlider';

// Functions

// Import CSS
import './BKAudioPlayer.scss';

// ETC
import playIcon from "../../img/play-btn.svg";
import pauseIcon from "../../img/pause-audio.png";
import playIconDemo from '../../img/play-btn.svg';
import pauseIconDemo from '../../img/pause-audio.png';

/***
    props:
    demo=bool                   //
    audioFile=url               //audio url to be played
    onAudioPlayStateChange=func //function to get audio play state
    onAudioEnded=func           //function to get audio ended state
***/

class BKAudioPlayer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            init: false,
            mediaDisabled: true,
            // play: true,
            // loop: true,
            initPlay: true,
            play: false,
            loop: false,
            loopStart: 0,
            loopEnd: 0,
            currentValue: 0,
            playBackRate: 1.0,
            startOffset: 1,
            ABLoopStart: 0,
            ABLoopDuration: 0,
            lock: false,
            finished: false,
            onFetch: false,

            /************** START - FOR BKAudioSlider *************/
            sliderValue: 0,
            dynamic: false,
            duration: 0,
            markerValues: [],
            thumbValue: 0,
            sliderStatus: 'continue', // 'move', 'stop', 'continue'
            /*************** END - FOR BKAudioSlider **************/
        
        };

        // binding to 'this'
        this.audioPausePlay     = this.audioPausePlay.bind(this);
        this.audioForwardRate   = this.audioForwardRate.bind(this);
        this.audioBackwardRate  = this.audioBackwardRate.bind(this);
        this.audioLoop          = this.audioLoop.bind(this);
        this.audioInitABLoop    = this.audioInitABLoop.bind(this);
        this.audioMove          = this.audioMove.bind(this);
        this.audioSeek          = this.audioSeek.bind(this);
        this.audioSeekForward   = this.audioSeekForward.bind(this);
        this.audioSeekBackward  = this.audioSeekBackward.bind(this);
        
        // Create variable for aborting the fetch process.
        this.AbortCon = new window.AbortController();
        this.controller = this.AbortCon;
        this.signal = this.controller.signal;
    }
	
	componentDidMount() {	
        // initial function start
        this.init();
        window.addEventListener('beforeunload', () => {
            this.close();
        });

        let audioFile = this.props.audioFile;

        if ( audioFile === "" ) {
            this.setState({
                mediaDisabled: true
            });
        } else {
            this.setState({
                mediaDisabled: false
            }); 
        }

        // let load;

        // // auto play on audio change
        // this.setState({
        //     lock : true
        // }, () => {
        //     if (this.source) {                   
        //         load = Promise.resolve(this.sourceStop());
        //         load
        //             .then(this.sourceDisconnect())
        //             .then(
        //                 // console.log("AUDIOLOAD TRIGGER")
        //             )
        //             .then(clearInterval(this.currentTimeInterval))
        //             .then(this.audioContext.suspend())
        //             .then(() => {
        //                 if ( 
        //                     this.state.dynamic === true                  && 
        //                     this.state.markerValues.length === 2         ) 
        //                 {
        //                     this.audioLoad(audioFile, this.state.ABLoopStart, this.state.ABLoopDuration);
        //                 } else {
        //                     this.audioLoad(audioFile)
        //                 }
        //             });
        //         return;
        //     } 
        //     this.audioLoad(audioFile);
        // });
    }
    
    componentDidUpdate(prevProps) {
        // console.log("componentDidUpdate")

        let audioFile       = this.props.audioFile;
        
        if (audioFile !== prevProps.audioFile) {
            // console.log("trigger audio load #1");

            if ( audioFile === "" ) {
                this.setState({
                    mediaDisabled: true
                });
            } else {
                this.setState({
                    mediaDisabled: false
                }); 
            }

            // let load;

            // // auto play on audio change
            // this.setState({
            //     lock : true
            // }, () => {
            //     if (this.source) {                   
            //         load = Promise.resolve(this.sourceStop());
            //         load
            //             .then(this.sourceDisconnect())
            //             .then(
            //                 // console.log("AUDIOLOAD TRIGGER")
            //             )
            //             .then(clearInterval(this.currentTimeInterval))
            //             .then(this.audioContext.suspend())
            //             .then(() => {
            //                 if ( 
            //                     this.state.dynamic === true                  && 
            //                     this.state.markerValues.length === 2         ) 
            //                 {
            //                     this.audioLoad(audioFile, this.state.ABLoopStart, this.state.ABLoopDuration);
            //                 } else {
            //                     this.audioLoad(audioFile)
            //                 }
            //             });
            //         return;
            //     } 
            //     this.audioLoad(audioFile);
            // });
        }


        // to trigger pause audio 
        if ( 
            this.props.pauseAudio !== prevProps.pauseAudio && 
            this.props.pauseAudio === true 
        ) {
            // console.log(this.audioContext.state)
            // console.log(this.state.play)
            if ( this.audioContext.state === 'running' && this.state.play ) {
                this.audioPausePlay();
            }
        }
    }

    componentWillUnmount() {
        this.clearBKAudioPlayerStates();
        this.close();
    }


    sourceStop = () => {
        if ( this.source === undefined ) return

        this.source.stop(0)
    }

    sourceDisconnect = () => {
        if ( this.source === undefined ) return

        this.source.disconnect()
    }

    onAudioPlayStateChange = (state) => {
        if ( this.props.onAudioPlayStateChange !== undefined ) {
            this.props.onAudioPlayStateChange(state)
        }
    }

    onAudioEnded = () => {
        if ( this.props.onAudioEnded !== undefined ) {
            this.props.onAudioEnded(true)
        }
    }

    init = () => {
        try{
            // set state 
            this.setState({
                init: true
            });
            // Fix up for prefixing
            var AudioContext = window.AudioContext || window.webkitAudioContext;
            this.audioContext = new AudioContext();
        }
        catch(e) {
            console.warn('Web Audio API is not supported in this browser');
        }

    }

    close = () => {
        try {
            this.audioContext.close();
        }
        catch(e) {
            console.warn('Context was not created');
        }
    }

    audioLoad = (audioFile, startValue = 0, endValue = 0) => {
        // console.log("audioLoad");
        
        // Abort the fetch if there is another one
        if (this.state.onFetch === true) {
            // console.log("onFetch: " + this.state.onFetch);
            this.setState({
                onFetch: false
            }, () => {
                // Define the AbortController before we sending the signal
                // into our soon to be aborted fetch. 
                this.AbortCon = new window.AbortController();
                this.controller = this.AbortCon;
                this.signal = this.controller.signal;
                this.controller.abort();
            });
            // We need to release the AbortController to make sure that
            // the next fetch process is not going to aborted too. Why we
            // need to do this? it is because of the nature of the AbortConttroler
            // which is resides as part of DOM (or you can say that this is a browser
            // feature, not a language feature).
            this.AbortCon = undefined;
            this.controller = undefined;
            this.signal = undefined;
            return;
        }

        // console.log("SINI GAK")
        // Check if the audioContext is suspended
        this.resumeSuspendedContext();

        // Use Fetch API if you want to read audio source on local file
        FetchBlobCached(audioFile, this.signal)
            .then(response  => {
                this.setState({
                    onFetch: true
                });
                // console.log(response)
                return response.arrayBuffer();
            })
            .then(arrayBuffer => {
                // console.log(arrayBuffer)
                return this.audioContext.decodeAudioData(arrayBuffer);
            })
            .then(audioBuffer => {
                // console.log(audioBuffer.duration);
                
                let offset          = startValue !== 0 ? startValue : 0;
                let playDuration    = endValue === 0 ? audioBuffer.duration - offset : endValue;
                let dynamicStatus   = this.state.dynamic;

                // Create the buffer source
                this.source         = this.audioContext.createBufferSource();
                this.source.buffer  = audioBuffer;
                this.source.onended = () => {this.audioOnEnded(audioFile)};
                this.source.playbackRate.value = this.state.playBackRate;

                // Connect it
                this.source.connect(this.audioContext.destination);

                let play = Promise.resolve (
                    this.source.start(
                        this.audioContext.currentTime + 0.2, 
                        offset, 
                        playDuration
                    )
                );

                // console.log(this.state)
                play
                    .then(this.trackTime());

                this.setState({
                  initPlay: false,
                  play: true,
                  lock: false,
                  finished: false,
                  startOffset: dynamicStatus ? startValue : offset,
                  onFetch: false
                }, () => {
                    this.onAudioPlayStateChange(this.state.play)

                    // console.log("startOffset: " + this.state.startOffset);
                    this.setAudioDuration(audioBuffer.duration);
                });

            })
            .catch(error => {
                this.setState({
                    onFetch: false
                }, () => {
                    // console.log(error);
                    // The same reason from before 
                    this.AbortCon = undefined;
                    this.controller = undefined;
                    this.signal = undefined;
                });
            });
    }
    
    audioOnEnded = (audioFile) => {
        // console.log("AUDIO ENDED");
        // console.log(audioFile)

        this.onAudioEnded()

        // Check if audio state is locked
        if (this.state.lock === true) {
            return;
        }
        // Set state, then callback
        this.setState({
            play: false,
            lock: true,
            finished: true,
        }, () => {
            this.onAudioPlayStateChange(this.state.play)

            let stop = Promise.resolve(clearInterval(this.currentTimeInterval));
            stop
                .then(this.audioContext.suspend())
                .then(() => {
                    if (this.state.loop) {
                        this.audioRestart(audioFile);
                    } else {
                        this.setState({
                            lock: false
                        });
                    }
                })
            
        });              
    }

    audioRestart = (audioFile) => {
        // console.log("audioRestart")
        let restart = Promise.resolve(clearInterval(this.currentTimeInterval));
        restart
            .then(this.sourceStop())
            .then(this.sourceDisconnect())
            .then(this.audioContext.suspend())
            .then(() => {
                this.setSliderValue(0.1)
                
                if (this.state.ABLoopDuration !== 0) {
                    this.audioLoad(audioFile, this.state.ABLoopStart, this.state.ABLoopDuration)
                } else {
                    this.audioLoad(audioFile)
                }
            });
    }

    trackTime = () => {
        // console.log("========================")
        // console.log(this.props.audioFile)

        // First, dispatch the initial value after starting or resuming,
        let track = new Promise((resolve, reject) => {
            resolve(() => {
                let slidervalue = (this.state.startOffset / this.state.duration) * 100 * this.state.playBackRate;
                this.setSliderValue(slidervalue);
                
                // console.log(slidervalue)
            });
        })
        // Then, record the value of slider until the end.
        track
            .then(() => {
                this.currentTimeInterval = setInterval( () => {
                    let startOffset = this.state.startOffset + 0.2;
                    let sliderValue = (startOffset / this.state.duration) *100 * this.state.playBackRate;            
                    if (this.state.loop && (sliderValue > 100)) {
                        // Set the maximum to 100 before the audio ended
                        sliderValue = 100;
                    }
                    this.setState({
                        startOffset: startOffset
                    }, () => {
                        this.setSliderValue(sliderValue)

                        // console.log(sliderValue)
                    });  
                },200);
            });
    }

    audioPausePlay(e) {
        if ( e !== undefined ) {
            e.stopPropagation();
        }
        
        // console.log(this.audioContext.state);
        // console.log(this.state.play);
        
        if (this.audioContext.state  === 'running' && this.state.play ) {
            // console.log("PAUSE")
            this.setState({
                play: false,
            }, () => {
                this.onAudioPlayStateChange(this.state.play)

                let pause = Promise.resolve(clearInterval(this.currentTimeInterval));
                pause
                    .then(this.audioContext.suspend())
            });

        } else {
            // console.log("PLAY")
            if ( this.state.initPlay ) {
                this.audioLoad(this.props.audioFile);

            } else {
                this.setState({
                    play: true
                }, () => { 
                    this.onAudioPlayStateChange(this.state.play)

                    // console.log("loop: "+this.state.loop)
                    // console.log("finished: "+this.state.finished)

                    if(this.state.loop === false && this.state.finished === true) {
                        // console.log("RESTART")
                        this.audioRestart(this.props.audioFile);
                        
                    } else {
                        // console.log("RESUME")
                        this.audioContext.resume()
                            .then(this.trackTime())

                    }
                });
            }
        }

    }

    audioForwardRate() {
        // console.log(this.source.playbackRate.value);
        let playBackNow = this.source.playbackRate.value;
        let playBackVal = (playBackNow >= 2.0) ?
            2.0 : Math.round((playBackNow + 0.2) * 10) / 10;
        this.setState({
            playBackRate : playBackVal
        }, () => {this.source.playbackRate.value = playBackVal});     
    }

    audioBackwardRate() {
        // console.log(this.source.playbackRate.value);
        let playBackNow = this.source.playbackRate.value;
        let playBackVal = ((playBackNow - 0.2) <= 0.4) ?
            0.4 : Math.round((playBackNow - 0.2) * 10) / 10;
        this.setState({
            playBackRate : playBackVal
        }, () => {this.source.playbackRate.value = playBackVal});

    }
    
    audioLoop() {
        let negationState = !this.state.loop;
        this.setState(()=> ({
            loop: negationState
        }));
    }

    audioInitABLoop() {
        // console.log("audioInitABLoop")
        let dynamicVal  = !this.state.dynamic;
        let audioFile   = this.props.audioFile;

        // console.log(dynamicVal)

        if ( dynamicVal === false ) {
            this.setState({
                ABLoopStart: 0,
                ABLoopDuration: 0
            }, () => {
                let load = Promise.resolve(clearInterval(this.currentTimeInterval));
                load
                    .then(this.sourceStop())
                    .then(this.sourceDisconnect())
                    .then(this.audioContext.suspend())
                    .then(this.audioLoad(audioFile));
            });
        }
        this.setDynamicValue(dynamicVal);
    }

    audioPlayABLoop = (markerValues = []) => {
        // console.log("audioPlayABLoop");
        let maxVal          = Math.max(...markerValues);
        let minVal          = Math.min(...markerValues);
        let toPlayValue     = minVal === 0 ? 
                maxVal : 100 - (minVal + (100 - maxVal));
        let playDuration    = this.sliderValueToCurrentTime(toPlayValue);
        let startOffset     = this.sliderValueToCurrentTime(minVal);
        let audioFile       = this.props.audioFile;

        this.setState({
            ABLoopStart: startOffset,
            ABLoopDuration: playDuration,
            startOffset: startOffset
        }, 
            () => {
                let load = Promise.resolve(clearInterval(this.currentTimeInterval));
                load
                    .then(this.sourceStop())
                    .then(this.sourceDisconnect())
                    .then(this.audioContext.suspend())
                    .then(this.audioLoad(audioFile, this.state.ABLoopStart, this.state.ABLoopDuration));                
            });
    }

    audioMove() {
        // console.log("audioMove")
        let startOffset = this.audioContext.currentTime;
        
        // console.log(startOffset)

        let stop = Promise.resolve(clearInterval(this.currentTimeInterval));
        stop
            .then(this.sourceDisconnect())
            .then(()=> {
                this.setState({
                    play: false,
                    startOffset: startOffset
                }, () => {
                    this.onAudioPlayStateChange(this.state.play)
                });
            });
    }

    audioSeek(value) {
        // console.log("audioSeek");
        // console.log(this.state.ABLoopDuration)
        if (this.state.sliderStatus !== 'stop') {
            return;
        }
        
        let toCurrentTime   = this.sliderValueToCurrentTime(value.thumbValue);
        let playDuration    = this.state.ABLoopDuration !== 0 ? 
            this.state.ABLoopDuration - (toCurrentTime - this.state.ABLoopStart) : 0;
        let audioFile       = this.props.audioFile;
        
        let restart = Promise.resolve(clearInterval(this.currentTimeInterval));
        restart
            .then(this.sourceStop())
            .then(this.sourceDisconnect())
            .then(this.audioContext.suspend())
            .then(() => {
                if (this.state.ABLoopDuration !== 0) {                   
                    this.audioLoad(audioFile, toCurrentTime, playDuration);
                } else {
                    this.audioLoad(audioFile, toCurrentTime);
                }
            });
    }
    
    audioSeekForward() {
        // console.log("audioSeekForward")

        let toCurrentTime   = this.sliderValueToCurrentTime(this.state.sliderValue);
        toCurrentTime = ( (toCurrentTime + 2) < this.state.duration ? (toCurrentTime + 2) : this.state.duration );
        
        let audioFile       = this.props.audioFile;

        let load = Promise.resolve(clearInterval(this.currentTimeInterval));
        load
            .then(this.sourceStop())
            .then(this.sourceDisconnect())
            .then(this.audioContext.suspend())
            .then(this.audioLoad(audioFile, toCurrentTime));
    }

    audioSeekBackward() {
        // console.log("audioSeekBackward")
        
        let toCurrentTime   = this.sliderValueToCurrentTime(this.state.sliderValue);
        toCurrentTime = ( (toCurrentTime - 2) < 0 ? 0 : (toCurrentTime - 2) );
        
        let audioFile       = this.props.audioFile;

        let load = Promise.resolve(clearInterval(this.currentTimeInterval));
        load
            .then(this.sourceStop())
            .then(this.sourceDisconnect())
            .then(this.audioContext.suspend())
            .then(this.audioLoad(audioFile, toCurrentTime));
    }

    /** UTILS FUNCTION */

    // This function is used for converting slider's length into the current time
    sliderValueToCurrentTime = (value) => {
        let curr = (value * this.state.duration) / (100);
        return curr;
    }

    // This function is used for checking is there any differences between
    // two arrays.
    arrayIsEqual = (arrayA, arrayB) => {
        const containsAll = (arr1, arr2) =>
            arr2.every(arr2Item => arr1.includes(arr2Item));
        
        return containsAll(arrayA, arrayB) && containsAll(arrayB, arrayA);
    }

    // This function is used for clearing all intervals within component 
    // Broken experimental
    clearAllIntervals = (interval) => {
        // Clear all interval first
        for (var i = 1; i < interval; i++) {
            this.clearInterval(i);
        }
    }

    // This function is used for check whether the audioContext is suspended or not
    resumeSuspendedContext = () => {
        if (this.audioContext.state === 'suspended') {
            try {
                this.audioContext.resume();
            } catch (e) {
                alert('Web Audio API resume error: ' + e);
            }
        } else {
            return false;
        }
    }
    /** */

    timeCalculation = (duration) => {
        
		// Time calculations for days, hours, minutes and seconds
		var days = Math.floor(duration / (60 * 60 * 24));
		var hours = Math.floor((duration % (60 * 60 * 24)) / (60 * 60));
		var minutes = Math.floor((duration % (60 * 60)) / 60);
		var seconds = Math.floor((duration % 60));


		// Display the result in the element with id="live-meeting-timer"
		var time;

		if ( days !== 0 ) {
			if (days < 10) {
				days = "0" + days;
			}

			if (hours < 10) {
				hours = "0" + hours;
			}

			if (minutes < 10) {
				minutes = "0" + minutes;
			}

			if (seconds < 10) {
				seconds = "0" + seconds;
			}

			time = days + ":" + hours + ":" + minutes + ":" + seconds;

		} else {
			if ( hours !== 0 ) {
				if (hours < 10) {
					hours = "0" + hours;
				}
	
				if (minutes < 10) {
					minutes = "0" + minutes;
				}

				if (seconds < 10) {
					seconds = "0" + seconds;
				}
				
				time = hours + ":" + minutes + ":" + seconds;

			} else {
				
				if (minutes < 10) {
					minutes = "0" + minutes;
				}

				if (seconds < 10) {
					seconds = "0" + seconds;
				}
				
				time = minutes + ":" + seconds;

			}
        }
        
        return time;
    }
    

    /************** START - FOR BKAudioSlider *************/
    onBKAudioSliderStateChange = (currentProps, nextProps) => {
        let minVal          = Math.min(...nextProps.markerValues);
        let maxVal          = Math.max(...nextProps.markerValues);
        let sliderVal       = currentProps.sliderValue;
        let dynamicStatus   = currentProps.dynamic;
        
        if (
            ( nextProps.markerValues.length === 2 && 
                this.arrayIsEqual(
                    nextProps.markerValues, 
                    currentProps.markerValues
                ) === false                       ) 
        ) {   
            let abloop = Promise.resolve(clearInterval(this.currentTimeInterval));
            abloop
                .then(this.sourceStop())
                .then(this.sourceDisconnect())
                .then(this.audioContext.suspend())
                .then(this.audioPlayABLoop(nextProps.markerValues));
        
        } else if ((currentProps.sliderStatus !== nextProps.sliderStatus)) {
            // console.log("sliderStatus not the same!");
            
            if (this.state.lock === true) { return; } 
            if (
                (maxVal > 0 && (sliderVal > minVal && sliderVal < maxVal)) ||
                (dynamicStatus === false)
            ) {
                switch(nextProps.sliderStatus) {
                    case 'move' :
                        this.audioMove();
                        break;
                    case 'stop' :
                        this.setState({
                            lock: true
                        }, () => {
                            let seek = Promise.resolve(clearInterval(this.currentTimeInterval));
                            seek
                                .then(this.sourceStop())
                                .then(this.sourceDisconnect())
                                .then(this.audioContext.suspend())
                                .then(this.audioSeek(nextProps));
                        });
                        break;
                    default:
                        // console.log("not gaining any status");
                        break;
                }
            }
        }
    }

    setSliderValue = (value) => {
        const currentProps = this.state;

        this.setState({
            sliderValue: value
        }, () => {
            const nextProps = this.state;

            this.onBKAudioSliderStateChange(currentProps, nextProps)
        })
    };

    setDynamicValue = (value) => {
        const currentProps = this.state;
        
        this.setState({
            dynamic: value
        }, () => {
            const nextProps = this.state;

            this.onBKAudioSliderStateChange(currentProps, nextProps)
        })
    };

    setAudioDuration = (value) => {
        const currentProps = this.state;
        
        this.setState({
            duration: value
        }, () => {
            const nextProps = this.state;

            this.onBKAudioSliderStateChange(currentProps, nextProps)
        })
    };
    
    setAudioMarkersValue = (markerValues) => {
        const currentProps = this.state;
        
        this.setState({
            markerValues: markerValues
        }, () => {
            const nextProps = this.state;

            this.onBKAudioSliderStateChange(currentProps, nextProps)
        })
    };

    setThumbValue = (value) => {
        const currentProps = this.state;
        
        this.setState({
            thumbValue: value,
            sliderStatus: 'stop'
        }, () => {
            const nextProps = this.state;

            this.onBKAudioSliderStateChange(currentProps, nextProps)
        });
    }

    setSliderStatus = (sliderStatus) => {
        const currentProps = this.state;
        
        this.setState({
            sliderStatus: sliderStatus
        }, () => {
            const nextProps = this.state;

            this.onBKAudioSliderStateChange(currentProps, nextProps)
        })
    };

    clearBKAudioPlayerStates = () => {
        const currentProps = this.state;
        
        this.setState({
            sliderValue: 0,
            dynamic: false,
            duration: 0,
            markerValues: [],
            thumbValue: 0,
            sliderStatus: 'continue',
        }, () => {
            const nextProps = this.state;

            this.onBKAudioSliderStateChange(currentProps, nextProps)
        })
    }
    /*************** END - FOR BKAudioSlider **************/


    render() {
        return (
            <div 
                id="audio-player-container" 
                disabled={this.state.mediaDisabled}
                className= { 
                    (
                    this.state.mediaDisabled ? 
                        'media-disabled' :
                        'media-enabled'
                    )
                }    
            >
                <div id="audio-player">
                    <div className="row no-gutters">
                        <div className="col-12">
                            {
                                !this.state.play ? (
                                    <a id="audio-play"
                                        onClick={this.audioPausePlay}
                                    >
                                        { this.props.demo ?
                                            <img 
                                                alt="audio-play-icon"
                                                className="md-36" 
                                                id="audio-play-icon"
                                                // onClick={this.audioPausePlay} 
                                                disabled={this.state.mediaDisabled}
                                                src={playIconDemo}
                                            />
                                            :
                                            <img 
                                                alt="audio-play-icon"
                                                className="md-36" 
                                                id="audio-play-icon"
                                                // onClick={this.audioPausePlay} 
                                                disabled={this.state.mediaDisabled}
                                                src={playIcon}
                                            />
                                        }                                        
                                    </a>
                                ) : (
                                    <a id="audio-play">
                                        {
                                            this.props.demo ?
                                            <img 
                                                alt="audio-play-icon"
                                                className="material-icons md-36" 
                                                id="audio-play-icon" 
                                                onClick={this.audioPausePlay}
                                                disabled={this.state.mediaDisabled}
                                                src={pauseIconDemo}
                                            /> :
                                            <img 
                                                alt="audio-play-icon"
                                                className="material-icons md-36" 
                                                id="audio-play-icon" 
                                                onClick={this.audioPausePlay}
                                                disabled={this.state.mediaDisabled}
                                                src={pauseIcon}
                                            />
                                        }                                        
                                    </a>
                                    )
                            }                            
                            <BKAudioSlider 
                                sliderValue={this.state.sliderValue}
                                dynamic={this.state.dynamic}
                                duration={this.state.duration}
                                markerValues={this.state.markerValues}
                                thumbValue={this.state.thumbValue}
                                sliderStatus={this.state.sliderStatus} 
                                setThumbValue={this.setThumbValue}
                                setSliderStatus={this.setSliderStatus}
                                setAudioMarkersValue={this.setAudioMarkersValue}
                                demo={this.props.demo}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(BKAudioPlayer);
