import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

//FUNCTIONS
import { fetchAuthUpdate } from '../../../../reducers/FetcherReducers';
import { reqProfileData } from '../../../../reducers/AccountManagementReducers';

//Component
import { Button, Form, InputGroup, Spinner } from 'react-bootstrap';
import NotifAlert from '../../NotifAlert/NotifAlert';

//CSS
import '../ModalProfile.scss'

//ASSETS
import eye from '../../../img/eye.svg'
import blind from '../../../img/eye-closed.svg'


export default function ChangePass(props) {
    const dispatch = useDispatch();
    
    const profile_data = useSelector(state => state.AccountManagementReducers.profile_data)
    
    const [loadingSave, setLoadingSave] = useState(false);
    
    const [old_pass, setCurrentPass] = useState("");
    const [new_pass, setPass] = useState("");
    const [confirm_new_pass, setCPass] = useState("");
    const [show_pass, setShowPass] = useState(false);
    const [show_npass, setShowNPass] = useState(false);
    const [show_cpass, setShowCPass] = useState(false);

    const [notifAlertData, setNotifAlertData] = useState(null);

    // const token = useSelector(state => state.AccountManagementReducers.token)

    useEffect(() => {
        return () => {
            setNotifAlertData(null)
        }
    }, []);

    function handleCurrentPassChange(e) {
        setCurrentPass(e.target.value);
    }

    function handlePassChange(e) {
        setPass(e.target.value);
    }

    function handleCPassChange(e) {
        setCPass(e.target.value);
    }

    function showHideCurrent(e) {
        e.preventDefault();
        e.stopPropagation();
        setShowPass(!show_pass);
    }

    function showHideNew(e) {
        e.preventDefault();
        e.stopPropagation();
        setShowNPass(!show_npass);
    }

    function showHideConfirm(e) {
        e.preventDefault();
        e.stopPropagation();
        setShowCPass(!show_cpass);
    }

    function handleSubmit(e) {
        e.preventDefault();
        
        if ( new_pass !== confirm_new_pass ) {
            setNotifAlertData({
                status: "error",
                text: "Password tidak sama",
                timeout: 3000
            })
            return;

        } else if ( old_pass.length < 8 || new_pass.length < 8 || confirm_new_pass.length < 8 ) {
            setNotifAlertData({
                status: "error",
                text: "password too short",
                timeout: 3000
            })
            return;

        }

        setLoadingSave(true);
        
        const auth = profile_data.auth[0];

        const dataAuth = {
            method: auth.method, 
            auth_name: auth.auth_name, 
            new_auth_pass: new_pass, 
            old_auth_pass: old_pass
        };

        dispatch(fetchAuthUpdate(dataAuth))
            .then((res) => {
                setLoadingSave(false);
        
                setNotifAlertData({
                    status: "success",
                    text: "Password updated",
                    timeout: 3000
                })
                
                dispatch(reqProfileData());
            })
            .catch((err) => {
                var error = err.error.toString();
                
                setLoadingSave(false);
        
                setNotifAlertData({
                    status: "error",
                    text: "Update password failed. "+error,
                    timeout: 3000
                })
            })
    };

    return (
        <div className="my-account-main-wrapper">
            { notifAlertData !== null &&
                <NotifAlert 
                    data={notifAlertData}
                />
            }
            <div className="my-account-main-header">
                <h5>Change Password</h5>
                <p>You can change  your password</p>
            </div>
            <div className="my-account-padding">
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="other-form">
                        <Form.Label>Current Password</Form.Label>
                        <InputGroup className="pass-form">
                            <Form.Control value={old_pass} placeholder="type current password" type={show_pass ? "text" : "password"} onChange={handleCurrentPassChange} />
                            <InputGroup.Append className="show-hide">
                                <Button variant="none" onClick={showHideCurrent}>
                                    <img alt="toggle-show-pass" src={show_pass === false ? blind : eye} />
                                </Button>
                            </InputGroup.Append>
                        </InputGroup>
                    </Form.Group>
                    <Form.Group className="other-form">
                        <Form.Label>New Password</Form.Label>
                        <InputGroup className="pass-form">
                            <Form.Control value={new_pass} placeholder="type new password" type={show_npass ? "text" : "password"} onChange={handlePassChange} />
                            <InputGroup.Append className="show-hide">
                                <Button variant="none" onClick={showHideNew}>
                                    <img alt="toggle-show-npass" src={show_npass === false ? blind : eye } />
                                </Button>
                            </InputGroup.Append>
                        </InputGroup>
                    </Form.Group>
                    <Form.Group className="other-form">
                        <Form.Label>Confirm Password</Form.Label>
                        <InputGroup className="pass-form">
                            <Form.Control value={confirm_new_pass} placeholder="type confirm password" type={show_cpass ? "text" : "password"} onChange={handleCPassChange} />
                            <InputGroup.Append className="show-hide">
                                <Button variant="none" onClick={showHideConfirm}>
                                    <img alt="toggle-show-cpass" src={show_cpass === false ? blind : eye} />
                                </Button>
                            </InputGroup.Append>
                        </InputGroup>
                    </Form.Group>
                    <Form.Group>
                        { loadingSave ?
                            <button className="btn-form-profile">
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            </button>
                            :
                            <button disabled={old_pass.length === 0 || new_pass.length === 0 ? true : false} className="btn-form-profile" type="submit">
                                Simpan
                            </button>
                        }
                    </Form.Group>
                </Form>
            </div>
        </div>
    )
}