export const threshold = {
    transcribe_quality  : [
        {label: "good", minValue: 0.8},
        {label: "standard", minValue: 0.6},
        {label: "bad", minValue: 0},
    ],
    word_quality: [
        {label: "good", minValue: 0.8},
        {label: "standard", minValue: 0.6},
        {label: "bad", minValue: 0},
    ]
}