import { Spinner } from 'react-bootstrap';
import './Buttons.scss'

function ButtonPrimaryLoading() {
    return (
        <button className="primary">
            <Spinner animation="border" variant="light" size="sm" />
        </button>
    )
}

export default ButtonPrimaryLoading;