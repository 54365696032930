import React, { Component } from "react";
import { Link } from "react-router-dom";
import store, { history } from "../../../store";

// functions
import { updateRegister } from "../../../reducers/AccountManagementReducers";
import { fetchAuthVerifyRegister } from "../../../reducers/FetcherReducers";

// components
import { Container, Spinner, Row, Col } from "react-bootstrap";
import SlideShow from "../../components/Slideshow/SlideShow"


class VerifyRegister extends Component {
    constructor(props) {
        super(props);
        this.state = {
            notifAlertData: null,

            showSuccess: false,
            isLoading: false,
        };
    }

    componentDidMount() {
        store.dispatch(updateRegister(null));

        const search = this.props.location.search; // should be '?token=<token>'
        const params = new URLSearchParams(search);
        const token = params.get('token'); // bar

        if ( token !== null ) {
            this.verifyToken(token);
        } else {
            history.push("/login")
        }
    }

    // setNotifAlertData = (data) => {
    //     this.setState({
    //         notifAlertData: data
    //     })
    // }

    verifyToken = (token) => {
        const data = {
            token,
        };
    
        store.dispatch(fetchAuthVerifyRegister(data))
            .then(res => {
                this.setState({
                    showSuccess: true
                })
            }).catch(err => {
                var error = err.error.toString();
                
                store.dispatch(updateRegister(
                    {
                        status: "error",
                        error: "Verifikasi registrasi gagal. "+error
                    }
                ));
                history.push("/register");
            });
    }

    VerifyingRegister() {
        return (
            <React.Fragment>
                <div className="form-wrapper" style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                    {/* {this.state.notifAlertData !== null &&
                        <NotifAlert
                            data={this.state.notifAlertData}
                        />
                    } */}
                    {/* <p className="onboard-header">Register to <br />Dikte.in</p>
                    <p className="onboard-span">Verifying Registration</p> */}
                    <p className="onboard-header">Verifying Registration</p>
                    <Spinner 
                        as="span"
                        animation="border"
                        size="lg"
                        role="status"
                        aria-hidden="true"
                    />
                </div>
            </React.Fragment>
        )
    }

    RegisterSuccess() {
        return (
            <React.Fragment>
                {/* <img className="button-back" src={close} alt="icon-back" onClick={() => this.props.history.push("/")} /> */}
                {/* <div className="onboard-wrapper onboard-form"> */}
                <div className="form-wrapper">
                    <div className="onboard-img-sent">
                        {/* <img src={sent} alt="sent-email-image"/> */}
                    </div>
                    <p className="onboard-header">Register success!</p>
                    <p className="onboard-header-span">
                        Let's sign in to continue to Minit
                    </p>

                    <form>
                        <Link to="/login">
                            <button type="submit" className="submit-button-onboard" id="submit">
                                sign in
                            </button>
                        </Link>
                    </form>
                </div>
                {/* </div> */}
            </React.Fragment>
        )

    }

    render() {
        return (
            <React.Fragment>
                <Container fluid className="no-padding">
                    <Row className="onboard-container">
                        <Col sm={5} className="onboard-form">
                            { !this.state.showSuccess ? 
                                this.VerifyingRegister()
                                :
                                this.RegisterSuccess()
                            }

                        </Col>
                        <Col sm={7} className="onboard-slider">
                            <div className="slider-wrapper">
                                <SlideShow />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </React.Fragment>
        )
    }
}

export default VerifyRegister
