import $ from 'jquery'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { useKeycloak } from "@react-keycloak/web";
import store from '../../../store'

//functions
import { clearCookie, clearLocalStorage, get_empty_avatar } from '../../../utils/functions'
import { fetchAuthLogout } from '../../../reducers/FetcherReducers'
import { toastNotification } from '../ToastNotification/ToastNotification'

//components
import { Row, Col, Dropdown, Nav, Badge } from 'react-bootstrap'
import ModalUser from '../ModalProfile/UserAndGroup/ModalUser'
import ModalProfile from '../ModalProfile/Account/ModalAccount'
import ModalKeywordMaster from '../ModalProfile/KeywordMaster/ModalKeywordMaster'
import ModalFilterTranscriptListGlobal from '../ModalFilterTranscriptListGlobal/ModalFilterTranscriptListGlobal'
import AvailableQuota from './AvailableQuota';

//style
import './NavStyle.scss'

//assets
import down from '../../img/expand-more.svg'
import search from '../../img/search-black.svg';
import filter from '../../img/filter.svg';


function DashboardNavbar(props) {
    const { 
        searchTranscriptGlobally,
        latRange,
        lngRange,
        lobRange,
        selectedLatRange,
        selectedLngRange,
        selectedLobRange,
        onSaveFilterTranscriptGlobally
    } = props;

	const token = useSelector(state => state.AccountManagementReducers.token)
    const profile_data = useSelector(state => state.AccountManagementReducers.profile_data)
    
    const [theme, setTheme] = useState('light');

    const [modalShow, setModalShow] = useState(false);
    const [modalUserShow, setModalUserShow] = useState(false);
    const [modalKeywordShow, setKeywordShow] = useState(false);

    const [showFilterTranscriptGlobally, setShowFilterTranscriptGlobally] = useState(false);
    const [cSearchTranscriptGlobally, setCSearchTranscriptGlobally] = useState("");

	const { keycloak } = useKeycloak();

    let email = "";
    var profPic = get_empty_avatar();
    if ( profile_data !== null && profile_data !== undefined ) {
        email = profile_data.auth[0].auth_name;

        var avatar = profile_data.actor[0].avatar;
        if ( avatar !==  null && avatar !== undefined && avatar.replace(/\s+/g, '') !== "" ) { 
            profPic = profile_data.actor[0].avatar;
        }
    }
    // console.log(profile_data)

    useEffect(() => {
        // render theme color
        const lcTheme = localStorage.getItem('theme');
        if ( lcTheme === 'dark' || lcTheme === 'light' ) {
            setTheme(lcTheme)
        }
    }, []);

    useEffect(() => {
        // update cSearchTranscriptGlobally on mount
        setCSearchTranscriptGlobally(searchTranscriptGlobally);        
    }, [searchTranscriptGlobally]);

    useEffect(() => {
        $('body').removeClass('dark');
        $('body').removeClass('light');

        $('body').addClass(theme);

        localStorage.setItem('theme', theme);
    
    }, [theme]);

    const onLogout = () => {
        const data = { token: token }

        store.dispatch(fetchAuthLogout(data))
            .then((res) => {
                localStorage.removeItem("token");
                window.location.reload();

            }).catch((err) => {
                var error = err.error.toString();
                
                toastNotification('error', 'Logout gagal. '+error)
            });

        keycloak.logout();

        clearCookie();
        clearLocalStorage();
    }

    const switchTheme = () => {
        if ( theme === 'light' ) {
            setTheme('dark')
        } else {
            setTheme('light')
        }
    }

    function FilterTranscriptGlobally() {
        var filter_active = false;
        if ( 
			searchTranscriptGlobally.replace(/\s+/g, '') !== ""                         ||
            JSON.stringify(selectedLatRange)             !== JSON.stringify(latRange)   ||
            JSON.stringify(selectedLngRange)             !== JSON.stringify(lngRange)   ||
            JSON.stringify(selectedLobRange)             !== JSON.stringify(lobRange)
        ) {
            filter_active = true;
        }

        const handleKeyDown = (e) => {
            if (e.key === 'Enter' ) {
                onSaveFilterTranscriptGlobally(
                    cSearchTranscriptGlobally, 
                    selectedLatRange,
                    selectedLngRange,
                    selectedLobRange
                )
            }
        }

        return (
            <div className="dashboard-tool">
                <ModalFilterTranscriptListGlobal
                    {...props}
                    show={showFilterTranscriptGlobally}
                    onHide={() => setShowFilterTranscriptGlobally(false)}
                />

                <div 
                    className={"filter-container " + (filter_active ? "active" : "")} 
                    onClick={() => setShowFilterTranscriptGlobally(true)}
                >
                    <img src={filter} alt="icon-filter" />
                </div>
                <div 
                    id="search-container" 
                    style={{margin: "auto 14px"}}
                >
                    <img src={search} className="icon" alt="search" />
                    <input 
                        placeholder="Search Transcript" 
                        value={cSearchTranscriptGlobally} 
                        onKeyDown={handleKeyDown}
                        onChange={(e) => setCSearchTranscriptGlobally(e.target.value)} 
                    />
                </div>
            </div>
        )
    }

    return(
        <>
            <Row className="nav-container">
                <Col className="col-max-1564">
                
                <div className="nav-cont">
                    <div className="logo">{process.env.REACT_APP_NAME}</div>

                    { process.env.REACT_APP_TYPE !== "PANORAMA" &&
                        <AvailableQuota />
                    }

                    <div className="detail-profile">
                        <Nav className="nav-detail">
                                                         
                            {/* { process.env.REACT_APP_TYPE === "PANORAMA" &&
                                FilterTranscriptGlobally()
                            } */}
                            
                            <Dropdown alignRight>
                                <Dropdown.Toggle variant="success" id="dropdown-basic" as="div">
                                    <>
                                        <img src={profPic} id="ava" alt="profile-user"/>
                                        <img src={down} id="arrow" alt="expand"/>
                                    </>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {/* { process.env.REACT_APP_TYPE !== "PANORAMA" &&
                                        <Dropdown.Item onClick={()=> setModalShow(true)}>Account</Dropdown.Item> 
                                    } */}
                                    <Dropdown.Header>
                                        <div>{email}</div>
                                        {/* <a onClick={()=> setModalShow(true)}>Edit Account</a> */}
                                        <a href={window.link.webconsole_account} target="_blank" rel="noopener noreferrer">Edit Account</a>
                                    </Dropdown.Header>
                                    <Dropdown.Divider />
                                    <Dropdown.Item onClick={()=> setModalUserShow(true)}>User</Dropdown.Item>
                                    <Dropdown.Item onClick={()=> setKeywordShow(true)}>Keyword Master</Dropdown.Item>
                                    <Dropdown.Item onClick={()=> switchTheme()}>
                                        Switch to {theme === "light" ? "dark" : "light"} theme 
                                        {/* { theme === "light" &&  */}
                                            <Badge variant="danger" style={{marginLeft: "5px"}}>beta</Badge>
                                        {/* } */}
                                    </Dropdown.Item>
                                    { process.env.REACT_APP_TYPE !== "PANORAMA" &&
                                        <>
                                            <Dropdown.Divider />
                                            <Dropdown.Item onClick={onLogout}>Log out</Dropdown.Item>
                                        </>
                                    }
                                </Dropdown.Menu>
                            </Dropdown>
                        </Nav>
                    </div>
                </div>
                </Col>
            </Row>
            <ModalProfile show={modalShow} handleClick={() => { setModalShow(false) }} />
            <ModalUser show={modalUserShow} handleClick={() => { setModalUserShow(false) }} />
            <ModalKeywordMaster show={modalKeywordShow} handleClick={() => setKeywordShow(false)}/>
        </>                
    )
}
export default DashboardNavbar;