import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import store from "../../../store";

//functions
import { get_random_uuid } from '../../../utils/functions'
import { updateIdToken } from "../../../reducers/AccountManagementReducers";
import { fetchAuthLogin } from "../../../reducers/FetcherReducers";

//components
import { Container, Spinner, Row, Col } from "react-bootstrap";
import SlideShow from "../../components/Slideshow/SlideShow";
import NotifAlert from "../../components/NotifAlert/NotifAlert";

//css
import './Landing.scss'

//assets
import eye from "../../img/eye.svg";
import eyeoff from "../../img/eye-closed.svg";
import google from "../../img/gmail.svg"


function Login(props) {    
    const [notifAlertData, setNotifAlertData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [type, setType] = useState("password");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

	const { keycloak } = useKeycloak();

    const textInput = useRef();
    const passInput = useRef();

    useEffect(() => {
        keycloak.login();
    }, [])

    const updateNotifAlertData = (status, text, timeout) => {
        var data = null;

        if ( status !== null ) {
            data = {
                status: status,
                text: text,
                timeout: timeout
            }
        }

        setNotifAlertData(data)
    }

    const focus = () => {
        // Explicitly focus the text input using the raw DOM API
        // Note: we're accessing "current" to get the DOM node
        textInput.current.focus();
    }

    const pfocus = () => {
        // Explicitly focus the text input using the raw DOM API
        // Note: we're accessing "current" to get the DOM node
        passInput.current.focus();
    }

    const showHide = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const newType = (type === "text" ? "password" : "text");
        setType(newType) 
        
    }

    const handleEmailChange = ({ target }) => {
        setIsLoading(false);
        updateNotifAlertData(null);

        setEmail(target.value);
    };

    const onBlurEmail = ({ target }) => {
        var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([a-zA-Z0-9-\\.]+)$/;
        if ( reg.test(target.value) === false ) {
            updateNotifAlertData("error", "Invalid Email Address", -1)
        }
    }

    const handlePasswordChange = ({ target }) => {
        setIsLoading(false);
        updateNotifAlertData(null);

        setPassword(target.value);
    };

    const handleKeyDown = (e) => {
        //block if input is space
        if (e.key === " ") {
          e.preventDefault();
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        
        // check email
        var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([a-zA-Z0-9-\\.]+)$/;
        if ( reg.test(email) === false ) {   // alert('Invalid Email Address');
            setIsLoading(false);
            updateNotifAlertData("error", "alamat email tidak sesuai", -1)

            return
        }   
        
        // check password
        if ( password.length < 8 ) {   // alert('Invalid Email Address');
            setIsLoading(false);            
            updateNotifAlertData("error", "password too short", -1)

            return
        }        
        
        let auth_name = email;
        let auth_password = password;
        let auth_method = 1;
        let req_id = get_random_uuid();
        
        setIsLoading(true);
        updateNotifAlertData(null)

        const data = {
            auth_name,
            auth_password,
            auth_method,
            req_id
        };

        store.dispatch(fetchAuthLogin(data))
            .then((res) => {
                const token = res.token;

                store.dispatch(updateIdToken(token));
                
                localStorage.setItem("keycloakToken", JSON.stringify(res.keycloakToken))
                    
                // history.push("/")
                window.location.reload()

            }).catch((err) => {
                var error = err.error.toString();
                if ( error.toLowerCase() === "unauthorized" ) {
                    error = "email atau password salah"   
                }

                updateNotifAlertData("error", error, -1)
                
                setIsLoading(false);
    
            });

    }

    return null;

    return (
        <React.Fragment>
            <Container fluid className="no-padding">
                <Row className="onboard-container">
                    <Col sm={5} className="onboard-form">
                        
                        <NotifAlert 
                            data={notifAlertData}
                        />

                        <div className="form-wrapper">
                            <p style={{ marginBottom: "70px" }} className="onboard-header">Sign in to <br />Dikte.in</p>
                                        
                            <>
                                <p className="onboard-span">
                                </p>
                                <div className="app-signin">
                                    <button 
                                        onClick={() => keycloak.login({
                                            idpHint: 'google',
                                            redirectUri: window.location.origin+"/redirector?"
                                        })}
                                    >
                                        <img src={google}/> Lanjutkan dengan Google
                                    </button>
                                </div>
                            </>
                
                            <p className="onboard-span">atau</p>

                            <form onSubmit={handleSubmit}>
                                <React.Fragment>
                                    <div className="form-input-onboard">
                                        <input
                                            alt="input-email"
                                            ref={textInput}
                                            type="text"
                                            id="email"
                                            placeholder=" "
                                            onChange={handleEmailChange}
                                            onBlur={onBlurEmail}
                                            onKeyDown={handleKeyDown}
                                        />
                                        <label onClick={focus} >Email</label>
                                    </div>
                                    <div className="form-input-onboard">
                                        <input
                                            alt="input-password"
                                            ref={passInput}
                                            type={type}
                                            id="password"
                                            placeholder=" "
                                            onChange={handlePasswordChange} 
                                            onKeyDown={handleKeyDown}
                                        />
                                        <label onClick={pfocus}>Password</label>
                                        <span className="password-show" onClick={showHide}>
                                            <img src={type === "text" ? eye : eyeoff} className="" alt="toggle-show-password" />
                                        </span>
                                    </div>
                                </React.Fragment>
                                <div className="form-input-onboard forgot-pass">
                                    <Link to="/forgot-password">Forgot Password?</Link>
                                </div>
                                <button type="submit" className={email.length !== 0 && password.length !== 0 ? "submit-button-onboard" : "submit-button-onboard disabled"} id="submit" disabled={email.length !== 0 && password.length !== 0 ? false : true}>
                                    {
                                        (isLoading) ?
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                variant="light"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            /> :
                                            "Sign in"
                                    }
                                </button>
                            </form>
                            <p className="onboard-span">Don't have an account? <Link to="/register">Get started FREE</Link></p>
                        </div>
                    </Col>
                    <Col sm={7} className="onboard-slider">

                        <div className="slider-wrapper">
                            <SlideShow />
                        </div>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    )
}

export default Login;

