import { useState, useEffect } from 'react'
import styled from 'styled-components'

// Functions
import { useDispatch, useSelector } from 'react-redux'
import { reqAllModelAsrList } from '../../../reducers/ModelAsrReducers'
import { updateIsUploading, updateListFileUpload, updateUploadingError, updateUploadingProgress } from '../../../reducers/RecordReducers'
import { fetchRecordAddFile } from '../../../reducers/FetcherReducers'
import { handlerParticipantAdd } from '../../../reducers/SpeakerAndParticipantReducers'

//component
import { Modal } from 'react-bootstrap'
import NotifAlert from '../NotifAlert/NotifAlert'
import DropFile from '../DropFile/DropFile'
import FormSelectUserAndGroup from '../FormSelectUserAndGroup/FormSelectUserAndGroup'

//style
import './CreateRecord.scss'

//assets
// import x from '../../img/x-close.svg'
import x from '../../img/x-cancel.svg'
import DropdownLanguageList from './DropdownLanguageList'


const Button = styled.button`
    border-radius: 3px;
    font-weight: 700;
    padding: 13px 30px;
    border-radius: 8px;
    margin-left: auto;
    margin-top: auto;
    font-size: 18px;
    width: 145px 
`;

function ModalAddNewFileToRecord(props) {
    const { id_record } = props;

    const dispatch = useDispatch();
    
    const recordDetails = useSelector(state => state.RecordReducers.recordDetails)

    const [notifAlertData, setNotifAlertData] = useState(null);

    const [attachment, setAttachment] = useState([]);
    const [selectedLanguage, setSelectedLanguage] = useState("");
    const [selectedUsersAndGroups, setSelectedUsersAndGroups] = useState([]);

    useEffect(() => {
        if ( !props.show ) {
            resetStates()
        } else {
            // dispatch(reqAllModelAsrList())
        }
    }, [props.show]);

    const resetStates = () => {
        setNotifAlertData(null);
        
        setAttachment([])
        setSelectedUsersAndGroups([]); 
    }

    const handleSelectedLanguageChange = (language) => {
        setSelectedLanguage(language);
    }
    
    const onSelectedUsersAndGroupsChange = (listData) => {
        // console.log(listData)
        setSelectedUsersAndGroups(listData)
    }

    const onUploadClick = async () => {
        if ( attachment.length === 0 ) {
            setNotifAlertData({
                status: "error",
                text: "Please select at least 1 attachment",
                timeout: 3000
            })
            return

        } 
        // else if ( attachment.findIndex(({model}) => model === 0) > -1 ) {
        //     setNotifAlertData({
        //         status: "error",
        //         text: "Please select file type",
        //         timeout: 3000
        //     })
        //     return
            
        // }
    
        const data = {
            id_record       : id_record,
            audio           : attachment.map(({file, model}) => {return({file: file.name, model: model})}),
            files           : attachment.map(({file}) => {return({file: file})}),
            use_translate   : recordDetails.translation,
            use_transcribe  : recordDetails.autoTranscribe,
            select_language : selectedLanguage
        }

        dispatch(updateIsUploading(true))
        dispatch(updateUploadingError(null))
        dispatch(updateUploadingProgress(0))
        dispatch(updateListFileUpload(data.files))

        // close modal
        props.onHide()

        var add_file_status;

        const progressCallback = (progress) => {
            dispatch(updateUploadingProgress(progress))
        }
        
        // fetch upload document
        await dispatch(fetchRecordAddFile(data, progressCallback))
            .then((res) => {
                // console.log(res)
                add_file_status = "success";

                dispatch(updateIsUploading(false))

                props.setNotifAlertData({
                    status: "success",
                    text: "Berhasil mengunggah file",
                    timeout: 3000
                })
            })
            .catch((err) => {
                var error = err.error.toString();

                add_file_status = "error";

                dispatch(updateUploadingError(error))

                props.setNotifAlertData({
                    status: "error",
                    text: "Gagal mengunggah file. "+error,
                    timeout: 3000
                })
            })
            
        // console.log(new_record)
        
        // add users & participants to record if add file success
        if ( add_file_status === "success" ) {
            dispatch(handlerParticipantAdd(id_record, selectedUsersAndGroups));
        }

    }

    return (
        <Modal
            {...props}
            size="md"
            centered
            className="modal-ct"
        >
            <div id="ct-container">
                { notifAlertData !== null &&
                    <NotifAlert 
                        data={notifAlertData}
                    />
                }
                <div className="ct-top">
                    <h5>Upload New File</h5>
                    <img src={x} alt="close" id="close" onClick={props.onHide} />
                </div>
                <>
                    <DropFile onFileSelected={(files) => setAttachment(files)} />
                </>
                <>
                    <div className="label-wrap">
                        <label>Transcript To</label>
                    </div>
                    <DropdownLanguageList onChange={handleSelectedLanguageChange} />
                </>
                <>
                    <label>Partisipan</label>            
                    <FormSelectUserAndGroup 
                        onSelectedUsersAndGroupsChange={onSelectedUsersAndGroupsChange}
                    />
                </>
                {/* <div className="ct-check">
                    <Form.Check
                        required
                        name="auto transkrip"
                        label="Auto transkrip when done"
                        id="autotranskrip"
                    />
                </div> */}
                <Button className="primary" onClick={onUploadClick}>
                    Upload
                </Button>
            </div>
        </Modal>
    );
}

export default ModalAddNewFileToRecord;