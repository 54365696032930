import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import store from "../../../store";

// functions
import { get_random_uuid } from "../../../utils/functions";
import { fetchAuthRegister } from "../../../reducers/FetcherReducers";
import { updateRegister } from "../../../reducers/AccountManagementReducers";

// components
import { Container, Spinner, Row, Col } from "react-bootstrap";
import SlideShow from "../../components/Slideshow/SlideShow"
import NotifAlert from "../../components/NotifAlert/NotifAlert";

// etc
import eye from "../../img/eye.svg"
import eyeoff from "../../img/eye-closed.svg"
import sent from "../../img/email-sent.svg"


class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: "password",
            username: "",
            email: "",
            password: "",
            showRegister: true,
            isLoading: false,
            
            showRegisterSuccess: false,

            notifAlertData: null
        };
        this.showHide = this.showHide.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleUsernameChange = this.handleUsernameChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onBlurEmail = this.onBlurEmail.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.textInput = React.createRef();
        this.focus = this.focus.bind(this);
        this.passInput = React.createRef();
        this.pfocus = this.pfocus.bind(this);
        this.unameInput = React.createRef();
        this.ufocus = this.ufocus.bind(this);
    }

    componentDidMount() {
        // console.log(this.props.reg_status);
        if (this.props.reg_status !== null && this.props.reg_status !== undefined) {
            if (this.props.reg_status.status === "error") {
                this.setState({
                    notifAlertData: {
                        status: "error",
                        text: "Registration Failed. "+this.props.reg_status.error,
                        timeout: 9999999
                    }
                })
            }     
        }       
    }

    componentWillUnmount() {
        this.setNotifAlertData(null)

        store.dispatch(updateRegister(null));
    }

    setNotifAlertData = (data) => {
        this.setState({
            notifAlertData: data
        })        
    }

    focus() {
        // Explicitly focus the text input using the raw DOM API
        // Note: we're accessing "current" to get the DOM node
        this.textInput.current.focus();       
    }

    pfocus() {
        this.passInput.current.focus();
    }

    ufocus() {
        this.unameInput.current.focus();
    }

    showHide(e) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            type: this.state.type === "text" ? "password" : "text"
        });
    }

    onBlurEmail = ({ target }) => {
        var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([a-zA-Z0-9-\\.]+)$/;
        if ( reg.test(target.value) === false ) { 
            this.setNotifAlertData({
                status: "error",
                text: "Invalid Email Address",
                timeout: -1
            })
        }
    }
    handleEmailChange = ({ target }) => {    
        this.setState({
            isLoading: false,
        });
        this.setNotifAlertData(null)

        this.setState({ email: target.value });
    };

    handlePasswordChange = ({ target }) => {
        this.setState({
            isLoading: false,
        });
        this.setNotifAlertData(null)

        this.setState({ password: target.value });
    };

    handleUsernameChange = ({ target }) => {
        const username = target.value.replace(/\s+/g, '').toLowerCase();

        this.setNotifAlertData(null)

        this.setState({
            isLoading: false,
            username: username 
        }, () => {
            this.validateUsername();
        });
    };

    
    validateUsername = () => {
        /* 
            Usernames can only have: 
            - Lowercase Letters (a-z) 
            - Numbers (0-9)
            - Dots (.)
            - Underscores (_)
        */
        const res = /^[a-z0-9_\.]+$/.exec(this.state.username);
        const valid = !!res;
        // return valid;

        if ( !valid ) {   
            // alert('Invalid Email Address');
            
            this.setNotifAlertData({
                status: "error",
                text: "Usernames can only have: Lowercase Letters (a-z), Numbers (0-9), Dots (.), Underscores (_)",
                timeout: -1
            })

            return false;
        }

        return true;
    }

    handleKeyDown = (e) => {
        //block if input is space
        if (e.key === " ") {
          e.preventDefault();
        }
    };

    handleSubmit = (e) => {
        e.preventDefault();

        // check fullname
        if ( this.state.username.length < 2 ) {
            this.setState({
                isLoading: false,
            });
            
            this.setNotifAlertData({
                status: "error",
                text: "Full name too short",
                timeout: -1
            })

            return

        } else if ( this.state.username.length > 20 ) {
            this.setState({
                isLoading: false,
            });
            
            this.setNotifAlertData({
                status: "error",
                text: "Full name too long",
                timeout: -1
            })

            return
        }

        // check email
        var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([a-zA-Z0-9-\\.]+)$/;
        if ( reg.test(this.state.email) === false ) {   // alert('Invalid Email Address');
            this.setState({
                isLoading: false,
            });
            
            this.setNotifAlertData({
                status: "error",
                text: "alamat email tidak sesuai",
                timeout: -1
            })

            return
        }
        
        // check password
        if ( this.state.password.length < 8 ) {   // alert('Invalid Email Address');
            this.setState({
                isLoading: false,
            });
            
            this.setNotifAlertData({
                status: "error",
                text: "password too short",
                timeout: -1
            })
            return
        }

        let username = this.state.username;
        let auth_name = this.state.email;
        let method = 1;
        let auth_pass = this.state.password;
        let req_id = get_random_uuid();
        
        this.setState({
            isLoading: true,
        });

        this.setNotifAlertData(null)

        const data = {
            username,
            method,
            auth_name,
            auth_pass,
            req_id
        };
        
        store.dispatch(fetchAuthRegister(data))
            .then(result => {
                if (result.status === "success") {
                    this.setState({
                        isLoading: false,
                        showRegisterSuccess: true
                    })
                } 

            }).catch(err => {
                var error = err.error.toString();
                                
                this.setState({
                    isLoading: false,
                });
                
                this.setNotifAlertData({
                    status: "error",
                    text: "Registrasi gagal. "+error,
                    timeout: -1
                })
                
            });
        
    }

    RegisterForm() {
        let showRegister = this.state.showRegister;

        if (showRegister) {
            return (
                <React.Fragment>
                    <div className="form-wrapper">
                        
                        <NotifAlert 
                            data={this.state.notifAlertData}
                        />

                        <p className="onboard-header">Register to <br />Dikte.in</p>
                        {/* <p className="onboard-span">Buat Akun Anda</p> */}
                        <form onSubmit={this.handleSubmit}>
                            <div className="form-input-onboard">
                                <input
                                    alt="input-username"
                                    ref={this.unameInput}
                                    type="text"
                                    id="username"
                                    placeholder=" "
                                    value={this.state.username}
                                    onChange={this.handleUsernameChange}
                                    // onKeyDown={this.handleKeyDown}
                                />
                                {/* <label onClick={this.ufocus}>Full name</label> */}
                                <label onClick={this.ufocus}>Username</label>
                            </div>
                            <div className="form-input-onboard">
                                <input
                                    alt="input-email"
                                    ref={this.textInput}
                                    type="email"
                                    id="email"
                                    placeholder=" "
                                    onBlur={this.onBlurEmail}
                                    onChange={this.handleEmailChange}
                                    onKeyDown={this.handleKeyDown}
                                />
                                <label onClick={this.focus}>Email</label>
                            </div>
                            <div className="form-input-onboard">
                                <input
                                    alt="input-password"
                                    ref={this.passInput}
                                    type={this.state.type}
                                    id="password"
                                    placeholder=" "
                                    onChange={this.handlePasswordChange} 
                                    onKeyDown={this.handleKeyDown}
                                />
                                <label onClick={this.pfocus}>Password</label>
                                <span className="password-show" onClick={this.showHide}>
                                    <img src={this.state.type === "text" ? eye : eyeoff} className="" alt="toggle-show-password" />
                                </span>
                            </div>

                            <button type="submit" 
                                className="submit-button-onboard" 
                                id="submit" 
                                disabled={ 
                                    this.state.email.length !== 0 && 
                                    this.state.password.length !== 0 && 
                                    this.state.username.length !== 0 ? false 
                                    : true 
                                }
                            >
                                {
                                    (this.state.isLoading) ?
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            variant="light"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        /> :
                                        "Create Account"
                                }
                            </button>
                        </form>
                        <p className="onboard-span-tns">By signing up, I agree to <Link to="/">the Privacy Policy and Terms of Service.</Link></p>
                        <p className="onboard-span">Have an Account? <Link to="/login">Sign in</Link></p>
                    </div>
                </React.Fragment>
            )
        }
    }

    RegisterSuccess() {
        return (
            <React.Fragment>
                {/* <img className="button-back" src={close} alt="icon-back" onClick={() => this.props.history.push("/")} /> */}
                <div className="form-wrapper">
                    <div className="onboard-img-sent"><img src={sent} alt="sent-email-image" /></div>
                    <p className="onboard-header">Register success!</p>
                    <p className="onboard-header-span">
                        An email has been sent. Let’s check your inbox and click in the received link to verify your account.
                    </p>
                    {/* <form onSubmit={this.handleSubmit}>
                        <Link to="/">
                            <button type="submit" className="submit-button-onboard" id="submit">
                                Check email
                            </button>
                        </Link>
                    </form> */}
                </div>
            </React.Fragment>
        )
    }

    render() {
        return (
            <React.Fragment>
                <Container fluid className="no-padding">
                    <Row className="onboard-container">
                        <Col sm={5} className="onboard-form">
                            { this.state.showRegisterSuccess ? 
                                this.RegisterSuccess() 
                                : 
                                this.RegisterForm()
                            }
                        </Col>
                        <Col sm={7} className="onboard-slider">
                            <div className="slider-wrapper">
                                <SlideShow />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        reg_status: state.AccountManagementReducers.reg_status
    };
}

export default connect(mapStateToProps)(Register);
