import { toastNotification } from "../apps/components/ToastNotification/ToastNotification";

/** 
Declare the type of action as constant.
WHY?
    - Help reduce typos 
    - Help reduce bugs and mistake
    - If you make typo and dispatch an undefined constants,
      the app will throw error to alert the mistake. 
*/

// Import

import store from '../store';
import { fetchConsoleGetDikteinQuota } from "./FetcherReducers";

// Functions

// Action Types
const UPDATE_AVAILABLE_QUOTA_DATA = "QuotaReducers/UPDATE_AVAILABLE_QUOTA_DATA";

// Action Creators
/* 
 *  !LEGEND! 
 *      req = request
 *      rcv = receive
 *      fch = fetch
 *      snd = send
 */


// Actions


// You can put impure functions within your action creators
export const updateAvailableQuotaData = data => ({
    type: UPDATE_AVAILABLE_QUOTA_DATA,
    payload: {
        available_quota: data
    }

})


//reducers
export const reqAvailableQuotaData = () => dispatch => {
    // Put outgoing requester here  
    dispatch(fetchConsoleGetDikteinQuota())
        .then((kuota) => {
            store.dispatch(rcvAvailableQuotaData({
                status: "success",
                kuota: kuota
            }));
        })
        .catch((err) => {
            // console.log(err)
            store.dispatch(rcvAvailableQuotaData(err));
            return;
        })  
};

export const rcvAvailableQuotaData = (msg) => (dispatch) => {

    if ( msg.status === "failed" ) {
        toastNotification("error", "Gagal memuat data kuota. "+msg.error)
        return;
    }

    dispatch(updateAvailableQuotaData(msg.kuota))
}

// Reducer's initial state
const initialState = {
    available_quota: null
};


// Reducers

// You must only write pure function when trying to build the reducer! 

export default function QuotaReducers(state = initialState, action) {
    switch (action.type) {
        case UPDATE_AVAILABLE_QUOTA_DATA:
            return {
                ...state,
                available_quota: action.payload.available_quota
            }
        default:
            return state;
    }
}



// Side effects, only as applicable
// e.g. thunks,epics, etc