import React, { Component } from "react"
import store, { history } from "../../../store";

//functions
import { fetchAuthVerifyPassword } from "../../../reducers/FetcherReducers";

// components
import { Container, Row, Col, Spinner } from "react-bootstrap";
import SlideShow from "../../components/Slideshow/SlideShow"
import NotifAlert from "../../components/NotifAlert/NotifAlert"

//assets
import eye from "../../img/eye.svg"
import eyeoff from "../../img/eye-closed.svg"


class NewPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingSave: false,

            type: "password",
            password: "",
            typeConfirm: "password",
            passwordConfirm: "",
            
            notifAlertData: null,
            token: "",            
        };
        this.passInput = React.createRef();
        this.passInputConfirm = React.createRef();

        this.redirectInterval = null;
    }

    componentDidMount() {
        const search = this.props.location.search; // should be '?token=<token>'
        const params = new URLSearchParams(search);
        const token = params.get('token'); // bar

        if ( token !== null ) {
            this.setState({
                token: token
            })
        } else {
            history.push("/forgot-password")
        }
    }

    componentWillUnmount() {
        window.clearInterval(this.redirectInterval);
        this.redirectInterval = null;

        this.setNotifAlertData(null)
    }

    showHide = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            type: this.state.type === "text" ? "password" : "text"
        });
    }

    showHideConfirm = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            typeConfirm: this.state.typeConfirm === "text" ? "password" : "text"
        });
    }

    handlePasswordChange = ({ target }) => {
        this.setState({ 
            password: target.value 
        });

        if (target.value.length > 0) {
            this.setNotifAlertData(null)
        }
    };

    handlePasswordConfirmChange = ({ target }) => {
        this.setState({ 
            passwordConfirm: target.value 
        });

        if (target.value.length > 0) {
            this.setNotifAlertData(null)
        }
    };

    setNotifAlertData = (data) => {
        this.setState({
            notifAlertData: data
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();

        if ( this.state.password !== this.state.passwordConfirm ) {
            this.setNotifAlertData({
                status: "error",
                text: "Password tidak sama",
                timeout: 3000
            })
            return;

        } else if ( this.state.password.length < 8 || this.state.passwordConfirm.length < 8 ) {
            this.setNotifAlertData({
                status: "error",
                text: "password too short",
                timeout: 3000
            })
            return;

        }

        this.setState({
            loadingSave: true
        })

        let token = this.state.token;
        let password = this.state.password;
        
        const data = {
            token,
            password,
        };
    
        store.dispatch(fetchAuthVerifyPassword(data))
            .then(res => {
                this.setState({
                    loadingSave: false
                })

                var count = 3;
                this.setNotifAlertData({
                    status: "success",
                    text: "Password updated. You wil be redirected to login page in "+count+"s",
                    timeout: 3000
                })
                this.redirectInterval = window.setInterval(() => {
                    if ( count === 0 ) {
                        window.clearInterval(this.redirectInterval);
                        this.redirectInterval = null;

                        history.push("/login")
                        return;
                    }

                    count--;

                    this.setNotifAlertData({
                        status: "success",
                        text: "Password updated. You wil be redirected to login page in "+count+"s",
                        timeout: 3000
                    })
                    
                }, 1000)

            }).catch(err => {
                var error = err.error.toString();
                
                this.setState({
                    loadingSave: false
                })

                this.setNotifAlertData({
                    status: "error",
                    text: "Reset password failed. "+error,
                    timeout: 3000
                })
            });
    }

    NewPass() {
        return (
            <React.Fragment>
                {/* <img className="button-back" src={back} alt="icon-back" onClick={() => this.props.history.go(-1)} /> */}

                <div className="form-wrapper">
                    {this.state.notifAlertData !== null &&
                        <NotifAlert
                            data={this.state.notifAlertData}
                        />
                    }
                    <p className="onboard-header" style={{ marginTop:"60px"}}>Reset Password</p>
                    <p className="onboard-header-span" style={{ marginBottom:"60px"}}>Let’s create your new password</p>
                    <form onSubmit={this.handleSubmit}>
                        <div className="form-input-onboard">
                            <input
                                alt="input-new-password"
                                ref={this.passInput}
                                type={this.state.type}
                                id="password"
                                placeholder=" "
                                onChange={this.handlePasswordChange} 
                            />
                            <label onClick={() => this.passInput.current.focus()}>New Password</label>
                            <span className="password-show" onClick={this.showHide}>
                                {this.state.type === "text" ? (
                                    <img src={eye} className="" alt="hide-password" />
                                ) : (
                                    <img src={eyeoff} className="" alt="show-password" />
                                )}
                            </span>
                        </div>
                        <div className="form-input-onboard">
                            <input
                                alt="input-new-password-confirm"
                                ref={this.passInputConfirm}
                                type={this.state.typeConfirm}
                                id="password-confirm"
                                placeholder=" "
                                onChange={this.handlePasswordConfirmChange} 
                            />
                            <label onClick={() => this.passInputConfirm.current.focus()}>Confirm New Password</label>
                            <span className="password-show" onClick={this.showHideConfirm}>
                                {this.state.typeConfirm === "text" ? (
                                    <img src={eye} className="" alt="hide-password" />
                                ) : (
                                    <img src={eyeoff} className="" alt="show-password" />
                                )}
                            </span>
                        </div>

                        { this.state.loadingSave ?
                            <button className="submit-button-onboard">
                                <Spinner
                                    as="span"
                                    animation="border"
                                    variant="light"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            </button>
                            :
                            <button type="submit" className="submit-button-onboard" id="submit" disabled={this.state.password.length !== 0 ? false : true}>
                                {/* Create */}
                                Save
                            </button>
                        }
                    </form>
                </div>
            </React.Fragment>
        );
    }


    render() {
        return (
            <React.Fragment>
                <Container fluid className="no-padding">
                    <Row className="onboard-container">
                        <Col sm={5} className="onboard-form">
                            {this.NewPass()}
                        </Col>
                        <Col sm={7} className="onboard-slider">
                            <div className="slider-wrapper">
                                <SlideShow />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </React.Fragment>
        )
    }
}

export default NewPassword